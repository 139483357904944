import React from 'react';
import axios from 'axios';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import Layout from '../../components/Layout';
import Portlet from '../../components/Portlet';
import DataTable from '../../components/DataTable';
import LoadingPage from '../../components/Layout/LoadingPage';
import {ToastContainer} from 'react-toastr';
import { Modal, Form,FormControl, FormGroup, Button } from 'react-bootstrap';
import '../../assets/css/toastr.css';

class UserProfile extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            id: "",
            meta: {},
            paramsFilter: "[]",
            showLoading: false,
            profile: {},
            data: [],
            reload: false,
            total: 0,
            showModal: false,
            submiting: false,
            email: '',
            userName: '',
            error: {},
        }
    }

    componentDidMount(){
        let {t, match} = this.props;
        let _this = this
        if(match.hasOwnProperty("params") && match.params.hasOwnProperty("id")){
            this.setState({showLoading: true})
            let {id} = match.params
            axios.get(apis.BASE_URL + 'users/' + id + '/detail', utils.getConfig())
            .then( res => {
                let total = res.data.items.hasOwnProperty("info") && res.data.items.info.hasOwnProperty("_meta") && res.data.items.info._meta.hasOwnProperty("totalCount") && res.data.items.info._meta.totalCount
                _this.setState({
                    id: id,
                    showLoading : false,
                    meta: res.data.items.hasOwnProperty("info") ? res.data.items.info._meta : {},
                    data: res.data.items.hasOwnProperty("data") ? res.data.items.data : {},
                    profile: res.data.items.hasOwnProperty("profile") ? res.data.items.profile : {},
                    total: total
                })
            })
            .catch( error => {
                _this.setState({showLoading: false});
                _this.refs.toastContainer.error(t("Cannot get user detail"));
            });
        }

    }

    changeMeta(meta, filter = false){
        const {t} = this.props
        let {id} = this.state
        let _this= this

        this.setState({showLoading: true})
        //define str to handle api
        filter = filter !== "" ? `["and",${filter},{"e_user__id":"${id}"}]` : `{"e_user__id":"${id}"}`
        let sort = meta.sortInfo ? '{"'+meta.sortInfo.property+'":"SORT_'+meta.sortInfo.direction+'"}' : '{"created_at":"SORT_DESC"}'
        let str = 'projects/list/'+meta.currentPage+'/'+meta.perPage+'/'+sort+'/'+filter
        axios.get(apis.BASE_URL + str, utils.getConfig())
        .then( res => {
            _this.setState({
                showLoading : false,
                meta: res.data._meta,
                data: res.data.items
            })
        })
        .catch( error => {
            _this.setState({showLoading : false});
            _this.refs.toastContainer.error(t("Cannot get user detail"));
        });
    }

    openModal(userId, modalStatus){
        this.setState({showModal: true})
    }

    hideModal(){
        this.setState({showModal: false, error: {}})
    }

    handleChange(event){
      let name = event.target.name
      let value = event.target.value
        this.setState({
          [name]: value,
          error: {}
        })
    }

    onBlurEmail(){
      const {email, error} = this.state
      if (email === '' || email === null) {return;}
      axios.get(apis.CENTER_URL + 'services/check-email?toemail='+email.toString().trim())
  		.then( res => {
        if (res.data.result && res.data.result === "valid") {
          error.emailIncorrect = false
          this.setState({error})
        }else {
          error.emailIncorrect = true
          this.setState({error})
        }
      })
    }

    onBlurUserName(){
  		let format = /[ àảãáạăằẳẵắặâầẩẫấậÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬđĐèẻẽéẹêềểễếệÈẺẼÉẸÊỀỂỄẾỆìỉĩíịÌỈĨÍỊòỏõóọôồổỗốộơờởỡớợÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢùủũúụưừửữứựÙỦŨÚỤƯỪỬỮỨỰỳỷỹýỵỲỶỸÝỴ!@#$%^&*()+\-=\]{};':"\\|,<>?]+/;
  		let error = this.state.error;

  		if(this.state.userName !== ""){
  			error.userIncorrect = this.state.userName.toString().trim().length <5;
  			error.userCharacter = format.test(this.state.userName);
  		}
  		else{
  			error.userIncorrect = false;
  			error.userCharacter = false;
  		}

  		this.setState({error})
  	}

    onSubmit(){
      let {id, userName, email, profile, error} = this.state
      const {t} = this.props
      let data = {
          'user_id': id,
          'username': userName,
          'email': profile.email === email ? "" : email
      }
      let validate = false;
      Object.keys(error).map((e, i) => {
          if(error[e]){
            validate = true;
          }
          return validate;
      });

      if(validate){
        this.setState({error})
        return;
      }

      let _this = this;
      _this.setState({submiting: true})
      if (email === '' || email === null) {
        axios.post( apis.BASE_URL + 'users/update-client-user', data, utils.getConfig())
        .then( res => {
          _this.refs.toastContainer.success('Success')
          _this.setState({submiting: false})
          setTimeout(function(){ _this.setState({reload: true}) }, 1000);
        }).catch( error => {
          if(error.response){
            console.log(error.response);
            _this.refs.toastContainer.error(error.response.data.errors.username)
          }
          _this.setState({submiting: false})
        });
      }else {
        axios.get(apis.CENTER_URL + 'services/check-email?toemail='+email.toString().trim())
        .then( res => {
          if (res.data.result && res.data.result === "valid") {
            axios.post( apis.BASE_URL + 'users/update-client-user', data, utils.getConfig())
            .then( res => {
              _this.refs.toastContainer.success('Success')
              _this.setState({submiting: false})
              setTimeout(function(){ _this.setState({reload: true}) }, 1000);
            }).catch( error => {
              if(error.response){
                _this.refs.toastContainer.error(error.response.data.errors.username)
              }
              _this.setState({submiting: false})
            });
          } else {
            let error = {emailIncorrect: true}
            _this.setState({error, submiting: false})
          }
        })
      }
    }

    render(){
        const {t} = this.props
        let {showLoading, profile, data, meta, total, showModal, id, submiting, email, userName, error, reload} = this.state
        if(reload === true){
          window.location.reload();
        }
        let config = {
            api: true,
            filter:true,
            cols: [
                { property: "proj_url", header: t("Project URL"), sortable: true,
                    filterType: {type: "text"},
                    decorator : () => ({ style : { width : "40%" } }),
                    renderer : value =>  value ? (<a href={value} target="_blank" rel="noopener noreferrer">{value}</a>) : ""
                },
                { property: "status", header: t("Status"), sortable: true,
                    filterType: {type: "number", prefix:"e_user_project__", values: [{label: t("Select all")+"...", value: ""},
                                                        {label: t("Deploy Fail"), value: "-2"},
                                                        {label: t("Inactive"), value: "-1"},
                                                        {label: t("Pause"), value: "0"},
                                                        {label: t("Active"), value: "1"},
                                                        {label: t("In Transaction"), value: "2"},
                                                        {label: t("Paid"), value: "3"},
                                                        {label: t("Deploying"), value: "4"},
                                                        {label: t("Unpaid"), value: "5"},
                                                        {label: t("Verifying Payment"), value: "6"},
                                                        {label: t("Verify Fail"), value: "8"}]},

                    decorator : () => ({ style : {width : "30%",minWidth: "60px"}}),
                    renderer : value => {
                        let className = utils.projectStatusClassName[value]
                        let text = utils.projectStatusText[value]
                        return (<span className={className}>{t(text)}</span>)
                    }
                },
                { property: "created_at", header: t("Created At"), sortable: true,
                    filterType: {type: "date", prefix:"e_user_project__"},
                    decorator : () => ({ style : { width : "30%", minWidth: "75px"} }),
                    renderer : value => utils.convertTimestampToDate(value)
                }
            ]
        }

        return(
            <Layout>
                {showLoading && <LoadingPage />}
                    <div className="m-subheader ">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h3 className="m-subheader__title">{t("User Profile")}</h3>
                            </div>
                        </div>
                    </div>
                <Layout.Content>
                    <div className="row">
                        <div className="col-xl-3 col-lg-4">
                            <div className="m-portlet m-portlet--full-height">
                                <div className="m-portlet__body">
                                    <div className="m-card-profile">
                                        <div className="m-card-profile__pic">
                                            <div className="m-card-profile__pic-wrapper">
                                                <img src="/assets/img/users/user.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div className="m-card-profile__details">
                                            <span className="m-card-profile__name">{(profile.hasOwnProperty("firstName") ? profile.firstName : "") + " " + (profile.hasOwnProperty("lastName") ? profile.lastName : "")}</span>
                                            <a href={`mailto:${profile.email}`} className="m-card-profile__email m-link">{profile.email}</a>
                                        </div>
                                    </div>
                                    <ul className="m-nav m-nav--hover-bg m-portlet-fit--sides">
                                        <li className="m-nav__separator m-nav__separator--fit"></li>
                                        <li className="m-nav__item">
                                            <span className="m-nav__link">
                                                <i className="m-nav__link-icon flaticon-profile-1"></i>
                                                <span className="m-nav__link-text">{t('Phone Number')}: {profile.phone}</span>
                                            </span>
                                        </li>
                                        <li className="m-nav__item">
                                            <span className="m-nav__link">
                                                <i className="m-nav__link-icon flaticon-share"></i>
                                                <span className="m-nav__link-text">{t('Address')}: {profile.address}</span>
                                            </span>
                                        </li>
                                        <li className="m-nav__item">
                                            <span className="m-nav__link">
                                                <i className="m-nav__link-icon flaticon-chat-1"></i>
                                                <span className="m-nav__link-text">{t('City')}: {profile.city}</span>
                                            </span>
                                        </li>
                                        <li className="m-nav__item">
                                            <span className="m-nav__link">
                                                <i className="m-nav__link-icon flaticon-chat-1"></i>
                                                <span className="m-nav__link-text">{t('Country')}: {profile.country}</span>
                                            </span>
                                        </li>
                                        <li className="m-nav__item">
                                            <span className="m-nav__link">
                                                <i className="m-nav__link-icon flaticon-chat-1"></i>
                                                <span className="m-nav__link-text">{t('Company')}: {profile.company}</span>
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="m-portlet__body-separator"></div>
                                    <div className="m-widget1 m-widget1--paddingless">
                                        <div className="m-widget1__item">
                                            <div className="row m-row--no-padding align-items-center">
                                                <div className="col">
                                                    <h3 className="m-widget1__title">{t('Project')}</h3>
                                                </div>
                                                <div className="col m--align-right">
                                                    <span className="m-widget1__number m--font-brand">{total}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="m-widget1__item">
                                            <div className="row m-row--no-padding align-items-center">
                                                <div className="col">
                                                    <h3 className="m-widget1__title">{t('Balance')}</h3>
                                                </div>
                                                <div className="col m--align-right">
                                                {
                                                    profile.accountBalance > 0 ?
                                                    <span className="m-widget1__number m--font-danger">- {utils.handleCurrency(profile.accountBalance)}</span> :
                                                    <span className="m-widget1__number m--font-success">{utils.handleCurrency(Math.abs(profile.accountBalance))}</span>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-card-profile">
                                    <div className="m-card-profile__pic">
                                        <button key="btn-add" className="btn btn-outline-accent m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air" onClick={this.openModal.bind(this, id, 1)}><i className="flaticon-edit"></i></button>
                                    </div>
                                </div>
                          </div>
                        </div>
                        <div className="col-xl-9 col-lg-8">
                            <Portlet >
                                <Portlet.Title>
                                    <Portlet.Caption>
                                        <h3 className="m-portlet__head-text">{t('Project List')}</h3>
                                    </Portlet.Caption>
                                </Portlet.Title>
                                <Portlet.Body>
                                    <DataTable t={t} className="margin-top--60" datasource={data} config={config} meta={meta} changeMeta={this.changeMeta.bind(this)}/>
                                </Portlet.Body>
                            </Portlet>
                        </div>
                    </div>
                </Layout.Content>
                <Modal
                    id="e_modal_add_credit"
                    show={showModal}
                    onHide={this.hideModal.bind(this)}
                    dialogClassName="modal-90w"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                          {t("Edit profile")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                          <FormGroup>
                            <Form.Label>{t('Email')}</Form.Label>
                            <Form.Control type="text" placeholder={t("Enter new email")} name="email" value={email} onChange={this.handleChange.bind(this)} onBlur={this.onBlurEmail.bind(this)}/>
                            {error.emailIncorrect ? <div className="form-control-feedback">{t('Email is invalid or already in use')}</div> : null}
                            {/* <p className="error-msg">{error ? error : null}</p> */}
                            <Form.Label>{t('User name')}</Form.Label>
                            <FormControl type="text" placeholder={t("Enter new user name")} name="userName" value={userName} onChange={this.handleChange.bind(this)} onBlur={this.onBlurUserName.bind(this)}/>
                            {error.userIncorrect ? <div className="form-control-feedback">{t('At least 5 characters')}.</div>
          								  : error.userCharacter ? <div className="form-control-feedback">{t('Cannot contain special characters')}</div> : null}
                            <div className="toastr-btn-confirm">
                                <Button variant="success" className={submiting?"m-loader m-loader--light m-loader--right":null} onClick={this.onSubmit.bind(this)}>{t('Submit')}</Button>
                                <Button variant="secondary" className="m--margin-left-10" onClick={this.hideModal.bind(this)}>{t('Cancel')}</Button>
                            </div>
                          </FormGroup>
                      </Form>
                    </Modal.Body>
                </Modal>
                <ToastContainer
                    ref="toastContainer"
                    className="toast-top-right"
                />
            </Layout>
        );
    }
}

export default UserProfile;
