import React from 'react';
import '../../components/DataTable/assets/css/datatable.css';
import { Modal } from 'react-bootstrap';
import DataTable from '../../components/DataTable';

class BillingDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			meta: {
				perPage: 10,
				currentPage: 1
			}
		}
	}

	hideModal () {
		this.props.onHideModal()
	}

	changeMeta(meta) {
		this.setState({ meta })
	}

	render() {
		const { t, show, users } = this.props;
		const { meta } = this.state,
			invoiceNumber = "",
			config = {
				api: false,
				sortDefault: {
					property: "invoiceDate",
					direction: "DESC"
				},
				cols: [
					{
						property: "executed_time",
						sortable: false,
						display: "none",
						renderer: value => {
							invoiceNumber = value
						}
					},
					{
						property: "usage_data", header: t("Username"),
						sortable: true,
						decorator: () => ({ style: { width: "20%" } }),
						renderer: value => <span className="m--font-bold">{JSON.parse(value).username}</span>
					},
					{
						property: "executed_time", header: t("Executed time"),
						sortable: true,
						decorator: () => ({ style: { width: "20%" } }),
						renderer: value => <span className="m--font-bold">{value}</span>
					},
				]
			}

		return (
			<Modal show={show} onHide={this.hideModal.bind(this)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('List users')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="dataTables_wrapper dt-bootstrap4">
						<div className="overTableScrollX">
							<DataTable datasource={users}
								config={config}
								meta={meta}
								changeMeta={this.changeMeta.bind(this)}
								t={t} />
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default BillingDetail;
