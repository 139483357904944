import React from 'react';
import '../../components/DataTable/assets/css/datatable.css';

class HistoryRow extends React.Component{
  constructor(props){
      super(props);
      this.state = {

      }
  }

	render(){
		return (
      <tr data-row="0" className="m-datatable__row " >
        <td data-field="proj_name" className="m-datatable__cell" >
          <span className="">test</span>
        </td>
        <td data-field="proj_name" className="m-datatable__cell" >
          <span className="">test</span>
        </td>
        <td data-field="proj_name" className="m-datatable__cell" >
          <span className="">test</span>
        </td>
        <td data-field="proj_name" className="m-datatable__cell" >
          <span className="">test</span>
        </td>
        <td data-field="proj_name" className="m-datatable__cell" >
          <span className="">test</span>
        </td>
      </tr>
			);
	}
}

export default HistoryRow;
