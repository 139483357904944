import React,{Fragment} from 'react';
import { View,Text,Font,Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import axios from 'axios';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import fontThin from "../../roboto/Roboto-Thin.ttf";
import fontBold from "../../roboto/Roboto-Bold.ttf";
import fontMe from "../../roboto/Roboto-Medium.ttf";

Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: fontThin
      },
      {
        src: fontBold,
        fontWeight: 'bold'
      },
      {
        src: fontMe,
        fontWeight: 'normal',
      },
      {
        src: fontThin,
        fontWeight: 'bold',
        fontStyle: 'italic'
      }
    ]
  })
const borderColor = '#f4f5f8';
const styles = StyleSheet.create({
    page: {
        // padding:20,
        // paddingBottom: 65,
        fontSize: 11,
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 20,
    }, 
    header:{
        flexDirection: 'row',
    },
    reportTitle:{
        fontSize: 25,
        width: '50%',
        textAlign:'center',
        paddingTop:35,
        fontFamily:'Roboto',
        fontWeight: 'bold',
    }, 
    logo: {
        width: 150,
        height: 70,
    },
    textlogo:{
        width: '50%',
        textAlign:'center'
    },
    invoiceNoContainer: {
        flexDirection: 'row',
        paddingRight:65,
        justifyContent: 'flex-end',
        fontFamily:'Roboto',
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate1: {
        fontSize: 12,
        fontFamily:'Roboto',
    },
    headerContainer2: {
        flexDirection: 'row',
        paddingLeft:60,
        marginTop: 5,
    },
    headerContainer3: {
        marginTop: 20,
        borderTop: '1 solid #ebedf2', 
    },
    billTo1: {
        width:'25%',
        textAlign:"left",
        paddingBottom: 3,
        fontFamily:'Roboto',
        fontWeight: 'bold',
    },
   
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
        textAlign:"left",
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#f4f5f8',
    },
    container: {
        flexDirection: 'row',
        borderBottomColor: '#f4f5f8',
        backgroundColor: '#e8eced',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        flexGrow: 1,
        color: '#337ab7',
        fontSize: 8,
        fontFamily:'Roboto',
        fontWeight:'bold',
        
    },
    description1: {
        width: '35%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textTransform: 'uppercase',
        padding:5,
       
    },
    project1: {
        width: '32%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textTransform: 'uppercase',
        padding:5,
    },
    start1: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textTransform: 'uppercase',
        padding:5,
    },
    amount1: {
        width: '13%',
        textTransform: 'uppercase',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        padding:5,
    },
    number1: {
        width: '10%',
        textTransform: 'uppercase',
        padding:5,
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#f4f5f8',
        borderBottomWidth: 1,
        // alignItems: 'center',
        margin: 'auto',
        fontFamily:'Roboto',
        fontSize: 8,
    },
    description: {
        width: '35%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        padding:'5 5',
        textAlign:'center',
    },
    project: {
        width: '32%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        padding:'5 2',
        textAlign:'center',
    },
    start: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        padding:'auto 5',
        textAlign:'center',
    },
    amount: {
        width: '13%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        padding:'5 2',
        textAlign:'center',
    },
    number: {
        width: '10%',
        padding:'5 5',
        textAlign:'center',
    },
    headerContainer: {
        flexDirection: 'row',
        marginTop: 36,
        paddingLeft:60,
        
    },
    billTo: {
        width: '60%',
        textAlign: 'right',
        fontFamily:'Roboto',
        fontWeight: 'bold',
    },
   
    invoiceDate: {
        paddingLeft: 20,
        flexDirection: 'row',
        width: '40%',
        textAlign: 'left',
        fontSize: 12,
        fontFamily:'Roboto',
        fontWeight: 'bold',
    },
  });

class Invoice extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data: [],
        }
    }
    componentWillMount() {
        // let _this = this;
        // const { t } = this.props
        // let config = utils.getConfig();
        // axios.get(apis.BASE_URL + 'users/invoice/' + this.props.invoice, config)
        //     .then(res => {
        //         let listBilling = []
        //         let test =res.data.items.items.length
        //         let res_temp=res.data.items
        //         if(test>0){
        //             res.data.items.items.map((item,index) => {
            
        //                 if (item.itemType === 'USAGE') {
        //                 axios.get('https://rtcenter.org/services/ecommerce-workspace-addon', { params: { name: item.planName, currency: localStorage.getItem("currency") } })
        //                     .then(res => {
        //                     let status = res.data.status
        //                     if (status === 'success') {
        //                         item.newPlanName = res.data.msg[0].content.title
        //                     }
        //                     listBilling.push(item)
        //                     if(test-1 ===index){
        //                         res_temp.items=listBilling
        //                         _this.setState({ data: res_temp })
        //                         _this.props.checkInvoice('success')
        //                     }
        //                     })
        //                 } else {
        //                 axios.get('https://rtcenter.org/services/ecommerce-workspace-plan', { params: { name: item.planName, currency: localStorage.getItem("currency") } })
        //                     .then(res => {
        //                     item.newPlanName = res.data.msg[0].content.title;
        //                     listBilling.push(item)
        //                     if(test-1 ===index){
        //                         res_temp.items=listBilling
        //                         _this.setState({ data: res_temp })
        //                         _this.props.checkInvoice('success')
        //                     }
        //                     })
        //                     .catch(error => {
        //                     console.log(error)
        //                     });
        //                     }
        //                 })
        //         }else{
        //             _this.setState({
        //                 data: res.data.items})
        //                 _this.props.checkInvoice('success')
        //         }
                
                
        //     })
        //     .catch(error => {
        //         _this.props.checkInvoice('error')
        //     });
        let _this = this;
        const { t } = this.props
        let config = utils.getConfig();
        axios.get(apis.BASE_URL + 'users/invoice/' + this.props.invoice, config)
            .then(res => {
                _this.setState({
                    data: res.data.items,
                    
                });
                _this.props.checkInvoice('success')
            })
            .catch(error => {
                _this.props.checkInvoice('error')
            });
      
    }
    render() {
        let {data}=this.state
        let {t}=this.props
        let rows=null
        if(data.items!= undefined && data.items.length > 0){
            rows = data.items.map( item => 
                    <View wrap style={styles.row} key={item.invoiceItemId.toString()}>
                        <Text style={styles.description}>{item.planName === null || item.planName === '' ? '' : utils.handleLanguageModule(item.planName)}</Text>
                        <Text style={styles.project}>{item.projectUrl}</Text>
                        <Text style={styles.start}>{item.startDate}</Text>
                        <Text style={styles.amount}>{utils.handleCurrency(item.amount)}</Text>
                        <Text style={styles.number}>{item.sum_user}</Text>
                    </View>
                )
        }
        return (
            <Document>
                <Page wrap style={styles.page}>
                    <View style={styles.header}>
                        <Text style={styles.reportTitle}>{t('Invoice')} {data.invoiceNumber}</Text>
                        <Text style={styles.textlogo}><Image style={styles.logo} src="/rta.png" /></Text>
                    </View>

                    <View style={styles.invoiceNoContainer}>
                        <Text style={styles.label}>{t('45 Vo Thi Sau Da Kao')}</Text>
                    </View >
            
                    <Text style={styles.headerContainer3}></Text>
                    <View style={styles.headerContainer}>
                        <Text style={styles.billTo1}>{t('INVOICE DATE')} </Text>
                        <Text style={styles.invoiceDate1}>{data.invoiceDate}</Text>
                    </View>
                    <View style={styles.headerContainer2}>
                        <Text style={styles.billTo1}>{t('TARGET DATE')} </Text>
                        <Text style={styles.invoiceDate1}>{data.targetDate}</Text>
                    </View>
                    
                    <View style={styles.tableContainer}>
                        <View style={styles.container}>
                            <Text style={styles.description1}>{t('DESCRIPTION')}</Text>
                            <Text style={styles.project1}>{t('PROJECT')}</Text>
                            <Text style={styles.start1}>{t('START DATE')}</Text>
                            <Text style={styles.amount1}>{t('AMOUNT')}</Text>
                            <Text style={styles.number1}>{t("NUMBER OF USERS")}</Text>
                        </View>
                        <Fragment>{rows}</Fragment>
                    </View>
                    				
                    <View style={styles.headerContainer}>
                        <Text style={styles.billTo}>{t('INVOICE TOTAL')}: </Text>
                        <Text style={styles.invoiceDate}>{utils.handleCurrency(data.amount)}</Text>
                    </View>
                    <View style={styles.headerContainer2}>
                        <Text style={styles.billTo}>{t('CREDITS')}: </Text>
                        <Text style={styles.invoiceDate}>{utils.handleCurrency(data.creditAdj)}</Text>
                    </View>
                    <View style={styles.headerContainer2}>
                        <Text style={styles.billTo}>{t('REFUNDED')}: </Text>
                        <Text style={styles.invoiceDate}>{utils.handleCurrency(data.refundAdj)}</Text>
                    </View>
                    <View style={styles.headerContainer2}>
                        <Text style={styles.billTo}>{t('BALANCE')}: </Text>
                        <Text style={styles.invoiceDate}>{utils.handleCurrency(data.balance)}</Text>
                    </View>
                </Page>
            </Document>
         );
        }
    }
        
  export default Invoice