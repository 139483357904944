import React from 'react';

class RTCell extends React.Component {
  
    renderElement( value, rowIdx ) {
        let renderer = this.props.col.renderer;
        
        if ( typeof renderer === 'function' ) {
            try {
                return renderer.call(null, value, rowIdx);
            } catch ( e ) {
                return null;
            } 
        }
    }

    cellDecoration(value) {
        let decorator =  this.props.col.decorator;
        let decor = null;
        let style = {};
        let cellStyle = {};
        let className = "";
        let cellClassName = "";
        
        if ( typeof decorator === 'string' ) {
            className = decorator;
        } else if ( typeof decorator === 'function' ) {
            try {
                decor = decorator.call();

                if ( typeof decor === 'string' ) {
                    className = decor;
                } else  {
                    className = decor.className || "m-datatable__cell";
                    style = decor.style || {};
                    cellClassName = decor.cellClassName || "";
                    cellStyle = decor.cellStyle || {};
                }
            } catch ( e ) {
                throw new Error("decorator in invalid");
            }
        }

        return {
            style : style,
            cellStyle : cellStyle,
            className : className,
            cellClassName : cellClassName
        }
    }

    render(){
        const {record, col, rowIdx} = this.props;
        let renderedValue = record[col.property];
        
        if ( col.renderer ) {
            renderedValue = this.renderElement( renderedValue, rowIdx );
        }
        let decoration = this.cellDecoration(record[col.property]);

        if(col.display === "none"){
            return null
        } 
        return (
            <td data-field={col.property} className={decoration.className} style={decoration.style}>
                <span className={decoration.cellClassName} style={decoration.cellStyle}>{renderedValue}</span>
            </td>
        );
    }
}

export default RTCell;