import React from 'react';

class LeftBanner extends React.Component{

	render(){
		const {t} = this.props
		return(
				<div className="m-login__wrapper-1 m-portlet-full-height ">
					<div className="m-login__wrapper-1-1">
						<div className="m-login__contanier">
							<div className="m-login__content">
								<div className="m-login__logo">
									<a href="/">
										<img alt="" src="/rta.png" style={{width: "80%"}}/>
									</a>
								</div>
								<div className="m-login__title">
									<h3>{t('JOIN RTWORKSPACE COMMUNITY')}. {t('RUN YOUR RTWORKSPACE IN REAL-TIME')}</h3>
								</div>
							</div>
						</div>
						<div className="m-login__border">
							<div></div>
						</div>
					</div>
				</div>
			);
	}
}

export default LeftBanner;
