import React from 'react';
import { Link } from 'react-router-dom';

class SubItem extends React.Component{
	render(){
		const {t, path, pt, value, flaticon} = this.props;
		return(
			<li className={path === pt ? "m-menu__item m-menu__item--active" : "m-menu__item"} aria-haspopup="true">
                <Link to={pt} className="m-menu__link" replace>
                    <i className={flaticon}><span></span></i>
                    <span className="m-menu__link-text">{t(value)}</span>
                </Link>
            </li>
			);
	}
}

export default SubItem;