import React from 'react';
import axios from 'axios';
import {Form, Row, Col} from 'react-bootstrap';
import WizardNav from './WizardNav';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import Portlet from '../../components/Portlet';
import LoadingPage from '../../components/Layout/LoadingPage';
import { Redirect } from 'react-router-dom';
import {ToastContainer} from 'react-toastr';
import '../../assets/css/toastr.css';


class WizardFormSecondStep extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            attachName: "",
            attachment: "",
            error: false,
            transIdEmpty: false,
            showLoading: false,
            redirectListPage: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.saveDraft = this.saveDraft.bind(this)
    }

    handleChange(ev) {
        this.props.onChange(ev.target.name, ev.target.value);
        if(ev.target.name === "circle"){
            this.props.onChange("method", "");
        }
    }

    async handleChangeFile(ev){
        let _this = this;
        let file = ev.target.files[0];
        if(file){
            await utils.readUploadedFileAsBinaryString(file)
            .then( result => {
                let base64String = window.btoa(result);
                _this.setState({attachment: base64String, attachName: file.name})
            })
            .catch( error => {
                console.log(error)
            })
        }
    }

    onSubmit() {
        let _this = this
        let {attachment} = this.state
        let {t, nextPage, projectId, projectName, region, plan, balance, cyclePrice, phasePrice, couponPrice, couponApplied, circle, method, transactionId, price} = this.props
        if(method.length === 0) {
            this.setState({error: true, transIdEmpty: false})
            return false
        }
        if(method === "bank_transfer" && transactionId.length === 0) {
            this.setState({error: false, transIdEmpty: true})
            return false
        }
        let amount = price*circle
        let discount = 0
        if(couponPrice > 0){
            discount = (couponPrice > amount) ? amount : couponPrice
            amount = amount - discount
        }
        if(balance > 0){
            amount += balance
        }

        let paymentMethod = method
        let phaseName = ""
        if (method === "trial") {
            if (phasePrice > 0 && (balance >= 0 || ((Math.abs(balance) - parseInt(phasePrice)) < 0) ) ) {
                this.refs.toastContainer.warning(t("Your balance is not enough to pay") + ". "  + t("Please recharge your account"))
                return false;
            }
            amount = phasePrice
            paymentMethod = "balance"
            phaseName = "TRIAL"
        } else if(method === "promo"){
            amount = 0
            paymentMethod = "balance"
        }

        let data = {
            "project_id": projectId,
            "payment_method": paymentMethod,
            "amount": amount,
            "banking_transaction_number" : transactionId,
            "banking_transaction_file" : attachment,
            "coupon_code" : couponApplied,
            "quantity" : circle,
            "phase_name": phaseName
        }
        this.setState({showLoading: true})
        let config = utils.getConfig();
        if (method === "trial") {
            let postData = {
                project_name: projectName,
                node_region: region,
                plan: plan+"_trial"
            }
            axios.put(apis.BASE_URL + 'projects/' + projectId, postData, config)
            .then( res => {
                data.coupon_code = ""
                axios.post(apis.BASE_URL + 'payment/payment-getway', data, config)
                .then( () => nextPage())
                .catch( error => {
                    _this.setState({showLoading: false})
                    if(error.response){
                        _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))
                    } else {
                       _this.refs.toastContainer.error(t('Error payment gateway'))
                    }
                });
            })
            .catch( error => {
                _this.setState({showLoading: false})
               if(error.response){
                   _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))
               }
               else{
                   _this.refs.toastContainer.error(t('Error payment gateway'))
               }
            });
        } else {
            axios.post(apis.BASE_URL + 'payment/payment-getway', data, config)
            .then( res => {
               if(method === "paypal") {
                   window.location.replace(res.data.items)
               } else {
                   nextPage()
               }
            })
            .catch( error => {
                _this.setState({showLoading: false})
                if(error.response){
                   _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))
               }
               else{
                   _this.refs.toastContainer.error(t('Error payment gateway'))
               }
            });
        }
    }

    saveDraft(){
        this.setState({redirectListPage: true})
    }

    render(){
        const {t, balance, cyclePrice, priceDesc, phasePrice, couponPrice, projectName, planName, planCategory, previousPage, circle, method, transactionId, price, projectDomain}= this.props
        let {attachName, error, transIdEmpty, attachment, redirectListPage, showLoading} = this.state
        if(redirectListPage){
            return (<Redirect to="/"/>);
        }
        let displayBalance = utils.getDisplayBalance(balance)
        let total = price*circle
        let discount = 0, unusePromoAmount = 0, enableBalance = false, enablePromo = false
        if(couponPrice > 0){
            discount = (couponPrice > total) ? total : couponPrice
            enablePromo = (couponPrice > total)
            unusePromoAmount = (couponPrice > total) ? (couponPrice - total) : 0
            total = total - discount
        }

        if(balance > 0){
            // owing
            total += balance
            enableBalance = (parseInt(total) < 0)
        } else {
            enableBalance = (Math.abs(balance) - parseInt(total) >= 0)
        }

        let leftPanelCls = (phasePrice >= 0 && method === "trial") ? " disabled" : ""
		return(
            <div className="m-wizard m-wizard--2 m-wizard--success" id="m_wizard">
                {showLoading && <LoadingPage />}
                <WizardNav t={t} step={2} />
                <div className="m-wizard__form">
                    <div className="m-form m-form--label-align-left- m-form--state-">
                        <div className="m-wizard__form-step m-wizard__form-step--current" id="m_wizard_form_step_2">
                            <div className="row m-form__group">
                                <div className={"col-lg-6 col-md-6 col-sm-12 order-information" + leftPanelCls}>
                                    <Portlet>
                                        <Portlet.Title>
                                            <Portlet.Caption>
                                                <h3 className="m-portlet__head-text">{t("Order")}: rtWorkspace {planName} {planCategory}</h3>
                                            </Portlet.Caption>
                                        </Portlet.Title>
                                        <Portlet.Body>
                                            <div className="overlay"></div>
                                            {/* <Form.Group as={Row}>
                                                <Form.Label column sm={9}>{t('Number of Payment Cycles')}</Form.Label>
                                                <Col sm={3}>
                                                    <Form.Control as="select" name="circle" value={circle} onChange={this.handleChange}>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                        <option>6</option>
                                                        <option>7</option>
                                                        <option>8</option>
                                                        <option>9</option>
                                                        <option>10</option>
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>
                                            <hr/> */}
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={9} className="text-word-break">https://{projectName}.{projectDomain}</Form.Label>
                                                <Form.Label column sm={3} className="text-right">
                                                    - {utils.handleCurrency(price)}
                                                </Form.Label>
                                            </Form.Group>
                                            <hr/>
                                            {
                                                balance > 0 ?
                                                <div>
                                                    <label>{t('Curent Balance')}</label> <span className="price">- {utils.handleCurrency(balance)}</span>
                                                    <hr/>
                                                </div>
                                                : null
                                            }
                                            <div><label>{t('Promo Code')}</label><span className="price">{discount > 0 ? "+ " + utils.handleCurrency(discount) : utils.handleCurrency(0)}</span></div>
                                            {
                                                unusePromoAmount > 0 ?
                                                <div>
                                                     <hr/>
                                                    <label>{t('Unused Promo Amount')}</label><span className="price">{utils.handleCurrency(unusePromoAmount)}</span>
                                                    <p><small>{t("If you do not use up your promotional money, it will be lost")}</small></p>
                                                </div>
                                                :null
                                            }
                                            <hr/>
                                            <div><label>{t('Total Amount')}</label> <span className={total > 0 ? "price red" : "price green"}>{total > 0 ? "- " + utils.handleCurrency(total) :  utils.handleCurrency(Math.abs(total))}</span></div>

                                        </Portlet.Body>
                                    </Portlet>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <Portlet>
                                        <Portlet.Title>
                                            <Portlet.Caption>
                                                <h3 className="m-portlet__head-text">{t("Payment Methods")}</h3>
                                            </Portlet.Caption>
                                        </Portlet.Title>
                                        <Portlet.Body>
                                             <div className="m-radio-list payment-methods">
                                                {
                                                    enablePromo ?
                                                    <label className="m-radio promo-method">
                                                        <input type="radio" name="method" value="promo" checked={method === "promo"} onChange={this.handleChange} />
                                                        <p>{t('Promo Code Methods')}</p>
                                                        <span className="m--margin-top-10"></span>
                                                    </label> : null
                                                }
                                                {
                                                    phasePrice >= 0 ?
                                                    <label className="m-radio trial-method">
                                                        <input type="radio" name="method" value="trial" checked={method === "trial"} onChange={this.handleChange} />
                                                        <p>{t('Trial Methods')}</p>
                                                        <span className="m--margin-top-10"></span>
                                                    </label> : null
                                                }
                                                <label className={enableBalance ? "m-radio balance-account" : "m-radio balance-account disabled"}>
                                                    <input type="radio" name="method" value="balance" disabled={!enableBalance} checked={method === "balance"} onChange={this.handleChange}/>
                                                    <p>{t('Your Balance')} ({displayBalance})</p>
                                                    <span className="m--margin-top-10"></span>
                                                </label>
                                                <label className="m-radio paypal-account">
                                                    <input type="radio" name="method" value="paypal" checked={method === "paypal"} onChange={this.handleChange} /><img src="https://www.paypalobjects.com/webstatic/mktg/logo/PP_AcceptanceMarkTray-NoDiscover_243x40.png" alt={t('Pay with Paypal')} />
                                                    <span className="m--margin-top-10"></span>
                                                </label>
                                                <label className="m-radio bank-tranfer">
                                                    <input type="radio" name="method" value="bank_transfer" checked={method === "bank_transfer"} onChange={this.handleChange} />
                                                    <p>{t('Bank Wire Transfer')}</p>
                                                    <span className="m--margin-top-10"></span>
                                                </label>
                                                {error && <p className="error-msg">{t('Please choose a method payment')}.</p>}
                                            </div>
                                            {
                                                method === "bank_transfer" ?
                                                <div className="bank-tranfer-info">
                                                    <div className="m-form__group">
                                                        <div className="account-info">
                                                            <div className="account-info-desc">{t('If you are located in Vietnam please TT to one of our bank accounts below')}</div>
                                                            <div className="account-list">
                                                                <ol>
                                                                    <li>
                                                                        <span>Vietcombank (VCB)</span>
                                                                        <ul>
                                                                            <li>Account No. : 0331000419590 (VND)</li>
                                                                            <li>{t('Beneficiary')}: {t('Real-Time Analytics Company Limited')}</li>
                                                                            <li>{t('Branch')} : {t('Sai Gon Branch')}</li>
                                                                        </ul>
                                                                    </li>
                                                                </ol>
                                                            </div>
                                                            <p className="note"> {t('Note')}: {t('Please write transfer contents')} "{projectName}.{projectDomain}"</p>
                                                        </div>
                                                    </div>

                                                    <div className="m-form__group">
                                                        <label className="form-control-label">{t('Transfer account code')} <span className="required">*</span></label>
                                                        <input type="text" className="form-control" name="transactionId" value={transactionId} onChange={this.handleChange}/>
                                                        {transIdEmpty && <p className="error-msg">{t('Please enter your transfer transaction code')}.</p>}
                                                    </div>

                                                    <div className="m-form__group">
                                                        <label className="form-control-label">{t('Attached photos (optional, help RTA check faster)')}</label>
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" accept="image/*,.pdf" name="attachment" id="customFile" onChange={this.handleChangeFile}/>
                                                            <label className="custom-file-label" htmlFor="customFile">{attachName ? attachName : t('Choose file')}</label>
                                                        </div>
                                                        {attachment === ""? null : <img alt="" className="Img_Bank_Wire_Transfer" src={"data:image/jpeg;base64,"+attachment} />}

                                                    </div>
                                                </div> : null
                                            }
                                        </Portlet.Body>
                                    </Portlet>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between form__actions">
                                <button className="btn btn-metal m-btn m-btn--custom m-btn--icon" onClick={previousPage}>
                                    <span>
                                        <i className="fa fa-arrow-left"></i>
                                        <span>{t('Back')}</span>
                                    </span>
                                </button>
                                <div>
                                    <button className="btn btn-info m-btn m-btn--custom m-btn--icon margin-right_10" onClick={this.saveDraft}>
                                        <span>
                                            <span>{t('Save Draft')}</span>
                                            <i className="la la-save"></i>
                                        </span>
                                    </button>
                                    <button className="btn btn-success m-btn m-btn--custom m-btn--icon" onClick={this.onSubmit}>
                                        <span>
                                            <span>{t('Next')}</span>
                                            <i className="fa fa-arrow-right"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    ref="toastContainer"
                    className="toast-top-right"
                />
            </div>
			);
	}
}

export default WizardFormSecondStep;
