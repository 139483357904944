import React from 'react';
import PaymentUser from './PaymentUser'

class PaymentDate extends React.Component{
	constructor(props){
      super(props);
      this.state = {

      }
  }

	onRecievedUser=()=>{
		this.setState({
			recievedUser: !this.state.recievedUser
		})
	}

	render(){
    const {t} = this.props
		let {recievedUser} = this.state
		return (
      <div>
        <h5>Mon 30/Dec 2019</h5><hr/><br/>
				<PaymentUser t={t} />
      </div>
			);
	}
}

export default PaymentDate;
