import React from 'react';

class Footer extends React.Component
{
	render() {
        const { t } = this.props;
		return (
			<footer className="m-grid__item		m-footer ">
                <div className="m-container m-container--fluid m-container--full-height m-page__container">
                    <div className="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
                        <div className="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
                        <span className="m-footer__copyright">
                            <span>{t('Copyright 2019 by')} <a href="https://cms.rta.vn" className="m-link">{t('Real-Time Analytics')}</a> | {t('All Rights Reserved')}.</span>
                        </span>
                        </div>
                        <div className="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
                            <ul className="m-footer__nav m-nav m-nav--inline m--pull-right">
                            <li className="m-nav__item">
                                <a href="https://cms.rta.vn" className="m-nav__link">
                                    <span className="m-nav__link-text">
                                        {t('Version')} 1.0
                                    </span>
                                </a>
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
		);
	}
}

export default Footer;