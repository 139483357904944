import React from 'react';
import RTColumn from './RTColumn';
import RTBody from './RTBody';
import Pagination from './Pagination';
import './assets/css/datatable.css';

class DataTable extends React.Component {

    constructor(props){
        super(props);

        let {config, datasource} = props;
        let sortInfo = config.sortDefault ? config.sortDefault : {property: "", direction: "ASC"};

        this.state = {
            filterParam: "",
            sortInfo: sortInfo,
            config: config,
            datasource: datasource
        }
    }

    componentWillReceiveProps(nextProps){
        let {datasource} = nextProps;
        this.setState({ datasource: datasource });
    }

    sortData(property){
        let {sortInfo, config} = this.state;
        if(sortInfo.property === property){
            sortInfo.direction = (sortInfo.direction === "ASC") ? "DESC" : "ASC";
        }
        else{
            sortInfo.property = property;
            sortInfo.direction = "ASC";
        }
        this.setState({sortInfo: sortInfo});

        if(config.api !== false){
            this.sort()
        }
    }

    updateFilter(value){
        this.setState({filterParam: value})
    }

    // begin api
    changePage(currentPage){
        const {meta} = this.props,
        {sortInfo, filterParam} = this.state
        meta.currentPage = currentPage
        if(sortInfo.property !== ""){
            meta.sortInfo = sortInfo
        }
        this.props.changeMeta(meta, filterParam)

    }

    changePerPage(event){
        const {meta} = this.props,
        {sortInfo, filterParam} = this.state
        meta.currentPage = 1
        meta.perPage = event.target.value
        if(sortInfo.property !== ""){
            meta.sortInfo = sortInfo
        }
        this.props.changeMeta(meta, filterParam)
    }

    sort(){
        const {sortInfo, filterParam} = this.state,
        {meta} = this.props;
        meta.sortInfo = sortInfo
        this.props.changeMeta(meta, filterParam)

    }
    //End api

    render(){
        const {datasource, sortInfo} = this.state;
        const {config, meta, changeMeta, t} = this.props;
        let filter = config.hasOwnProperty('filter') ? config.hasOwnProperty('filter') : false;

        // begin react
        if(config.api === false){
            // pagination
             meta.totalCount = datasource.length
            if(meta.totalCount%meta.perPage === 0){
                meta.pageCount = Math.round(meta.totalCount/meta.perPage)
            }
            else{
                meta.pageCount = Math.trunc(meta.totalCount/meta.perPage)+1
            }

            //sort default
            datasource.sort((a, b) => {
                let keyA = a[sortInfo.property],
                    keyB = b[sortInfo.property];
                // Compare
                if(sortInfo.direction === "ASC" && keyA < keyB) return -1;
                if(sortInfo.direction === "ASC" && keyA > keyB) return 1;
                if(sortInfo.direction === "DESC" && keyA > keyB) return -1;
                if(sortInfo.direction === "DESC" && keyA < keyB) return 1;
                return 0;
            });
        }
        // end react
        //
        if( !config || !datasource || !config.hasOwnProperty('cols') || meta === undefined) return null;

        return (
            // <div style={{overflowX: "scroll"}} data-scrollable="true" className="m-scrollable">
            <div className="dataTables_wrapper dt-bootstrap4">
                <div className="overTableScrollX" style={{overflow:'auto'}}>
                    <table className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline" >
                        <RTColumn changeMeta={changeMeta} updateFilter={this.updateFilter.bind(this)} filter={filter} cols={config.cols} sortInfo={sortInfo} sortData={this.sortData.bind(this)} />
                        {datasource.length === 0? null: <RTBody config={config} datasource={datasource} meta={meta}/>}
                    </table>
                </div>

                    {meta ? <Pagination t={t} meta={meta}
                            changePage={this.changePage.bind(this)}
                            changePerPage={this.changePerPage.bind(this)}/> : null}

            </div>
        );
    }
}

export default DataTable;
