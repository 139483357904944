import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import en_US from './locales/en';
import fr_FR from './locales/fr';
import vi_VN from './locales/vi';

let resources = {
    en: {
        translation: en_US
    },
    fr: {
        translation: fr_FR
    },
    vi: {
        translation: vi_VN
    }
}

let missingKeyHandlerFunc = (lng, ns, key, fallbackValue) => {
    let missingKey = localStorage.getItem("i18nextMissingKey");
    missingKey = missingKey ? JSON.parse(missingKey) : {};
    missingKey[key] = fallbackValue;
    localStorage.setItem("i18nextMissingKey", JSON.stringify(missingKey));
}
localStorage.removeItem("i18nextMissingKey");
i18n
    .use(initReactI18next)
    .init({
        debug: false,
        // debug: true,
        saveMissing: true,
        missingKeyHandler: missingKeyHandlerFunc,
        react: {
            wait: true
        },
        resources,
        lng: "en",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;