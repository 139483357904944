import React from 'react';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';
import PageContent from './PageContent';
import PageSidebar from './PageSidebar';

class Layout extends React.Component
{
    render() {
        return(
            <div className={ this.props.className || 'm-grid__item m-grid__item--fluid m-wrapper' }>
                { this.props.children }
            </div>
        );
    }
}

Layout.Header = PageHeader;

Layout.Title = PageTitle;

Layout.Content = PageContent;

Layout.Sidebar = PageSidebar;

export default Layout;
