import React from 'react';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import Portlet from '../../components/Portlet';
import { ToastContainer } from 'react-toastr';
import LoadingPage from '../../components/Layout/LoadingPage';
import '../../assets/css/toastr.css';


class PaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachName: "",
      attachment: "",
      error: false,
      reload: false,
      showLoading: false,
      transIdEmpty: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeFile = this.handleChangeFile.bind(this)
    this.checkaAmount = this.checkaAmount.bind(this)
    this.onClickConfirm = this.onClickConfirm.bind(this)
  }

  handleChange(ev) {
    this.props.onHandleChange(ev.target.name, ev.target.value);
    if (ev.target.name === "circle") {
      this.props.onHandleChange("method", "");
    }
  }

  async handleChangeFile(ev) {
    let _this = this;
    let file = ev.target.files[0];
    if (file) {
      await utils.readUploadedFileAsBinaryString(file)
        .then(result => {
          let base64String = window.btoa(result);
          _this.setState({ attachment: base64String, attachName: file.name })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  checkaAmount() {
    let { balance, amount, freeCredit } = this.props
    let total = 0
    let totalAmount = freeCredit - amount
    if (balance > 0) {
      if (totalAmount >= 0) {
        total = Math.abs(balance)
      } else {
        total = Math.abs(Math.abs(balance) + Math.abs(totalAmount))
      }
    } else {
      if (totalAmount >= 0) {
        total = 0
      } else {
        if (Math.abs(balance) - (amount - freeCredit) >= 0) {
          total = 0
        } else {
          total = Math.abs(Math.abs(balance) - Math.abs(totalAmount))
        }
      }
    }
    return total
  }

  onClickConfirm() {
    let { t, module_list, list_dependent, amount, balance, projectId, payment, freeCredit, listDeployed, listTran, isTransaction } = this.props
    let method = payment.method
    let _this = this
    let transactionId = payment.transactionId
    let { attachment } = this.state
    module_list = module_list.concat(list_dependent);
    let listModuleAdd = []
    module_list.map((module, index) => {
      listModuleAdd = listModuleAdd.concat(module.name)
    })
    let moduleString = listModuleAdd.join()
    let config = utils.getConfig();
    let isAdmin = localStorage.getItem("rtisad")
    let totalPrice = freeCredit + Math.abs(balance) - amount
    let data = {
      "project_id": projectId,
      "module_list": [],
      "free_credit": freeCredit,
      "payment_method": method,
      "amount": this.checkaAmount(),
      "language": localStorage.getItem("language")
    }
    if (method === "bank_transfer") {
      data.banking_transaction_number = transactionId
      data.banking_transaction_file = attachment
    }
    if (method.length === 0) {
      this.setState({ error: true, transIdEmpty: false })
      return false
    }
    if (method === "bank_transfer" && transactionId.length === 0) {
      this.setState({ error: false, transIdEmpty: true })
      return false
    }
    this.setState({ showLoading: true })
    if (listModuleAdd.length > 0) {
      if (isAdmin === "true") {
        axios.get('https://rtcenter.org/services/ecommerce-workspace-sorted-addon-list', { params: { planNameList: moduleString } })
          .then(res => {
            let status = res.data.status
            if (status === "success") {
              let modules = res.data.addons
              if(!isTransaction){
                listDeployed.map((module, ind) => {
                  let index = modules.indexOf(module.name)
                  if (index !== -1 ){
                    modules.splice(index, 1)
                  }
                })
              }else {
                modules.map((item, index)=>{
                  if(listTran.map(function (itemName) { return itemName.name }).indexOf(item) === -1){
                    if(listDeployed.map(function (itemName) { return itemName.name }).indexOf(item) !== -1){
                      modules.splice(index, 1)
                    }
                  }
                })
              }
              data.module_list = modules
              axios.post(apis.BASE_URL + 'projects/add-module', data, config)
                .then(res => {
                  this.refs.toastContainer.success(t("Payment Successful"))
                  localStorage.removeItem("listModuleWP")
                  setTimeout(function () { _this.setState({ reload: true }) }, 1000);
                }).catch(error => {
                  _this.setState({ showLoading: false })
                  if(error.response){
                    _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))
                  } else{
                    _this.refs.toastContainer.error(t("Sorry! Error server"));
                  }
                });
            }
          }).catch(error => {
            _this.setState({ showLoading: false })
            _this.refs.toastContainer.error(t('Payment Unsuccessful'))
            console.log(error)
          });
      } else {
        if (method === "balance") {
          if (balance <= 0) {
            if (totalPrice >= 0) {
              axios.get('https://rtcenter.org/services/ecommerce-workspace-sorted-addon-list', { params: { planNameList: moduleString } })
                .then(res => {
                  let status = res.data.status
                  if (status === "success") {
                    let modules = res.data.addons
                    if(!isTransaction){
                      listDeployed.map((module, ind) => {
                        let index = modules.indexOf(module.name)
                        if (index !== -1 ){
                          modules.splice(index, 1)
                        }
                      })
                    }else {
                      modules.map((item, index)=>{
                        if(listTran.map(function (itemName) { return itemName.name }).indexOf(item) === -1){
                          if(listDeployed.map(function (itemName) { return itemName.name }).indexOf(item) !== -1){
                            modules.splice(index, 1)
                          }
                        }
                      })
                    }
                    data.module_list = modules
                    axios.post(apis.BASE_URL + 'projects/add-module', data, config)
                      .then(res => {
                        this.refs.toastContainer.success(t("Payment Successful"))
                        localStorage.removeItem("listModuleWP")
                        setTimeout(function () { _this.setState({ reload: true }) }, 1000);
                      }).catch(error => {
                        _this.setState({ showLoading: false })
                        if(error.response){
                          _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))
                        } else{
                          _this.refs.toastContainer.error(t("Sorry! Error server"));
                        }
                      });
                  }
                }).catch(error => {
                  _this.setState({ showLoading: false })
                  _this.refs.toastContainer.error(t('Payment Unsuccessful'))
                  console.log(error)
                });
            }
            else {
              _this.setState({ showLoading: false })
              this.refs.toastContainer.warning(t("You do not have enough money"))
              return false;
            }
          } else {
            _this.setState({ showLoading: false })
            this.refs.toastContainer.warning(t("You do not have enough money"))
            return false;
          }
        } else {
          axios.get('https://rtcenter.org/services/ecommerce-workspace-sorted-addon-list', { params: { planNameList: moduleString } })
            .then(res => {
              let status = res.data.status
              if (status === "success") {
                let modules = res.data.addons
                  listDeployed.map((module, ind) => {
                    let index = modules.indexOf(module.name)
                    if (index !== -1) {
                      modules.splice(index, 1)
                    }
                  })
                data.module_list = modules
                axios.post(apis.BASE_URL + 'projects/add-module', data, config)
                  .then(res => {
                    this.refs.toastContainer.success(t("Payment Successful"))
                    localStorage.removeItem("listModuleWP")
                    if (method === "paypal") {
                      window.location.replace(res.data.items)
                    } else {
                      setTimeout(function () { _this.setState({ reload: true }) }, 1000);
                    }
                  }).catch(error => {
                    _this.setState({ showLoading: false })
                    if(error.response){
                      _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))
                    } else{
                      _this.refs.toastContainer.error(t("Sorry! Error server"));
                    }
                  });
              }
            }).catch(error => {
              _this.setState({ showLoading: false })
              _this.refs.toastContainer.error(t('Payment Unsuccessful'))
              console.log(error)
            });
        }
      }
    } else {
      _this.setState({ showLoading: false })
      this.refs.toastContainer.warning(t("No modules have been selected"))
      return false;
    }
  }

  render() {
    let { t, balance, payment, amount, freeCredit, isTransaction } = this.props
    let { attachName, attachment, error, reload, transIdEmpty, showLoading } = this.state
    let method = payment.method
    let projectName = payment.projectName
    let transactionId = payment.transactionId
    let displayBalance = utils.getDisplayBalance(balance)
    let enableBalance = false
    let checkBalance = freeCredit + Math.abs(balance) - amount
    if (checkBalance >= 0) {
      enableBalance = true
    } else {
      enableBalance = false
    }
    if (reload === true) {
      window.location.reload();
    }

    let totalAmount = freeCredit - amount
    let newAmount = 0
    if (balance > 0) {
      newAmount = balance + Math.abs(totalAmount)
    } else {
      if (totalAmount >= 0) {
        newAmount = 0
      } else {
        if (Math.abs(balance) - (amount - freeCredit) >= 0) {
          newAmount = 0
        } else {
          newAmount = Math.abs(Math.abs(balance) - Math.abs(totalAmount))
        }
      }
    }

    let Debt = <div className="m-portlet__head-text">
      <p>{t("Balance")}: <span className={balance > 0 ? "debtclass" : "balanceClass"}>{balance > 0 ? "-" + utils.handleCurrency(balance) : utils.handleCurrency(Math.abs(balance))}</span></p>
      <p>{t("Total Price")}: <span className="balanceClass">{utils.handleCurrency(amount)}</span></p>
      <p>{t("Free Credit")}: <span className="balanceClass ">{utils.handleCurrency(Math.abs(freeCredit))}</span></p>
      {t("Amount")}: <span className="debtclass">{utils.handleCurrency(Math.abs(newAmount))}</span>
    </div>
    
    return (
      <Row>
        {showLoading && <LoadingPage />}
        <Col>
          <Portlet>
            <Portlet.Title>
              <Portlet.Caption>
                <h3 className="m-portlet__head-text">{t("Payment Methods")}</h3>
              </Portlet.Caption>
            </Portlet.Title>
            <Portlet.Body>
              <div className="m-radio-list payment-methods">
                <label className={enableBalance === true ? "m-radio balance-account" : "m-radio balance-account input_disable"}>
                  <input type="radio" name="method" value="balance" disabled={!enableBalance} checked={method === "balance"} onChange={this.handleChange} />
                  <p>{t('Your Balance')} ({displayBalance})</p>
                  <span className="m--margin-top-1"></span>
                </label>
                <label className={enableBalance === false ? "m-radio paypal-account" : "m-radio paypal-account "}>
                  <input type="radio" name="method" value="paypal" checked={method === "paypal"} onChange={this.handleChange} /><img src="https://www.paypalobjects.com/webstatic/mktg/logo/PP_AcceptanceMarkTray-NoDiscover_243x40.png" alt={t('Pay with Paypal')} />
                  <span className="m--margin-top-10"></span>
                </label>
                <label className={enableBalance === false ? "m-radio bank-tranfer" : "m-radio bank-tranfer "}>
                  <input type="radio" name="method" value="bank_transfer" checked={method === "bank_transfer"} onChange={this.handleChange} />
                  <p>{t('Bank Wire Transfer')}</p>
                  <span className="m--margin-top-0"></span>
                </label>
                {error && <p className="error-msg">{t('Please choose a method payment')}.</p>}
              </div>
              {
                method === "bank_transfer" ? isTransaction ?  <span className="bank-tranfer-info fontSmall">{t("Modules in the transaction")}</span> :
                  <div className="bank-tranfer-info fontSmall">
                    <div className="m-form__group">
                      <div className="account-info">
                        <div className="account-info-desc">{t('If you are located in Vietnam please TT to one of our bank accounts below')}</div>
                        <div className="account-list">
                          <ol>
                            <li>
                              <span>Vietcombank (VCB)</span>
                              <ul>
                                <li>Account No. : 0331000419590 (VND)</li>
                                <li>{t('Beneficiary')}: {t('Real-Time Analytics Company Limited')}</li>
                                <li>{t('Branch')} : {t('Sai Gon Branch')}</li>
                              </ul>
                            </li>
                          </ol>
                        </div>
                        <p className="note"> {t('Note')}: {t('Please write transfer contents')} "{projectName}.rtworkspace.com"</p>
                      </div>
                    </div>

                    <div className="m-form__group">
                      <label className="form-control-label">{t('Transfer account code')} <span className="required">*</span></label>
                      <input type="text" className="form-control" name="transactionId" value={transactionId} onChange={this.handleChange} />
                      {transIdEmpty && <p className="error-msg">{t('Please enter your transfer transaction code')}.</p>}
                    </div>

                    <div className="m-form__group">
                      <label className="form-control-label">{t('Attached photos (optional, help RTA check faster)')}</label>
                      <div className="custom-file">
                        <input type="file" className="custom-file-input" accept="image/*,.pdf" name="attachment" id="customFile" onChange={this.handleChangeFile} />
                        <label className="custom-file-label" htmlFor="customFile">{attachName ? attachName : t('Choose file')}</label>
                      </div>
                      {attachment === "" ? null : <img alt="" className="Img_Bank_Wire_Transfer" src={"data:image/jpeg;base64," + attachment} />}

                    </div>
                  </div> : null
              }
            </Portlet.Body>
          </Portlet>
        </Col>
        <Col>
          <Portlet>
            <Portlet.Body>
              <div>
                {Debt}
                <div className="m-pricing-table toastr-btn-confirm bottombtn ">
                  <div className="btn-group m-btn-group  m-btn-group--air " role="group">
                    <button type="button" className="btn m-btn--pill m-btn--wide m-btn--uppercase m-btn--bolder activeBtn" onClick={this.onClickConfirm}>
                      {t("Confirm")}
                    </button>
                  </div>
                </div>
              </div>
            </Portlet.Body>
          </Portlet>
        </Col>
        <ToastContainer
          ref="toastContainer"
          className="toast-top-right"
        />
      </Row>
    );
  }
}

export default PaymentMethod;
