export const CENTER_URL = "https://rtcenter.org/";
// export const BASE_URL = "https://ecommerce.rtworkspace.com/api/";
// export const SOCKET_URL = "wss://ws.rtworkspace.com";

// export const BASE_URL = "https://ecommerce.rta.vn/api/";
// export const SOCKET_URL = "wss://ws.rta.vn";

// export const BASE_URL = "http://210.211.116.82:8484/api/";
// export const SOCKET_URL = "ws://210.211.116.82:8082";

export const BASE_URL = "https://ecommerce.rtworkspace.com/api/";
export const SOCKET_URL = "wss://ws.rtworkspace.com";

// export const BASE_URL = "http://localhost:8383/api/";
// export const SOCKET_URL = "ws://localhost:8082";
