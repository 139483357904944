import React from 'react';
import SubItem from './SubItem';

import '../../assets/css/sidebar.css';


class PageSidebar extends React.Component {
    render() {
        const { t, path, minimize } = this.props;
        let rtisad = localStorage.getItem("rtisad");

        return (
            <div id="m_aside_left" className={minimize?"m-grid__item m-aside-left m-aside-left--skin-dark m-aside-left--on":"m-grid__item m-aside-left m-aside-left--skin-dark"}>
                <div id="m_ver_menu" className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark m-aside-menu--dropdown" data-menu-vertical="true" m-menu-dropdown="1" m-menu-scrollable="0" m-menu-dropdown-timeout="500">
                    {
                        rtisad === "true" ?
                        <ul className="m-menu__nav  m-menu__nav--dropdown-submenu-arrow">
                            <SubItem path={path} t={t} pt="/admin/project" value="Project" flaticon="m-menu__link-icon flaticon-layers"/>
                            <SubItem path={path} t={t} pt="/admin/tracking" value="Tracking" flaticon="m-menu__link-icon flaticon-share"/>
                            <SubItem path={path} t={t} pt="/admin/user" value="User" flaticon="m-menu__link-icon flaticon-user"/>
                        </ul> :
                        <ul className="m-menu__nav  m-menu__nav--dropdown-submenu-arrow">
                            <SubItem path={path} t={t} pt="/project" value="Project" flaticon="m-menu__link-icon flaticon-layers"/>
                            <SubItem path={path} t={t} pt="/billing" value="Billing" flaticon="m-menu__link-icon flaticon-piggy-bank"/>
                            <SubItem path={path} t={t} pt="/referrals" value="Referrals" flaticon="m-menu__link-icon flaticon-coins"/>
                            <SubItem path={path} t={t} pt="/websites" value="Websites" flaticon="m-menu__link-icon flaticon-browser"/>
                            <li className="m-menu__item m-menu__item--submenu" aria-haspopup="true">
                                <a href="https://forum.rtworkspace.com/" className="m-menu__link" target="_blank" rel="noopener noreferrer">
                                    <i className="m-menu__link-icon flaticon-exclamation"></i>
                                    <span className="m-menu__link-text">{t('Forum')}</span>
                                </a>
                            </li>
                        </ul>
                    }
                </div>
            </div>
        );
    }
}

export default PageSidebar;
