import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import * as apis from '../../constants/Apis';
import * as utils from '../../utils';
import { Link } from 'react-router-dom';
import { Modal, FormGroup, Button, Table } from 'react-bootstrap';

const getChild = ((data, chose, checked) => {
    if (Object.keys(data).length > 0) {
        let config = Object.keys(data).map(index => {
            return (<tr key={index}>
                <th scope="row" >
                    <label className="m-checkbox m-checkbox--state-success" >
                        <input type="checkbox" onChange={chose} value={index} checked={checked[index]} />
                        <span></span>
                    </label>
                </th>
                <td>{data[index].proj_name}</td>
                <td>{utils.handleCurrency(data[index].price_plan)}</td>
            </tr>);
        })
        return config;
    }
})
export class PaymentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            reProject: false,
            loading: false
        }
    }
    submit(total) {
        // check total
        let error = {};
        const { data, checked, balance } = this.props;
        if (balance > 0 || total > Math.abs(balance)) {
            error.reChange = true;
            this.setState({
                error
            })
            return false;
        }
        // submit server
        let id = [],
            _this = this,
            config = utils.getConfig();

        //get id chosen
        Object.keys(checked).map(index => {
            if (checked[index]) {
                id.push(data[index].id)
            }
            return true;
        })

        if (Object.keys(id).length === 0) {
            error.Empty = true;
            this.setState({
                error
            })
            return false;
        }

        if (window.confirm("Do you want pay " + utils.handleCurrency(total))) {
            this.setState({ loading: true })
            axios.post(apis.BASE_URL + 'projects/payment', {
                project_ids: id,
                amount: total
            }, config)
                .then(res => {
                    _this.setState({
                        reProject: true,
                        loading: false
                    })

                }).catch(error => {
                    this.setState({
                        error: true,
                        loading: false
                    })
                });
        }
    }
    render() {
        if (this.state.reProject) {
            window.location.reload();
        }

        const { t, show, hideModal, data, checked, checkedAll, chose, choseCheckedAll, balance } = this.props,
            { error, loading } = this.state;

        let balanceClass = (balance > 0) ? "positive" : "negative",
            displayBalance = utils.getDisplayBalance(balance);

        // caculate total payment all
        let total = 0;
        Object.keys(checked).map(index => {
            if (checked[index]) {
                total = total + data[index].price_plan
            }
            return true
        })

        return (
            <Modal
                id="m_modal_payment"
                show={show}
                onHide={hideModal}
                dialogClassName="modal-90w"
                aria-labelledby="m_modal_payment"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('Choose projects you want to pay')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <Table className="m-table m-table--head-bg-success">
                            <thead>
                                <tr>
                                    <th>
                                        <label className="m-checkbox m-checkbox--state-light">
                                            <input type="checkbox" value="all" onChange={choseCheckedAll} checked={checkedAll} />
                                            <span></span>
                                        </label>
                                    </th>
                                    <th>{t('Project Name')}</th>
                                    <th>{t('Price')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getChild(data, chose, checked)}
                            </tbody>
                        </Table>
                        <div className="summary m-stack m-stack--ver m-stack--general">
                            <div className="balance m-stack__item m-stack__item--left"> <strong>{t('Your balance')} : </strong><span className={balanceClass}>{displayBalance}</span></div>
                            <div className="total m-stack__item m-stack__item--right"> <strong>{t('Total')} :</strong> <span> ${total}</span></div>
                        </div>
                    </FormGroup>
                    {
                        Object.keys(this.state.error).length > 0 ? <div >
                            {this.state.error.reChange ? <div className="m-alert m-alert--icon alert alert-danger" role="alert"><div className="m-alert__text"><span>{t('Your balance is not enough to pay')}.<br />{t('Please recharge before performing this action')}.</span> </div><div className="m-alert__actions">
                                <Link className="btn btn-outline-light btn-sm m-btn m-btn--hover-default" to='/billing'>{t('RECHANGE')}</Link>
                            </div></div> : null}
                            {this.state.error.Empty ? <div className="alert alert-danger" role="alert"><span>{t('Please chose project need payment')}.</span></div> : null}
                        </div> : null
                    }
                    {error === true ? <p className="error-msg">{t('An error occurred Please try again later')}.</p> : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideModal}>{t('Close')}</Button>
                    <Button className={loading ? "m-loader m-loader--light m-loader--right" : null} variant="success" onClick={this.submit.bind(this, total, data, checked)}>{t('Pay All')}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export const mapStateToProps = ({ ecommerce }) => {
    return {
        balance: ecommerce.balance
    }
}

export default connect(mapStateToProps)(PaymentModal);
