import React from 'react';
import '../../components/DataTable/assets/css/datatable.css';
import HistoryRow from './HistoryRow'
import { Modal } from 'react-bootstrap';

class PaymentHistory extends React.Component{

	hideModal=()=>{
		this.props.onHideModal()
	}

	render(){
    const {t, show} = this.props
		return (
			<Modal show={show} onHide={this.hideModal}>
					<Modal.Header closeButton>
							<Modal.Title>
									{t('Payment History')}
							</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="dataTables_wrapper dt-bootstrap4">
							<div className="overTableScrollX">
								<table className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline">
									<thead className="m-datatable__head">
										<tr className="m-datatable__row" >
											<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
												<span className="">{t("Payment Date")}</span>
											</th>
											<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
												<span className="">{t("Payment Amount")}</span>
											</th>
											<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
												<span className="">{t("Payment Currency")}</span>
											</th>
											<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
												<span className="">{t("Commissions %")}</span>
											</th>
											<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
												<span className="">{t("Commission Amount")}</span>
											</th>
										</tr>
									</thead>
									<tbody className="m-datatable__body">
										<HistoryRow />
										<HistoryRow />
										<HistoryRow />
										<HistoryRow />
										<HistoryRow />
									</tbody>
								</table>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
					</Modal.Footer>
			</Modal>
		);
	}
}

export default PaymentHistory;
