import React from 'react';

class PageHeader extends React.Component
{
	render() {
		return (
			<div className="m-subheader">
                <div className="d-flex align-items-center">
                    <div className="mr-auto">
                        {this.props.children}
                    </div>
                </div>
            </div>
		);
	}
}

export default PageHeader;