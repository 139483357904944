import React from 'react';
import { Alert, Form, FormGroup, Col, FormControl, Row } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Portlet from '../../components/Portlet';
import axios from 'axios';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import {ToastContainer} from 'react-toastr';
import '../../assets/css/toastr.css';


class ChangePassword extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            data: {
                currentPassword : "",
                newPassword : "",
                confirmPassword : "",
            },
            error:[]
        }
    }

    test(error, name, value){
        const {t} = this.props
        let statusEmpty = {
            "currentPassword": t("CurrentPassword cannot empty"),
            "newPassword": t("NewPassword cannot empty"),
            "confirmPassword": t("confirmPassword cannot empty")
        }
        if (value.toString().trim().length === 0) {
            error[name] = statusEmpty[name];
        } else if (name === 'newPassword' && value.toString().trim().length <6) {
            error[name] = t("At least 6 characters");
        } else if (name === 'confirmPassword' && value !== this.state.data.newPassword){
            error[name] = t("Password not match");
        } else{
            delete error[name];
        }
        return error;
    }

    handleChange(ev){
        let newValue = this.state.data,
        error = this.state.error,
        name = ev.target.name,
        value = ev.target.value;
        newValue[name] = value;
        error= this.test(error, name, value);
        this.setState({data: newValue,
                     error: error});
    }

    handleClear(event){
        event.preventDefault();
        let data ={
            currentPassword : "",
            newPassword : "",
            confirmPassword : ""
        }
        this.setState({data})
    }

    handleSave(event){
        event.preventDefault();
        let _this = this,
        {error, data} = this.state;
        const {t} = this.props
        Object.keys(data).map(index=>{
            error = this.test(error, index, data[index])
            return true
        })

        if(Object.keys(error).length >0){
            this.refs.toastContainer.warning(t('Error Please try again'));
            this.setState(error);
            return false;
        }

        let id = localStorage.getItem("id");
        axios.put( apis.BASE_URL + 'users/'+id+'/change-password',data, utils.getConfig())
        .then( res => {
            _this.refs.toastContainer.success("success");
        }).catch( error => {
            if(error.response){
                _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.errors.code));
            }
            else{
                _this.refs.toastContainer.error(t("Sorry! Error server"));
            }
            console.log('Error: ',error);
        });
    }

	render(){
		const {t} = this.props,
        {error, data} = this.state,
        {currentPassword, newPassword, confirmPassword} = data;

		return(
			<Layout>
                <Layout.Content>
                    <Form>
                    <Portlet className="m-portlet m-portlet--success m-portlet--head-solid-bg">
                        <Portlet.Title>
                            <Portlet.Caption>
                                <h3 className="m-portlet__head-text">{t("Change Password")}</h3>
                            </Portlet.Caption>
                        </Portlet.Title>
                        <Portlet.Body>
                           <div className="m-form m-form--fit m-form--label-align-right" onSubmit={(ev) => { ev.preventDefault(); }}>
                                <div className="form-body">
                                    <Alert className="m-alert m-alert--outline  alert-warning alert-dismissible">
                                        {t('Fields with')} <span className="required">*</span> {t('are required')}.
                                    </Alert>
                                    <FormGroup className="m-form__group row">
                                        <Col  sm={12} lg={3} className="col-form-label">{t('Current Password')} <span className="required">*</span></Col>
                                        <Col sm={12} md={9} lg={4}>
                                            <FormControl placeholder={t("Current Password")} type="password" name="currentPassword" maxLength="255" onChange = {this.handleChange.bind(this)} value={currentPassword} />
                                            {error['currentPassword'] && <p className="error-msg">{error['currentPassword']}</p>}

                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="m-form__group row">
                                        <Col sm={12} lg={3} className="col-form-label">{t('New Password')} <span className="required">*</span></Col>
                                        <Col sm={12} md={9} lg={4}>
                                            <FormControl placeholder={t("New Password")} type="password" name="newPassword" maxLength="255" onChange = {this.handleChange.bind(this)} value={newPassword} />
                                            {error['newPassword'] && <p className="error-msg">{error['newPassword']}</p>}

                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="m-form__group row">
                                        <Col sm={12} lg={3} className="col-form-label">{t('Confirm Password')} <span className="required">*</span></Col>
                                        <Col sm={12} md={9} lg={4}>
                                            <FormControl placeholder={t("Confirm Password")} type="password" name="confirmPassword" maxLength="255" onChange = {this.handleChange.bind(this)} value={confirmPassword} />
                                            {error['confirmPassword'] && <p className="error-msg">{error['confirmPassword']}</p>}
                                        </Col>
                                    </FormGroup>
                                </div>
                            </div>
                        </Portlet.Body>
                        <Portlet.Foot>
                            <Row className="m-form__actions">
                                <Col lg={9} className="ml-lg-auto">
                                    <button type="submit" className="btn btn-success m-btn m-btn--custom m-btn--air" onClick={this.handleSave.bind(this)}>
                                        <span>
                                            <span>{t('Save')}</span>
                                            <i className="fa fa-save"></i>
                                        </span>
                                    </button>
                                    <button className="btn btn-info m-btn m-btn--custom m-btn--air" onClick={this.handleClear.bind(this)}>
                                        <span>
                                            <span>{t('Clear')}</span>
                                            <i className="fa flaticon-delete"></i>
                                        </span>
                                    </button>
                                </Col>
                            </Row>
                        </Portlet.Foot>
                    </Portlet>
                    </Form>
                </Layout.Content>
                <ToastContainer
                    ref="toastContainer"
                    className="toast-top-right"
                />
            </Layout>
			);
	}
}
export default ChangePassword;
