import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import * as apis from "../../constants/Apis";
import * as utils from "../../utils";
import Layout from "../../components/Layout";
import Portlet from "../../components/Portlet";
import LoadingPage from "../../components/Layout/LoadingPage";
import { Col, Form, Row } from "react-bootstrap";
import DomainHelpModal from "./DomainHelpModal";
import NewWebsiteHelp from "./NewWebsiteHelp";

export class NewWebsite extends React.Component {
  constructor(props) {
    super(props);
    let params = new URLSearchParams(props.location.search);
    let website = JSON.parse(localStorage.getItem("prebuiltWebsite"));
    console.log(website);

    this.state = {
      layout: website && website[1] ? website[1] : this.props.match.params.id,
      layout_category: website && website[0] ? website[0] : this.props.match.params.category,
      protocal: "https",
      domain: "",
      domain_error: "",
      is_valid_domain: "",
      website_title: "",
      website_title_error: "",
      site_tagline: "",
      site_tagline_error: "",
      username: "",
      username_error: "",
      password: "",
      password_error: "",
      email: "",
      email_error: "",
      showModal: false,
      showTipModal: false,
      showProtocalOptions: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.checkDomain = this.checkDomain.bind(this);
    this.handleDeploy = this.handleDeploy.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.hideTipModal = this.hideTipModal.bind(this);
    this.handleChangeProtocal = this.handleChangeProtocal.bind(this);
    this.showProtocolOptions = this.showProtocolOptions.bind(this);
  }

  componentDidMount() {
    console.log(this.props);
  }

  componentDidUpdate() {}

  renderErrorMsg(msg) {
    return <p className="error-msg">{msg}</p>;
  }

  handleChange(e) {
    let data = { ...this.state };
    if (e.target.type === "checkbox") {
      data[e.target.name] = e.target.checked;
    } else {
      data[e.target.name] = e.target.value;
    }
    this.setState(data);
  }

  handleChangeProtocal(protocal) {
    let data = { ...this.state };
    data["protocal"] = protocal;
    data["showProtocalOptions"] = "";
    this.setState(data);
  }

  showProtocolOptions() {
    let data = { ...this.state };
    data["showProtocalOptions"] = "show";
    console.log(data);
    this.setState(data);
  }

  resetErrorMsg(state) {
    state.domain_error = "";
    state.website_title_error = "";
    state.site_tagline_error = "";
    state.username_error = "";
    state.password_error = "";
    state.email_error = "";
    return state;
  }

  checkDomain(e) {
    let data = { ...this.state };
    data = this.resetErrorMsg(data);
    if (data.domain === "") {
      data.domain_error = "Domain Name cannot be empty";
      data.is_valid_domain = "";
    } else if (
      !/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/g.test(
        data.domain
      )
    ) {
      data.domain_error = "Please enter a valid domain name";
      data.is_valid_domain = "";
    }
    if (data.domain_error) {
      this.setState({ ...data });
    } else {
      const config = utils.getConfig();
      axios
        .post(
          apis.BASE_URL + "website/check-domain",
          { domain: e.target.value },
          config
        )
        .then((res) => {
          if (res.data !== true) {
            data.domain_error = res.data;
            data.is_valid_domain = false;
            this.setState({ ...data });
          } else {
            data.domain_error = "";
            data.is_valid_domain = true;
            this.setState({ ...data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleDeploy() {
    let data = { ...this.state };
    let hasError = false;
    data = this.resetErrorMsg(data);
    if (data.domain === "") {
      data.domain_error = "Domain Name cannot be empty";
      hasError = true;
    }
    if (data.website_title === "") {
      data.website_title_error = "Website Title cannot be empty";
      hasError = true;
    }
    if (data.site_tagline === "") {
      data.site_tagline_error = "Description cannot be empty";
      hasError = true;
    }
    if (data.username === "") {
      data.username_error = "Username cannot be empty";
      hasError = true;
    }
    if (data.password === "") {
      data.password_error = "Password cannot be empty";
      hasError = true;
    }
    if (data.email === "") {
      data.email_error = "Email cannot be empty";
      hasError = true;
    } else {
      const isValidEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
          data.email
        );
      if (!isValidEmail) {
        data.email_error = "Please provide a valid email";
        hasError = true;
      }
    }

    this.setState({ ...data }, () => {
      if (!hasError) {
        const config = utils.getConfig();
        const {
          domain,
          email,
          layout,
          layout_category,
          password,
          protocal,
          site_tagline,
          username,
          website_title,
          chat_plugin,
        } = data;
        axios
          .post(
            apis.BASE_URL + "website/deploy",
            {
              domain,
              email,
              layout,
              layout_category,
              password,
              protocal,
              site_tagline,
              username,
              website_title,
              chat_plugin,
              uid: localStorage.getItem("id"),
            },
            config
          )
          .then((res) => {
            console.log(res);
            localStorage.removeItem("prebuiltWebsite");
            window.location.replace("/#/websites");
          })
          .catch((error) => {
            console.log(error);
          });

      }
    });
  }

  hideModal(e) {
    let data = { ...this.state };
    this.setState({
      ...data,
      showModal: false,
    });
  }

  hideTipModal(e) {
    let data = { ...this.state };
    this.setState({
      ...data,
      showTipModal: false,
    });
  }

  showModal(e) {
    e.preventDefault();
    let data = { ...this.state };
    this.setState({
      ...data,
      showModal: true,
    });
  }

  showTipModal(e) {
    let data = { ...this.state };
    this.setState({
      ...data,
      showTipModal: true,
    });
  }

  render() {
    const { t, dispatch } = this.props;
    const { is_valid_domain, showModal, showTipModal } = this.state;
    let isValidDomain = "";
    if (is_valid_domain === true) {
      isValidDomain = "is-valid";
    } else if (is_valid_domain === false) {
      isValidDomain = "is-invalid";
    }

    return (
      <Layout>
        <Layout.Content>
          <Portlet>
            <Portlet.Title>
              <Portlet.Caption>
                <h3 className="m-portlet__head-text">{t("New Website")}</h3>
              </Portlet.Caption>
              <Portlet.Tool>
                <button
                  type="input"
                  className="btn btn-outline-info btn-elevate btn-sm btn-circle btn-icon"
                  onClick={this.showTipModal.bind(this)}
                >
                  <i className="fa fa-question"></i>
                </button>
              </Portlet.Tool>
            </Portlet.Title>
            <Portlet.Body>
              {/* {step === 1 && <DeployFirstStep {...this.props} {...this.state} nextPage={this.nextPage} onChange={this.handleChange}/>}
                            {step === 2 && <DeploySecondStep {...this.props} {...this.state} previousPage={this.previousPage} onChange={this.handleChange} />} */}
              <Row>
                <Col lg={6}>
                  <Form.Group as={Row} className="m-form__group">
                    <Col lg={1}></Col>
                    <Col lg={10}>
                      <h4 className="font-size-lg text-dark font-weight-bold mb-6">
                        1. Website Details:
                      </h4>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="m-form__group">
                    <Col lg={1}></Col>
                    <Col lg={10}>
                      <label>
                        <b>{t("Domain Name")}</b>{" "}
                        <span className="required">*</span>
                      </label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <button
                            type="button"
                            className={`btn btn-secondary dropdown-toggle ${this.state.showProtocalOptions}`}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            onClick={() => {
                              this.showProtocolOptions();
                            }}
                          >
                            {`${this.state.protocal}://`}
                          </button>
                          <div
                            className={`dropdown-menu ${this.state.showProtocalOptions}`}
                          >
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                this.handleChangeProtocal("https");
                              }}
                            >
                              https
                            </button>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                this.handleChangeProtocal("http");
                              }}
                            >
                              http
                            </button>
                          </div>
                        </div>
                        <input
                          name="domain"
                          id="rta-domain"
                          className={`form-control form-control-solid ${isValidDomain}`}
                          placeholder="rta.vn"
                          onChange={this.handleChange}
                          onBlur={this.checkDomain}
                        />
                      </div>

                      {/* <a
                        href="#"
                        className="text-muted text-sm"
                        onClick={this.showModal.bind(this)}
                      >
                        <i className="fa fa-info-circle text-primary"></i>{" "}
                        {t(
                          "You need to point your domain to our webhost. Click here to read documentation."
                        )}
                      </a> */}
                      {this.state.domain_error &&
                        this.renderErrorMsg(this.state.domain_error)}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="m-form__group">
                    <Col lg={1}></Col>
                    <Col lg={10}>
                      <label>
                        <strong>{t("Website Title")}</strong>{" "}
                        <span className="required">*</span>
                      </label>
                      <input
                        name="website_title"
                        className="form-control form-control-solid"
                        placeholder="Realtime Analytics"
                        onChange={this.handleChange}
                      />
                      {this.state.website_title_error &&
                        this.renderErrorMsg(this.state.website_title_error)}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="m-form__group">
                    <Col lg={1}></Col>
                    <Col lg={10}>
                      <div className="form-coupon">
                        <label>
                          <strong>{t("Shortened Description")}</strong>{" "}
                          <span className="required">*</span>
                        </label>
                        <input
                          name="site_tagline"
                          className="form-control form-control-solid"
                          placeholder="Known Your True In Time"
                          onChange={this.handleChange}
                        />
                        {this.state.site_tagline_error &&
                          this.renderErrorMsg(this.state.site_tagline_error)}
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="m-form__group">
                    <Col lg={1}></Col>
                    <Col lg={10}>
                      <label>
                        <input
                          type="checkbox"
                          name="chat_plugin"
                          className="m-checkbox"
                          onChange={this.handleChange}
                        />{" "}
                        {t("Do you want to use Chat Widget?")}
                      </label>
                    </Col>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group as={Row} className="m-form__group">
                    <Col lg={1}></Col>
                    <Col lg={10}>
                      <h4 className="font-size-lg text-dark font-weight-bold mb-6">
                        2. Account Info:
                      </h4>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="m-form__group">
                    <Col lg={1}></Col>
                    <Col lg={10}>
                      <label>
                        <b>{t("Username")}</b>{" "}
                        <span className="required">*</span>
                      </label>
                      <input
                        name="username"
                        type="text"
                        className="form-control form-control-solid"
                        required={true}
                        placeholder="admin"
                        onChange={this.handleChange}
                      />
                      {this.state.username_error &&
                        this.renderErrorMsg(this.state.username_error)}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="m-form__group">
                    <Col lg={1}></Col>
                    <Col lg={10}>
                      <label>
                        <strong>{t("Password")}</strong>{" "}
                        <span className="required">*</span>
                      </label>
                      <input
                        name="password"
                        type="password"
                        className="form-control form-control-solid"
                        required={true}
                        placeholder="password"
                        onChange={this.handleChange}
                      />
                      {this.state.password_error &&
                        this.renderErrorMsg(this.state.password_error)}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="m-form__group">
                    <Col lg={1}></Col>
                    <Col lg={10}>
                      <label>
                        <strong>{t("Contact Email")}</strong>{" "}
                        <span className="required">*</span>
                      </label>
                      <input
                        name="email"
                        className="form-control form-control-solid"
                        placeholder="info@rta.vn"
                        required={true}
                        onChange={this.handleChange}
                      />
                      {this.state.email_error &&
                        this.renderErrorMsg(this.state.email_error)}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Portlet.Body>
            <Portlet.Foot>
              <div className="d-flex justify-content-between form__actions">
                <div></div>
                <button
                  type="button"
                  className="btn btn-success m-btn m-btn--custom m-btn--icon"
                  onClick={this.handleDeploy}
                >
                  <span>
                    <span>{t("Deploy")}</span>
                  </span>
                </button>
              </div>
            </Portlet.Foot>
          </Portlet>
        </Layout.Content>
        <DomainHelpModal t={t} hideModal={this.hideModal} show={showModal} />
        <NewWebsiteHelp
          t={t}
          hideTipModal={this.hideTipModal}
          show={showTipModal}
        />
      </Layout>
    );
  }
}
export const mapStateToProps = (state) => ({
  balance: state.ecommerce.balance,
  tempData: state.ecommerce.projectTempData,
});

export default connect(mapStateToProps)(NewWebsite);
