import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import * as utils from '../../utils';
import '../../assets/css/toastr.css';
import Popup from "reactjs-popup";

class Dependencies extends React.Component {
    render(){
      const {module} = this.props;
      let usage = module.content.hasOwnProperty("usageHtml") ? <div>
                    <h4>Usage Price:</h4>
                    {module.hasOwnProperty("content") && module.content.hasOwnProperty("usageHtml") && ReactHtmlParser(utils.handleLanguageModule(module.content.usageHtml))}
                    <hr/>
                    <br/>
                </div> : ''
      let popupContent = <span>
                            <div className="modal-header modal_header">
                              <h4 className="modal-title" id="exampleModalLongTitle">{module.hasOwnProperty("content") && module.content.hasOwnProperty("title") && utils.handleLanguageModule(module.content.title)}</h4>

                            </div>
                            <div className="content modal_content">
                                <img
                                  className="module-image"
                                  alt={module.hasOwnProperty("content") && module.content.hasOwnProperty("title") && module.content.title}
                                  src={module.hasOwnProperty("content") && module.content.hasOwnProperty("thumbnailUrl") && module.content.thumbnailUrl}
                                />
                                <h3>{module.hasOwnProperty("content") && module.content.hasOwnProperty("title") && utils.handleLanguageModule(module.content.title)}</h3>
                                <div>
                                    <h2>{module.hasOwnProperty("content") && module.content.hasOwnProperty("priceHtml") && ReactHtmlParser(utils.handleLanguageModule(module.content.priceHtml))}</h2>
                                </div>
                                <br/>
                                {usage}
                                {module.hasOwnProperty("content") && module.content.hasOwnProperty("htmlDescription") && ReactHtmlParser(utils.handleLanguageModule(module.content.htmlDescription))}
                                <br/>
                            </div>
                          </span>

        return(
          <div className="col-sm-4 module-detail">
            <Popup modal trigger={
              <img
                className="module-image cursor"
                alt={module.hasOwnProperty("content") && module.content.hasOwnProperty("title") && utils.handleLanguageModule(module.content.title)}
                src={module.hasOwnProperty("content") && module.content.hasOwnProperty("thumbnailUrl") && module.content.thumbnailUrl}
              />}
            >
              {popupContent}
            </Popup>
          <div >
              <div className="col-xl-8 fl-left">
                  <div className="m-pricing-table__head">
                    <Popup modal trigger={<p className="module-title cursor">{module.hasOwnProperty("content") && module.content.hasOwnProperty("title") && utils.handleLanguageModule(module.content.title)}</p>}>
                        {popupContent}
                     </Popup>
                  </div>
                  <div className="m-pricing-table__price">
                      {module.hasOwnProperty("content") && module.content.hasOwnProperty("priceHtml") && ReactHtmlParser(utils.handleLanguageModule(module.content.priceHtml))}
                  </div>
              </div>
            </div>
            <div className="m-pricing-table__description clear module-content">
              {module.hasOwnProperty("content") && module.content.hasOwnProperty("htmlContent") && ReactHtmlParser(utils.handleLanguageModule(module.content.htmlContent))}
            </div>
          </div>
        );
    }
}

export default Dependencies;
