import React from 'react';
import axios from 'axios';
import * as apis from '../../constants/Apis';
import * as utils from '../../utils';
import {Link} from 'react-router-dom';
import Translate from '../../components/Translate';
import LeftBanner from '../../components/Layout/LeftBanner';
import {ToastContainer} from 'react-toastr';
import '../../assets/css/toastr.css';
import background from '../../assets/bg-3.jpg';

class ResetPassword extends React.Component
{
	constructor(props){
		super(props);
		let code =""
		let params = new URLSearchParams(props.location.search);
		if(params.get('code')){
			code = params.get('code');
		}
		this.state = {
			data: {
				newPassword: "",
				confirmPassword: ""
			},
			reset_code: code,
			submitLoading: false,
			error: {},
			responseError:"",
			reSignin: false,
			invalidCode: false
		}
	}

	componentDidMount(){
        let _this = this;
        let config = utils.getConfig();
        axios.post(apis.BASE_URL + 'users/check-code', {code: this.state.reset_code}, config)
        .then( res => {
            _this.setState({invalidCode: false});
        })
        .catch( error => {
            _this.setState({invalidCode: true});
        });        
    }

	onChange(ev){
		let newValue = this.state.data,
        error = this.state.error,
        name = ev.target.name,
        value = ev.target.value;
        newValue[name] = value; 
        error= this.test(error, name, value);
        this.setState({data: newValue,
                     error: error,
                     responseError: {}});
	}

	test(error, name, value){
		const {t} = this.props
        if (value.toString().trim().length === 0) {
            error[name] = name === "newPassword" ? t("NewPassword cannot empty") : t("confirmPassword cannot empty");
        } else if (name === 'newPassword' && value.toString().trim().length < 6) {
            error[name] = t("At least 6 characters");
        } else if (name === 'confirmPassword' && value !== this.state.data.newPassword){
            error[name] = t("Password not match");
        } else {
            delete error[name];
        }
        return error;
    }

	submit(ev){
		ev.preventDefault();
        let _this = this,
        {error, data, reset_code} = this.state;
        const {t} = this.props
        Object.keys(data).map(index=>{
            error = this.test(error, index, data[index])
            return true
        })

        if(Object.keys(error).length >0){
            this.refs.toastContainer.warning(t('Error Please try again'));
            this.setState(error);
            return false;
        }

        this.setState({submitLoading: true})

        axios.post( apis.BASE_URL + 'users/reset-password', {reset_code: reset_code, new_password: data.newPassword}, utils.getConfig())
        .then( res => {
            _this.setState({reSignin: true});
        }).catch( error => {
            if (error.response) {
                _this.refs.toastContainer.warning(t("Error Please try again"));
                _this.setState({submitLoading: false, responseError: error.response.data.errors});
            } else {
            	_this.setState({submitLoading: false})
                _this.refs.toastContainer.error(t("Sorry! Error server"));
            }            
        });  
	}
	render(){
		const {t, i18n} = this.props
		let {submitLoading, error, responseError, reSignin, invalidCode} = this.state

		if (invalidCode) {
			return(
				<div className="m-grid__item m-grid__item--fluid m-grid  m-error-6 m-login m-login--signin  m-login--5" style={{backgroundImage: "url("+background+")"}}>
					<div className="m-error_container m-success-container">
						<div className="swal2-icon swal2-error swal2-animate-error-icon" style={{display: "flex"}}><span className="swal2-x-mark"><span className="swal2-x-mark-line-left"></span><span className="swal2-x-mark-line-right"></span></span></div>
						<p className="m-success_description m--font-dark">
							{t('Your reset code is invalid')}.
							{t('Please try again')}.
						</p>
						<div className="swal2-actions" style={{display: "flex"}}>
							<Link to={{ pathname: "/project"}} className="swal2-confirm btn btn-success m-btn m-btn--custom" aria-label="">{t('Back to home')}</Link>
						</div>
					</div>
				</div>
				);
		}

		return(
			<div className="m-login m-login--signin  m-login--5" id="m_login" style={{backgroundImage: "url("+background+")"}}>
				<LeftBanner t={t} />
				<div className="m-login__wrapper-2 m-portlet-full-height">
					<div className="m-login__contanier">
						<div className="m-login__signin animated flipInX">
							<div className="m-login__head">
							<h3 className="m-login__title">{t('Reset Password')}</h3>
							</div>					
							
							{
								reSignin ?
								<div className="m-alert m-alert--outline alert alert-success" role="alert" style={{marginTop:"4rem"}}>
									{t('Password reset successful')}. <Link to="/sign-in">{t('Click here')}</Link> {t('to sign in with new password')}.
								</div> :
								<form className="m-login__form m-form" action="">
									<div className="form-group m-form__group">
										<input className="form-control m-input" type="password" placeholder={t('New Password')} name="newPassword" onChange = {this.onChange.bind(this)}/>
										{error['newPassword'] && <p className="error-msg">{error['newPassword']}</p>}  
									</div>	
									<div className="form-group m-form__group">
										<input className="form-control m-input m-login__form-input--last" type="password" placeholder={t('Re-Password')} name="confirmPassword" onChange = {this.onChange.bind(this)} />
										{error['confirmPassword'] && <p className="error-msg">{error['confirmPassword']}</p>}       
									</div>
									{Object.keys(responseError).length>0?<div className="alert alert-danger margin-top--10" role="alert">{t(responseError)} <Link to="/forget-password"> Try again</Link></div>:''}							
									<div className="m-login__form-action">
										<button disabled={submitLoading} className={submitLoading?"btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-loader m-loader--light m-loader--right":"btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air"} onClick={this.submit.bind(this)}>{t('Submit')}</button>
										<Link to="/sign-in" className="btn btn-outline-focus m-btn m-btn--pill m-btn--custom ">{t('Cancel')}</Link>	
									</div>
								</form>
							}
						</div>
					</div>
					<ToastContainer 
		                ref="toastContainer"
		                className="toast-top-right"
		            /> 		
		            <Translate i18n={i18n}/>
				</div>
			</div>
			);
	}

}

export default ResetPassword;