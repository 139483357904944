import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {Link} from 'react-router-dom';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import {updateBalance, updateUserInfo} from '../../actions/ecommerce';

export class Header extends React.Component
{
    constructor(props){
        super(props);
        this.state = {
            showLanguage: false,
            showUserProfile: false,
            defaults: "020-flag.svg",
            vietnam: "flag_vietnam.png",
            usa: "020-flag.svg",
            french: "flag_french.png",
            error:{},
            minimize: this.props.minimize,
            mobileToggle: false,
            overlayHeader: false,
        }
        this.toggleLanguage = this.toggleLanguage.bind(this)
        this.toggleUserProfile = this.toggleUserProfile.bind(this)
    }

    componentDidMount(){
        const { dispatch } = this.props;

        //language
        let languageStore = localStorage.getItem("language")
        this.ChangeFlag(languageStore)

        let rtisad = localStorage.getItem("rtisad")
        if (rtisad === "true") {
            dispatch(updateUserInfo({firstName: "Admin", lastName: "", email: ""}))
            return false;
        }

        let config = utils.getConfig(),
        userId = utils.getUserId();
        axios.get(apis.BASE_URL + 'users/' + userId + '/balance', config)
        .then( res => {
            let balance = res.data.items.accountBalance;
            dispatch(updateBalance(balance))
        })
        .catch( error => {
            console.log(error);
        });
        axios.get( apis.BASE_URL + 'user/' + userId + '/billing-info', config)
        .then( res => {
            localStorage.setItem('currency', res.data.items.currency);
            if(res.data.items.status === "1"){
              localStorage.setItem('module_set', res.data.items.module_set);
            }else {
              localStorage.removeItem("module_set")
            }
            let data = res.data.items;
            dispatch(updateUserInfo(data))

        }).catch( error => {
            console.log(error);
        });
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.minimize){
            document.body.classList.remove('m-brand--minimize');
            document.body.classList.remove('m-aside-left--minimize');

            document.body.classList.add('m-aside-left--on');
        }
        else{
            document.body.classList.add('m-brand--minimize');
            document.body.classList.add('m-aside-left--minimize');

            document.body.classList.remove('m-aside-left--on');
        }

        if(nextState.mobileToggle){
            document.body.classList.add('m-topbar--on');
        }
        else{
            document.body.classList.remove('m-topbar--on');
        }

        return true;
    }

    ChangeFlag(value){
        const {i18n} = this.props
        let defaults = ""
        localStorage.setItem('language', value)
        switch (value) {
            case "en":
                defaults= "020-flag.svg";
                break;
            case "vi":
                defaults= "flag_vietnam.png";
                break;
            default:
                defaults= "flag_french.png";
                break;
        }
        i18n.changeLanguage(value)
        this.setState({defaults: defaults,showLanguage: false, overlayHeader: false});
    }

    toggleLanguage(){
        let {showLanguage} = this.state
        this.setState({showLanguage: !showLanguage, showUserProfile: false, overlayHeader: true})
    }

    toggleUserProfile(){
        let {showUserProfile} = this.state
        this.setState({showUserProfile: !showUserProfile, showLanguage: false, overlayHeader: true})
    }

    changeMobileToggle(){
        const {mobileToggle} = this.state;
        this.setState({mobileToggle: !mobileToggle,
                        overlayHeader: true})
    }

    changeOverlayHeader(){
        const {overlayHeader} = this.state;
        this.setState({overlayHeader: !overlayHeader,
                        showLanguage: false,
                        showUserProfile: false,
                        mobileToggle: false
                        })
    }
    redirectToPage(event){
        event.stopPropagation();
        localStorage.setItem("access_token", "");
        window.location="https://rtworkspace.com"
    }
    render() {
        const { t, balance, firstName, lastName, email, minimize, changeminimize, freeCredit } = this.props;
        let balanceClass = (balance > 0) ? "positive" : "negative";
        let {showLanguage, showUserProfile, overlayHeader} = this.state
        let rtisad = localStorage.getItem("rtisad")
        let Credit = parseInt(freeCredit) > 0 ? <span className="free_credit_header">
                                                   + ({utils.handleCurrency(freeCredit)})
                                                </span>
                                              : ''

        return (
            <header className="m-grid__item    m-header "  data-minimize-offset="200" data-minimize-mobile-offset="200" >
                <div className="m-container m-container--fluid m-container--full-height">
                    <div className="m-stack m-stack--ver m-stack--desktop">
                        <div className="m-stack__item m-brand  m-brand--skin-dark " id = "m_aside_left_menu">
                            <div className="m-stack m-stack--ver m-stack--general">
                                <div className="m-stack__item m-stack__item--middle m-brand__logo">
                                    <a href="/" className="m-brand__logo-wrapper">
                                        <img alt="" src="/rta.png" height="40"/>
                                    </a>
                                </div>
                                <div className="m-stack__item m-stack__item--middle m-brand__tools">
                                    <span id="m_aside_left_minimize_toggle" className={minimize ? "m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block" : "m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block m-brand__toggler--active"} onClick={changeminimize}>
                                        <span></span>
                                    </span>
                                    <span id="m_aside_left_offcanvas_toggle" className={minimize ? "m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block m-brand__toggler--active" : "m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block"}  onClick={changeminimize}>
                                        <span></span>
                                    </span>
                                    <span id="m_aside_header_topbar_mobile_toggle" className="m-brand__icon m--visible-tablet-and-mobile-inline-block" onClick={this.changeMobileToggle.bind(this)}>
                                        <i className="flaticon-more"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
                            <button className="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-dark " id="m_aside_header_menu_mobile_close_btn">
                                <i className="la la-close"></i>
                            </button>
                            <div id="m_header_menu" className="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark ">

                            </div>
                            <div id="m_header_topbar" className="m-topbar  m-stack m-stack--ver m-stack--general">
                                <div className="m-stack__item m-topbar__nav-wrapper">
                                    <ul className="m-topbar__nav m-nav m-nav--inline">
                                        {
                                            rtisad !== "true" ?
                                            <li className="m-nav__item m-topbar__balance">
                                                <span className="label">{t('Balance')}</span>
                                              <span className={balanceClass+" item"}>{balance > 0 ? "-" + utils.handleCurrency(balance) : utils.handleCurrency(Math.abs(balance))} {Credit}</span>
                                            </li> : null
                                        }
                                        <li className={showLanguage ? "m-nav__item m-topbar__languages m-dropdown m-dropdown--small m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--open" : "m-nav__item m-topbar__languages m-dropdown m-dropdown--small m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width"} m-dropdown-toggle="click">
                                            <div className="m-nav__link m-dropdown__toggle">
                                                <span className="m-nav__link-text">
                                                    <img onClick={this.toggleLanguage} className="m-topbar__language-selected-img" alt="" src={"assets/img/flags/"+this.state.defaults}/>
                                                </span>
                                            </div>
                                            <div className="m-dropdown__wrapper">
                                                <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                                                <div className="m-dropdown__inner">
                                                    <div className="m-dropdown__header m--align-center" style={{background: "url(assets/img/misc/quick_actions_bg.jpg)", backgroundSize: "cover"}}>
                                                        <span className="m-dropdown__header-subtitle">{t('Select your language')}</span>
                                                    </div>
                                                    <div className="m-dropdown__body">
                                                        <div className="m-dropdown__content">
                                                            <ul className="m-nav m-nav--skin-light">
                                                                <li id="flagEn" className="m-nav__item" onClick={this.ChangeFlag.bind(this,'en')}>
                                                                    <div className="m-nav__link ">
                                                                        <span className="m-nav__link-icon"><img className="m-topbar__language-img"alt="" src={"assets/img/flags/"+this.state.usa}/></span>
                                                                        <span className="m-nav__link-title m-topbar__language-text m-nav__link-text">English</span>
                                                                    </div>
                                                                </li>
                                                                <li id="flagFr" className="m-nav__item" onClick={this.ChangeFlag.bind(this,'fr')}>
                                                                    <div className="m-nav__link">
                                                                        <span className="m-nav__link-icon"><img className="m-topbar__language-img" alt="" src={"assets/img/flags/"+this.state.french}/></span>
                                                                        <span className="m-nav__link-title m-topbar__language-text m-nav__link-text">Français</span>
                                                                    </div>
                                                                </li>
                                                                <li id="flagVi" className="m-nav__item" onClick={this.ChangeFlag.bind(this,'vi')}>
                                                                    <div className="m-nav__link ">
                                                                        <span className="m-nav__link-icon"><img className="m-topbar__language-img" alt="" src={"assets/img/flags/"+this.state.vietnam}/></span>
                                                                        <span className="m-nav__link-title m-topbar__language-text m-nav__link-text">Tiếng Việt</span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className={showUserProfile ? "m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light m-dropdown--open" : "m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"}
                                         m-dropdown-toggle="click">
                                            <div className="m-nav__link m-dropdown__toggle">
                                                <span className="m-topbar__userpic">
                                                    <img onClick={this.toggleUserProfile} src="assets/img/users/user.jpg" className="m--img-rounded m--marginless" alt="" />
                                                </span>
                                                <span className="m-topbar__username m--hide">Admin</span>
                                            </div>
                                            <div className="m-dropdown__wrapper">
                                                <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                                                <div className="m-dropdown__inner">
                                                    <div className="m-dropdown__header m--align-center" style={{background: "url(assets/img/misc/user_profile_bg.jpg)", backgroundSize: "cover"}}>
                                                        <div className="m-card-user m-card-user--skin-dark">
                                                            <div className="m-card-user__pic">
                                                                 <img src="/assets/img/users/user.jpg" className="m--img-rounded m--marginless" alt=""/>
                                                            </div>
                                                            <div className="m-card-user__details">
                                                                <span className="m-card-user__name m--font-weight-500">
                                                                    {(firstName ? firstName : "") + " " + (lastName ? lastName : "")}
                                                                </span>
                                                                <a href="/" className="m-card-user__email m--font-weight-300 m-link">
                                                                    {email}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="m-dropdown__body">
                                                        <div className="m-dropdown__content">
                                                            <ul className="m-nav m-nav--skin-light">
                                                                {rtisad !== "true" ?
                                                                <li className="m-nav__item" onClick={this.toggleUserProfile}>
                                                                    <Link to="/billing?info" className="m-nav__link">
                                                                        <i className="m-nav__link-icon flaticon-profile-1"></i>
                                                                        <span className="m-nav__link-text">{t('My Profile')}</span>
                                                                    </Link>
                                                                </li> : null}
                                                                <li className="m-nav__item" onClick={this.toggleUserProfile}>
                                                                    <Link to="/change-password" className="m-nav__link">
                                                                        <i className="m-nav__link-icon flaticon-security"></i>
                                                                        <span className="m-nav__link-text">{t('Change Password')}</span>
                                                                    </Link>
                                                                </li>
                                                                <li className="m-nav__separator m-nav__separator--fit"></li>
                                                                <li className="m-nav__item">
                                                                    <button to="/sign-out" onClick={this.redirectToPage.bind(this)} className="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder">{t('Logout')}</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {overlayHeader?<div className="m-header-overlay" onClick={this.changeOverlayHeader.bind(this)}></div>:null}
            </header>
        );
    }
}
export const mapStateToProps = ({ecommerce}) => {
    return {
            balance: ecommerce.balance,
            freeCredit: ecommerce.freeCredit,
            firstName: ecommerce.userInfo.firstName,
            lastName: ecommerce.userInfo.lastName,
            email: ecommerce.userInfo.email
        }
}

export default connect(mapStateToProps)(Header);

                // {overlayHeader?<div className="m-header-overlay" onClick={this.changeOverlayHeader.bind(this)}></div>:null}
