import React from 'react';
import axios from 'axios';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import Layout from '../../components/Layout';
import Portlet from '../../components/Portlet';
import image from '../../assets/rta.png';
import DataTable from '../../components/DataTable';
import { ToastContainer } from 'react-toastr';
import BillingDetail from '../Project/BillingDetail'
import { Link } from 'react-router-dom';
import '../../assets/css/toastr.css';
import LoadingPage from '../../components/Layout/LoadingPage';

class DetailPayment extends React.Component {
    constructor(props) {
        super(props);
        let params = new URLSearchParams(props.location.search);
        this.state = {
            showLoading: false,
            invoiceID: params.get('Iv'),
            data: [],
            showModal: false,
            listUsers: [],
            meta: {
                currentPage: 1,
                perPage: 10
            }
        }
    }

    changeMeta(meta) {
        this.setState({ meta })
    }

    onShowDetail(users) {
        this.setState({ showModal: true, listUsers: users })
    }

    onCloseModal() {
        this.setState({ showModal: false })
    }

    componentDidMount() {
        // let _this = this;
        // const { t } = this.props
        // this.setState({ showLoading: true })
        // let config = utils.getConfig();
        // _this.setState({ showLoading: true })
        // axios.get(apis.BASE_URL + 'users/invoice/' + _this.state.invoiceID, config)
        //     .then(res => {
                
        //         let listBilling = []
        //         let test =res.data.items.items.length
        //         let res_temp=res.data.items
        //         if(test>0){
        //             res.data.items.items.map((item,index) => {
        //                 console.log('anhdo',item.itemType)
        //                 if (item.itemType === 'USAGE') {
        //                 axios.get('https://rtcenter.org/services/ecommerce-workspace-addon', { params: { name: item.planName, currency: localStorage.getItem("currency") } })
        //                     .then(res => {
        //                         console.log('anhdo',res.data.status)
        //                     let status = res.data.status
        //                     if (status === 'success') {
        //                         item.newPlanName = res.data.msg[0].content.title
        //                     }
        //                     listBilling.push(item)
        //                     if(test-1 ===index){
        //                         res_temp.items=listBilling
        //                         _this.setState({ data: res_temp,showLoading: false })
        //                     }
        //                     })
        //                 } else {
        //                 axios.get('https://rtcenter.org/services/ecommerce-workspace-plan', { params: { name: item.planName, currency: localStorage.getItem("currency") } })
        //                     .then(res => {
        //                     item.newPlanName = res.data.msg[0].content.title;
        //                     listBilling.push(item)
        //                     if(test-1 ===index){
        //                         res_temp.items=listBilling
        //                         _this.setState({ data: res_temp,showLoading: false })
        //                     }
        //                     })
        //                     .catch(error => {
        //                     console.log(error)
        //                     _this.setState({  showLoading: false })
        //                     });
        //                     }
                            
        //                 })
        //         }else{
        //             _this.setState({
        //                 data: res.data.items,showLoading: false})
        //         }
        //     })
        //     .catch(error => {
        //         if (error.response) {
        //             _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))
        //         }
        //         else {
        //             this.refs.toastContainer.error(t('Sorry! Error Server'))
        //         }
        //         _this.setState({  showLoading: false })
        //     });
        let _this = this;
        const { t } = this.props
        this.setState({ showLoading: true })
        let config = utils.getConfig();
        axios.get(apis.BASE_URL + 'users/invoice/' + _this.state.invoiceID, config)
            .then(res => {
                _this.setState({
                    data: res.data.items,
                    showLoading: false
                });
            })
            .catch(error => {
                _this.setState({
                    showLoading: false
                })
                if (error.response) {
                    _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))
                }
                else {
                    this.refs.toastContainer.error(t('Sorry! Error Server'))
                }
            });
    }

    render() {
        const { t } = this.props
        const { data, meta, showModal, listUsers,showLoading } = this.state;
        let plan_name = '';
        let config = {
            api: false,
            cols: [
                {
                    property: "description", header: t("DESCRIPTION"), sortable: true,
                    display: "none",
                    decorator: () => ({ style: { width: "20%" } }),
                    renderer: value => { plan_name = value }
                }, 
                {
                    property: "planName", header: t("DESCRIPTION"), sortable: true,
                    decorator: () => ({ style: { width: "20%" } }),
                    renderer: value => value === null || value === '' ? plan_name : utils.handleLanguageModule(value)
                },
                {
                    property: "projectUrl", header: t("PROJECT"), sortable: true,
                    decorator: () => ({ style: { width: "20%" } }),
                    renderer: value => value !== null || value !=='' ? value : '',
                },
                {
                    property: "startDate", header: t("START DATE"), sortable: true,
                    decorator: () => ({ style: { width: "20%" } }),
                    renderer: value => value
                },
                {
                    property: "amount", header: t("AMOUNT"), sortable: true,
                    decorator: () => ({ style: { width: "20%" } }),
                    renderer: value => utils.handleCurrency(value)
                },
                {
                    property: "sum_user", header: t("NUMBER OF USERS"), sortable: true,
                    decorator: () => ({ style: { width: "20%" } }),
                    renderer: value => value
                },
                {
                    property: "description", header: t("COMMENTS"), sortable: true,
                    decorator: () => ({ style: { width: "20%" } }),
                    renderer: value => null
                },
                {
                    property: "users", header: t(""), sortable: true,
                    decorator: () => ({ style: { width: "20%" } }),
                    renderer: value => value.length > 0 && <span className="btn btn-outline-info m-btn m-btn--custom m-btn--icon m-btn--air" onClick={() => this.onShowDetail.bind(this)(value)}><span>{t('Detail')}</span></span>
                },
            ]
        }
        return (
            <Layout>
                {showLoading && <LoadingPage />}
                <Layout.Content>
                    <Portlet>
                        <Portlet.Body>
                            <div className="m-invoice-2">
                                {this.props.location.state!=undefined?<Link to={{pathname: "/billing",
                                           search: "?history",
                                           state:  this.props.location.state.meta
                                    }} className="btn btn-outline-success m-btn m-btn--custom m-btn--icon m-btn--air">
                                        <span>{t('Go Back')}</span>
                                </Link>:null}
                                <div className="m-invoice__wrapper">
                                    <div className="m-invoice__head">
                                        <div className="m-invoice__container m-invoice__container--centered">
                                            <div className="m-invoice__logo">
                                                <a href="/">
                                                    <h1>{t('Invoice')} {data.invoiceNumber}</h1>
                                                </a>
                                                <a href="/">
                                                    <img src={image} alt="" style={{ width: "220px" }} />
                                                    <span className="m-invoice__desc" style={{color:"#575962"}}>
                                                        <span>{t('45 Vo Thi Sau Da Kao')}</span>
                                                    </span>
                                                </a>
                                                
                                            </div>
                                            
                                            <div className="m-invoice__items">
                                                <div className="row">
                                                    <div className="col-lg-6 col-12 invoice_content">
                                                        <div className="invoice_text">
                                                            <span className="info">{t('INVOICE DATE')}</span>
                                                            <span className="amount">{data.invoiceDate}</span>
                                                        </div>
                                                        <div className="invoice_text">
                                                            <span className="info">{t('TARGET DATE')}</span>
                                                            <span className="amount">{data.targetDate}</span>
                                                        </div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {data.items ? <DataTable t={t} datasource={data.items} config={config} meta={meta} changeMeta={this.changeMeta.bind(this)} /> : null}

                                    <div className="row f-invoice">
                                        <div className="col-lg-5 col-12"></div>
                                        <div className="col-lg-6 col-12 footer_invoice">
                                            <div className="invoice_text">
                                                <span className="info">{t('INVOICE TOTAL')}:</span>
                                                <span className="amount">{utils.handleCurrency(data.amount)} </span>
                                            </div >
                                            <div className="invoice_text">
                                                <span className="info">{t('CREDITS')}:</span>
                                                <span className="amount">{utils.handleCurrency(data.creditAdj)}</span>
                                            </div>
                                            <div className="invoice_text">
                                                <span className="info">{t('REFUNDED')}:</span>
                                                <span className="amount">{utils.handleCurrency(data.refundAdj)}</span>
                                            </div>
                                            <div className="invoice_text">
                                                <span className="info">{t('BALANCE')}:</span>
                                                <span className="amount">{utils.handleCurrency(data.balance)}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-12"></div>
                                    </div>
                                </div>
                                <BillingDetail show={showModal} t={t} onHideModal={this.onCloseModal.bind(this)} users={listUsers} />
                            </div>
                        </Portlet.Body>
                    </Portlet>
                </Layout.Content>
                <ToastContainer ref="toastContainer" className="toast-top-right" />
            </Layout>
        );
    }
}

export default DetailPayment;
