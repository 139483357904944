export const ERRORCODE = {
	"P001": "Invalid characters in subdomain name",
	"P003": "Project does not define",
	"P004": "Cannot update project",
	"P006": "Delete Fail",
	"P007": "Cannot detete",
	"P008": "Cannot get detail",
	"P009": "Cannot Re-Deploy",
	"P010": "Cannot Add Module - You must be have project site",
	"F001": "Unable to upload a file: This file type is not supported",
	"F002": "Quantity Invalid",
	"F003": "Coupon Code Invalid",
	"F004": "Amount does not correct",
	"F005": "Cannot add payment method",
	"F006": "Cannot subscription for project",
	"F007": "Plan does not define",
	"F008": "This transaction is already confirmed before",
	"F009": "Cannot add credit",
	"F010": "Payment transaction does not exist",

	"B001": "Billing info does not exist",
	"B002": "Cannot get Invoice data",
	"B003": "Invoice Id Invalid",

	"U001": "Invalid User",
	"U002": "Cannot create access token",
	"U003": "Verify Code has been used or invalid.",
	"U004": "Active fail",
	"U005": "Email does not exist",
	"U006": "Invalid Code",
	"U007": "New password have to be different from old password",
	"U008": "Current password is invalid",
	"U009": "This username has already been taken",
	"U010": "This email address has already been taken",
	"U011": "Incorrect username or password",
	"U012": "Your account has been inactive",
	"U013": "Your account has not been verified please check email",
	"U014": "This account has already been verified",
	"U015": "This account has already been inactived",
	"U016": "Authcode Expired",
	"U017": "Add credit fail",

	"defa": "Undefined error"
}
