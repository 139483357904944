import React from 'react';
import '../../components/DataTable/assets/css/datatable.css';
import '../../assets/css/custom.css';

class FriendRow extends React.Component{
  constructor(props){
      super(props);
      this.state = {
				checkFriend: false,
      }
  }

  componentDidMount(){

  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      checkFriend: nextProps.checkAll
    })
  }

  onCheck=(e)=>{
		let target = e.target
		let name = target.name
		let value = target.checked
		this.setState({
			[name]: value
		})
	}

  onShowHistoryItem=()=>{
    this.props.onShowHistory()
  }

	render(){
    let {checkFriend} = this.state
		return (
      <tr data-row="0" className="m-datatable__row " >
        <td data-field="proj_name" className="m-datatable__cell ref-checkbox-friends-list" >
          <span className="">
            <label>
              <input type="checkbox" name="checkFriend" value={checkFriend} checked={checkFriend === true} onChange={this.onCheck}/>
            </label>
          </span>
        </td>
        <td data-field="proj_name" className="m-datatable__cell" >
          <span className="">test</span>
        </td>
        <td data-field="proj_name" className="m-datatable__cell" >
          <span className="">test</span>
        </td>
        <td data-field="proj_name" className="m-datatable__cell" >
          <span className="">test</span>
        </td>
        <td data-field="proj_name" className="m-datatable__cell" >
          <span className="">test</span>
        </td>
        <td data-field="proj_name" className="m-datatable__cell" >
          <span className="">test</span>
        </td>
        <td data-field="proj_name" className="m-datatable__cell" >
          <span className="">test</span>
        </td>
        <td data-field="proj_name" className="m-datatable__cell" >
          <span className="">test</span>
        </td>
        <td data-field="proj_name" className="m-datatable__cellx" >
          <span className="btn btn-outline-info m-btn m-btn--custom m-btn--icon m-btn--air" onClick={this.onShowHistoryItem}><i className="m-menu__link-icon flaticon-search"><span></span></i></span>
        </td>
      </tr>
			);
	}
}

export default FriendRow;
