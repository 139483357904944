import React from 'react';
import * as utils from '../../utils';

class PaymentUser extends React.Component{
	constructor(props){
      super(props);
      this.state = {
        recievedUser: false,
        showDetail: false,
        activeTab: true
      }
  }

  onRecievedUser=()=>{
		this.setState({
			recievedUser: !this.state.recievedUser
		})
	}

  onShowDetail=()=>{
    this.setState({
      showDetail: !this.state.showDetail
    })
  }

  onActiveTab=(status)=>{
    this.setState({
      activeTab: status
    })
  }

	render(){
    const {t} = this.props
    let {recievedUser, showDetail, activeTab} = this.state
		return (
			<div className="ref-commission-boder">
					<div className={showDetail===false?"ref-commission-user-header":"ref-commission-user-header-show"}>
						<div className="col-sm-8 ref-fl-left">
							<span>{t("Payment ID")}: 45 - {t("User")}: Test - {t("Available to Recieve")}: {utils.handleCurrency(200000)}</span>
						</div>
						<div className="col-sm-4 ref-fl-right ref-commission-content-right">
							<span className="">
								<label>
									<input type="checkbox" name="recievedUser" value={recievedUser} checked={recievedUser === true} onChange={this.onRecievedUser}/>
								</label>
							</span>
							<span >	{t("Recieved")}</span>
						  <span className={showDetail===false?"dropdown-toggle ref-mar-left":"dropdown-toggle ref-mar-left dropdown-toggle-show"} onClick={this.onShowDetail}></span>
						</div>
					</div>
					{showDetail===true?
            <div className="col-sm-12 ref-commission-user-body">
  						<div className="col-sm-12 ">
  							<span>{t("Number Of Paid Users Before This Payment")}: 3 {t("user(s)")}</span><br/>
  							<span>{t("Amount Payment Before This Payment")}: {utils.handleCurrency(200000)}</span>
  						</div><br/>
              <div className="ref-commission-user-body-content ref-clear">
                <div className=" ref-fl-left ref-tab-content-wrapper">
                  <p className={activeTab===true ? " ref-tab-content ref-tab-content-active" : "ref-tab-content"} onClick={()=>this.onActiveTab(true)}>{t("Paid Users")}</p>
                  <p className={activeTab===false ? " ref-tab-content ref-tab-content-active" : "ref-tab-content"} onClick={()=>this.onActiveTab(false)}>{t("Payment Amount")}</p>
                </div>
                <div className="ref-fl-left ref-commission-content-wrapper">
                  <div className="dataTables_wrapper dt-bootstrap4">
                    <div className="overTableScrollX">
                      <table className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline">
                        <thead className="m-datatable__head">
                          <tr className="m-datatable__row" >
                            <th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
                              <span className="">{t("User")}</span>
                            </th>
                            <th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
                              <span className="">{t("Payment Amount")}</span>
                            </th>
                            <th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
                              <span className="">{t("Fixed Commission")}</span>
                            </th>
                            <th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
                              <span className="">{t("Percentage Amount (0.1%)")}</span>
                            </th>
                            <th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
                              <span className="">{t("Total Commission")}</span>
                            </th>
                            <th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
                              <span className="">{t("Currency")}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="m-datatable__body">
                          <tr>
                            <td data-field="proj_name" className="m-datatable__cell" >
                              <span className="">test</span>
                            </td>
                            <td data-field="proj_name" className="m-datatable__cell" >
                              <span className="">test</span>
                            </td>
                            <td data-field="proj_name" className="m-datatable__cell" >
                              <span className="">test</span>
                            </td>
                            <td data-field="proj_name" className="m-datatable__cell" >
                              <span className="">test</span>
                            </td>
                            <td data-field="proj_name" className="m-datatable__cell" >
                              <span className="">test</span>
                            </td>
                            <td data-field="proj_name" className="m-datatable__cell" >
                              <span className="">test</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
					  </div>
          :""}
				</div>
		);
	}
}

export default PaymentUser;
