import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import * as utils from '../../utils';
import { Row, Col } from 'react-bootstrap';
import '../../assets/css/toastr.css';
import axios from 'axios';
import * as apis from '../../constants/Apis';
import Popup from "reactjs-popup";
import { ToastContainer } from 'react-toastr';

class Module extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPurchase: false,
      submitLoading: false
    }
  }

  componentWillMount() {
    let _this = this
    let config = utils.getConfig();
    let { projectId, module } = this.props
    axios.get(apis.BASE_URL + 'projects/' + projectId + '/added-modules', config)
      .then(res => {
        let modules = res.data.items
        modules.map((item, ind) => {
          if (item.plan_name === module.name) {
            _this.setState({
              isPurchase: true,
            })
          }
        })
      })
  }

  componentDidMount() {
    const { module_list, module } = this.props
    module_list.map((item, ind) => {
      if (item.name === module.name) {
        this.setState({
          isPurchase: true,
        })
      }
    })
  }

  onClickPurchase = () => {
    this.props.addModule(this.props.module);
    this.setState({
      isPurchase: true
    })
  }

  onClickRemove = () => {
    this.props.removeModule(this.props.module);
    this.setState({
      isPurchase: false
    })
  }

  onReDeploy = (id) => {
    const { t } = this.props
    let _this = this
    _this.setState({ submitLoading: true })
    axios.put(apis.BASE_URL + 'projects/' + id + '/re-deploy-module', [], utils.getConfig())
      .then(res => {
          const {meta} = _this.props
        _this.props.updateDeloy(meta);
        _this.refs.toastContainer.success(t("Re-Deploy success") + "!!!");
        _this.setState({ submitLoading: false })
      })
      .catch(error => {
        _this.refs.toastContainer.error(t("Re-Deploy not success") + "!!!");
        _this.setState({ submitLoading: false })
      });
  }

  render() {
    const { t, module, activeButton, listStatusModule } = this.props;
    const { isPurchase, submitLoading } = this.state
    let classNameButton = "btn m-btn--pill m-btn--bolder m-handel btn-wide fl-right activeBtn"
    let isAdmin = localStorage.getItem("rtisad")
    let buttonPurchase = <button type="button" className={classNameButton} onClick={this.onClickPurchase}>
      {t("Purchase")}
    </button>

    let buttonRemove = <button type="button" className={classNameButton} onClick={this.onClickRemove}>
      {t("Remove")}
    </button>

    let usage = module.content.hasOwnProperty("usageHtml") ? <div>
      <h4>Usage Price:</h4>
      {module.hasOwnProperty("content") && module.content.hasOwnProperty("usageHtml") && ReactHtmlParser(utils.handleLanguageModule(module.content.usageHtml))}
      <hr />
      <br />
    </div> : ''
    let showDependencies = module.dependencies.map((depen, ind) => {
      return <Col key={ind} className="col-sm-4 module-detail-margin">
        <img
          className="module-image"
          alt={depen.hasOwnProperty("content") && depen.content.hasOwnProperty("title") && utils.handleLanguageModule(depen.content.title)}
          src={depen.hasOwnProperty("content") && depen.content.hasOwnProperty("thumbnailUrl") && depen.content.thumbnailUrl}
        />
        <h5 className="m-pricing-table__title">{depen.hasOwnProperty("content") && depen.content.hasOwnProperty("title") && utils.handleLanguageModule(depen.content.title)}</h5>
        {depen.hasOwnProperty("content") && depen.content.hasOwnProperty("htmlContent") && ReactHtmlParser(utils.handleLanguageModule(depen.content.htmlContent))}
      </Col>
    })

    let btnShowLog, btnReDeploy, handleButton, moduleStatus = ''
    if (activeButton === -1) {
      listStatusModule.map((moduleItem, ind) => {
        if (moduleItem.plan_name === module.name) {
          switch (moduleItem.status) {
            case "-2":
              moduleStatus = <span className="clear module_status hightlight_error">{t("Deploy Fail")}</span>
              btnReDeploy = <button type="button" disabled={submitLoading} className={submitLoading ? "btnShowlog activeBtn fl-right btn m-loader m-loader--light m-loader--right" : "btnShowlog activeBtn fl-right btn"} onClick={() => this.onReDeploy(moduleItem.name)}>{t("Re-Deploy")}</button>
              btnShowLog = <Popup modal trigger={<button type="button" className="btnShowlog activeBtn fl-right btn mrg_right">{t("Show Log")}</button>}>{close => <span>  <div className="modal-header modal_header"><h4>{t("Log Error")}</h4><button type="button" className={classNameButton} onClick={close}>{t("Close")}</button></div>  <div className="content modal_content">  {moduleItem.logs}</div></span>}</Popup>
              break
            case "-1":
              moduleStatus = <span className="clear module_status hightlight_warning">{t("In Active")}</span>
              break
            case "1":
              moduleStatus = <span className="clear module_status hightlight_success">{t("Active")}</span>
              break
            case "0":
              moduleStatus = <span className="clear module_status hightlight_warning">{t("Pause")}</span>
              break
            case "2":
              moduleStatus = <span className="clear module_status hightlight_error">{t("In Transaction")}</span>
              break
            case "3":
              moduleStatus = <span className="clear module_status hightlight_success">{t("Paid")}</span>
              break
            case "4":
              moduleStatus = <span className="clear module_status hightlight_warning">{t("Deploying")}</span>
              btnReDeploy = isAdmin === "true" && <button type="button" className="btnShowlog activeBtn fl-right btn" onClick={() => this.onReDeploy(moduleItem.name)}>{t("Re-Deploy")}</button>
              break
            case "5":
              moduleStatus = <span className="clear module_status hightlight_error">{t("Unpaid")}</span>
              break
            case "6":
              moduleStatus = <span className="clear module_status hightlight_success">{t("Verifying")}</span>
              break
            case "7":
              moduleStatus = <span className="clear module_status hightlight_error">{t("Verify fail")}</span>
              break
            case "8":
              moduleStatus = <span className="clear module_status hightlight_error">{t("Subcription fail")}</span>
              break
            default:
              break
          }
        }
      })
    } else if (activeButton === 0) {
      if (isPurchase === false) {
        handleButton = buttonPurchase
      } else {
        handleButton = <p className="bought left_panel_text">{t("Added")}</p>
      }
    } else if (activeButton === 1) {
      handleButton = buttonRemove
    }

    let popupContent = <span>
      <div className="modal-header modal_header">
        <h4 className="modal-title" id="exampleModalLongTitle">{module.hasOwnProperty("content") && module.content.hasOwnProperty("title") && utils.handleLanguageModule(module.content.title)}</h4>
        {handleButton}
      </div>
      <div className="content modal_content">
        <img
          className="module-image"
          alt={module.hasOwnProperty("content") && module.content.hasOwnProperty("title") && module.content.title}
          src={module.hasOwnProperty("content") && module.content.hasOwnProperty("thumbnailUrl") && module.content.thumbnailUrl}
        />
        <h3>{module.hasOwnProperty("content") && module.content.hasOwnProperty("title") && utils.handleLanguageModule(module.content.title)}</h3>
        <div>
          <h2>{module.hasOwnProperty("content") && module.content.hasOwnProperty("priceHtml") && ReactHtmlParser(utils.handleLanguageModule(module.content.priceHtml))}</h2>
        </div>
        <br />
        {usage}
        {module.hasOwnProperty("content") && module.content.hasOwnProperty("htmlDescription") && ReactHtmlParser(utils.handleLanguageModule(module.content.htmlDescription))}
        <br />
        <h4>{t("Dependencies")}:</h4>
        <Row className="boder_module">
          {module.dependencies.length > 0 ? showDependencies : <p className="text_indent">{t("This module has no dependencies")}</p>}
        </Row>
      </div>
    </span>

    return (
      <div className="col-sm-4 module-detail">
        <Popup modal trigger={
          <img
            className="module-image cursor"
            alt={module.hasOwnProperty("content") && module.content.hasOwnProperty("title") && utils.handleLanguageModule(module.content.title)}
            src={module.hasOwnProperty("content") && module.content.hasOwnProperty("thumbnailUrl") && module.content.thumbnailUrl}
          />}
        >
          {popupContent}
        </Popup>
        <div className={activeButton === -1 ? "col-xl-12 fl-left" : "col-xl-8 fl-left"}>
          <div className="m-pricing-table__head ">
            <Popup modal trigger={<p className="module-title cursor">{module.hasOwnProperty("content") && module.content.hasOwnProperty("title") && utils.handleLanguageModule(module.content.title)}</p>}>
              {popupContent}
            </Popup>
          </div>
          <div className="m-pricing-table__price">
            {module.hasOwnProperty("content") && module.content.hasOwnProperty("priceHtml") && ReactHtmlParser(utils.handleLanguageModule(module.content.priceHtml))}
          </div>
          <div className="clear deploy_status">{moduleStatus}{btnReDeploy}{btnShowLog}</div>
        </div>
        <div className="col-xl-4 fl-left">
          {handleButton}
        </div>
        <div className="m-pricing-table__description clear module-content">
          {module.hasOwnProperty("content") && module.content.hasOwnProperty("htmlContent") && ReactHtmlParser(utils.handleLanguageModule(module.content.htmlContent))}
        </div>
        <ToastContainer
          ref="toastContainer"
          className="toast-top-right"
        />
      </div>
    );
  }
}
export default Module;
