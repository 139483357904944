import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import * as apis from '../../constants/Apis';
import * as utils from '../../utils';
import Layout from '../../components/Layout';
import LoadingPage from '../../components/Layout/LoadingPage';
import { setProjectDetail, setUserList, setSubmissionList } from '../../actions/ecommerce';
import '../../assets/css/toastr.css';
import Listmodules from './ListModules';
import { ToastContainer } from 'react-toastr';
import Detail from './Detail'

const UnlimitedItem = ({ type, t }) => (
	<div className="m-widget24__item">
		<h4 className="m-widget24__title">
			{type === "user" ? t('Number Of User') : t('Number Of Submission')}:
		</h4>
		<span className="h_unlimited">{t('Unlimited')}</span>
	</div>
)

export class ProjectDetail extends React.Component {
	constructor(props) {
		super(props);
		let activeButton = -1;
		let listModuleWP = [];
		if (props.location.hasOwnProperty('state') && props.location.state !== undefined) {
			let parentState = props.location.state
			if (parentState.hasOwnProperty('activeButton') && parentState.activeButton !== undefined) {
				activeButton = parentState.activeButton
			}
			if (parentState.hasOwnProperty('listModuleWP') && parentState.listModuleWP !== undefined) {
				listModuleWP = parentState.listModuleWP
			}
		}
		this.state = {
			id: window.location.href.split('/')[window.location.href.split('/').length - 1],
			showLoading: false,
			plan: {},
			addon: [],
			categories: '',
			activeButton: activeButton,
			listModuleWP: listModuleWP,
			pagination: {
				totalCount: 0,
				currentPage: 1,
				pageCount: 0,
				perPage: 9
			},
			searchName: '',
			orderBy: 'price ASC'
		}
	}

	componentDidMount() {
		const { id, pagination } = this.state,
			{ t } = this.props
		let _this = this;
		let currency = localStorage.getItem("currency")
		this.setState({ showLoading: true });

		// get project detail
		axios.get(apis.BASE_URL + 'projects/' + id + '/detail', utils.getConfig())
			.then(res => {
				_this.props.dispatch(setProjectDetail(res.data.items))

				// get plan info
				axios.get('https://rtcenter.org/services/ecommerce-workspace-plan', { params: { name: res.data.items.plan_name.replace('_trial', ''), currency: currency } })
					.then(res => {
						_this.setState({
							plan: res.data.msg[0]
						});
					})
					.catch(error => {
						_this.setState({ showLoading: false });
						console.log(error)
					});

				// get data user
				axios.get(apis.BASE_URL + 'projects/' + id + '/user/1/10/{"created_at":"SORT_ASC"}/[]', utils.getConfig())
					.then(res => {
						_this.props.dispatch(setUserList({ data: res.data.items, _meta: res.data._meta }))

						// get data submision
						axios.get(apis.BASE_URL + 'projects/' + id + '/submission/1/10/{"created_at":"SORT_ASC"}/[]', utils.getConfig())
							.then(res => {
								_this.props.dispatch(setSubmissionList({ data: res.data.items, _meta: res.data._meta }))
							})
							.catch(error => {
								_this.refs.toastContainer.error(t("Cannot get user project"));
								_this.setState({ showLoading: false });
								console.log(error);
							});
					})
					.catch(error => {
						_this.refs.toastContainer.error(t("Cannot get user project"));
						_this.setState({ showLoading: false });
						console.log(error);
					});
			})
			.catch(error => {
				_this.props.dispatch(setProjectDetail({}))
				_this.setState({ showLoading: false });
				_this.refs.toastContainer.error(t("Cannot get detail project"));
				console.log(error);
			});
		axios.get('https://rtcenter.org/services/ecommerce-workspace-addon', { params: { orderBy: _this.state.orderBy, search: _this.state.searchName, limit: pagination.perPage, currency: currency } })
			.then(res => {
				let status = res.data.status
				if (status === 'success') {
					_this.setState({
						addon: res.data.msg,
						showLoading: false,
						pagination: {
							totalCount: parseInt(res.data.totalPlans),
							currentPage: parseInt(res.data.currentPage),
							pageCount: Math.ceil(parseInt(res.data.totalPlans) / parseInt(pagination.perPage)),
							perPage: parseInt(pagination.perPage)
						},
					})
				}
			})
			.catch(error => {
				_this.setState({ showLoading: false });
				console.log(error)
			});
	}

	onClickStatus = (activeNum) => {
		window.scrollTo(0, 0);
		this.setState({
			activeButton: activeNum,
		})
	}

	onFilter = (filter) => {
		let { pagination } = this.state
		let _this = this
		let currency = localStorage.getItem("currency")
		let params = filter.searchName === '' ? { orderBy: filter.orderBy, limit: pagination.perPage, currency: currency } : { orderBy: filter.orderBy, search: filter.searchName, limit: pagination.perPage, currency: currency }
		this.setState({ showLoading: true });
		axios.get('https://rtcenter.org/services/ecommerce-workspace-addon', { params: params })
			.then(res => {
				let status = res.data.status
				if (status === 'success') {
					_this.setState({
						addon: res.data.msg,
						showLoading: false,
						pagination: {
							totalCount: parseInt(res.data.totalPlans),
							currentPage: parseInt(res.data.currentPage),
							pageCount: Math.ceil(parseInt(res.data.totalPlans) / parseInt(pagination.perPage)),
							perPage: parseInt(pagination.perPage)
						},
					})
				} else {
					this.setState({
						addon: [],
						showLoading: false,
					});
				}
			})
			.catch(error => {
				console.log(error)
			});
	}

	changeMetaModule = (listModule, pagination, dataFilter) => {
		this.setState({
			addon: listModule,
			pagination: pagination,
			searchName: dataFilter.search,
			orderBy: dataFilter.orderBy
		})
	}

	render() {
		const { t, projectDetail } = this.props
		let { plan, showLoading, id, listModuleWP, addon, pagination } = this.state
		let percent_user = (projectDetail.usage_user / projectDetail.max_user).toFixed(2) * 100,
			percent_submition = (projectDetail.usage_submission / projectDetail.max_submission).toFixed(2) * 100;
		let buttonClassName = "btn m-btn--pill m-btn--wide m-btn--uppercase m-btn--bolder ";
		let { activeButton } = this.state;
		return (
			<Layout>
				{showLoading && <LoadingPage />}
				<Layout.Content>
					<div className="m-pricing-table toastr-btn-wrapper">
						<div className="btn-group m-btn-group m-btn-group--pill m-btn-group--air toast-btn-boder" role="group">
							<button type="button" className={activeButton === -1 ? buttonClassName + 'activeBtn' : buttonClassName} onClick={() => this.onClickStatus(-1)}>
								{t("Current Plan")}
							</button>
							<button type="button" className={activeButton === 0 ? buttonClassName + 'activeBtn' : buttonClassName} onClick={() => this.onClickStatus(0)}>
								{t("Module Library")}
							</button>
							<button type="button" className={activeButton === 1 ? buttonClassName + 'activeBtn' : buttonClassName} onClick={() => this.onClickStatus(1)}>
								{t("Confirm")}
							</button>
						</div>
					</div>
					{activeButton === -1 && <Detail t={t} plan={plan} projectDetail={projectDetail} UnlimitedItem={UnlimitedItem} percent_user={percent_user} percent_submition={percent_submition} />}
					<Listmodules t={t} projectId={id} listofmodules={addon} activeButton={activeButton} pagination={pagination} listModuleWP={listModuleWP} onClickStatus={this.onClickStatus} onFilter={this.onFilter} changeMetaModule={this.changeMetaModule} orderBy={this.state.orderBy} />
				</Layout.Content>
				<ToastContainer
					ref="toastContainer"
					className="toast-top-right"
				/>
			</Layout>
		);
	}
}

export const mapStateToProps = ({ ecommerce }) => {
	return {
		userList: ecommerce.userList,
		submissionList: ecommerce.submissionList,
		projectDetail: ecommerce.projectDetail,
	}
}

export default connect(mapStateToProps)(ProjectDetail)
