import React from 'react';

const Child =(props)=>{
    let currentPage = props.currentPage,
    pageCount = props.pageCount,
    changePage= props.changePage,
    prev = currentPage -1,
    next = currentPage +1,
    showitems = 4,
    showpages ='',
    resuilt = [];

    //first, prev pages
    if(currentPage===1){
        //disabled
        resuilt.push(<li className="paginate_button page-item first disabled" key="first"><button className="page-link" disabled="disabled" title="First"><i className="la la-angle-double-left"></i></button></li>);
        resuilt.push(<li className="paginate_button page-item previous disabled" key="prev"><button className="page-link" disabled="disabled" title="Previous"><i className="la la-angle-left"></i></button></li>);        
    }
    else{
        //enabled
        resuilt.push(<li className="paginate_button page-item first " key="first"><button className="page-link" title="First" onClick={()=>changePage(1)}><i className="la la-angle-double-left"></i></button></li>);
        resuilt.push(<li className="paginate_button page-item previous " key="prev"><button className="page-link" title="Previous" onClick={()=>changePage(prev)}><i className="la la-angle-left"></i></button></li>);
    }

    if( currentPage > showitems ) {
        resuilt.push(<li className="paginate_button page-item " key="more-prev"><button className="page-link" title="More pages" ><i className="la la-ellipsis-h"></i></button></li>);
    }

    //show page button
    for(let i= 1 ; i<= pageCount; i++){
        if(1 !== pageCount &&( !(i >= currentPage+showitems || i <= currentPage-showitems) || pageCount <= showitems )){
            if(currentPage === i){
                resuilt.push(<li className="paginate_button page-item active" key={i}><button  className="page-link" title={i}>{i}</button></li>);
            }
            else {
                resuilt.push(<li className="paginate_button page-item " key={i}><button className="page-link" title={i} onClick={()=>changePage(i)}>{i}</button></li>);
            }
            showpages = i;
        }

    }

    // show ...
    if( currentPage < pageCount-1 && showpages < pageCount ){
        // showpages = showpages + 1;
        resuilt.push(<li className="paginate_button page-item " key="more-next"><button className="page-link" title="More pages"><i className="la la-ellipsis-h"></i></button></li>);
    }
    //next, end page
    if( currentPage === pageCount ) {
        // disabled
        resuilt.push(<li className="paginate_button page-item next disabled" key="next"><button className="page-link" disabled="disabled" title="Next"><i className="la la-angle-right"></i></button></li>);
        resuilt.push(<li className="paginate_button page-item last disabled" key="last"><button className="page-link" disabled="disabled" title="Last"><i className="la la-angle-double-right"></i></button></li>);
    }
    else{
        //enabled
        resuilt.push(<li className="paginate_button page-item next " key="next"><button className="page-link" title="Next" onClick={()=>changePage(next)}><i className="la la-angle-right"></i></button></li>);
        resuilt.push(<li className="paginate_button page-item last " key="last"><button className="page-link" title="Last"onClick={()=>changePage(pageCount)}><i className="la la-angle-double-right"></i></button></li>);
    }

    return resuilt;

} 


class Pagination extends React.Component {
    render() {
        const {meta, changePage, changePerPage, t} = this.props;        
        let startIdx = (meta.currentPage - 1) * meta.perPage + 1;
        let endIdx = startIdx + (meta.perPage - 1);
        endIdx = (endIdx > meta.totalCount) ? meta.totalCount : endIdx;
        return (
            <div className="row">
                <div className="col-sm-12 col-md-4">
                    <div className="dataTables_info" id="m_table_1_info" role="status" aria-live="polite">{t('Showing')} {startIdx} {t('to')} {endIdx} {t('of')} {meta.totalCount} {t('entries')}</div>
                </div>
                <div className="col-sm-12 col-md-8 dataTables_pager">
                    <div className="dataTables_length" id="m_table_1_length">
                        <label>{t('Show')} 
                            <select name="m_table_1_length" aria-controls="m_table_1" className="custom-select custom-select-sm form-control form-control-sm" value={meta.perPage} onChange={changePerPage}>
                                <option value="1">1</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="30">30</option>
                                <option value="50">50</option>                                
                            </select> {t('entries')}
                        </label>
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers" id="m_table_1_paginate">
                        <ul className="pagination">
                        {meta.pageCount <= 1? null:
                            <Child currentPage={meta.currentPage} pageCount={meta.pageCount} changePage={changePage}/>
                        }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pagination;
