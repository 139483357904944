import React from 'react';
import '../../components/DataTable/assets/css/datatable.css';
import FriendRow from './FriendRow'
import PaymentHistory from './PaymentHistory'
import * as utils from '../../utils';

class MyFriendsTab extends React.Component{
	constructor(props){
      super(props);
      this.state = {
				checkAll: false,
				showHistory: false,
      }
  }

	componentDidMount(){

	}

	onCheckAll=(e)=>{
		let target = e.target
		let name = target.name
		let value = target.checked
		this.setState({
			[name]: value
		})
	}

	onRemove=()=>{
		console.log("remove");
	}

	onShowHistory=()=>{
		this.setState({
			showHistory: true
		});
	}

	onHideHistory=()=>{
		this.setState({
			showHistory: false
		});
	}

	render(){
    const {t} = this.props
		let {checkAll, showHistory} = this.state
		return (
			<div>
				<div className="dataTables_wrapper dt-bootstrap4">
					<div className="overTableScrollX">
						<table className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline">
							<thead className="m-datatable__head">
								<tr className="m-datatable__row" >
									<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
										<span className="">
											<label>
					              <input type="checkbox" name="checkAll" value={checkAll} onChange={this.onCheckAll}/>
					            </label>
										</span>
									</th>
									<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
										<span className="">{t("Registered Date")}</span>
									</th>
									<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
										<span className="">{t("Full Name")}</span>
									</th>
									<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
										<span className="">{t("Affiliate Code")}</span>
									</th>
									<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
										<span className="">{t("Last Payment Date")}</span>
									</th>
									<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
										<span className="">{t("Last Payment Amount")}</span>
									</th>
									<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
										<span className="">{t("Last Payment Currency")}</span>
									</th>
									<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
										<span className="">{t("Commission status")}</span>
									</th>
									<th data-field="proj_name" className="m-datatable__cell m-datatable__cell--sort" >
										<span className="">{t("Payment History")}</span>
									</th>
								</tr>
							</thead>
							<tbody className="m-datatable__body">
								<FriendRow checkAll= {checkAll} onShowHistory={this.onShowHistory}/>
								<FriendRow checkAll= {checkAll} onShowHistory={this.onShowHistory}/>
							</tbody>
						</table>
					</div>
				</div>
				<button className="ref-friends-tag-remove-btn ref-button-edit ref-mar-left btn m-btn--pill m-btn--wide m-btn--uppercase m-btn--bolder activeBtn" onClick={this.onRemove}>{t("Remove")}</button>
				 <PaymentHistory t={t} show={showHistory} onHideModal={this.onHideHistory}/>
			</div>
			);
	}
}

export default MyFriendsTab;
