import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import * as utils from './utils';
import * as apis from './constants/Apis';
import { updateBalance, setProjectTrans, setProjectList, setProjectDetail, setUserList, setSubmissionList, setAdminProject, setWebsitesList } from './actions/ecommerce';

export class Socket extends React.Component {
    componentDidMount() {
        let { adminProject, projectList, dispatch, userList } = this.props,
            isAdmin = localStorage.getItem('rtisad'),
            config = utils.getConfig()
        const ws = new WebSocket(apis.SOCKET_URL);
        ws.onmessage = (res) => {
            let data = JSON.parse(res.data),
                meta = { currentPage: 1, perPage: 10 }
            let proj_id = ""
            if (data && data["action"]) {
                switch (data["action"]) {
                    case "updateBalance":
                        // console.log("Socket updateBalance")
                        axios.get(apis.BASE_URL + 'users/' + utils.getUserId() + '/balance', config)
                            .then(res => {
                                let balance = res.data.items.accountBalance;
                                dispatch(updateBalance(balance))
                            })
                            .catch(error => console.log(error));
                        break;
                    case "updateProject":
                        // console.log("Socket updateProject")

                        //load projectlist
                        let metaPL = meta
                        if (Object.keys(projectList.meta).length > 0) {
                            metaPL = projectList.meta;
                        }
                        axios.get(apis.BASE_URL + 'projects/1/50/{"id":"SORT_DESC"}/{"e_user_project__status":2}', config)
                            .then(res => {
                                dispatch(setProjectTrans(res.data.items))
                                axios.get(apis.BASE_URL + 'projects/' + metaPL.currentPage + '/' + metaPL.perPage + '/{"id":"SORT_DESC"}/["not",{"e_user_project__status":2}]', config)
                                    .then(res => {
                                        dispatch(setProjectList({ data: res.data.items, meta: res.data._meta }))
                                    }).catch(error => console.log(error));
                            }).catch(error => console.log(error));

                        //load data admin project
                        if (isAdmin === "true") {
                            let metaAP = meta
                            if (Object.keys(adminProject.meta).length > 0) {
                                metaAP = adminProject.meta;
                            }
                            axios.get(apis.BASE_URL + 'projects/list/' + metaAP.currentPage + '/' + metaAP.perPage + '/{"created_at":"SORT_DESC"}/[]', utils.getConfig())
                                .then(res => {
                                    dispatch(setAdminProject({ data: res.data.items, meta: res.data._meta }))
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }

                        break;

                    case "updateUserList":
                        // console.log("Socket updateUserList")
                        let metaUL = meta
                        if (Object.keys(userList._meta).length > 0) {
                            metaUL = userList._meta
                        }
                        if (window.location.href.split('/')[window.location.href.split('/').length - 2] === "detail") {
                            proj_id = window.location.href.split('/')[window.location.href.split('/').length - 1]
                            axios.get(apis.BASE_URL + 'projects/' + proj_id + '/user/' + metaUL.currentPage + '/' + metaUL.perPage + '/{"created_at":"SORT_ASC"}/[]', utils.getConfig())
                                .then(res => {
                                    dispatch(setUserList({ data: res.data.items, _meta: res.data._meta }))
                                    axios.get(apis.BASE_URL + 'projects/' + proj_id + '/detail', utils.getConfig())
                                        .then(res => {
                                            dispatch(setProjectDetail(res.data.items))
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                        break;

                    case "updateSubmissionList":
                        // console.log("Socket updateSubmissionList")
                        let metaSL = meta;
                        if (Object.keys(userList._meta).length > 0) {
                            metaSL = userList._meta
                        }
                        if (window.location.href.split('/')[window.location.href.split('/').length - 2] === 'detail') {
                            proj_id = window.location.href.split('/')[window.location.href.split('/').length - 1]
                            axios.get(apis.BASE_URL + 'projects/' + proj_id + '/submission/' + metaSL.currentPage + '/' + metaSL.perPage + '/{"created_at":"SORT_ASC"}/[]', utils.getConfig())
                                .then(res => {
                                    dispatch(setSubmissionList({ data: res.data.items, _meta: res.data._meta }))
                                    axios.get(apis.BASE_URL + 'projects/' + proj_id + '/detail', utils.getConfig())
                                        .then(res => {
                                            dispatch(setProjectDetail(res.data.items))
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                        break;

                    case "updateWebsiteDeployStatus":
                        // do something
                        const config = utils.getConfig();
                        const userId = localStorage.getItem("id");
                        axios
                            .get(apis.BASE_URL + "website/user/" + userId, config)
                            .then((res) => {
                                if (res.status === 200) {
                                    const responseData = res.data;
                                    dispatch(setWebsitesList(responseData))
                                }
                            })
                            .catch((error) => console.log(error));
                        break;

                    default:
                        break;
                }
            }
        };
        ws.onopen = (e) => {
            ws.send(JSON.stringify({ 'action': 'setToken', 'token': localStorage.getItem("access_token") }));
        };
    }
    render() {
        return (<span />)
    }
}
export const mapStateToProps = ({ ecommerce }) => ({
    projectList: ecommerce.projectList,
    userList: ecommerce.userList,
    submissionList: ecommerce.submissionList,
    projectDetail: ecommerce.projectDetail,
    adminProject: ecommerce.adminProject
})

export default connect(mapStateToProps)(Socket);