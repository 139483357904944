import React from 'react';
import axios from 'axios';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import { Redirect } from 'react-router-dom';
import {Link} from 'react-router-dom';
import background from '../../assets/bg-3.jpg';
import Translate from '../../components/Translate';
import LeftBanner from '../../components/Layout/LeftBanner';
import {ToastContainer} from 'react-toastr';
import '../../assets/css/toastr.css';

class SignUp extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			username: "",
			password: "",
			email: "",
			repassword: "",
			referral_code: "",
			referral_set: "",
			referral_user: "",
			isReferral: false,
			checkRefferal: true,
			agreeTerm: false,
			error: {},
			submitLoading: false,
			success: false
		}
	}

	componentDidMount(){
		let _this = this
		let params = new URLSearchParams(this.props.location.search);
		let username = ""
		if (params.get("ref")) {
				let ref = params.get("ref")
				_this.setState({referral_code: ref,})
				if(params.get("set")){
					let set = params.get("set")
					_this.setState({referral_set: set,})
				}
				axios.get('https://rtcenter.org/services/get-referral-info', {params: {referralCode: ref}})
				.then( res => {
					if(res.data.status === "success"){
						username = res.data.info.username;
						_this.setState({
							referral_user: username,
							isReferral: true,
							checkRefferal: true
						})
					}else {
						_this.setState({
							isReferral: true,
							checkRefferal: false
						})
					}
				}).catch( error => {
	          console.log(error)
	      });
		}
	}

	onChange(ev){
		let newValue = {},
        error = this.state.error,
        name = ev.target.name,
        value = ev.target.value;
        newValue[name] = value;
			let _this = this
			let username = ""
				if(name === "referral_code"){
					if(value.trim() !== ""){
						axios.get('https://rtcenter.org/services/get-referral-info', {params: {referralCode: value}})
						.then( res => {
							if(res.data.status === "success"){
								username = res.data.info.username;
								_this.setState({
									referral_user: username,
									isReferral: true,
									checkRefferal: true
								})
							}else {
								_this.setState({
									isReferral: true,
									checkRefferal: false
								})
							}
						})
					}else {
						_this.setState({isReferral: false, checkRefferal: true})
					}
				}
        error.errMsg = false;
        newValue.error = error;
        this.setState(newValue);
	}

	onBlurRefferalCode(){
		let {error, checkRefferal} = this.state
				if(checkRefferal === true ){
						error.refferalCodeIncorrect = false
				}else {
						error.refferalCodeIncorrect = true
				}
	}

	onBlurUserName(){
		let format = /[ àảãáạăằẳẵắặâầẩẫấậÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬđĐèẻẽéẹêềểễếệÈẺẼÉẸÊỀỂỄẾỆìỉĩíịÌỈĨÍỊòỏõóọôồổỗốộơờởỡớợÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢùủũúụưừửữứựÙỦŨÚỤƯỪỬỮỨỰỳỷỹýỵỲỶỸÝỴ!@#$%^&*()+\-=\]{};':"\\|,<>?]+/;
		let error = this.state.error;

		error.userEmpty = this.state.username === "";
		if(this.state.username !== ""){
			error.userIncorrect = this.state.username.toString().trim().length <5;
			error.userCharacter = format.test(this.state.username);
		}
		else{
			error.userIncorrect = false;
			error.userCharacter = false;
		}

		this.setState({error})
	}

	onBlurEmail(){
		const {email, error} = this.state;
		error.emailEmpty = email === "";
		if(email !== ""){
			error.emailIncorrect = !utils.validateEmail(email)
		}
		else{
			error.emailIncorrect = false;
		}

		this.setState({error})
	}

	onBlurPassword(){
		const {error, password} = this.state
		error.passEmpty = password === "";
		if(password !== ""){
			error.passIncorrect =password.toString().trim().length <6;
		}
		else{
			error.passIncorrect = false;
		}
		this.setState({error})
	}

	onBlurRePassword(){
		const {error,password, repassword} = this.state;
		error.repassEmpty = repassword === "";
		if(repassword === ""){
			error.repassMatch = false;
		}
		else{
			 error.repassMatch = repassword !== password
		}
		this.setState({error})
	}

	agreeTerm(){
		this.setState({
			agreeTerm : !this.state.agreeTerm
		})
	}

	clearData(){
		this.setState({
			username: "",
			password: "",
			email: "",
			repassword: "",
			agreeTerm: false,
			error: {},
			submitLoading: false
		})
	}

	submit(event){
		event.preventDefault();
		const {error, username, email, password, repassword, agreeTerm} = this.state,
		{t} = this.props

        error.userEmpty = username.toString().trim().length === 0 ? true : false;

        error.emailEmpty = email.toString().trim().length === 0 ? true : false;

        error.passEmpty = password.toString().trim().length === 0 ? true : false;

        error.repassEmpty = repassword.toString().trim().length === 0 ? true : false;

        error.repassMatch = repassword !== password;

 		let validate = false;
        Object.keys(error).map((e, i) => {
            if(error[e]){
            	validate = true;
            }
            return validate;
        });

        if(validate){
        	this.setState({error})
        	return;
        }

        if(!agreeTerm){
        	this.refs.toastContainer.warning(t("Please agree our terms and conditions"));
        	return;
        }
        let _this = this;
		this.setState({submitLoading: true})
		axios.get(apis.CENTER_URL + 'services/check-email?toemail='+email.toString().trim())
		.then( res => {
			if (res.data.result && res.data.result === "valid") {
				axios.post( apis.BASE_URL + 'users/sign-up', {
					username: _this.state.username.toString().trim(),
					password: _this.state.password.toString().trim(),
					email: _this.state.email.toString().trim(),
					referral_code: _this.state.referral_code.toString().trim(),
					module_set: _this.state.referral_set.toString()
				})
				.then( res => {
					_this.setState({success: true, referral_code: '', isReferral: false})
					_this.clearData();
				}).catch( error => {
					if(error.response){
						_this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))
					} else{
						_this.refs.toastContainer.error(t("Sorry! Error server"));
					}
					_this.setState({submitLoading: false})
				});
			} else {
				let error = {emailIncorrect: true}
				_this.setState({error, submitLoading: false})
			}
		})
	}
	render(){
		const {t, i18n, location} = this.props,
		{submitLoading, error, success, username, password, email, repassword, agreeTerm, referral_code, isReferral, referral_user, checkRefferal} = this.state
		let access_token = localStorage.getItem("access_token");
		if( access_token ){
            return(<Redirect to='/'/>);
        }
		return(
			<div className="m-login m-login--signin  m-login--5" id="m_login" style={{backgroundImage: "url("+background+")"}}>
				<LeftBanner t={t} />
				<div className="m-login__wrapper-2 m-portlet-full-height">
					<div className="m-login__contanier">
						<div className="m-login__signin animated flipInX">
							<div className="m-login__head">
								<h3 className="m-login__title">{t('Sign Up')}</h3>
								<div className="m-login__desc">{t('Enter your details to create your account')}:</div>
							</div>
							<div className="m-login__account">
								<span className="m-login__account-msg">
								{t('Do already have an account')} ?
								</span>
								<Link to={{pathname: "/sign-in", state: location.state}} className="m-link m-link--dark m-login__account-link">{t('Sign In')}</Link>

							</div>
							<form className="m-login__form m-form" action="">
								<div className="form-group m-form__group">
									<input className="form-control m-input" type="text" placeholder={t('Username')} name="username" value={username} onBlur={this.onBlurUserName.bind(this)} onChange = {this.onChange.bind(this)}/>
									{error.userEmpty ? <div className="form-control-feedback">{t('This field is required')}.</div>
									: error.userIncorrect ? <div className="form-control-feedback">{t('At least 5 characters')}.</div>
								: error.userCharacter ? <div className="form-control-feedback">{t('Cannot contain special characters')}</div> : null}

								</div>
								<div className="form-group m-form__group">
									<input className="form-control m-input" type="email" placeholder={t('Email')} name="email" autoComplete="off" value={email} onBlur={this.onBlurEmail.bind(this)} onChange = {this.onChange.bind(this)}/>
									{error.emailEmpty ? <div className="form-control-feedback">{t('This field is required')}.</div>
									: error.emailIncorrect ? <div className="form-control-feedback">{t('Email Invalid')}</div> : null}

								</div>
								<div className="form-group m-form__group">
									<input className="form-control m-input" type="password" placeholder={t('Password')} name="password" value={password} onBlur={this.onBlurPassword.bind(this)} onChange = {this.onChange.bind(this)}/>
									{error.passEmpty ? <div className="form-control-feedback">{t('This field is required')}.</div>
									: error.passIncorrect ? <div className="form-control-feedback">{t('At least 6 characters')}.</div> : null}

								</div>
								<div className="form-group m-form__group">
									<input className="form-control m-input m-login__form-input--last" type="password" placeholder={t('Confirm Password')} name="repassword" value={repassword} onBlur={this.onBlurRePassword.bind(this)} onChange = {this.onChange.bind(this)}/>
									{error.repassEmpty ? <div className="form-control-feedback">{t('This field is required')}.</div>
									: error.repassMatch ? <div className="form-control-feedback">{t("Password don't match")} !!!.</div> : null}
								</div>
								<div className="form-group m-form__group">
									<input autoComplete="off" className="form-control m-input" type="text" placeholder={t('Referral Code')} name="referral_code" value={referral_code} onBlur={this.onBlurRefferalCode.bind(this)} onChange = {this.onChange.bind(this)}/>
									{isReferral === true ? <div className={checkRefferal === true ? "form-control-feedback form-control-referralCode-success" : "form-control-feedback"}> {checkRefferal === true ? t("This referral code invited by")+": "+referral_user+"." : t("Referral Code ")+referral_code+t(" does not exists.")}</div> : ""}
								</div>
								<div className="m-login__form-sub">
									<label className="m-checkbox m-checkbox--focus">
										<input type="checkbox" checked = {agreeTerm} name="agree" onChange={this.agreeTerm.bind(this)} />
										{t('I agree to the')} <a href="https://rtsurvey.com/terms-of-service" className="m-link m-link--focus" target="_blank" rel="noopener noreferrer">{t('Terms of Service')}</a> {t('and')} <a href="https://rtsurvey.com/privacy-policy" className="m-link m-link--focus" target="_blank" rel="noopener noreferrer">{t('Privacy Policy')}</a>.
										<span></span>
									</label>
									<span className="m-form__help"></span>
								</div>
								{success?
									<div className="alert alert-success" role="alert">
										<strong>{t('Well done')}!</strong> {t('Please check your email to active and then sign in')}
									</div>
								:null}
								<div className="m-login__form-action">
			        				<button disabled={submitLoading} className={submitLoading?"btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-loader m-loader--light m-loader--right":"btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air"} onClick = {this.submit.bind(this)}>{t('Sign Up')}</button>
								</div>
							</form>
						</div>
					</div>
	           		<Translate i18n={i18n}/>
				</div>
				<ToastContainer
	                ref="toastContainer"
	                className="toast-top-right"
	            />
			</div>
			);
	}
}

export default SignUp;
