import React from 'react';
import { DropdownButton, Dropdown, Button } from 'react-bootstrap';
import PaymentDate from './PaymentDate';
import * as utils from '../../utils';

class CommissionsTab extends React.Component{
	constructor(props){
      super(props);
      this.state = {
				showUnrecieve: true,
      }
  }

	onRecieve = () =>{
		console.log("recieve");
	}

	onShowRecieve=()=>{
		this.setState({
			showUnrecieve: !this.state.showUnrecieve
		})
	}

	render(){
    const {t} = this.props
		let {showUnrecieve} = this.state
		return (
      <div>
        <div>
					<div className="col-sm-6 ref-fl-left ">
						<span className="ref-fl-left ref-commission-text-header">{t("Month")}:</span>
						<DropdownButton id="dropdown-basic-button" className="ref-fl-left ref-mar-left" title={t("This Month")}>
						  <Dropdown.Item >{t("Last Month")}</Dropdown.Item>
							<Dropdown.Item >{t("All Time")}</Dropdown.Item>
						</DropdownButton>
						<div className="ref-fl-left ref-mar-left">
							<label className="ref-switch ref-mar-left">
								<input type="checkbox" className="ref-check-switch ref-mar-left" checked={showUnrecieve} onChange={this.onShowRecieve}/>
								<span className="ref-slider ref-round"></span>
							</label>
						</div>
						<span className="ref-fl-left ref-mar-left ref-commission-text-header">{t("Show unreceived only")}</span>
					</div>
					<div className="col-sm-6 ref-fl-right ref-commission-content-right ref-padd-bottom">
						<p>{t("Available (not receied yet)")}: {utils.handleCurrency(200000)}</p>
						<p>{t("Recieved")}: {utils.handleCurrency(200000)}</p>
						<p>{t("Total")}: {utils.handleCurrency(200000)}</p>
						<Button onClick={this.onRecieve}>{t("Recieve")}</Button>
					</div>
				</div>
				<div className="ref-clear">
					<PaymentDate t={t}/>
				</div>
      </div>
			);
	}
}

export default CommissionsTab;
