import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import * as apis from '../../constants/Apis';
import * as utils from '../../utils';
import { Container, Row, Col } from 'react-bootstrap';
import Portlet from '../../components/Portlet';
import Popup from "reactjs-popup";
import '../../assets/css/toastr.css';
import ReactHtmlParser from 'react-html-parser';
import PaginationList from '../../components/PaginationList';
import LoadingPage from '../../components/Layout/LoadingPage';
import Module from './Module'
import PaymentMethod from './PaymentMethod'
import Dependencies from './Dependencies'
import { updateFreeCredit } from '../../actions/ecommerce';

class ListModules extends React.Component {
  constructor(props) {
    super(props);
    let listModuleWP = []
    if (this.props.listModuleWP) {
      listModuleWP = this.props.listModuleWP
    }
    this.state = {
      listModuleWP: listModuleWP,     //list of modules on https://rtworkspace.com
      moduleDeployed: [],             //list of modules deployed to render on UI
      listDeployed: [],               //list of modules deployed include status
      listTran: [],                   //list of modules  in transaction
      moduleWarning: [],              //list of modules that have been added earlier
      module_list: [],
      list_dependent: [],
      freeCredit: 0,
      amount: 0,
      showLoading: false,
      isTransaction: false,
      filter: {
        searchName: '',
        orderBy: this.props.orderBy,
      },
      listStatusModule: [],
      payment: {
        method: '',
        projectName: '',
        transactionId: ''
      },
      paginationDeployed: {
        currentPage: 1,
        pageCount: 0,
        perPage: 6,
        totalCount: 0
      },
      filterStatusModule: {
        filter:'[]',
        name:'All'
      },
      focusSearch: false,
      statusActive:false,
      statusDeloyFail:false,
      statusDeloying:false,
    }
  }

  componentWillMount() {
    let module_set = localStorage.getItem("module_set");
    let currency = localStorage.getItem("currency")
    let _this = this
    let { projectId, listDeployed } = _this.props
    let statusActive=false
    let statusDeloyFail=false
    let statusDeloying=false
    if (module_set !== null || module_set !== "") {
      axios.get(apis.BASE_URL + 'projects/' + projectId + '/added-modules', utils.getConfig())
        .then(res => {
          res.data.items.map((item)=>{
              if(item.status==='1'){
                statusActive=true;
              }
              else if(item.status==='-2'){
                statusDeloyFail=true;
              }
              else if(item.status==='4'){
                statusDeloying=true;
              }
          })
          this.setState({
            statusActive:statusActive,
            statusDeloyFail:statusDeloyFail,
            statusDeloying:statusDeloying
          })
          let modules = res.data.items
          axios.get('https://rtcenter.org/services/ecommerce-set-referral', { params: { code: module_set } })
            .then(res => {
              let status = res.data.status
              if (status === "success") {
                let { dispatch } = _this.props
                let moduleList = []
                let amount = 0
                res.data.msg.map((item, ind) => {
                  if (modules.map(function (itemName) { return itemName.plan_name }).indexOf(item) === -1) {
                    axios.get('https://rtcenter.org/services/ecommerce-workspace-addon', { params: { name: item, currency: currency } })
                      .then(res => {
                        let status = res.data.status
                        if (status === 'success') {
                          let addon = res.data.msg[0]
                          if (_this.getIndexOfItemInArrObj(listDeployed, addon.name) === -1) {
                            moduleList.push(addon)
                          }
                          amount = _this.getTotalPrice(moduleList)
                          _this.setState({
                            module_list: moduleList,
                            freeCredit: amount.freeCredit,
                            amount: amount.price
                          })
                          dispatch(updateFreeCredit(amount.freeCredit))
                        }
                      })
                  }
                })
              }
            })
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  componentDidMount() {
    let _this = this
    let config = utils.getConfig();
    let currency = localStorage.getItem("currency")
    let { projectId, t } = _this.props
    let { paginationDeployed, listModuleWP, listDeployed } = _this.state
    axios.get(apis.BASE_URL + 'projects/' + projectId + '/added-modules', config)
      .then(res => {
        let modules = res.data.items
        let listDeployed = []
        let listTran = []
        modules.map((module, ind) => {
          axios.get('https://rtcenter.org/services/ecommerce-workspace-addon', { params: { name: module.plan_name, currency: currency } })
            .then(res => {
              let status = res.data.status
              if (status === 'success') {
                listDeployed.push(res.data.msg[0])
                _this.setState({
                  listDeployed: listDeployed,
                })
                if (module.status === "2") {
                  listTran.push(res.data.msg[0])
                  _this.setState({
                    listTran: listTran,
                  })
                }
              }
            })
        })
      })
    axios.get(apis.BASE_URL + 'projects/' + projectId + '/module-list/' + paginationDeployed.currentPage + '/' + paginationDeployed.perPage + '/[]/[]', config)
      .then(res => {
        let items = res.data.items
        let moduleDeployed = []
        items.map((item, index) => {
          axios.get('https://rtcenter.org/services/ecommerce-workspace-addon', { params: { name: item.plan_name, currency: currency } })
            .then(res => {
              let status = res.data.status
              if (status === 'success') {
                moduleDeployed.push(res.data.msg[0])
                _this.setState({
                  moduleDeployed: moduleDeployed,
                })
              }
            })
        })
        _this.setState({
          listStatusModule: items,
          paginationDeployed: {
            currentPage: parseInt(res.data._meta.currentPage),
            pageCount: parseInt(res.data._meta.pageCount),
            perPage: parseInt(res.data._meta.perPage),
            totalCount: parseInt(res.data._meta.totalCount)
          }
        })
        if (listModuleWP.length > 0) {
          let { dispatch } = _this.props
          let moduleList = []
          let dependentList = []
          let added = []
          axios.get(apis.BASE_URL + 'projects/' + projectId + '/added-modules', config)
            .then(res => {
              let modules = res.data.items
              listModuleWP.map((item, ind) => {
                if (modules.map(function (itemName) { return itemName.plan_name }).indexOf(item) === -1) {
                  axios.get('https://rtcenter.org/services/ecommerce-workspace-addon', { params: { name: item, currency: currency } })
                    .then(res => {
                      let status = res.data.status
                      if (status === 'success') {
                        let addon = res.data.msg[0]
                        if (addon.name === item) {
                          moduleList = moduleList.concat(addon)
                          addon.dependencies.map((dependentItem, index) => {
                            if (_this.checkItemInArr(listModuleWP, dependentItem.name) === false) {
                              if (modules.map(function (itemName) { return itemName.plan_name }).indexOf(dependentItem.name) === -1) {
                                if (_this.getIndexOfItemInArrObj(dependentList, dependentItem.name) === -1) {
                                  dependentList.push(dependentItem)
                                }
                              }
                            }
                          })
                        }
                      }
                      let amount = _this.getTotalPrice(moduleList.concat(dependentList))
                      _this.setState({
                        module_list: moduleList,
                        list_dependent: dependentList,
                        freeCredit: amount.freeCredit,
                        amount: amount.price
                      })
                      dispatch(updateFreeCredit(amount.freeCredit))
                    })
                } else {
                  axios.get('https://rtcenter.org/services/ecommerce-workspace-addon', { params: { name: item, currency: currency } })
                    .then(res => {
                      let status = res.data.status
                      if (status === 'success') {
                        let addon = res.data.msg[0]
                        added.push(addon);
                        _this.setState({
                          moduleWarning: added
                        })
                      }
                    })
                }
              })
            })
        }
      })
      .catch(error => {
        console.log(error)
      });

    if (projectId) {
      axios.get(apis.BASE_URL + 'projects/1/10/[]/{"e_user_project__id":"' + projectId + '"}', config)
        .then(res => {
          let projects = res.data.items;
          if (projects.length > 0) {
            _this.setState({
              payment: {
                method: projects[0].method,
                projectName: projects[0].proj_name,
                transactionId: projects[0].transaction_id
              }
            })
          }
        }).catch(error => {
          console.log(error)
        });
    }
  }

  componentWillUnmount() {
    let { dispatch } = this.props
    dispatch(updateFreeCredit(0))
  }

  confirmPurchase = () => {
    this.props.onClickStatus(1);
  }

  checkItemInArr = (arr, item) => {
    let index = arr.indexOf(item);
    if (index !== -1) {
      return true
    } else {
      return false
    }
  }

  getIndexOfItemInArrObj(arr, item) {
    return arr.map(function (itemName) {
      return itemName.name
    }).indexOf(item)
  }

  getTotalPrice = (moduleList) => {
    let price = { "price": 0, "freeCredit": 0 }
    if (moduleList.length > 0) {
      moduleList.map((module, ind) => {
        price.price += module.price
        if (module.content.freeCredit) {
          price.freeCredit += module.content.freeCredit
        }
      })
    }
    return price
  }

  checkModulePurchase = (module, action) => {
    let { module_list, listDeployed, listTran } = this.state
    let listDepen = []
    if (action === "ADD") {
      if (this.getIndexOfItemInArrObj(module_list, module.name) === -1) {
        if (this.getIndexOfItemInArrObj(listDeployed, module.name) === -1) {
          module_list = module_list.concat(module)
        }
      }
    } else if (action === "REMOVE") {
      let index = this.getIndexOfItemInArrObj(module_list, module.name)
      if (index !== -1) {
        module_list.splice(index, 1)
      }
      if(listTran.length>0){
        let noTran=[]
        listTran.map((item, i) => {
          if (this.getIndexOfItemInArrObj(module_list, item.name) !== -1) {
              noTran=noTran.concat(item)
          }
        })
        if(noTran.length===0){
          this.setState({
            isTransaction: false
          })
        }
      }
      
    }
    module_list.map((item, ind) => {
      item.dependencies.map((moduleDepen, id) => {
        if (this.getIndexOfItemInArrObj(listDeployed, moduleDepen.name) === -1) {
          if (this.getIndexOfItemInArrObj(module_list, moduleDepen.name) === -1) {
            if (this.getIndexOfItemInArrObj(listDepen, moduleDepen.name) === -1) {
              listDepen = listDepen.concat(moduleDepen)
            }
          }
        }
      })
    })
    let price = this.getTotalPrice(module_list.concat(listDepen))
    return {
      modulesList: module_list,
      modulesDependent: listDepen,
      freeCredit: price.freeCredit,
      price: price.price
    };
  }

  addModule = (moduleAdd) => {
    let { dispatch } = this.props
    let module = this.checkModulePurchase(moduleAdd, "ADD")
    this.setState({
      module_list: module.modulesList,
      list_dependent: module.modulesDependent,
      freeCredit: module.freeCredit,
      amount: module.price
    })
    dispatch(updateFreeCredit(module.freeCredit))
  }

  removeModule = (moduleRemove) => {
    let { dispatch } = this.props
    let module = this.checkModulePurchase(moduleRemove, "REMOVE")
    this.setState({
      module_list: module.modulesList,
      list_dependent: module.modulesDependent,
      freeCredit: module.freeCredit,
      amount: module.price
    })
    dispatch(updateFreeCredit(module.freeCredit))
  }

  changeMetaModuleDeployed(meta) { 
    this.setState({ showLoading: true });
    let currency = localStorage.getItem("currency")
    let _this = this
    let { projectId } = _this.props
    let statusActive=false
    let statusDeloyFail=false
    let statusDeloying=false

      axios.get(apis.BASE_URL + 'projects/' + projectId + '/added-modules', utils.getConfig())
        .then(res => {
          res.data.items.map((item)=>{
              if(item.status==='1'){
                statusActive=true;
              }
              else if(item.status==='-2'){
                statusDeloyFail=true;
              }
              else if(item.status==='4'){
                statusDeloying=true;
              }
          })
          this.setState({
            statusActive:statusActive,
            statusDeloyFail:statusDeloyFail,
            statusDeloying:statusDeloying
          })
        });
    axios.get(apis.BASE_URL + 'projects/' + projectId + '/module-list/' + meta.currentPage + '/' + meta.perPage + '/[]/'+_this.state.filterStatusModule.filter, utils.getConfig())
      .then(res => {
        let items = res.data.items
        let moduleDeployed = []
        items.map((item, index) => {
          axios.get('https://rtcenter.org/services/ecommerce-workspace-addon', { params: { name: item.plan_name, currency: currency } })
            .then(res => {
              let status = res.data.status
              if (status === 'success') {
                moduleDeployed.push(res.data.msg[0])
                _this.setState({
                  moduleDeployed: moduleDeployed,
                })
              }
            })
        })
        _this.setState({ showLoading: false });
        _this.setState({
          listStatusModule: items,
          paginationDeployed: {
            currentPage: parseInt(res.data._meta.currentPage),
            pageCount: parseInt(res.data._meta.pageCount),
            perPage: parseInt(res.data._meta.perPage),
            totalCount: parseInt(res.data._meta.totalCount)
          }
        })
      })
  }

  changeMetaModule(meta) {
    let { t } = this.props
    this.setState({ showLoading: true });
    let currency = localStorage.getItem("currency")
    let params = { orderBy: this.state.filter.orderBy, search: this.state.filter.searchName, limit: meta.perPage, page: meta.currentPage, currency: currency }
    let dataFilter = { orderBy: this.state.filter.orderBy, search: this.state.filter.searchName }
    let _this = this
    axios.get('https://rtcenter.org/services/ecommerce-workspace-addon', { params: params })
      .then(res => {
        window.scrollTo(0, 0);
        _this.setState({ showLoading: false });
        let moduleList = res.data.msg
        let pagination = {
          totalCount: parseInt(res.data.totalPlans),
          currentPage: parseInt(res.data.currentPage),
          pageCount: Math.ceil(parseInt(res.data.totalPlans) / parseInt(meta.perPage)),
          perPage: parseInt(meta.perPage)
        }
        _this.props.changeMetaModule(moduleList, pagination, dataFilter)
      })
      .catch(error => {
        _this.refs.toastContainer.error(t("Cannot get user project"));
        console.log(error);
      });
  }

  onSearchModule = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      filter: {
        searchName: value,
        orderBy: this.state.filter.orderBy,
      },
      focusSearch: true
    })
    if (event.keyCode === 13) {
      event.preventDefault();
      this.props.onFilter(this.state.filter)
      return true
    }
    if (value.length === 0 && event.keyCode === undefined) {
      this.setState({ focusSearch: false })
      this.props.onFilter({ "searchName": '', "orderBy": this.state.filter.orderBy })
    }
  }

  onClearSearch = () => {
    if (this.state.filter.searchName) {
      this.setState({ filter: { searchName: '', orderBy: this.state.filter.orderBy, }, focusSearch: false })
      this.props.onFilter({ "searchName": '', "orderBy": this.state.filter.orderBy })
    }
  }

  onSortModule = (status) => {
    this.setState({
      filter: {
        searchName: this.state.filter.searchName,
        orderBy: status,
      }
    })
    this.props.onFilter(this.state.filter)
  }

  handleBankTransfer = (name, value) => {
    let data = this.state.payment;
    if (name === 'circle') {
      value = parseInt(value)
    }
    data[name] = value;
    this.setState(data);
  }

  purchaseTransaction = () => {
    let { module_list, amount, freeCredit, listTran } = this.state
    let modulesList = []
    if (module_list.length > 0) {
      listTran.map((item, i) => {
        if (this.getIndexOfItemInArrObj(module_list, item.name) === -1) {
          modulesList = modulesList.concat(item)
        }
      })
    } else {
      modulesList = modulesList.concat(listTran)
    }

    let price = this.getTotalPrice(modulesList)
    this.setState({
      module_list: module_list.length > 0 ? module_list.concat(modulesList) : modulesList,
      amount: amount > 0 ? amount + price.price : price.price,
      freeCredit: freeCredit > 0 ? freeCredit + price.freeCredit : price.freeCredit,
      isTransaction: true
    })
    this.props.onClickStatus(1);
    this.props.dispatch(updateFreeCredit(freeCredit > 0 ? freeCredit + price.freeCredit : price.freeCredit))
  }
  filterStatusModule(filter,name){
    if(this.state.filterStatusModule.name===name){
      return false
    }
    this.setState({
      filterStatusModule: {
        filter: filter,
        name:name
      },
      paginationDeployed: {
        currentPage: 1,
        pageCount: 0,
        perPage: 6,
        totalCount: 0
      }
    }, () => {
      this.changeMetaModuleDeployed(this.state.paginationDeployed)
  })
  }
  updateStatusDeloy(meta) {
    this.setState({ showLoading: true });
    let currency = localStorage.getItem("currency")
    let _this = this
    let { projectId } = _this.props
    let filter='[]'
    let statusActive=false
    let statusDeloyFail=false
    let statusDeloying=false
    axios.get(apis.BASE_URL + 'projects/' + projectId + '/added-modules', utils.getConfig())
        .then(res => {
          res.data.items.map((item)=>{
              if(item.status==='1'){
                statusActive=true;
              }
              else if(item.status==='-2'){
                statusDeloyFail=true;
              }
              else if(item.status==='4'){
                statusDeloying=true;
              }
          })
          this.setState({
            statusActive:statusActive,
            statusDeloyFail:statusDeloyFail,
            statusDeloying:statusDeloying
          })
        });
    if(this.state.filterStatusModule.filter!=='[]'){
        filter='{"e_project_subscription__status":-2}';
    }
    axios.get(apis.BASE_URL + 'projects/' + projectId + '/module-list/' + meta.currentPage + '/' + meta.perPage + '/[]/'+filter, utils.getConfig())
      .then(res => {
       
        let items = res.data.items
        if(this.state.filterStatusModule.filter!=='[]'){
          let moduleDeployed = []
          items.map((item, index) => {
            axios.get('https://rtcenter.org/services/ecommerce-workspace-addon', { params: { name: item.plan_name, currency: currency } })
              .then(res => {
                let status = res.data.status
                if (status === 'success') {
                  moduleDeployed.push(res.data.msg[0])
                  _this.setState({
                    moduleDeployed: moduleDeployed,
                  })
                }
              })
          })
        }
        _this.setState({ showLoading: false });
        _this.setState({
          listStatusModule: items,
          paginationDeployed: {
            currentPage: parseInt(res.data._meta.currentPage),
            pageCount: parseInt(res.data._meta.pageCount),
            perPage: parseInt(res.data._meta.perPage),
            totalCount: parseInt(res.data._meta.totalCount)
          }
        })
      })
  }
  render() {
    let { t, listofmodules, activeButton, balance, projectId, pagination } = this.props
    let { module_list, list_dependent, moduleDeployed, filter, payment, amount, freeCredit, listStatusModule, showLoading, paginationDeployed, listDeployed, listTran, moduleWarning, isTransaction } = this.state
    moduleDeployed.sort((a, b) => (utils.handleLanguageModule(a.content.title) > utils.handleLanguageModule(b.content.title)) ? 1 : -1)
    let title, elmModule, dependenciesClass = ''
    let confirmPurchase = <div className="total-count">
      <div className="m-pricing-table toastr-btn-confirm-purchase">
        <div className="btn-group m-btn-group  m-btn-group--air " role="group">
          <button type="button" className="btn m-btn--pill m-btn--wide m-btn--uppercase m-btn--bolder activeBtn" onClick={this.confirmPurchase}>
            {t("Confirm Purchase")}
          </button>
        </div>
      </div>
    </div>
    let purchaseTransaction = <div className="total-count">
      <p className="warning_tran">{t("You have ") + listTran.length + t(" modules in transaction")}</p>
      <div className="m-pricing-table toastr-btn-confirm-purchase">
        <div className="btn-group m-btn-group  m-btn-group--air " role="group">
          <button type="button" className="btn m-btn--pill m-btn--wide m-btn--uppercase m-btn--bolder activeBtn" onClick={this.purchaseTransaction}>
            {t("Purchase Transaction")}
          </button>
        </div>
      </div>
    </div>
    let searchBar = <div className="col-md-9 search_container fl-right">
      <form >
        <input name="searchName" autoComplete="off" type="text" id="search_bar" placeholder={t("    Type keywords and press enter")} value={filter.searchName} onChange={(e) => this.onSearchModule(e)} onKeyDown={(e) => this.onSearchModule(e)} />
        <button
          type="reset"
          className={this.state.focusSearch === false ? "display-none" : ''}
        >
          <span className="flaticon-close" onClick={this.onClearSearch}></span>
        </button>
      </form>
    </div>
    let sortBar = <div className="col-md-3 sort_bar fl-right">
      <button className="dropbtn"><span>{t("Price")}: <b>{this.state.filter.orderBy === "price ASC" ? t("Lowest") : t("Highest")}</b></span></button>
      <div className="col-md-12 dropdown-content">
        <button name="orderBy" onClick={() => this.onSortModule("price ASC")} className="sort_item">{t("Lowest")}</button>
        <button name="orderBy" onClick={() => this.onSortModule("price DESC")} className="sort_item">{t("Highest")}</button>
      </div>
    </div>

    let filterCurrent = <div className="col-md-4 sort_bar fl-right">
      <button className="dropbtn"><span>{t("Status")}: <b>{t(this.state.filterStatusModule.name)}</b></span></button>
      <div className="col-md-12 dropdown-content">
        <button  onClick={() => this.filterStatusModule('[]','All')} className="sort_item">{t("All")}</button>
        {this.state.statusActive ? <button  onClick={() => this.filterStatusModule('{"e_project_subscription__status":1}','Active')} className="sort_item">{t("Active")}</button> : null}
        {this.state.statusDeloyFail ? <button  onClick={() => this.filterStatusModule('{"e_project_subscription__status":-2}','Deploy Fail')} className="sort_item">{t("Deploy Fail")}</button> : null}
        {this.state.statusDeloying ? <button  onClick={() => this.filterStatusModule('{"e_project_subscription__status":4}','Deploying')} className="sort_item">{t("Deploying")}</button> : null}
      </div>
    </div>

    let warning = moduleWarning.length > 0 ? <Popup modal trigger={<span className="ref-code-warning warning-deploy-module">WARNING: {t("Some modules have been deployed on this site. Click to see which modules have been deployed.")}</span>}>
      <span>
        <div className="modal-header modal_header">
          <h4 className="modal-title" id="exampleModalLongTitle">{t("Modules have been deployed")}</h4>
        </div>
        <div className="content modal_content">
          <Row className="boder_module">
            {moduleWarning.map((module, ind) => {
              return <Col key={ind} className="col-sm-4 module-detail-margin">
                <img
                  className="module-image"
                  alt={module.hasOwnProperty("content") && module.content.hasOwnProperty("title") && utils.handleLanguageModule(module.content.title)}
                  src={module.hasOwnProperty("content") && module.content.hasOwnProperty("thumbnailUrl") && module.content.thumbnailUrl}
                />
                <h5 className="m-pricing-table__title">{module.hasOwnProperty("content") && module.content.hasOwnProperty("title") && utils.handleLanguageModule(module.content.title)}</h5>
                {module.hasOwnProperty("content") && module.content.hasOwnProperty("htmlContent") && ReactHtmlParser(utils.handleLanguageModule(module.content.htmlContent))}
              </Col>
            })}
          </Row>
        </div>
      </span>
    </Popup> : ""

    if (activeButton === -1) {
      title = t('Deployed Modules')
      elmModule = moduleDeployed.map((module, idx) => {
        return <Module key={module.name} t={t} module={module} meta={paginationDeployed} updateDeloy={this.updateStatusDeloy.bind(this)} 
          activeButton={activeButton} freeCredit={freeCredit} listStatusModule={listStatusModule}
          addModule={this.addModule} removeModule={this.removeModule} projectId={projectId} module_list={module_list} />
      })
    } else if (activeButton === 0) {
      title = t('Select Workflows To Deploy')
      elmModule = listofmodules.map((module, idx) => {
        return <Module key={module.name} t={t} module={module}
          activeButton={activeButton} freeCredit={freeCredit} listStatusModule={listStatusModule}
          addModule={this.addModule} removeModule={this.removeModule} projectId={projectId} module_list={module_list} />
      })
    } else if (activeButton === 1) {
      title = t('Select Modules To Be Deployed')
      elmModule = module_list.map((module, idx) => {
        dependenciesClass = <div>
          <Portlet.Title>
            <Portlet.Caption>
              <h3 className="m-portlet__head-text total-count">{t("Dependencies")}</h3>
            </Portlet.Caption>
          </Portlet.Title>
          <Portlet.Body>
            <Row>
              {
                list_dependent.map((module, idx) => {
                  return <Dependencies key={module.name} t={t} module={module} />
                })
              }
            </Row>
          </Portlet.Body>
        </div>
        return <Module key={module.name} t={t} module={module}
          activeButton={activeButton} freeCredit={freeCredit} listStatusModule={listStatusModule}
          addModule={this.addModule} removeModule={this.removeModule} projectId={projectId} module_list={module_list} />
      })
    }
    let module_deloy=(listStatusModule.length === 0 || listofmodules.length === 0) ?
        <Portlet.Body> <div className="total-count toastr-btn-wrapper">{t("There are no modules")}</div></Portlet.Body> :
        <Portlet.Body>
          <Row >
            {elmModule}
          </Row>
          {dependenciesClass}
          <div className="total-count">
            {activeButton === 1 ? <PaymentMethod t={t} isTransaction={isTransaction} listTran={listTran} balance={balance} payment={payment} amount={amount} projectId={projectId} freeCredit={freeCredit} listDeployed={listDeployed} module_list={module_list} list_dependent={list_dependent} onHandleChange={this.handleBankTransfer} /> : ''}
          </div>
          <span className="paginationModule">
            {activeButton === 0 && <PaginationList t={t} meta={pagination} changeMeta={this.changeMetaModule.bind(this)} />}
            {activeButton === -1 && <PaginationList t={t} meta={paginationDeployed} changeMeta={this.changeMetaModuleDeployed.bind(this)} />}
          </span>
          {activeButton === 0 && confirmPurchase}
          {activeButton === -1 && listTran.length > 0 && purchaseTransaction}
        </Portlet.Body>
    return (
      <Portlet className="list-modules">
        {showLoading && <LoadingPage />}
        <Portlet.Title>
          <Row>
            <Col className={activeButton === 1 ? "col-sm-12" : "col-sm-4"}>
              <Portlet.Caption>
                <h3 className="m-portlet__head-text">{title}</h3>
                {activeButton === 1 && warning}
              </Portlet.Caption>
            </Col>
            {activeButton === 0 &&
              <Col className="col-sm-8 container">
                <Row>
                  {searchBar}
                  {sortBar}
                </Row>
              </Col>}
              {activeButton === -1 &&
              <Col className="col-sm-8">
                  {filterCurrent}
              </Col>}
          </Row>
        </Portlet.Title>
        {
          listofmodules.length !== 0 && activeButton !== -1?
            <Portlet.Body>
              <Row >
                {elmModule}
              </Row>
              {dependenciesClass}
              <div className="total-count">
                {activeButton === 1 ? <PaymentMethod t={t} isTransaction={isTransaction} listTran={listTran} balance={balance} payment={payment} amount={amount} projectId={projectId} freeCredit={freeCredit} listDeployed={listDeployed} module_list={module_list} list_dependent={list_dependent} onHandleChange={this.handleBankTransfer} /> : ''}
              </div>
              <span className="paginationModule">
                {activeButton === 0 && <PaginationList t={t} meta={pagination} changeMeta={this.changeMetaModule.bind(this)} />}
                {activeButton === -1 && <PaginationList t={t} meta={paginationDeployed} changeMeta={this.changeMetaModuleDeployed.bind(this)} />}
              </span>
              {activeButton === 0 && confirmPurchase}
              {activeButton === -1 && listTran.length > 0 && purchaseTransaction}
            </Portlet.Body>
            : module_deloy
        }
      </Portlet>
    );
  }
}

export const mapStateToProps = (state) => ({
  balance: state.ecommerce.balance,
  freeCredit: state.ecommerce.freeCredit
})

export default connect(mapStateToProps, null)(ListModules);
