import { ERRORCODE } from './constants/ErrorCode'
import i18n from './i18n';

// union two object
export const unionObject = (target, source) => {
  return Object.keys(target).reduce((obj, key) => {
    if (source.hasOwnProperty(key) && source[key] !== null) {
      obj[key] = source[key];
    } else {
      obj[key] = target[key];
    }
    return obj;
  }, {});
}

// Convert PHP Unix Timestamp to Javascript Timestamp format
export const convertTimestamp = (time) => {
  let string = parseInt(time) * 1000;
  let datetime = new Date(string).toLocaleString();
  return datetime;
}

export const getFromDate = (time) => {
  let string = parseInt(time) * 1000;
  // create date
  let startDate = new Date(string);
  let sdd = startDate.getDate();
  //date now
  let date = new Date();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();
  let yyyy = date.getFullYear();

  let fmm = 0             //from month
  let fyyyy = yyyy          //from year
  let tmm = 0           // to month
  let tyyyy = yyyy          // to year

  if (dd >= sdd) {
    tmm = mm + 1
    fmm = mm
    if (tmm === 13) {
      tmm = 1
      tyyyy += 1
    }
  } else {
    fmm = mm - 1
    tmm = mm
    if (fmm === 0) {
      fmm = 12
      fyyyy -= 1
    }
  }
  let dateTimeFrom = formatDate(new Date(fyyyy + '-' + fmm + '-' + sdd))
  let dateTimeTo = formatDate(new Date(tyyyy + '-' + tmm + '-' + sdd))

  return {
    dateTimeFrom: `${dateTimeFrom.year}-${dateTimeFrom.month}-${dateTimeFrom.day}`,
    dateTimeTo: `${dateTimeTo.year}-${dateTimeTo.month}-${dateTimeTo.day}`
  }
}

export const formatDate = (date) => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' })
  const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat .formatToParts(date)
  return {day, month, year};
}

export const handleLanguageModule = (string) => {
  let language = localStorage.getItem("language")
  let pattern = new RegExp('\<' + language + '\>(.*?)\<\/' + language + '\>');
  if (string) {
    if (string.match(pattern)) {
      let matches = string.match(pattern)
      return matches[1]
    } else {
      return string
    }
  }
}

export const handleCurrency = (price) => {
  if (price !== undefined) {
    let currency = localStorage.getItem("currency")
    price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    if (currency === "VND") {
      return price + " VND"
    } else {
      return "$" + price
    }
  }
}

export const convertTimestampToDate = (time) => {
  let string = parseInt(time) * 1000;
  let datetime = new Date(string);
  let mm = datetime.getMonth() + 1;
  let dd = datetime.getDate();
  let yyyy = datetime.getFullYear();
  let h = addZero(datetime.getHours());
  let m = addZero(datetime.getMinutes());
  let s = addZero(datetime.getSeconds());
  let format = yyyy + '/' + mm + '/' + dd +' '+h + ":" + m + ":" + s;;
  return format;
}

export const addZero=(i)=> {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

export const validateEmail = (email) => {
  let regex = /\S+@\S+\.\S+/;
  return email.match(regex)
}

export const validateNumber = (number) => {
  let regex = /^[0-9]*$/;
  return regex.test(number)
}

export const validateNotDot = (number) => {
  let regex = /\./;
  return !regex.test(number)
}

export const validateDomain = (domain) => {
  let regex = /^[a-zA-Z0-9]*$/;
  return regex.test(domain) && domain.length <= 63
}

export const getDisplayBalance = (balance) => {
  return (balance > 0) ? "-" + handleCurrency(balance) : handleCurrency(Math.abs(balance))
}

// Get current access token
export const getAccessToken = () => (localStorage.getItem("access_token"));

// get current logined user id
export const getUserId = () => (localStorage.getItem("id"));

// create config for api
export const getConfig = () => ({ headers: { "Authorization": "Bearer " + getAccessToken() } })

// catch error
export const catchMessageError = (code, index = '') => {
  if (index !== '') {
    let error = ""
    switch (code) {
      case "P002":
        error = i18n.t('Project name') + index + i18n.t('has already been taken')
        break;

      case "P005":
        error = i18n.t('Invalid Input') + index
        break;

      case "F0011":
        error = i18n.t('Missing') + index + i18n.t('param')
        break

      case "S001":
        error = i18n.t('Param ') + index + i18n.t(' is not allowed')
        break;

      case "S002":
        error = i18n.t('Missing param ') + index
        break;

      default:
        break;
    }
    return error
  }
  return ERRORCODE.hasOwnProperty(code) ? i18n.t(ERRORCODE[code]) : i18n.t(ERRORCODE['defa'])
}

// get project status
export const projectStatusText = {
  "-2": "DEPLOY FAIL",
  "-1": "INACTIVE",
  "0": "PAUSE",
  "1": "ACTIVE",
  "2": "IN TRANSACTION",
  "3": "PAID",
  "4": "DEPLOYING",
  "5": "UNPAID",
  "6": "VERIFYING PAYMENT",
  "8": "VERIFY FAIL"
}
export const projectStatusClassName = {
  "-2": "m-badge m-badge--danger m-badge--wide",
  "-1": "m-badge m-badge--metal m-badge--wide",
  "0": "m-badge m-badge--pending m-badge--wide",
  "1": "m-badge m-badge--accent m-badge--wide",
  "2": "m-badge m-badge--brand m-badge--wide",
  "3": "m-badge m-badge--success m-badge--wide",
  "4": "m-badge m-badge--warning m-badge--wide",
  "5": "m-badge m-badge--secondary m-badge--wide",
  "6": "m-badge m-badge--pink m-badge--wide",
  "8": "m-badge m-badge--danger m-badge--wide"
}

// get website status
export const websiteStatusText = {
  "-1": "DEPLOY FAIL",
  "0": "DEPLOYING",
  "1": "DEPLOYED",
}
export const websiteStatusClassName = {
  "-1": "m-badge m-badge--danger m-badge--wide",
  "0": "m-badge m-badge--warning m-badge--wide",
  "1": "m-badge m-badge--success m-badge--wide",
}

export const domainHosts = {
  "godaddy": "Godaddy",
  "google": "Google",
  "pavietnam": "PAVietnam",
}


export const readUploadedFileAsBinaryString = inputFile => {
  const temporaryFileReader = new FileReader();

  return new Promise(async (resolve, reject) => {
    try {
      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result)
      }
      await temporaryFileReader.readAsBinaryString(inputFile)
    } catch (err) {
      temporaryFileReader.abort()
      reject(err.message)
    }
  })
}
