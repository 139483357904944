import * as types from '../constants/ActionTypes';

const initialState = {
    balance: 0,
    freeCredit:0,
    projectId: '',
    userInfo: {},
    projectList: {
        data: [],
        meta: []
    },
    projectTrans: [],
    projectTempData: {
        step: "",
        projectId: "",
        projectName: "",
        plan: "",
        region: "",
        couponApplied: "",
        circle: 1,
        method: "",
        transactionId: ""
    },
    userList: {
        data: [],
        _meta: []
    },
    submissionList: {
        data: [],
        _meta: []
    },
    projectDetail: {},
    adminProject: {
        data: [],
        meta: []
    },
    adminUsers: {
        data: [],
        meta: []
    },
    websitesList: {
        data: [],
        _meta: []
    }
};

export default function ecommerce(state = initialState, action = {}) {
    switch (action.type) {

        case types.UPDATE_PROJECT_ID:
            return Object.assign({}, state, {
                projectId: action.id
            });

        case types.UPDATE_BALANCE:
            return Object.assign({}, state, {
                balance: action.balance
            });

        case types.UPDATE_FREECREDIT:
            return Object.assign({}, state, {
                freeCredit: action.freeCredit
            });

        case types.UPDATE_USERINFO:
        	let userInfo = Object.assign({}, state.userInfo, action.data);
            return Object.assign({}, state, {
                userInfo: userInfo
            });

        case types.SET_PROJECTLIST:
            let projectList= {};
            projectList.data = action.value.data;
            projectList.meta = action.value.meta;
            return Object.assign({}, state, {
                projectList
            });

        case types.SET_PROJECTTRANS:
            return Object.assign({}, state, {
                projectTrans: action.value
            });

        case types.UPDATE_TEMP_DATA:
            return Object.assign({}, state, {
                projectTempData: action.value
            });

        case types.SET_USERLIST:
            return Object.assign({}, state, {
                userList: action.value
            });

        case types.SET_SUBMISSIONLIST:
            return Object.assign({}, state, {
                submissionList: action.value
            })

        case types.SET_PROJECTDETAIL:
            return Object.assign({}, state, {
                projectDetail: action.value
            })

        case types.SET_ADMINPROJECT:
            return Object.assign({}, state, {
                adminProject: action.value
            })

        case types.SET_ADMINUSER:
            return Object.assign({}, state, {
                adminUsers: action.value
            })

        case types.SET_WEBSITES_LIST:
            return Object.assign({}, state, {
                websitesList: action.value
            })

        default:
            return state;
    }
}
