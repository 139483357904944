import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import * as apis from '../../constants/Apis';
import * as utils from '../../utils';
import SuccessIcon from '../../components/Layout/SuccessIcon'
import background from '../../assets/bg-3.jpg';

class Verify extends React.Component {
    constructor(props){
        super(props);
        let params = new URLSearchParams(props.location.search);
        let code = params.get("code") ? params.get("code") : ""
        this.state = {
            code: code,
            verifyStatus: 0,
            error: "",
            error_code:true
        }
    }
    componentDidMount() {
        let _this = this
        let {code} = this.state
        let config = utils.getConfig()
        axios.post(apis.BASE_URL + 'users/verify', {"verify_code": code}, config)
        .then(() => _this.setState({verifyStatus: 1}))
        .catch((error) => {
            let msg = ""
            if(error.response.data && error.response.data.hasOwnProperty('error_code')) {
                if(error.response.data.error_code==='U014'){
                    _this.setState({error_code: false})
                }
                msg = utils.catchMessageError(error.response.data.error_code)
            }
            _this.setState({verifyStatus: -1, error: msg})
        })
    }

    resendCode(){
        const _this = this
        let {code} = this.state
        let config = utils.getConfig()
        axios.post(apis.BASE_URL + 'users/resend-code', {"verify_code": code}, config)
        .then(() => _this.setState({verifyStatus: 2}))
        .catch((error) => {
            let msg = ""
            if(error.response.data && error.response.data.hasOwnProperty('error_code')) {
                msg = utils.catchMessageError(error.response.data.error_code)
            }
            _this.setState({verifyStatus: -1, error: msg})
        })
    }

    render(){
        const {t} = this.props
        let {verifyStatus, error} = this.state
        if (verifyStatus === 1) {
            // verify success
            return (
                <div className="m-grid__item m-grid__item--fluid m-grid  m-error-6 m-login m-login--signin  m-login--5" style={{backgroundImage: "url("+background+")"}}>
                    <div className="m-error_container m-success-container">
                        <SuccessIcon />
                        <div className="m-error_subtitle m--font-dark m-success-subtitle">
                            <h1>{t('Verify Success')}</h1>
                        </div>
                        <div className="swal2-actions" style={{display: "flex"}}>
                            <Link to={{ pathname: "/sign-in"}} className="swal2-confirm btn btn-success m-btn m-btn--custom" aria-label="">{t('Sign In Your Account')}</Link>
                        </div>
                    </div>
                </div>
            );
        }  else if (verifyStatus === 2) {
            // resend code success
            return (
                <div className="m-grid__item m-grid__item--fluid m-grid  m-error-6 m-login m-login--signin  m-login--5" style={{backgroundImage: "url("+background+")"}}>
                            <div className="m-error_container m-success-container">
                                <SuccessIcon />
                                <div className="m-error_subtitle m--font-dark m-success-subtitle">
                                    <h2>{t('Resend code success')}</h2>
                                    <p>{t('Please check your email to active and then sign in')}</p>
                                </div>
                                <div className="swal2-actions" style={{display: "flex"}}>
                                    <Link to={{ pathname: "/sign-in"}} className="swal2-confirm btn btn-success m-btn m-btn--custom" aria-label="">{t('Sign In Your Account')}</Link>
                                </div>
                            </div>
                        </div>
            );
        } else if (verifyStatus === -1) {
            // verify failed
            return (
                <div className="m-grid__item m-grid__item--fluid m-grid  m-error-6 m-login m-login--signin  m-login--5" style={{backgroundImage: "url("+background+")"}}>
                    <div className="m-error_container m-success-container">
                        <div className="swal2-icon swal2-error swal2-animate-error-icon" style={{display: "flex"}}><span className="swal2-x-mark"><span className="swal2-x-mark-line-left"></span><span className="swal2-x-mark-line-right"></span></span></div>
                        <div className="m-error_subtitle m--font-dark m-success-subtitle">
                            <h1>{t('Verify Failed')}</h1>
                        </div>
                        {
                            error !== "" ? (
                                <p className="m-error_description m--font-dark">
                                    {error === "Authcode Expired" ? t('Authcode Expired') : t(error)} {t('Please try again')}.
                                </p>
                            ) : null
                        }
                        <div className="swal2-actions" style={{display: "flex"}}>
                            { this.state.error_code ? <button className="btn btn-info m--margin-right-10" onClick={this.resendCode.bind(this)}>{t('Resend Code')}</button> : null}
                            <Link to={{ pathname: "/"}} className="btn btn-success" aria-label="">{t('Back to home')}</Link>
                        </div>
                    </div>
                </div>
            );
        } else {
            // verifying
            return (
                <div className="m-grid__item m-grid__item--fluid m-grid  m-error-6 m-login m-login--signin  m-login--5" style={{backgroundImage: "url("+background+")"}}>
                    <div className="m-error_container m-success-container">
                        <div className="m-error_subtitle m--font-dark m-success-subtitle" style={{display: "flex", justifyContent: "center"}}>
                            <h3 className="m--margin-right-20">{t('Verifying')}</h3>
                            <div className="loading">
                                <div className="m-loader m-loader--brand" style={{width: "30px", display: "inline-block"}}></div>
                                <div className="m-loader m-loader--primary" style={{width: "30px", display: "inline-block"}}></div>
                                <div className="m-loader m-loader--success" style={{width: "30px", display: "inline-block"}}></div>
                                <div className="m-loader m-loader--info" style={{width: "30px", display: "inline-block"}}></div>
                                <div className="m-loader m-loader--warning" style={{width: "30px", display: "inline-block"}}></div>
                                <div className="m-loader m-loader--danger" style={{width: "30px", display: "inline-block"}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    }
}

export default Verify;
