import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import Layout from '../../components/Layout';import Portlet from '../../components/Portlet';
import DataTable from '../../components/DataTable';
import { convertTimestampToDate } from '../../utils';
import PaymentModal from './PaymentModal';
import LoadingPage from '../../components/Layout/LoadingPage';
import {setProjectList, setProjectTrans} from '../../actions/ecommerce';
import Countdown from "react-countdown-now";
import {Row, Col } from 'react-bootstrap';
import {ToastContainer} from 'react-toastr';
import '../../assets/css/toastr.css';
import { Modal } from 'react-bootstrap';

const mergeData = ((plan, projectTrans) =>{
    // change plan same plan_name: price
    let planNew={};
        Object.keys(plan).map(index=>{
            let key = plan[index].name,
            value = plan[index].full_cycle_price
            planNew[key] = value
            return true;
        })

        Object.keys(projectTrans).map(index=>{
            projectTrans[index].price_plan = planNew[projectTrans[index].plan_name]
            return true;
        })
        return projectTrans;

})

export class Project extends React.Component{
    constructor(props){
        super(props);
        let params = new URLSearchParams(props.location.search);
        let modules = []
        if (params.get("modules")) {
            modules = params.get("modules").split(",")
            localStorage.setItem('listModuleWP', JSON.stringify(modules));
        }
        this.state = {
            plan:[],
            meta:[],
            showPaymentModal: false,
            checked: [],
            checkedAll: false,
            showLoading: false,
            showModal: false,
            redirectLogin: false,
            listModuleWP: [],
            listProjects:[]
        }
    }
    componentWillMount(){
        let _this = this;
        axios.get(apis.BASE_URL + 'projects/1/9999/{"id":"SORT_DESC"}/["not",{"e_user_project__status":2}]', utils.getConfig())
        .then( res => {
            _this.setState({
                listProjects:res.data.items
            })
        })
    }
    componentDidMount(){
        let _this = this,
        checked = [],
        config = utils.getConfig();
        const {dispatch} = this.props
        this.setState({showLoading: true})
        let token = utils.getAccessToken();
        if(token === null || token === ''){
          this.setState({redirectLogin: true})
        }
        if(localStorage.getItem("listModuleWP") !== null){
          this.setState({
            showModal: true,
            listModuleWP: JSON.parse(localStorage.getItem("listModuleWP"))
          })
        }
        // get data transaction
        axios.get(apis.BASE_URL + 'projects/1/50/{"id":"SORT_DESC"}/{"e_user_project__status":2}', config)
        .then( res => {
            Object.keys(res.data.items).map(index=>{
                checked[index] =  false
                return true
            })
            _this.setState({checked});
           dispatch(setProjectTrans(res.data.items))
            // get project list
            axios.get(apis.BASE_URL + 'projects/1/10/{"id":"SORT_DESC"}/["not",{"e_user_project__status":2}]', config)
            .then( res => {
                dispatch(setProjectList({data: res.data.items,meta: res.data._meta}))
                _this.setState({showLoading: false})
            })
            .catch( error => {
                _this.setState({showLoading: false});
                _this.refs.toastContainer.error("Cannot get project list");
            });
        })
        .catch( error => {
            _this.setState({showLoading: false});
            _this.refs.toastContainer.error("Cannot get project list");
        });
    }

    deleteProject(id){
        const {t} = this.props
        if (window.confirm(t("Are you sure you want to delete this item?"))) {
            let _this = this;
            axios.delete(apis.BASE_URL + 'projects/'+id, utils.getConfig())
            .then(() => {
                window.location.reload()
            })
            .catch( error => {
                console.log(error);
                if(error.response){
                   _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code));
                }
                else{
                   _this.refs.toastContainer.error(t('Can not delete'))
                }

            });
        }
    }

    //chose payment all
    chose(ev){
        let idx = ev.target.value,
        checked = this.state.checked;
        checked[idx] = !checked[idx]
        this.setState({
            checked
        })
    }

    handleTimeLimit(id){
        axios.delete(apis.BASE_URL + 'projects/'+id, utils.getConfig())
        .catch( error =>  console.log(error));
    }

    // handle click check all in payment component
    choseCheckedAll(){
        let checked = this.state.checked;

        if(!this.state.checkedAll){
            Object.keys(checked).map(index=>{
                checked[index] = true
                return true
            })
            this.setState({
                checked,
                checkedAll: true
            })
        }
        else{
            Object.keys(checked).map(index=>{
                checked[index] = false
                return true
            })
            this.setState({
                checked,
                checkedAll: false
            })
        }
    }

    changeMeta(meta){
        let sort= {property: "id", direction: "ASC"};
        const {t} = this.props
        if(meta.sortInfo){
            sort = meta.sortInfo
        }
        let _this= this;
        let str = 'projects/'+meta.currentPage+'/'+meta.perPage+'/{"'+sort.property+'":"SORT_'+sort.direction+'"}/["not",{"e_user_project__status":2}]'
        axios.get(apis.BASE_URL + str, utils.getConfig())
        .then( res => {
             _this.props.dispatch(setProjectList({data: res.data.items,meta: res.data._meta}))
        })
        .catch( error => {
            console.log(error);
            _this.refs.toastContainer.error(t('Can not change meta'));
        });
    }

    changeSelection(e){
      if(this.refs.proId){
        e.preventDefault();
        this.props.history.push({
          pathname:"project/detail/"+this.refs.proId.value ,
          state: { activeButton: 1, listModuleWP: this.state.listModuleWP}
        })
      }
    }

    onCloseModal(){
      let {t} = this.props
      if (window.confirm(t('Warning')+': '+ t('This site will delete all modules that you have selected on rtworkspace.com'))) {
        localStorage.removeItem("listModuleWP")
        window.location = '/#/';
        this.setState({
            showModal:false
        })
      }
    }

    showModal(){
        this.setState({showPaymentModal: true})
    }

    hideModal(){
        this.setState({showPaymentModal: false})
    }

    render(){
        const {checked, showLoading, plan, showPaymentModal, checkedAll, redirectLogin} = this.state,
        { t, projectTrans, projectList} = this.props;
        if(redirectLogin === true){
          return <Redirect to='/sign-in'/>;
        }
        let pro_id = "",
        status = "",
        config = {
            sortDefault: {property: "id", direction: "DESC"},
            api: true,
            // filter: true,
            cols: [
                { property: "id", header: t("Id"),sortable: false,
                    display:"none",
                    noFilter: true,
                    decorator : () => ({ style : { width : "20%" } }),
                    renderer : value => {
                        pro_id=value
                    }
                },
                { property: "proj_url", header: t("Project URL"),sortable: true,
                    decorator : () => ({ style : { width : "35%" } }),
                    renderer : value => value ? (<a style={value.length>20?{ wordBreak: 'break-word'}:null} href={value} target="_blank" rel="noopener noreferrer">{value}</a>) : ""
                },
                { property: "status", header: t("Status"),sortable: true,
                    noFilter: true,
                    decorator : () => ({ style : { width : "15%" } }),
                    renderer : value => {
                        status = value
                        let className = utils.projectStatusClassName[value]
                        let text = utils.projectStatusText[value]
                        return (<span style= {{ width : "110px" }} className={className}>{t(text)}</span>)
                    }
                },
                { property: "created_at", header: t("Created At"),sortable: true,
                    noFilter: true,
                    decorator : () => ({ style : { width : "15%" } }),
                    renderer : value => convertTimestampToDate(value)
                },
                { property: "created_at", header: t(""),sortable: false,
                    noFilter:true,
                    decorator : () => ({ style : t('Next Billing')==='Next Billing'?{ width : "16%" }:{ width : "19%" } }),
                    renderer : value => {return(<div>{status === "1"?<Link to={{pathname: "project/billing-payment/"+pro_id+"/"+value
                                                                              }} className="btn btn-outline-info m-btn m-btn--custom m-btn--icon m-btn--air">
                                                                        <span>
                                                                            <i className="fa fa-file-excel-o"></i>
                                                                            <span style= {{ minWidth : "65px",display:'block',}}>{t('Next Billing')}</span>
                                                                        </span>
                                                                    </Link>:""}</div>);}
                },
                { property: "id", header: t(""),sortable: false,
                    noFilter:true,
                    decorator : () => ({ style : { width : "20%" } }),
                    renderer : value => {return(<div>{status === "1"?<Link to={{pathname: "project/detail/"+pro_id,
                                                                                state: { activeButton: 0}
                                                                              }} className="btn btn-outline-info m-btn m-btn--custom m-btn--icon m-btn--air">
                                                                        <span>
                                                                            <i className="fa fa-file-excel-o"></i>
                                                                            <span style= {{ minWidth : "65px",display:'block',}}>{t('Add Modules')}</span>
                                                                        </span>
                                                                    </Link>:
                                                                    <Link to={{pathname: "/project/create",
                                                                                search: "?pid="+value,
                                                                                state: { projectId: value, step: 4}
                                                                            }} className="btn btn-outline-info m-btn m-btn--custom m-btn--icon m-btn--air">
                                                                        <span>
                                                                            <i className="fa fa-file-excel-o"></i>
                                                                            <span style= {{ minWidth : "65px",display:'block',}}>{t('Add Modules')}</span>
                                                                        </span>
                                                                    </Link>}</div>);}
                },
                { property: "id", header: t(""),sortable: false,
                    noFilter:true,
                    decorator : () => ({ style : { width : "20%" } }),
                    renderer : value => {return(<div>{status === "1"?<Link to={{pathname: "project/detail/"+pro_id,
                                                                                state: { activeButton: -1}
                                                                              }} className="btn btn-outline-info m-btn m-btn--custom m-btn--icon m-btn--air">
                                                                        <span>
                                                                            <i className="fa fa-file-excel-o"></i>
                                                                            <span style= {{ width : "50px",display:'block',}}>{t('Detail')}</span>
                                                                        </span>
                                                                    </Link>:
                                                                    <Link to={{pathname: "/project/create",
                                                                                search: "?pid="+value,
                                                                                state: { projectId: value, step: 4}
                                                                            }} className="btn btn-outline-info m-btn m-btn--custom m-btn--icon m-btn--air">
                                                                        <span>
                                                                            <i className="fa fa-file-excel-o"></i>
                                                                            <span style= {{ width : "50px",display:'block',}}>{t('Detail')}</span>
                                                                        </span>
                                                                    </Link>}</div>);}
                }

            ]
        },

        create_at = 0,
        trans_id = "",
        configTrans = {
            cols: [
                { property: "created_at",
                    sortable: false,
                    display :"none",
                    renderer : value => {
                        create_at = parseInt(value)
                    }
                },
                { property: "id", header: t("Id"),sortable: false,
                    display: "none",
                    decorator : () => ({ style : { width : "20%" } }),
                    renderer : value => {
                        trans_id = value
                    }
                },
                { property: "proj_url", header: t("Project URL"),sortable: false,
                    decorator : () => ({ style : { width : "20%" } }),
                    renderer : value => {
                        return (
                            <div>
                                <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
                                <p>
                                    <i>{t("To secure the URL, please make payment within")}</i><br/>
                                    <span><Countdown date={(create_at + 24 * 60 * 60 - 60)* 1000} daysInHours={true} onComplete={this.handleTimeLimit.bind(this, trans_id)}/></span>
                                </p>
                            </div>)
                    }
                },
                { property: "status", header: t("Status"),sortable: false,
                    decorator : () => ({ style : { width : "20%" } }),
                    renderer : value => {
                        let text = ""
                        let className=""
                        if(value === "2") {
                            text = t("IN TRANSACTION")
                            className= "m-badge m-badge--brand m-badge--wide"
                        } else {
                            text = t("UNKNOWN")
                             className= "m-badge m-badge--danger m-badge--wide"
                        }
                        return (<span className={className}>{text}</span>);
                    }
                },
                { property: "created_at", header: t("Created At"),sortable: false,
                    decorator : () => ({ style : { width : "20%" } }),
                    renderer : value => convertTimestampToDate(value)
                },
                { property: "id", header: t(""),sortable: false,
                    decorator : () => ({ style : { width : "20%" } }),
                    renderer : value => {return(<div><Link to={{pathname: "/project/create",
                                                                search: "?pid="+value,
                                                                state: { projectId: value}
                                                              }} className="btn btn-outline-accent m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air">
                                                            <i className="la la-chevron-right"></i>
                                                        </Link>
                                                        <button className="btn btn-outline-danger m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air" onClick={this.deleteProject.bind(this, value)}>
                                                            <i className="flaticon-delete"></i>
                                                        </button>
                                                </div>);}
                },
            ]
        }

        let option = this.state.listProjects.map((project, idx) => {
                                         return  project.status !== "6" && <option key={project.id} className="dropdown-item" value={project.id}>{project.proj_url}</option>
                                        })

        let modal = this.state.showModal === false ? '' : <Modal
                                                              show={this.state.showModal}
                                                              onHide={this.hideModal.bind(this)}
                                                              dialogClassName="modal-90w"
                                                              aria-labelledby="m_modal_payment"
                                                          >
                                                              <Modal.Header>
                                                                  <Modal.Title>
                                                                      <span >{t('Choose project you want to add')}</span>
                                                                      { projectList.data.length !== 0 ?
                                                                        <div className="m-portlet__head-tools fl-right ">
                                                                          <Link to="/project/create" className=" btn btn-outline-accent m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air">
                                                                              <i className="fa fa-plus"></i>
                                                                          </Link>
                                                                        </div> : ''
                                                                    }
                                                                  </Modal.Title>
                                                              </Modal.Header>
                                                              <Modal.Body>
                                                                <div className="kt-section__content">
                                                                {
                                                                  projectList.data.length === 0 ? t('You have no project')
                                                                  : <Row>
                                                                  <Col className="col-sm-9">
                                                                    <div>
                                                                      <select ref="proId" className="btn btn-secondary dropdown-toggle choose-project select_project">
                                                                        {option}
                                                                      </select>
                                                                    </div>
                                                                  </Col>
                                                                  <Col className="col-sm-3">
                                                                    <div>{<Link onClick={this.changeSelection.bind(this)} to={{pathname: "project/"}} className="fl-right btn btn-outline-info m-btn m-btn--custom m-btn--icon m-btn--air">
                                                                              <span>
                                                                                  <i className="fa fa-file-excel-o"></i>
                                                                                  <span>{t('Choose')}</span>
                                                                              </span>
                                                                          </Link>}
                                                                        </div>
                                                                    </Col>
                                                                  </Row>
                                                                }
                                                                </div>
                                                              </Modal.Body>
                                                              <Modal.Footer>
                                                                <div className="m-portlet__head-tools fl-right">
                                                                  <button onClick={this.onCloseModal.bind(this)} className="fl-right btn btn-outline-info m-btn m-btn--custom m-btn--icon m-btn--airr">
                                                                      {t('Close')}
                                                                  </button>
                                                                </div>
                                                                {projectList.data.length === 0 ?
                                                                  <div className="m-portlet__head-tools fl-right">
                                                                    <Link to="/project/create" className="fl-right btn btn-outline-info m-btn m-btn--custom m-btn--icon m-btn--airr">
                                                                        {t('Create')}
                                                                    </Link>
                                                                  </div>
                                                                   :''
                                                              }
                                                              </Modal.Footer>
                                                          </Modal>

        return(
            <Layout>
              { modal}
                {showLoading && <LoadingPage />}
                <Layout.Content>
                    <Portlet>
                        <Portlet.Title>
                            <Portlet.Caption>
                                <h3 className="m-portlet__head-text">{this.state.showModal === true ? "Choose Project" : t("Project List")}</h3>
                            </Portlet.Caption>
                            <div className="m-portlet__head-tools">
                                <ul className="m-portlet__nav">
                                    <li className="m-portlet__nav-item">
                                        <Link to="/project/create" className=" btn btn-outline-accent m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air">
                                            <i className="fa fa-plus"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </Portlet.Title>
                        <Portlet.Body>
                            {Object.keys(projectTrans).length !== 0 && Object.keys(plan).length > 0 ?
                                <button className="btn m-btn--square  btn-success" onClick={this.showModal.bind(this)}>{t("Pay All")}</button>
                            : null}
                            {projectTrans.length === 0?null:<DataTable t={t} datasource={projectTrans} config={configTrans} meta={false}/>}
                            <br/>
                            <DataTable t= {t} className="margin-top--60"
                                datasource={projectList.data}
                                config={config} meta={projectList.meta}
                                changeMeta={this.changeMeta.bind(this)}
                            />
                            {Object.keys(plan).length >0 ?
                            <PaymentModal data={mergeData(plan, projectTrans)}
                                t={t}
                                show={showPaymentModal}
                                hideModal={this.hideModal.bind(this)}
                                checked = {checked}
                                chose= {this.chose.bind(this)}
                                choseCheckedAll={this.choseCheckedAll.bind(this)}
                                checkedAll={checkedAll} />:
                                null}
                        </Portlet.Body>
                    </Portlet>
                </Layout.Content>
                <ToastContainer
                    ref="toastContainer"
                    className="toast-top-right"
                />
            </Layout>
            );
    }
}
export const mapStateToProps = ({ecommerce}) => {
    return {
            projectList: ecommerce.projectList,
            projectTrans: ecommerce.projectTrans
        }
}

export default connect(mapStateToProps)(Project);
