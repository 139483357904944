import React from 'react';
import { Modal, FormGroup, Button, Form } from 'react-bootstrap';
import * as utils from '../../utils';
import EnterAmount from '../../components/EnterAmount';

class Amount extends React.Component {
    render(){
        const {t, show, hideModal, submit, amount, error, listChoseId, updateAmount, submitLoading}= this.props
        return(
            <Modal
                id="m_modal_payment"
                show={show}
                onHide={hideModal}
                dialogClassName="modal-90w"
                aria-labelledby="m_modal_payment"
            >
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t('Enter the required Amount')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-12 col-12 amount_info">
                                <div className="amount_text">
                                    <span className="info">{t('Banking Transaction Number')}</span>
                                    <span className="amount">: {listChoseId.banking_transaction_number}</span>
                                </div>
                                <div className="amount_text">
                                    <span className="info">{t('Amount')}</span>
                                    <span className="amount">: {utils.handleCurrency(listChoseId.amount)}</span>
                                </div>
                                {listChoseId.coupon_code?
                                <div className="amount_text">
                                    <span className="info">{t('Coupon Code')}</span>
                                    <span className="amount">: {utils.handleCurrency(listChoseId.coupon_code)}</span>
                                </div>:null
                                }
                                {listChoseId.coupon_amount?
                                <div className="amount_text">
                                    <span className="info">{t('Coupon Amount')}</span>
                                    <span className="amount">: {utils.handleCurrency(listChoseId.coupon_amount)}</span>
                                </div>:null
                                }
                                {listChoseId.coupon_code?
                                <div className="amount_text">
                                    <span className="info">{t('Total')}</span>
                                    <span className="amount">: {utils.handleCurrency(parseInt(listChoseId.coupon_amount) + parseInt(listChoseId.amount))}</span>

                                </div>:null
                                }
                            </div>
                        </div>
                        <FormGroup >
                            <Form.Label>{t('Enter Amount')}:</Form.Label>
                                <EnterAmount errorEmpty={error.amountEmpty} amount={amount} updateAmount= {updateAmount} t={t}/>
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={hideModal}>{t('Close')}</Button>
                        <Button disabled={submitLoading} className={submitLoading&&" m-loader m-loader--light m-loader--right"} type="submit" variant="success" onClick={submit}>{t('Submit')}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

export default Amount;
