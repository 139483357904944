import React from 'react';
import axios from 'axios';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import background from '../../assets/bg-3.jpg';
import {Link} from 'react-router-dom';
import Translate from '../../components/Translate';
import LeftBanner from '../../components/Layout/LeftBanner';

class ForgetPassword extends React.Component
{
	constructor(props){
		super(props);
		this.state = {
			email:"",
			error:{},
			success: false,
			fail: false,
			message: ""
		}
		this.submit = this.submit.bind(this);
	}

	onChange(ev){
		let newValue = {},
        error = {},
        name = ev.target.name,
        value = ev.target.value
        newValue[name] = value;      
        error.emailEmpty = value === '';
        if(value !== ""){
			error.emailIncorrect = !utils.validateEmail(value)
		}
		else{
			error.emailIncorrect = false;
		}
        error.errMsg = false;
        newValue.error = error;
        this.setState(newValue);
	}

	submit(ev){
		ev.preventDefault();
		let validate = false,
		error = this.state.error
		const {t} = this.props
		error.emailEmpty = this.state.email.toString().trim().length === 0 ? true : false;
        Object.keys(this.state.error).map((e, i) => {
            if(error[e]){
            	validate = true;
            }
            return validate;
        });

        if(validate){
        	this.setState({error})
        	return false;
        }
		
		let _this = this;
        this.setState({submitLoading: true})
        axios.post( apis.BASE_URL + 'users/forgot-password', {
            email: _this.state.email.toString().trim()
        })
        .then( res => {
            _this.setState({
				email:"",
            	fail: false,
            	success: true,
                submitLoading: false
            })
        }).catch( error => {	
        	_this.setState({
        		fail: true,
        		success: false,
        		submitLoading: false
        	})
        	if(error.response){
        		_this.setState({message: utils.catchMessageError(error.response.data.error_code)})
        	}
        	else{
        		_this.setState({message: (t('Error server'))})
        	}
        });
	}

	render(){
		const {submitLoading, success, fail, message, email} = this.state,
		{t, i18n} = this.props;
		return(
			<div className="m-login m-login--signin  m-login--5" id="m_login" style={{backgroundImage: "url("+background+")"}}>
				<LeftBanner t={t} />
				<div className="m-login__wrapper-2 m-portlet-full-height">		
					<div className="m-login__contanier">			
						<div className="m-login__forget-password animated flipInX">
							<div className="m-login__head">
								<h3 className="m-login__title">{t('Forgotten Password')} ?</h3>
								<div className="m-login__desc">{t('Enter your email to reset your password')}:</div>
							</div>
							<form className="m-login__form m-form" action="">								
								<div className="form-group m-form__group">
									<input className="form-control m-input" type="email" placeholder={t('Email')} name="email" value={email} autoComplete="off" onChange = {this.onChange.bind(this)}/>
									{this.state.error.emailEmpty ? <div className="form-control-feedback">{t('This field is required')}.</div> : null}
									{this.state.error.emailIncorrect ? <div className="form-control-feedback">{t('Email Invalid')}</div> : null}

								</div>
								<div className="m-login__form-action">
									<button type="submit" disabled={submitLoading} className={submitLoading?"btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-loader m-loader--light m-loader--right":"btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air"} onClick={this.submit}>{t('Request')}</button>
									<Link to="/sign-in" className="btn btn-outline-focus m-btn m-btn--pill m-btn--custom ">{t('Cancel')}</Link>
								</div>
							</form>
							{success?
								<div className="alert alert-success" role="alert">
									<strong>{t('Well done')}!</strong> {t('Please check your email to get new password in')}.
								</div>
							:null}

							{fail?
								<div className="alert alert-danger" role="alert">
									{message}
								</div>
							:null}
						</div>
					</div>
					<Translate i18n={i18n}/>
				</div>
			</div>				
			);
	}
}
export default ForgetPassword;