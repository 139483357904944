import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import * as apis from './constants/Apis';
import * as utils from './utils';

import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import thunkMiddleware from 'redux-thunk';
// import logger from 'redux-logger';
import rootReducer from './reducers';
import * as types from './constants/ActionTypes';

export const customMiddleWare = store => next => action => {
	if (action.type === types.UPDATE_TEMP_DATA && action.value.projectId !== "") {
		let {projectId} = action.value
		let data = {
			step: action.value.step,
			project_name: action.value.projectName,
			plan: action.value.plan,
			node_region: action.value.region,
			coupon: action.value.couponApplied,
			quantity: action.value.circle,
			method: action.value.method,
			transaction_id: action.value.transactionId
		}
		axios.put(apis.BASE_URL + 'projects/' + projectId, data, utils.getConfig())
	}
	next(action);
}

// const createStoreWithMiddleware = applyMiddleware(thunkMiddleware, logger, customMiddleWare)(createStore);
const createStoreWithMiddleware = applyMiddleware(thunkMiddleware, customMiddleWare)(createStore);

// const store = createStoreWithMiddleware(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const store = createStoreWithMiddleware(rootReducer);

ReactDOM.render(<Provider store = {store}><App/></Provider>, document.getElementById("root"));
