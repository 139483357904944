import React from 'react';
import axios from 'axios';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import Layout from '../../components/Layout';
import Portlet from '../../components/Portlet';
import DataTable from '../../components/DataTable';
import Amount from './Amount';
import {ToastContainer} from 'react-toastr';
import '../../assets/css/toastr.css';
import LoadingPage from '../../components/Layout/LoadingPage';

class Tracking extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			data: [],
            plan:[],
			meta : [],
			listChoseId : {},
            amount :0,
			showLoading: false,
            showPaymentModal: false,
            showConfirm: false,
            submitLoading: false,
			error: {}
		}
	}

    componentDidMount(){
        let _this = this;
        const {t} = this.props;
        // get data
        this.setState({showLoading: true})
        axios.get(apis.BASE_URL + 'payment/1/10/{"status":"SORT_ASC","created_at":"SORT_DESC"}/{}', utils.getConfig())
        .then( res => {
            _this.setState({
                data: res.data.items,
                meta: res.data._meta,
                showLoading: false
            });
            axios.get("https://rtcenter.org/services/ecommerce-plan")
            .then( res => {
                console.log('get success ecommerce-plan data')
                _this.setState({plan: res.data.msg})
            })
            .catch( error => {
                _this.refs.toastContainer.error(t("Cannot get data plan"));
            });
        })
        .catch( error => {
            _this.setState({showLoading: false})
            _this.refs.toastContainer.error(t("Cannot get data tracking"));
        });
    }

	hideModal(){
        this.setState({showPaymentModal: false})
    }

    showModal(id){
        let list = this.state.data.filter(item =>{
            return item.payment_id === id;
        })
        this.setState({
        	listChoseId: list[0],
        	showPaymentModal: true})
    }

	 // begin load page by pagination
    changeMeta(meta, filter=""){
        const {t} = this.props
        let _this= this
        this.setState({showLoading: true})

        let sort = meta.sortInfo ? '{"'+meta.sortInfo.property+'":"SORT_'+meta.sortInfo.direction+'"}' : '{"status":"SORT_ASC","created_at":"SORT_DESC"}'
        filter = filter !== "" ? filter : "[]"

        let str = 'payment/'+meta.currentPage+'/'+meta.perPage+'/'+sort+'/'+filter
        axios.get(apis.BASE_URL + str, utils.getConfig())
        .then( res => {
            _this.setState({
                data: res.data.items,
                meta: res.data._meta,
                showLoading: false
            });

        })
        .catch( error => {
            console.log(error);
            this.setState({showLoading: false})
            _this.refs.toastContainer.error(t("Cannot get data tracking"));
        });
    }

     updateAmount(amount){
         this.setState({amount: amount})
     }

    submit(ev){
        ev.preventDefault();
        const {listChoseId} = this.state,
        {t} = this.props
        let amount = this.state.amount

        let error={}
        if(amount.toString().length === 0){
            error.amountEmpty = true
        }
        this.setState({error})
        if(Object.keys(error).length > 0){
            return false
        }

        let _this =this;

        this.setState({submitLoading : true})
        // get data
        axios.post(apis.BASE_URL + 'payment/confirm-bank-transfer', {
            transaction_id: listChoseId.transaction_id,
            transaction_number : listChoseId.banking_transaction_number,
            payment_status : 1,
            amount : amount}, utils.getConfig())
        .then( res => {
            _this.setState({submitLoading: false})
            _this.refs.toastContainer.success(t("Successful"));
            setTimeout(()=>{
                window.location.reload()
            }, 1000)
        })
        .catch( error => {
             _this.refs.toastContainer.error(t("Cannot confirm amount"));
            console.log(error);
            _this.setState({submitLoading: false})
        });
    }

    getDetailPlan(name){
        const {plan} = this.state;
        let temp = plan.filter(item =>{
            return item.name === name;
        })
        return <div><p>{temp[0].content.title}</p> <p>{temp[0].content.name}</p></div>
    }

	render(){
		const {data, meta, showPaymentModal, amount, error, showLoading, listChoseId, submitLoading} = this.state,
        { t } = this.props;
        let status = 0,
        config = {
            api: true,
            filter: true,
            cols: [
                { property: "banking_transaction_number", header: t("Trans Number"), sortable: true,
                    filterType: {type:"text"},
                    decorator : () => ({ style : { width : "15%" } }),
                    renderer : value => value ? value : t("(not set)")
                },
                { property: "proj_name", header: t("Project Name"), sortable: true,
                    filterType: {type: "text", prefix: "e_user_project__"},
                    decorator : () => ({ style : { width : "15%" } }),
                    renderer : value =>   value ? value: ""
                },
                { property: "plan_name", header: t("Plan Name"), sortable: true,
                    filterType: {type:"text"},
                    decorator : () => ({ style : { width : "15%" } }),
                    renderer : value =>  value ? this.getDetailPlan("rtsurvey-user-pro-annual"): ""
                },
                { property: "status", header: t("Status"), sortable: true,
                    filterType: {
                        type: "number",
                        prefix: "e_user_payment_tracking__",
                        values: [
                            {label: t("Select all")+"...", value: ""},
                            {label: t("VERIFY FAIL"), value: "-1"},
                            {label: t("PENDING"), value: "0"},
                            {label: t("COMPLETE"), value: "1"}
                        ]
                    },
                    decorator : () => ({ style : { width : "15%" } }),
                    renderer : value => {
                    	status = value
                        let text = "",
                        className=""
                        switch (value) {
                        	case "-1":
                                text = t("VERIFY FAIL")
                                className="m-badge m-badge--danger m-badge--wide"
                                break;

                            case "0":
                                text = t("PENDING")
                                className="m-badge m-badge--Pending m-badge--wide"
                                break;

                            default: // 1
                                text = t("COMPLETE")
                                className= "m-badge m-badge--success m-badge--wide"
                                break;
                        }
                        return (<span className={className}>{text}</span>);
                    }
                },
                { property: "amount", header: t("Amount"), sortable: true,
                    decorator : () => ({ style : { width : "10%" } }),
                    renderer : value => utils.handleCurrency(value)
                },
                { property: "created_at", header: t("Create At"), sortable: true,
                    filterType: {type: "date", prefix: "e_user_payment_tracking__",},
                    decorator : () => ({ style : { width : "15%" } }),
                    renderer : value => utils.convertTimestampToDate(value)
                },
                { property: "payment_id", header: t("Action"), sortable: false,
                    decorator : () => ({ style : { width : "15%" } }),
                    renderer : value => {
                    	let text = "",
                        className=""
                    	switch (status) {
                    		case "-1":
                    			text=t("Retry");
                    			className="btn btn-outline-danger m-btn m-btn--custom m-btn--icon m-btn--air";
                    			break;

                    		case "0":
                    			text=t("Confirm");
                    			className="btn btn-outline-info m-btn m-btn--custom m-btn--icon m-btn--air";
                    			break;

                    		default:
                    			break;
                    	}
                    	return(status === "1" ? null :<button className={className} onClick={this.showModal.bind(this, value)}>
                                    <span>
                                        <i className="fa fa-file-excel-o"></i>
                                        <span>{text}</span>
                                    </span>
                                </button>)
                    }
                }
            ]
        }
		return(
			<Layout>
				{showLoading && <LoadingPage />}
                <Layout.Content>
                    <Portlet>
                        <Portlet.Title>
                            <Portlet.Caption>
                                <h3 className="m-portlet__head-text">{t("Bank List")}</h3>
                            </Portlet.Caption>
                        </Portlet.Title>
                        <Portlet.Body>
                            <DataTable t={t} datasource={data} config={config} meta={meta} changeMeta={this.changeMeta.bind(this)}/>
                            <Amount t={t} show={showPaymentModal} hideModal={this.hideModal.bind(this)} amount={amount} listChoseId={listChoseId} error={error} updateAmount={this.updateAmount.bind(this)} submitLoading={submitLoading} submit={this.submit.bind(this)}/>
                        </Portlet.Body>
                    </Portlet>
                </Layout.Content>
                <ToastContainer
                    ref="toastContainer"
                    className="toast-top-right"
                />
            </Layout>
		);
	}
}

export default Tracking;
