import React from 'react';

class PortletCaption extends React.Component
{
	render() {
		return (
            <div className={ this.props.className || 'm-portlet__head-caption' }>
                <div className='m-portlet__head-title'>
                    { this.props.children }
                </div>
			</div>
		);
	}
}

export default PortletCaption;
