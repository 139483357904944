import React from 'react';
import axios from 'axios';
import '../../assets/css/pricing_table.css'
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import { withRouter } from 'react-router'
import Layout from '../../components/Layout';
import Portlet from '../../components/Portlet';
import LoadingPage from '../../components/Layout/LoadingPage';
import BillingDetail from './BillingDetail';
import DataTable from '../../components/DataTable';

class BillingPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      showModal: false,
      createDay: window.location.href.split('/')[window.location.href.split('/').length - 1],
      id: window.location.href.split('/')[window.location.href.split('/').length - 2],
      total: 0,
      listContent: [],
      listUsers: [],
      meta: {
        perPage: 10,
        currentPage: 1
      }
    }
  }

  componentDidMount() {
    const { id, createDay } = this.state
    let from_date = utils.getFromDate(createDay).dateTimeFrom
    let to_date = utils.getFromDate(createDay).dateTimeTo
    let _this = this
    let data = {
      project_id: id,
      from_date: from_date,
      to_date: to_date
    }
    _this.setState({ showLoading: true })
    axios.post(apis.BASE_URL + 'projects/check-dry-run-billing-info', data, utils.getConfig())
      .then(res => {
        if (res.status === 200) {
          let listBilling = []
          res.data.items.data.map((item) => {
            if (item.itemType === 'USAGE') {
              axios.get('https://rtcenter.org/services/ecommerce-workspace-addon', { params: { name: item.planName, currency: localStorage.getItem("currency"), ignoreStatus: 1 } })
                .then(res => {
                  let status = res.data.status
                  if (status === 'success') {
                    item.newPlanName = res.data.msg[0].content.title
                  }
                  listBilling.push(item)
                  _this.setState({ listContent: listBilling })
                })
            } else {
              axios.get('https://rtcenter.org/services/ecommerce-workspace-plan', { params: { name: item.planName, currency: localStorage.getItem("currency"), ignoreStatus: 1 } })
                .then(res => {
                  item.newPlanName = res.data.msg[0].content.title;
                  listBilling.push(item)
                  _this.setState({ listContent: listBilling })
                })
                .catch(error => {
                  console.log(error)
                });
            }
            _this.setState({ total: res.data.items.totalAmount, showLoading: false })
          })
        }
      }).catch(error => {
        _this.setState({ showLoading: false })
        console.log(error)
      });
  }

  onShowDetail = (users) => {
    this.setState({ showModal: true, listUsers: users })
  }

  onCloseModal = () => {
    this.setState({ showModal: false })
  }

  changeMeta(meta) {
    this.setState({ meta })
  }

  render() {
    const { t } = this.props
    const { showLoading, showModal, total, listContent, listUsers, meta } = this.state,
      invoiceNumber = "",
      config = {
        api: false,
        sortDefault: {
          property: "invoiceDate",
          direction: "DESC"
        },
        cols: [
          {
            property: "subscription_id",
            sortable: false,
            display: "none",
            renderer: value => {
              invoiceNumber = value
            }
          },
          {
            property: "newPlanName", header: t("Decription"),
            sortable: true,
            decorator: () => ({ style: { width: "20%" } }),
            renderer: value => <span className="m--font-bold">{utils.handleLanguageModule(value)}</span>
          },
          {
            property: "startDate", header: t("Start date"),
            sortable: true,
            decorator: () => ({ style: { width: "20%" } }),
            renderer: value =>  <span className="m--font-bold">{value}</span>
          },
          {
            property: "endDate", header: t("End date"),
            sortable: true,
            decorator: () => ({ style: { width: "20%" } }),
            renderer: value => <span className="m--font-bold">{value}</span>
          },
          {
            property: "sum_user", header: t("Number of Users"),
            sortable: true,
            decorator: () => ({ style: { width: "20%" } }),
            renderer: value => <span className="m--font-bold"> {value}</span>
          },
          {
            property: "itemDetails", header: t("Tier Price"),
            sortable: true,
            decorator: () => ({ style: { width: "10%" } }),
            renderer: value => <span className="m--font-bold"> {utils.handleCurrency(JSON.parse(value).tierDetails[0].tierPrice)}</span>
          },
          {
            property: "amount", header: t("Amount"),
            sortable: true,
            decorator: () => ({ style: { width: "10%" } }),
            renderer: value => <span className="m--font-bold"> {utils.handleCurrency(value)}</span>
          },
          {
            property: "users", header: t(""),
            sortable: false,
            decorator: () => ({ style: { width: "20%" } }),
            renderer: value => value.length > 0 && <span className="btn btn-outline-info m-btn m-btn--custom m-btn--icon m-btn--air" onClick={() => this.onShowDetail(value)}><span style= {{ width : "50px",display:'block',}}>{t('Detail')}</span></span> ,
          },
        ]
      }

    return (
      <Layout>
        {showLoading && <LoadingPage />}
        <Layout.Content>
          <Portlet >
            <Portlet.Title>
              <Portlet.Caption>
                <h3>{t("Billing Info")}</h3>
              </Portlet.Caption>
            </Portlet.Title>
            <Portlet.Body>
              <Portlet.Caption>
                <h3>{t("Total amount")}: {utils.handleCurrency(total)}</h3>
              </Portlet.Caption>
              <div className="dataTables_wrapper dt-bootstrap4">
                <div className="overTableScrollX">
                  <DataTable datasource={listContent}
                    config={config}
                    meta={meta}
                    changeMeta={this.changeMeta.bind(this)}
                    t={t} />
                </div>
                <BillingDetail show={showModal} t={t} onHideModal={this.onCloseModal} users={listUsers} />
              </div>
            </Portlet.Body>
          </Portlet>
        </Layout.Content>
      </Layout>
    );
  }
}

export default withRouter(BillingPayment);
