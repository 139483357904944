import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import {Form, Row, Col} from 'react-bootstrap'
import Plan from './Plan';
import WizardNav from './WizardNav';
import LoadingPage from '../../components/Layout/LoadingPage';
import * as apis from '../../constants/Apis';
import * as utils from '../../utils';
import {ToastContainer} from 'react-toastr';
import '../../assets/css/toastr.css';

class WizardFormFirstStep extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            error: false,
            errorMsg: "",
            warning: false,
            warningMsg: "",
            domainValid: false,
            projectLoading: false,
            regionEmpty: false,
            redirectListPage: false,
            showLoading: false,
            errCouponMsg: ""
        }
        this.handleBlur = this.handleBlur.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.createProject = this.createProject.bind(this)
        this.handleRegionChange = this.handleRegionChange.bind(this)
    }

    handleBlur(ev) {
        let _this = this
        const {t, projectId, onChange} = this.props
        let value = ev.target.value
        if(value.length === 0) {
            this.setState({domainValid: false, warning: false, warningMsg: "", error: true, errorMsg: t("Subdomain can not empty")})
            return false
        }

        this.setState({projectLoading: true, domainValid: false})
        let config = utils.getConfig();
        axios.get(apis.BASE_URL + 'projects/1/10/[]/["and",{"proj_name":"'+ value +'"},{"e_user_project__status":"2"}]', config)
        .then( res => {
            if (res.data.items.length === 0) {
                axios.post(apis.BASE_URL + 'projects/check-domain', {"project_name": value}, config)
                .then( res => {
                    onChange('hasError', false)
                    _this.setState({domainValid: true, projectLoading: false, warning: false, warningMsg: "", error: false, errorMsg:""});
                })
                .catch( error => {
                    onChange('hasError', true)
                    _this.setState({domainValid: false, projectLoading: false, warning: false, warningMsg: "", error: true, errorMsg: t('Subdomain') + " \"" + value + "\" " + t("has already been taken")});
                });
            } else {
                // if project searched difference from current project
                if(res.data.items[0].id !== projectId) {
                    onChange('hasError', true)
                    _this.setState({domainValid: false, projectLoading: false, error: false, errorMsg:"", warning: true, warningMsg: t("Subdomain is in your project list Please choose another subdomain")})
                } else {
                    onChange('hasError', false)
                    _this.setState({domainValid: true, projectLoading: false, warning: false, warningMsg: "", error: false, errorMsg:""});
                }
            }
        })
        .catch( error => {
            _this.setState({domainValid: false, projectLoading: false, warning: false, warningMsg: "", error: true, errorMsg: error.response.data.errors});
        });
    }

    handleKeyPress(ev) {
        if (ev.key === 'Enter') {
            this.handleBlur(ev)
        }
    }

    handleChange(ev){
        this.props.onChange(ev.target.name, ev.target.value);
        this.setState({"errCouponMsg": ""})
    }

    checkDomainValid(ev){
        const {t, onChange} = this.props
        if(utils.validateDomain(ev.target.value)){
            onChange(ev.target.name, ev.target.value);
            this.setState({domainValid: false, projectLoading: false, error: false, errorMsg:"", warning: false, warningMsg: ""})
        } else if(utils.validateDomain(ev.target.value)===false && ev.target.value.length<=63){
            this.setState({domainValid: false, projectLoading: false, error: false, errorMsg:"", warning: true, warningMsg: t("Invalid characters in subdomain name Enter only letters and numbers")})
        } else if(utils.validateDomain(ev.target.value)===false && ev.target.value.length>63){
            this.setState({domainValid: false, projectLoading: false, error: false, errorMsg:"", warning: true, warningMsg: t("Subdomains exceed maximum length of 63 characters")})
        }
    }

    handlePlanChange(name, value){
        this.props.onChange(name, value);
    }

    handleRegionChange(ev){
        this.setState({regionEmpty: false}, this.props.onChange("region", ev.target.value))
    }

    checkCoupon(){
        const {t, plan, couponCode, onChange}= this.props
        let _this = this
        let userId = utils.getUserId();
        if(plan.length === 0) {
            this.refs.toastContainer.error(t("Plan can not empty Please choose a plan to deloy"))
            return false
        }
        axios.get('https://rtcenter.org/services/ecommerce-coupon-checking/' + plan + '/' + couponCode + '/' + userId)
        .then( res => {
            let status = res.data.status
            if(status === 'success') {
                let couponPrice = res.data.msg.price
                 _this.setState({"errCouponMsg": ""})
                 onChange("couponApplied", couponCode)
                 onChange("couponPrice", couponPrice)
                 onChange("couponCode", "")
            } else {
                _this.setState({"errCouponMsg": t('Promo Code') + ' "' + couponCode + '" ' + t('is invalid')})
            }
        })
        .catch( error => {
            console.log(error)
        });
    }

    removeCoupon(){
        this.setState({"errCouponMsg": ""})
        this.props.onChange("couponApplied", "")
        this.props.onChange("couponPrice", 0)
    }

    saveDraft(step){
        // step = 1 : save draft
        // step = 2 :  create project
        let _this = this
        let {error, errorMsg, warning, warningMsg} = this.state
        let {t, plan, projectId, projectName, region, couponApplied, onChange, nextPage} = this.props
        if(projectName.length === 0) {
            this.refs.toastContainer.error(t("Subdomain can not empty"))
            this.setState({domainValid: false, projectLoading: false, warning: false, warningMsg: "", error: true, errorMsg: t("Subdomain can not empty")})
            return false
        }
        if(plan.length === 0) {
            this.refs.toastContainer.error(t("Plan can not empty Please choose a plan to deloy"))
            return false
        }
        if(error) {
            this.refs.toastContainer.error(errorMsg)
            return false
        }
        if(warning) {
            this.refs.toastContainer.error(warningMsg)
            return false
        }
        let config = utils.getConfig();
        let postData = {
            project_name: projectName,
            node_region: region,
            plan: plan,
            step: step,
            project_domain: this.props.projectDomain,
            project_type: this.props.projectType,
            coupon: couponApplied
        }
        this.setState({showLoading: true})
        if (projectId === "") {
            // create
            axios.post(apis.BASE_URL + 'projects', postData, config)
            .then( res => {
                let projId = res.data.items.id.toString()
                if(step === 1){
                    _this.setState({redirectListPage: true})
                } else {
                    onChange("projectId", projId)
                    nextPage(projId)
                }
            })
            .catch( error => {
                _this.setState({showLoading: false})
                _this.refs.toastContainer.error(t("Create new project is not success"));
            });
        } else {
            axios.put(apis.BASE_URL + 'projects/' + projectId, postData, config)
                .then( res => {
                    if(step === 1){
                        _this.setState({redirectListPage: true})
                    } else {
                        nextPage()
                    }
                })
                .catch( error => {
                    _this.setState({showLoading: false})
                    _this.refs.toastContainer.error(t("Update project is not success"));
                });
        }
    }

    createProject() {

        let _this = this
        let {couponCode, couponApplied, plan} = this.props
        //check coupon
        if(couponCode.length !== 0 && !this.checkCoupon()){
            return false
        }
        if(couponApplied !== "") {
            let userId = utils.getUserId();
            axios.get('https://rtcenter.org/services/ecommerce-coupon-checking/' + plan + '/' + couponApplied + '/' + userId)
            .then( res => {
                let status = res.data.status
                if(status === 'success') {
                    _this.saveDraft(2)
                } else {
                    _this.setState({"errCouponMsg": res.data.msg})
                }
            })
            .catch( error => {
                console.log(error)
            });
        } else {
            this.saveDraft(2)
        }
    }

    render(){
        const {t, projectName, region, plan, couponCode, couponApplied,  projectDomain, category}= this.props
        let {error, domainValid, errorMsg, warning, warningMsg, projectLoading, regionEmpty, errCouponMsg, redirectListPage, showLoading} = this.state
        if(redirectListPage) {
            return (<Redirect to="/project"/>)
        }
		return(
            <div className="m-wizard m-wizard--2 m-wizard--success" id="m_wizard">
                    {showLoading && <LoadingPage />}
                    <WizardNav t={t} step={1} />
                    <div className="m-wizard__form">
                        <div className="m-form m-form--label-align-left- m-form--state-">
                            <div className="m-wizard__form-step m-wizard__form-step--current" id="m_wizard_form_step_1">
                                <Form.Group className="m-form__group">
                                    <Plan t={t} id={plan} category={category} onChange={this.handlePlanChange.bind(this)}/>
                                </Form.Group>
                                <Form.Group as={Row} className="m-form__group">
                                    <Col lg={1}></Col>
                                    <Col lg={5}>
                                        <label><b>{t('Subdomain')}</b> <span className="required">*</span></label>
                                        <div className="input-group">
                                            <input className="form-control form-control-warning" type="text" placeholder={t('Enter subdomain')} name="projectName" value={projectName} onChange={this.checkDomainValid.bind(this)} onBlur={this.handleBlur} onKeyPress={this.handleKeyPress} />
                                            <div className="input-group-append">
                                                {projectLoading && <span className="m-loader m-loader--success"></span>}
                                                {domainValid  && <span className="fa fa-check project-check"></span>}
                                            </div>
                                        </div>
                                        {warning && <p className="warning-msg">{warningMsg}</p>}
                                        {error && <p className="error-msg">{errorMsg}</p>}
                                        <p className="m--margin-top-10" style={{"overflowWrap": "break-word"}}><strong>{t('Project URL')}: </strong><span>https://{projectName}.{projectDomain}</span></p>
                                    </Col>
                                    <Col lg={5}>
                                        <label><b>{t('Region')}</b> <span className="required">*</span></label>
                                        <div className="region">
                                            <Form.Control as="select" value={region} onChange={this.handleRegionChange}>
                                                <optgroup label="Asia">
                                                    <option value="ap-south">Vietnam, VN</option>
                                                    <option value="ap-northeast">Tokyo, JP</option>
                                                    <option value="ap-northeast-1a">Tokyo 2, JP</option>
                                                </optgroup>
                                                <optgroup label="North America">
                                                    <option value="us-central">Central, US</option>
                                                    <option value="us-west">West, US</option>
                                                    <option value="us-southeast">Southeast, US</option>
                                                    <option value="us-east">East, US</option>
                                                </optgroup>
                                                <optgroup label="Europe">
                                                    <option value="eu-west">London, UK</option>
                                                    <option value="eu-central">Frankfurt, DE</option>
                                                </optgroup>
                                            </Form.Control>
                                            {regionEmpty && <p className="error-msg">{t('Region can not empty')}.</p>}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Row>
                                    <Col lg={1}></Col>
                                    <Col lg={5}>
                                        <div className="form-coupon">
                                            <label><strong>{t('Promo Code')}</strong></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control form-control-warning" placeholder={t('Enter your promo code')} name="couponCode" value={couponCode} onChange={this.handleChange}  disabled={couponApplied!==""}/>
                                                <div className="input-group-append">
                                                    <button className="btn btn-success check-coupon" type="button" onClick={this.checkCoupon.bind(this)} disabled={couponCode===""}>{t('Apply')}</button>
                                                </div>
                                            </div>
                                            {errCouponMsg && <p className="error-msg">{errCouponMsg}</p>}
                                            {couponApplied && <p className="m-badge m-badge--success m-badge--wide coupon-code">{couponApplied}<i className="la la-close" onClick={this.removeCoupon.bind(this)}></i></p>}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="m-form__actions form__actions">
                                    <div className="row">
                                        <div className="col-lg-8 offset-lg-2 m--align-right">
                                            <button className="btn btn-info m-btn m-btn--custom m-btn--icon margin-right_10" onClick={this.saveDraft.bind(this, 1)}>
                                                <span>
                                                    <span>{t('Save Draft')}</span>
                                                    <i className="la la-save"></i>
                                                </span>
                                            </button>
                                            <button className="btn btn-success m-btn m-btn--custom m-btn--icon create-project" onClick={this.createProject}>
                                                <span>
                                                    <span>{t('Next')}</span>
                                                    <i className="fa fa-arrow-right"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <ToastContainer
                    ref="toastContainer"
                    className="toast-top-right"
                />
            </div>
			);
	}
}

export default WizardFormFirstStep;
