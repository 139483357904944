import React, { useState, useEffect, useRef } from 'react';
import {Helmet} from "react-helmet";
import ReactLoading from 'react-loading';
import logo from '../../assets/rtWork-icon-min.png';
import background from '../../assets/16-dark.png';
import axios from 'axios';
import Translate from '../../components/Translate';

function SignUpProject(props) {
	const {t, i18n} = props;
	const [state, setState] = useState({
		cloudUrl: '',
		referralCode: '',
		// ownerEmail: '',
		verifyCode: ''
	});
	const [onlynumber, setOnlynumber] = useState('');
	const [onlynumber1, setOnlynumber1] = useState('');
	const [onlynumber2, setOnlynumber2] = useState('');
	const [verification, setVerification] = useState(null);
	const [verification1, setVerification1] = useState(null);
	const [confirm, setConfirm] = useState('');
	const [confirm1, setConfirm1] = useState('');
	const [moduleSets, setModuleSets] = useState(null);
	const [moduleSetId, setModuleSetId] = useState(null);
	const [arrWorkflow, setArrWorkflow] = useState([]);
	const [orgIdIndex, setOrgIdIndex] = useState('');
	const inputEl = useRef(null);

	// -------------------- JS for Cloud Url --------------------
	const clickInputDiv = () => {
		inputEl.current.focus();
	};

	// Handle sub-domain input
	useEffect(() => {
		const hide = document.getElementById('hide');
		const txt = document.getElementById('txt');
		
		resize();
		txt.addEventListener("input", resize);

		function resize(e) {
			hide.textContent = txt.value;
			txt.style.width = hide.offsetWidth + "px";
		}
	}, [state.cloudUrl]);

	// Handle module sets
	useEffect(() => {
		setOrgIdIndex(window.location.href.indexOf("org_id"));

		fetch('https://invoice.rta.vn/items/plan_set',{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer 21U09isUGatu5rvvIYTe8tDa5h1qlBEn'
			  }
		})
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				throw new Error('Failed to fetch module sets from rtCenter.')
			})
			.then(data => {
				if (data.data.length > 0) {
					// Set the first module set id as selected
					let firstID = data.data[0].code;
					setModuleSetId(firstID);

					setModuleSets(data.data);
				} else {
					setModuleSets([])
				}
			})
			.catch(error => {
				setModuleSets([])
			})

	}, []);

	// Handle Verification in step5
	function handleVerification() {
		axios.post('https://rtcenter.org/billing_scripts/verify_subscription.php', {
			verify_code: state.verifyCode
		},
		{
			headers: { "Authorization": "Bearer 21U09isUGatu5rvvIYTe8tDa5h1qlBEn" }
		}
		)
		.then(function (response) {
			setVerification(true);
			document.getElementById("btn-verify").style.display = "none";
		})
		.catch(function (error) {
			// console.log("error",error);
			setVerification(false);

			if (localStorage.getItem("language") === "en") {
				setConfirm(error.response.data.errors[0]);
			} 
			else if (localStorage.getItem("language") === "vi") {
				// console.log("error-check", error.response.data)
				if(error.response.data.errors[0].indexOf('invalid') !== -1) {
					setConfirm("Mã xác minh đã được sử dụng hoặc không hợp lệ.")
				} else if (error.response.data.errors[0].indexOf('verified') !== -1) {
					setConfirm("Tài khoản này đã được xác minh.")
				} else {
					setConfirm("Mã xác thực đã hết hạn.")
				}
			} 
			else {
				if(error.response.data.errors[0].indexOf('invalid') !== -1) {
					setConfirm("Vérifiez que le code a été utilisé ou n'est pas valide.")
				} else if (error.response.data.errors[0].indexOf('verified') !== -1) {
					setConfirm("Ce compte a déjà été vérifié.")
				} else {
					setConfirm("Code d'authentification expiré.")
				}
			}
		});		

		document.getElementById('show').style.display = "none";
		document.getElementById('show1').style.display = "none";

		setTimeout(() => {
			document.getElementById('show').style.display = null;
		}, 1000);
	}

	// Open to login account in step5
	function goToMyAccount() {
		window.open('https://myaccount.rtworkspace.com', '_blank');
	}

	// Handle request new code in step5
	function requestNewCode() {
		axios.post('https://rtcenter.org/billing_scripts/resend_verification_code.php', {
			verify_code: state.verifyCode
		},
		{
			headers: { "Authorization": "Bearer 21U09isUGatu5rvvIYTe8tDa5h1qlBEn" }
		}
		)
		.then(function (response) {
			setVerification1(true);
		})
		.catch(function (error) {
			setVerification1(false);
			
			if (localStorage.getItem("language") === "en") {
				setConfirm1(error.response.data.errors[0]);
			} else if (localStorage.getItem("language") === "vi") {
				if(error.response.data.errors[0].indexOf('used or invalid') !== -1) {
					setConfirm1("Mã xác minh đã được sử dụng hoặc không hợp lệ.")
				} else {
					setConfirm1("Dự án không hợp lệ")
				}
			} else {
				if(error.response.data.errors[0].indexOf('used or invalid') !== -1) {
					setConfirm1("Vérifiez que le code a été utilisé ou n'est pas valide.")
				} else {
					setConfirm1("Projet invalide")
				}	
			}
		});		
		
		document.getElementById('show').style.display = "none";
		document.getElementById('show1').style.display = "none";

		setTimeout(() => {
			document.getElementById('show1').style.display = null;
		}, 1000);
		
	}

	// Handle to prevent enter press in step4
	function checkEnterPress(e) {
		const keyCode = 'which' in e ? e.which : e.keyCode;
		if( keyCode === 13) {
			e.preventDefault();
		}
	}

	// Handle to prevent enter space in email input
	function checkEnterSpace(e) {
		const keyCode = 'which' in e ? e.which : e.keyCode;
		if( keyCode === 32) {
			e.preventDefault();
		}
	}

	// Handle to change value for each state input form
	function handleChange(evt) {
		const value = evt.target.value;
		setState({
			...state,
			[evt.target.name]: value
		});
	}

	// Handle to change ModuleSet with radio in step1
	function handleChangeModuleSet(event) {
		setModuleSetId(event.target.value);
	}

	// Handle to change ModuleSet with checkbox in step1
	function handleChangeModuleSet1(event) {
		let newArray = [...arrWorkflow, event.target.value];
		if (arrWorkflow.includes(event.target.value)) {
			newArray = newArray.filter(val => val !== event.target.value);
		}
		setArrWorkflow(newArray)
	}

	if (state.cloudUrl !== "") {
		document.getElementById('txt').style.background = "antiquewhite"; 
	}

	setTimeout(() => {
		document.getElementById("loading").classList.add("display-none");
		document.getElementById("newloader-text").classList.add("display-none");
		document.getElementById("signup-container").classList.remove("display-none");
		document.getElementById("signup-container").classList.add("d-flex", "flex-column", "flex-root", "bg-body");
	}, 6000);
	
	return (			
		<>
			<Helmet>
				<base href="../../" />
				<title>rtWorkspace</title>
				<meta charset="utf-8" />
				<meta name="description" content="The most advanced Bootstrap Admin Theme on Themeforest trusted by 94,000 beginners and professionals. Multi-demo, Dark Mode, RTL support and complete React, Angular, Vue &amp; Laravel versions. Grab your copy now and get life-time updates for free." />
				<meta name="keywords" content="Metronic, bootstrap, bootstrap 5, Angular, VueJs, React, Laravel, admin themes, web design, figma, web development, free templates, free admin themes, bootstrap theme, bootstrap template, bootstrap dashboard, bootstrap dak mode, bootstrap button, bootstrap datepicker, bootstrap timepicker, fullcalendar, datatables, flaticon" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="article" />
				<meta property="og:title" content="Metronic - Bootstrap 5 HTML, VueJS, React, Angular &amp; Laravel Admin Dashboard Theme" />
				<meta property="og:url" content="https://keenthemes.com/metronic" />
				<meta property="og:site_name" content="Keenthemes | Metronic" />
				<link rel="canonical" href="https://preview.keenthemes.com/metronic8" />
				<link rel="shortcut icon" href="assets/media/logos/favicon.ico" />
				<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700" />
				<link href="assets/css/plugins.bundle.css" rel="stylesheet" type="text/css" />
				<link href="assets/css/style.bundle.css" rel="stylesheet" type="text/css" />

				<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
				<link rel="stylesheet" href="/vendors/formvalidation/dist/css/formValidation.min.css" />  
            </Helmet>
			<div id="loading" 
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					marginTop: "-50px",
					marginLeft: "-50px",
					width: "100px",
					height: "100px",
					textAlign: "center"
				}}
			>
				<span className="newloader"></span>
			</div>
			<div id="newloader-text"
				style={{ 
					position: "absolute",
					top: "50%",
					left: "50%",
					marginTop: "10px",
					marginLeft: "-90px",
					width: "200px",
					textAlign: "center",
					fontWeight: "700",
					color: "#009ef7",
					fontSize: "medium",
				}}
			>
                Real-Time Workspace...
            </div>
			<div id="signup-container" className="display-none"> 
				<div className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column" id="kt_signup_project_stepper">
					
					<div className="d-flex flex-column flex-lg-row-auto w-xl-500px bg-lighten shadow-sm">
						
						<div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-500px scroll-y">
							
							<div className="d-flex flex-row-fluid flex-column flex-center p-20 pt-lg-20">
								{/* href="../../demo1/dist/index.html" */}
								<div className="mb-10 mb-lg-20" style={{textAlign: "center"}}>
									<img alt="Logo" src={logo} className="h-50px" style={{marginTop: "-0.7rem"}} />
									<span className="rtw-txt">Real-Time Workspace</span>
								</div>
								
								<div className="stepper-nav">
								
									<div className="stepper-item current" data-kt-stepper-element="nav">
										
										<div className="stepper-line w-40px"></div>
										
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">1</span>
										</div>
										
										<div className="stepper-label">
											<h3 className="stepper-title">{t('Choose Workflows')}</h3>
											<div className="stepper-desc fw-bold">{t('Setup rtWorkspace Cloud')}</div>
										</div>
										
									</div>
									
									<div className="stepper-item" data-kt-stepper-element="nav">
										
										<div className="stepper-line w-40px"></div>
										
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">2</span>
										</div>
										
										<div className="stepper-label">
											<h3 className="stepper-title">{t('Business Identity')}</h3>
											<div className="stepper-desc fw-bold">{t('Setup your business identity')}</div>
										</div>
										
									</div>
									
									<div className="stepper-item" data-kt-stepper-element="nav">
										
										<div className="stepper-line w-40px"></div>
										
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">3</span>
										</div>
										
										<div className="stepper-label">
											<h3 className="stepper-title">{t('Business Owner')}</h3>
											<div className="stepper-desc fw-bold">{t('rtWork Owner Account')}</div>
										</div>
										
									</div>
									
									<div className="stepper-item" data-kt-stepper-element="nav">
										
										<div className="stepper-line w-40px"></div>
										
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">4</span>
										</div>
										
										<div className="stepper-label">
											<h3 className="stepper-title">{t('IT Supporter')}</h3>
											<div className="stepper-desc fw-bold">{t('IT Support at your business')}</div>
										</div>
										
									</div>
									
									<div className="stepper-item" data-kt-stepper-element="nav">
									
										<div className="stepper-line w-40px"></div>
										
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">5</span>
										</div>
										
										<div className="stepper-label">
											<h3 className="stepper-title">{t('Completed')}</h3>
											<div className="stepper-desc fw-bold">{t('Woah, we are here')}</div>
										</div>
										
									</div>
									
								</div>
								
							</div>
							
							<div 
								id="bg-img"
								className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-150px min-h-lg-300px" 
								style={{ backgroundImage: `url(${background})` }}
							>
							</div>
							
						</div>
						
					</div>
					
					<div className="d-flex flex-column flex-lg-row-fluid py-10">
						
						<div className="d-flex flex-center flex-column flex-column-fluid">
							
							<div className="w-lg-700px p-10 p-lg-15 mx-auto">
								
								<form onKeyPress={checkEnterPress} className="my-auto pb-5" noValidate="novalidate" id="kt_signup_project_form">
									
									<div className="current" data-kt-stepper-element="content">
										
										<div className="w-100">
											
											<div className="pb-10 pb-lg-15">
												
												<h2 className="fw-bolder d-flex align-items-center text-dark">{t('Choose Workflows')}</h2>
												{/* <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Billing is issued based on your selected account type"></i> */}
												
												<div className="text-muted fw-bold fs-6">
													{t('Choose workflows. If you need more information, see')}&nbsp;
													<a href="https://rtworkspace.com/" target="blank" className="link-primary fw-bolder">{t('Help Page')}</a>.
												</div>

											</div>
											
											<div className="fv-row">
												
												<div className="row">
													{moduleSets && moduleSets.map((moduleSet, index) => (
														<div key={index} className="col-lg-6">
															<input
																// type="radio" 
																type="checkbox" 
																className="btn-check" 
																name="module_set_workflow" 
																// checked={ parseInt(moduleSetId) === parseInt(moduleSet.id) }
																value={moduleSet.code}
																onChange={handleChangeModuleSet1}
																id={"module_set_" + index}
															/>
															<label className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" htmlFor={"module_set_" + index}>
																{/* begin::Render svg icon */}
																<div dangerouslySetInnerHTML={{__html: moduleSet.svg_icon}}/>
																{/* end::Render svg icon */}
																<span className="d-block fw-bold text-start">
																	<span className="text-dark fw-bolder d-block fs-4 mb-2">{moduleSet.title}</span>
																	<span className="text-muted fw-bold fs-6">{moduleSet.description}</span>
																</span>
															</label>
														</div>
													))}
													{/* Hidden to get value of workflows  */}
													<div className="mb-10 fv-row">
														<input 
															type="text" 
															name="arrWorkflow" 
															style={{display: "none"}} 
															defaultValue={arrWorkflow} 
														/>
													</div>
													<div className="mb-10 fv-row">
														<label className="form-label mb-3 required">{t('Choose your rtWork Cloud URL')}</label>
														<div 
															style={{ 
																display: "flex", 
																alignItems: "center",
																cursor: "text"
															}} 
															className="form-control form-control-lg form-control-solid"
															onClick={clickInputDiv}
														>
															{state.cloudUrl !== "" && <span>https://</span>}
															<span id="hide"></span>
															<input 
																ref={inputEl}
																id="txt" 
																name="cloudUrl" 
																type="text"
																value={(state.cloudUrl).toLowerCase()}
																onChange={handleChange}
																className="rta-input"
															/> 
															{state.cloudUrl !== "" && <span>.rtworkspace.com</span>}
														</div>
														
														<div className="form-text">{t('You have your own cloud server.')}</div>
													</div>

													<div className="mb-10 fv-row">
														<label className="form-label mb-3">{t('Referral Code')}</label>
														<input 
															type="text" 
															className="form-control form-control-lg form-control-solid rta-input" 
															name="referralCode"
															value={state.referralCode}
															onChange={handleChange}
															placeholder={t('Can be empty')}
														/>
														<div className="form-text">{t('Enter a referral code to get Free Credit.')}</div>
													</div>
													
												</div>
												
											</div>
											
										</div>
										
									</div>
									
									<div className="" data-kt-stepper-element="content">
										{/* Screen2 */}
										<div className="w-100">
											
											<div className="pb-10 pb-lg-15">
												<h2 className="fw-bolder text-dark">{t('Business Identity')}</h2>
												<div className="text-muted fw-bold fs-6">{t('Name, contact information displayed in your rtWork system.')}</div>
											</div>

											<div className="mb-10 fv-row">
												<label className="form-label mb-3 required">{t('Tax code')}</label>
												<input 
													type="text" 
													className="form-control form-control-lg form-control-solid rta-input" 
													name="taxCode" 
													placeholder={t('Please enter exactly 10 digits or 14 characters')}
												/>
												<div className="form-text">{t('Your VAT invoice will be issued to this tax code.')}</div>
											</div>
											{/* Depends on tax code */}
											<div className="dp-tc" style={{ display: "none"}}>
												<div className="mb-10 fv-row">
													<label className="form-label mb-3 required">{t('Business Name')}</label>
													<input
														style={{ cursor: "not-allowed"}}
														disabled="disabled" 
														type="text" 
														className="form-control form-control-lg form-control-solid rta-input" 
														name="businessName"
														placeholder="" 
													/>
												</div>

												<div className="mb-10 fv-row" name="team-size" style={{ pointerEvents : "none"}} >
													<label className="d-flex align-items-center form-label mb-3 required">{t('Specify Team Size')}
														{/* <i 
															className="fas fa-exclamation-circle ms-2 fs-7" 
															style={{ cursor: "context-menu"}}
															data-bs-toggle="tooltip" 
															title={t('Provide your team size to help us setup your billing')}
														/> */}
													</label>
													<div className="row mb-2" data-kt-buttons="true">
														<div className="col">
															<label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
																<input 
																	type="radio" 
																	className="btn-check" 
																	name="account_team_size" 
																	defaultValue="1"
																/>
																<span className="fw-bolder fs-3">1</span>
															</label>	
														</div>
														<div className="col">
															<label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 active">
																<input 
																	type="radio" 
																	className="btn-check" 
																	name="account_team_size" 
																	defaultChecked="checked" 
																	defaultValue="2-15"
																/>
																<span className="fw-bolder fs-3">2 - 15</span>
															</label>
														</div>
														<div className="col">
															<label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
																<input 
																	type="radio" 
																	className="btn-check" 
																	name="account_team_size" 
																	defaultValue="16-30" 
																/>
																<span className="fw-bolder fs-3">16 - 30</span>
															</label>
														</div>
														<div className="col">
															<label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
																<input 
																	type="radio" 
																	className="btn-check" 
																	name="account_team_size" 
																	defaultValue="31-100" 	
																/>
																<span className="fw-bolder fs-3">31 - 100</span>
															</label>
														</div>
														<div className="col">
															<label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
																<input 
																	type="radio" 
																	className="btn-check" 
																	name="account_team_size" 
																	defaultValue="100+" />
																<span className="fw-bolder fs-3">100 +</span>
															</label>
														</div>
													</div>
													<div className="form-text">{t('Provide your team size to help us setup your billing')}</div>
												</div>

												<div className="mb-10 fv-row">
													<label className="form-label mb-3 required">{t('Telephone number')}</label>
													<input
														style={{ cursor: "not-allowed"}}
														disabled="disabled" 
														type="text" 
														className="form-control form-control-lg form-control-solid rta-input"
														maxLength="11"
														name="phoneNumber"
														value={onlynumber}
														onChange={(e) => {
															const re = /^[0-9\b]+$/;
															if (e.target.value === '' || re.test(e.target.value)) {
																setOnlynumber(e.target.value)
															}	
														}}
													/>
												</div>

												<div className="mb-10 fv-row">
													<label className="form-label mb-3 required">{t('Business Email')}</label>
													<input
														style={{ cursor: "not-allowed"}}
														disabled="disabled" 
														type="text" 
														className="form-control form-control-lg form-control-solid rta-input" 
														name="businessEmail" 
														onKeyPress={checkEnterSpace}
														placeholder="" 
													/>
													<div 
														id="business-invalid-email"
														className="fv-plugins-message-container invalid-feedback" 
														style={{color: "#198754", fontWeight: "400", display: "none"}}
													>
														{t('Invalid email address in automated checking system. If you are sure please skip.')}
													</div>
												</div>

												<div className="mb-10 fv-row">
													<label className="form-label mb-3 required">{t('Business Address')}</label>
													<input
														style={{ cursor: "not-allowed"}}
														disabled="disabled" 
														type="text" 
														className="form-control form-control-lg form-control-solid rta-input" 
														name="businessAddress" 
														placeholder="" 
													/>
												</div>	
											</div>
										</div>
										
									</div>
									
									<div className="" data-kt-stepper-element="content">
										{/* Screen3 */}
										<div className="w-100">
											
											<div className="pb-10 pb-lg-12">
												
												<h2 className="fw-bolder text-dark">{t('rtWork Owner Account')}</h2>
												
												<div className="text-muted fw-bold fs-6">{t('Owner is the supreme user. See more privileges of Owner')}&nbsp;
												<a href="https://rtworkspace.com/" target="blank" className="link-primary fw-bolder">{t('Here')}</a>.</div>
												
											</div>

											<div className="fv-row mb-10" style={{ display: (orgIdIndex !== -1 ? "" : "none") }}>
												<label className="form-label required">{t('Available options')}</label>
												<select 
													id="ownerName_select"
													name="ownerName_select" 
													className="form-select form-select-lg form-select-solid custom-type1" 
													data-control="select2" 
													data-placeholder={t('...')}
													data-allow-clear="true" 
													data-hide-search="false"
												>
													<option></option>
												</select>
											</div>

											<div className="dp-slname3" style={{ display: (orgIdIndex !== -1 ? "none" : "") }}>
												<div className="fv-row mb-10">
													<label className="form-label required">{t('Name')}</label>
													<input 
														name="ownerName" 
														className="form-control form-control-lg form-control-solid rta-input" 
													/>
												</div>

												<div className="fv-row mb-10">
													<label className="form-label required">{t('Telephone number')}</label>
													<input 
														type="text"
														className="form-control form-control-lg form-control-solid rta-input" 
														maxLength="11"
														name="ownerPhoneNumber"
														value={onlynumber1}
														onChange={(e) => {
															const re = /^[0-9\b]+$/;
															if (e.target.value === '' || re.test(e.target.value)) {
																setOnlynumber1(e.target.value)
															}	
														}}
													/>
												</div>
												
												<div className="fv-row mb-10">
													<label className="fs-6 fw-bold form-label required">{t("Owner's Email")}</label>
													<input 
														name="ownerEmail" 
														className="form-control form-control-lg form-control-solid rta-input" 
														// value={state.ownerEmail}
														// onChange={handleChange}
														onKeyPress={checkEnterSpace}
													/>
													<div 
														id="owner-invalid-email"
														className="fv-plugins-message-container invalid-feedback" 
														style={{color: "#198754", fontWeight: "400", display: "none"}}
													>
														{t('Invalid email address in automated checking system. If you are sure please skip.')}
													</div>
												</div>

												{/* <div className="dp-sc3" style={{ display: "none"}}> */}
													<div className="fv-row mb-10">
														<label className="fs-6 fw-bold form-label required">{t("Owner's UserName")}</label>
														<input 
															name="ownerUserName" 
															className="form-control form-control-lg form-control-solid rta-input" 
														/>
													</div>

													<div className="fv-row mb-10">
														<label className="fs-6 fw-bold form-label required">{t("Owner's Password")}</label>
														<input	
															type="text"
															name="ownerPassword" 
															className="form-control form-control-lg form-control-solid rta-input" 
														/>
													</div>
												{/* </div> */}
											</div>
											
										</div>
										
									</div>
									
									<div className="" data-kt-stepper-element="content">
										{/* Screen4: Allow empty field */}
										<div className="w-100">
											
											<div className="pb-10 pb-lg-15">
												<h2 className="fw-bolder text-dark">{t('IT Supporter Account')}</h2>
												{/* <div className="text-muted fw-bold fs-6">{t('Owner is the supreme user. See more privileges of Owner')}&nbsp;
												<a href="#" className="text-primary fw-bolder">{t('Here')}</a>.</div> */}

												<div className="text-muted fw-bold fs-6">{t('IT Support at your business')}</div>
												
											</div>

											<div className="fv-row mb-10" style={{ display: (orgIdIndex !== -1 ? "" : "none") }}>
												<label className="form-label">{t('Available options')}</label>
												<select 
													id="itName_select"
													name="itName_select" 
													className="form-select form-select-lg form-select-solid custom-type2" 
													data-control="select2" 
													data-placeholder={t('...')}
													data-allow-clear="true" 
													data-hide-search="false"
												>
													<option></option>
												</select>
											</div>

											{/* <div className="dp-slname4" style={{ display: (orgIdIndex !== -1 ? "none" : "") }}> */}
												<div className="fv-row mb-10">
													<label className="form-label">{t('Name')}</label>
													<input 
														name="itName" 
														className="form-control form-control-lg form-control-solid rta-input" 
														placeholder={t('Can be empty')}
													/>
												</div>

												<div className="fv-row mb-10">
													<label className="form-label">{t('Telephone number')}</label>
													<input
														type="text" 
														className="form-control form-control-lg form-control-solid rta-input" 
														maxLength="11"
														name="itPhoneNumber"
														placeholder={t("Must not be the same as Owner's phone number (Can be empty)")}
														value={onlynumber2}
														onChange={(e) => {
															const re = /^[0-9\b]+$/;
															if (e.target.value === '' || re.test(e.target.value)) {
																setOnlynumber2(e.target.value)
															}	
														}}
													/>
												</div>
												
												<div className="fv-row mb-10">
													<label className="fs-6 fw-bold form-label">{t("IT Supporter's Email")}</label>
													<input 
														name="itEmail" 
														className="form-control form-control-lg form-control-solid rta-input"
														placeholder={t("Must not be the same as Owner's email (Can be empty)")}
														onKeyPress={checkEnterSpace}
													/>
													<div 
														id="it-invalid-email"
														className="fv-plugins-message-container invalid-feedback" 
														style={{color: "#198754", fontWeight: "400", display: "none"}}
													>
														{t('Invalid email address in automated checking system. If you are sure please skip.')}
													</div>
												</div>

												<div className="dp-sc4" style={{ display: "none"}}>
													<div className="fv-row mb-10">
														<label className="fs-6 fw-bold form-label">{t("IT's UserName")}</label>
														<input 
															name="itUserName" 
															className="form-control form-control-lg form-control-solid rta-input" 
															placeholder={t("Must not be the same as Owner's UserName")}
														/>
													</div>

													<div className="fv-row mb-10">
														<label className="fs-6 fw-bold form-label">{t("IT's Password")}</label>
														<input 
															type="text"
															name="itPassword" 
															className="form-control form-control-lg form-control-solid rta-input" 
														/>
													</div>
												</div>
											{/* </div> */}
										</div>
										
									</div>
									
									<div className="" data-kt-stepper-element="content">
										{/* Screen5 */}
										<div className="w-100">
											<div className="pb-8 pb-lg-10">
												<h2 className="fw-bolder text-dark">{t('Activate your Account!')}</h2>
												<div className="text-muted fw-bold fs-6">{t('Your rtWork system is deployed ready shortly.')}</div>
												{/* <a href="../../demo1/dist/authentication/sign-in/basic.html" className="link-primary fw-bolder">Sign In</a>.</div> */}
											</div>
											
											<div className="mb-0">
												<div 
													// className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6"
													className="notice d-flex rounded p-6" 
													style={{ background: "#f1faff"}}
												>
													<div style={{ display: "flex", justifyContent: "center", flexGrow: "1"}}> 
														<div style={{ textAlign: "center"}} className="fw-bold">
															<div className="fs-3 fw-bold text-muted mb-10">{t('We have sent an email to')}&nbsp; 
																{/* <a href="#" className="link-primary fw-bolder">max@keenthemes.com</a> */}
																<span id="showOEmail" className="link-primary fw-bolder"></span>
																<br/>{t('please check and enter the verification code in the input below.')}
															</div>
															{/* <div className="fs-6 text-gray-700">Did't receive an email?&nbsp;
																<a href="#" className="fw-bolder">Resend</a>
															</div> */}
														</div>
													</div>	
												</div>
												<div style={{marginTop: "2em"}} className="fv-row mb-10">
													<label className="form-label required">{t('Your verification code')}</label>
													<input
														name="verifyCode" 
														className="form-control form-control-lg form-control-solid" 
														value={state.verifyCode}
														onChange={handleChange}
													/>
													<button
														id="btn-verify"
														type="button" 
														className="btn btn-lg btn-primary"
														style={{
															marginTop: "1em",
															width: "100%",
															fontWeight: "700"
														}}
														onClick= {handleVerification}
													>	
														{t('Verify')}
													</button>
													<div id="show" style={{display: "none"}}>
														{
															verification !== null && verification === false 
															? (
																<div 
																	className="notice rounded p-6" 
																	style={{
																		background: "#f1faff",
																		marginTop: "2em", 
																		border: "1px solid #009ef7" 
																	}}
																>
																	<div> 
																		<div className="fw-bold">
																			<i className="flaticon-warning" style={{color: "red", fontSize: "1.5rem"}}></i>
																			<span style={{ color: "red", fontSize: "15px", marginLeft: "14px" }}>
																				{confirm}
																			</span>
																			{
																				(confirm === "Authcode Expired" || 
																				confirm === "Mã xác thực đã hết hạn." || 
																				confirm === "Code d'authentification expiré.") && 
																				<span 
																					style={{ 
																						color: "#009ef7", 
																						fontSize: "15px", 
																						marginLeft: "4px", 
																						textDecoration: "underline", 
																						cursor: "pointer" 
																					}} 
																					onClick={requestNewCode}
																				>
																					{t('Request new code')}
																				</span>
																			}
																			<span
																				style={{
																					float: "right",
																					fontSize: "1.75rem",
																					fontWeight: "700",
																					lineHeight: "1",
																					color: "red",
																					textShadow: "0 1px 0 #fff",
																					cursor: "pointer",
																					opacity: "0.5"
																				}}
																				onClick={() => {
																					document.getElementById('show').style.display = "none" 
																				}} 
																			>×</span>
																		</div>
																	</div>
																	{/* {(	confirm === "Authcode Expired" ||
																		confirm === "Mã xác thực đã hết hạn." ||
																		confirm === "Code d'authentification expiré.") &&
																		<div 
																			className="fw-bold" 
																			style={{ 
																				display: "flex", 
																				justifyContent: "space-evenly",
																				flexGrow: "1", 
																				marginTop: "1em" 
																			}} 
																		>
																			<button 
																				className="btn btn-info" 
																				type="button" 
																				onClick={requestNewCode}
																			>
																				{t('Request new code')}
																			</button>
																			<button 
																				className="btn btn-light" 
																				type="button" 
																				onClick={() => {
																					document.getElementById('show').style.display = "none" 
																				}} 
																			>
																				{t('Cancel')}
																			</button>	
																		</div>
																	} */}
																</div>
															)
															: (
																<div 
																	className="notice rounded p-6" 
																	style={{
																		background: "#f1faff",
																		marginTop: "2em", 
																		border: "1px solid #009ef7" 
																	}}
																>
																	<div> 
																		<div className="fw-bold">
																			<i className="fa fa-check-circle-o" style={{color: "#009688", fontSize: "1.5rem"}}></i>
																			<span style={{ color: "#009688", fontSize: "15px", marginLeft: "14px" }}>
																				<strong>{t('Authentication successful.')}&nbsp;</strong> 
																				<strong>{t('Your Workspace is initializing, we will send your accounts to you via email.')}</strong> 
																			</span>
																		</div>
																	</div>	
																</div>
															)
														}
													</div>
													<div id="show1" style={{display: "none"}}>
														{
															verification1 !== null && verification1 === false 
															? (
																<div 
																	className="notice rounded p-6" 
																	style={{ 
																		background: "#f1faff",
																		marginTop: "2em", 
																		border: "1px solid #009ef7" 
																	}}
																>
																	<div> 
																		<div className="fw-bold">
																			<i className="flaticon-warning" style={{color: "red", fontSize: "1.5rem"}}></i>
																			<span style={{ color: "red", fontSize: "15px", marginLeft: "14px" }}>
																				{confirm1}
																			</span>
																			<span
																				style={{
																					float: "right",
																					fontSize: "1.75rem",
																					fontWeight: "700",
																					lineHeight: "1",
																					color: "red",
																					textShadow: "0 1px 0 #fff",
																					cursor: "pointer",
																					opacity: "0.5"
																				}}
																				onClick={() => {
																					document.getElementById('show1').style.display = "none" 
																				}} 
																			>×</span>
																		</div>
																	</div>
																</div>
															)
															: (
																<div 
																	className="notice rounded p-6" 
																	style={{ 
																		background: "#f1faff",
																		marginTop: "2em", 
																		border: "1px solid #009ef7" 
																	}}
																>
																	<div> 
																		<div className="fw-bold">
																			<i className="fa fa-check-circle-o" style={{color: "#009688", fontSize: "1.5rem"}}></i>
																			<span style={{ color: "#009688", fontSize: "15px", marginLeft: "14px" }}>
																				<strong>{t('Please check your email again!')}</strong>
																			</span>
																			<span
																				style={{
																					float: "right",
																					fontSize: "1.75rem",
																					fontWeight: "700",
																					lineHeight: "1",
																					color: "#009688",
																					textShadow: "0 1px 0 #fff",
																					cursor: "pointer",
																					opacity: "0.5"
																				}}
																				onClick={() => {
																					document.getElementById('show1').style.display = "none" 
																				}} 
																			>×</span>
																		</div>
																	</div> 
																</div>
															)
														}	
													</div>		
												</div>
											</div>
										</div>
									</div>
									
									<div className="d-flex flex-stack pt-15">
										<div className="mr-2">
										{/* { ( verification === null || (verification !== null && verification === false) ) &&  */}
											<button
												type="button" 
												className="btn btn-lg btn-light-primary me-3" 
												data-kt-stepper-action="previous"
											>
												<span className="svg-icon svg-icon-4 me-1">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
														<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black" />
														<path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black" />
													</svg>
												</span>
												{t('Previous')}
											</button>
										{/* } */}
										</div>
										<div>
											<button id="btn-submit" type="submit" className="btn btn-lg btn-primary" data-kt-stepper-action="submit">
											{/* <button type="submit" className="btn btn-lg btn-primary" data-kt-stepper-action="next"> */}
												<span className="indicator-label">
													{t('Submit')}
													<span className="svg-icon svg-icon-4 ms-2">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
															<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
														</svg>
													</span>
												</span>
												<span className="indicator-progress">Please wait...
												<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
											</button>
											<button id="btn-next" type="button" className="btn btn-lg btn-primary" data-kt-stepper-action="next">
												{t('Continue')}
												<span className="svg-icon svg-icon-4 ms-1">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
														<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
														<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
													</svg>
												</span>
											</button>
										</div>
									</div>	
								</form>	
							</div>	
						</div>
						<button style={{display: "none"}} id="en_US" type="button" className="setLocaleButton" data-locale="en_US">English</button>
						<button style={{display: "none"}} id="vi_VN" type="button" className="setLocaleButton" data-locale="vi_VN">Tiếng Việt</button>
						<button style={{display: "none"}} id="fr_FR" type="button" className="setLocaleButton" data-locale="fr_FR">Tiếng Pháp</button>
						<Translate i18n={i18n}/>
					</div>	
				</div>
			</div>
        </>
	);
}

export default SignUpProject;