import React from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import * as apis from '../../constants/Apis';
import * as utils from '../../utils';
import Layout from '../../components/Layout';
import Portlet from '../../components/Portlet';
import LoadingPage from '../../components/Layout/LoadingPage';
import WizardFormFirstStep from './WizardFormFirstStep';
import WizardFormSecondStep from './WizardFormSecondStep';
import WizardFormThirdStep from './WizardFormThirdStep';
import WizardFormFourStep from './WizardFormFourStep';
import {updateProjectTempData} from '../../actions/ecommerce';

export class NewProject extends React.Component {

    constructor(props){
        super(props);
        let step = 1, projectId = "", plan = "";
        let params = new URLSearchParams(props.location.search);
        if (params.get("pid")) {
            projectId = params.get("pid")
        }
        if (params.get("pln")) {
            plan = params.get("pln")
        }

        if (props.location.hasOwnProperty('state') && props.location.state !== undefined ) {
            let parentState = props.location.state
            if(parentState.hasOwnProperty('projectId') && parentState.projectId !== undefined){
                projectId = parentState.projectId
            }
            if(parentState.hasOwnProperty('step') && parentState.step !== undefined){
                step = parentState.step
            }
            if(parentState.hasOwnProperty('plan') && parentState.plan !== undefined){
                plan = parentState.plan
            }
        }

        this.state = {
            step: step,
            projectId: projectId,
            projectName: "",
            projectDomain: "rtworkspace.com",
            projectType: "rtwork",
            region: "ap-south",
            plan: plan,
            planName: "",
            planCategory: "",
            category: "",
            price: 0,
            cyclePrice: 0,
            priceDesc: "",
            phasePrice: 0,
            couponCode: "",
            couponApplied: "",
            couponPrice: 0,
            circle: 1,
            method: "",
            transactionId: "",
            showLoading: true,
            hasError: false
        }
        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount(){
        let _this = this
        let data = this.state
    		let currency = localStorage.getItem("currency")
        let userId = utils.getUserId()
        const {tempData, dispatch} = this.props
        if (data.projectId !== "") {
            this.autosave = setInterval(this.autoSaveProject.bind(this), 5000)
            let config = utils.getConfig();
            axios.get(apis.BASE_URL + 'projects/1/10/[]/{"e_user_project__id":"' + data.projectId + '"}', config)
            .then( res => {
                let projects = res.data.items;
                if (projects.length > 0) {
                    if(projects[0].step) {
                        data.step = parseInt(projects[0].step, 10)
                    }
                    data.plan = projects[0].plan_name.replace('_trial', '')
                    data.projectName = projects[0].proj_name
                    data.region = projects[0].node_region
                    data.circle = projects[0].quantity
                    data.method = projects[0].method
                    data.transactionId = projects[0].transaction_id
                    data.couponApplied = projects[0].coupon
                    data.showLoading = false

                    let newData = utils.unionObject(tempData, data)
                    dispatch(updateProjectTempData(newData))
                    axios.get('https://rtcenter.org/services/ecommerce-workspace-plan', {params: {name: data.plan, currency: currency}})
                    .then( res => {
                       data.price = res.data.msg[0].price
                       data.cyclePrice = res.data.msg[0].price
                       data.planName = res.data.msg[0].content.title
                       data.planCategory = res.data.msg[0].content.name
                       data.priceDesc = res.data.msg[0].content.price_desc || ""
                       data.phasePrice = res.data.msg[0].phase_price
                        _this.setState(data);
                    })
                    .catch( error => {
                        _this.setState(data);
                    });

                    axios.get('https://rtcenter.org/services/ecommerce-coupon-checking' + data.plan + '/' + data.couponApplied + '/' + userId)
                    .then( res => {
                      if(res.data.status === 'success') {
                        data.couponPrice = res.data.msg.price
                        _this.setState(data);
                      }
                    })
                    .catch( error => {
                      _this.setState(data);
                    })
                } else {
                    _this.setState({showLoading: false})
                }
            })
            .catch( error => console.log(error));
        }
    }

    componentWillUnmount(){
        if (this.autosave) {
            clearInterval(this.autosave)
        }
    }
    autoSaveProject(){
        let {tempData, dispatch} = this.props
        let data = utils.unionObject(tempData, this.state)
        if(JSON.stringify(tempData) === JSON.stringify(data)) return false
        if(this.state.hasError) return false
        dispatch(updateProjectTempData(data))
    }

    nextPage(isNew) {
        let {step} = this.state
        let {tempData, dispatch} = this.props
        this.setState({ step: step + 1, showLoading: false }, () => {
            let {projectId} = this.state
            if(isNew !== undefined) {
                this.props.history.push('/project/create?pid=' + projectId)
            }
            if((this.state.step === 3 && this.state.method === "bank_transfer") || this.state.step === 4) {
                let data = utils.unionObject(tempData, this.state)
                dispatch(updateProjectTempData(data))
            }
        })
    }

    previousPage() {
        let {step} = this.state
        this.setState({ step: step - 1 })
    }

    handleChange(name, value){
        let data = this.state;
        if (name === 'circle') {
            value = parseInt(value)
        }
        data[name] = value;
        this.setState(data);
    }

    render(){
        const {t, dispatch} = this.props
        let {step, projectId, showLoading} = this.state
        if (showLoading && projectId !=="") {
            return (<LoadingPage/>)
        }
		return(
			<Layout>
                <Layout.Content>
                    <Portlet>
                        <Portlet.Title>
                            <Portlet.Caption>
                                <h3 className="m-portlet__head-text">{t('New Project')}</h3>
                            </Portlet.Caption>
                        </Portlet.Title>
                        <Portlet.Body>
                            {step === 1 && <WizardFormFirstStep {...this.props} {...this.state} nextPage={this.nextPage} onChange={this.handleChange}/>}
                            {step === 2 && <WizardFormSecondStep {...this.props} {...this.state}  nextPage={this.nextPage} previousPage={this.previousPage} onChange={this.handleChange} />}
                            {step === 3 && <WizardFormThirdStep t={t} dispatch={dispatch} nextPage={this.nextPage} previousPage={this.previousPage} onChange={this.handleChange}/>}
                            {step === 4 && <WizardFormFourStep t={t} projectId={projectId} />}
                        </Portlet.Body>
                    </Portlet>
                </Layout.Content>
            </Layout>
			);
	}
}
export const mapStateToProps = (state) => ({
    balance: state.ecommerce.balance,
    tempData: state.ecommerce.projectTempData
})

export default connect(mapStateToProps)(NewProject);
