import React from 'react';

class Translate extends React.Component{
	
	changeLanguage(value) {
		const {i18n} = this.props;
		localStorage.setItem('language', value)
		i18n.changeLanguage(value);
	
		if (value === "en") {
			document.getElementById('en-lang').style.background = "#009ef7";
			document.getElementById('vi-lang').style.background = "white";
			document.getElementById('fr-lang').style.background = "white";
			document.getElementById("en_US").click();
		} else if (value === "vi") {
			document.getElementById('vi-lang').style.background = "#009ef7";
			document.getElementById('en-lang').style.background = "white";
			document.getElementById('fr-lang').style.background = "white";
			document.getElementById("vi_VN").click();
		} else {
			document.getElementById('fr-lang').style.background = "#009ef7";
			document.getElementById('en-lang').style.background = "white";
			document.getElementById('vi-lang').style.background = "white";
			document.getElementById("fr_FR").click();
		}
	}

	render() {
		setTimeout(() => {	
			if (localStorage.getItem("language") === "en" ) {
				document.getElementById('en-lang').style.background = "#009ef7";
			} else if (localStorage.getItem("language") === "vi") {
				document.getElementById('vi-lang').style.background = "#009ef7";
			} else {
				document.getElementById('fr-lang').style.background = "#009ef7";
			}
		}, 1400);
		
		return(
			<div className="language_login">
				<ul className="m-nav-sticky-h">
					<li id="en-lang" className="m-nav-sticky-h__item" style={{borderRadius: "6px"}}>
						<img onClick={this.changeLanguage.bind(this, 'en')} src="assets/img/flags/020-flag.svg" alt="en"/>
					</li>
					<li id="vi-lang" className="m-nav-sticky-h__item" style={{borderRadius: "6px"}}>
						<img onClick={this.changeLanguage.bind(this, 'vi')} src="assets/img/flags/flag_vietnam.png" alt="vn"/>
					</li>
					<li id="fr-lang" className="m-nav-sticky-h__item" style={{borderRadius: "6px"}}>
						<img onClick={this.changeLanguage.bind(this, 'fr')} src="assets/img/flags/flag_french.png" alt="fr"/>
					</li>
				</ul>
			</div>
		);
	}
}

export default Translate