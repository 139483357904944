import React from 'react';
import { Redirect } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import PageSidebar from './PageSidebar';
import Project from '../../containers/Project';
import NewProject from '../../containers/Project/NewProject';
import ProjectDetail from '../../containers/Project/ProjectDetail';
import BillingPayment from '../../containers/Project/BillingPayment'
import Billing from '../../containers/Billing';
import Referrals from '../../containers/Referrals';
import DetailPayment from '../../containers/Billing/DetailPayment';
import ChangePassword from '../../containers/User/ChangePassword';
import Tracking from '../../admin/Tracking';
import AdminProject from '../../admin/AdminProject';
import UserManagement from '../../admin/User';
import UserProfile from '../../admin/User/UserProfile';
import Socket from '../../Socket';
import Templates from '../../containers/Websites/Templates';
import NewWebsite from '../../containers/Websites/NewWebsite';
import WebsiteList from '../../containers/Websites/WebsiteList';



class Main extends React.Component
{
    constructor(props){
        super(props);
        this.state = {
            minimize: true
        }
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount(){
        if(window.innerWidth <= 1024){
            this.setState({minimize: false})
        }
        else{
            this.setState({minimize: true})
        }
        window.addEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        if(window.innerWidth <= 1024){
            this.setState({minimize: false})
        }
        else{
            this.setState({minimize: true})
        }
    }

    getPage() {
        const pages = {
            "Project"  : <Project {...this.props}/>,
            "NewProject": <NewProject {...this.props}/>,
            "Billing"  : <Billing {...this.props}/>,
            "DetailPayment"  : <DetailPayment {...this.props}/>,
            "ChangePassword" : <ChangePassword {...this.props} />,
            "Tracking" : <Tracking {...this.props} />,
            "AdminProject" : <AdminProject {...this.props} />,
            "ProjectDetail":<ProjectDetail {...this.props} />,
            "Billing-Payment": <BillingPayment {...this.props}/>,
            "UserProfile":<UserProfile {...this.props} />,
            "UserManagement":<UserManagement {...this.props} />,
            "Referrals": <Referrals {...this.props} />,
            "Templates":<Templates {...this.props} />,
            "NewWebsite":<NewWebsite {...this.props} />,
            "WebsiteList":<WebsiteList {...this.props} />

        };
        let rtisad = localStorage.getItem("rtisad");
        let defaultPage = rtisad === "true" ? "AdminProject" : "Project"
        if(localStorage.getItem("prebuiltWebsite")) {
            return pages["NewWebsite"];    
        }
        return pages[this.props.page ? this.props.page : defaultPage];
    }

    changeminimize(){
        const {minimize} = this.state;
        this.setState({minimize :!minimize})
    }

	render() {
        const {t, i18n, location} = this.props;
        const {minimize} = this.state;
        let access_token = localStorage.getItem("access_token");
        if( !access_token ){
            let state = {pathname: location.pathname}
            let params = new URLSearchParams(location.search);
            if (params.get("pln")) {
                state['plan'] = params.get("pln")
            }
            return (<Redirect to={{pathname: "/sign-in", state: state}}/>);
        }

        return (
            <div id="main-page">
                <Socket />
                <Header t={t} i18n={i18n} minimize={minimize} changeminimize={this.changeminimize.bind(this)}/>
                {minimize?<div className="m-aside-left-overlay" onClick={this.changeminimize.bind(this)}></div>:null}
                <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
                    <PageSidebar t={t} path = {this.props.match.path} minimize={minimize}/>
                    { this.getPage() }
                </div>
                <Footer t={t} />
            </div>
        );
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
}

export default Main;
