import * as types from '../constants/ActionTypes';

export const updateProjectId = id => ({
	type: types.UPDATE_PROJECT_ID,
	id
})

export const updateBalance = balance => ({
	type: types.UPDATE_BALANCE,
	balance
})

export const updateFreeCredit= freeCredit => ({
	type: types.UPDATE_FREECREDIT,
	freeCredit
})

export const updateUserInfo = data => ({
	type: types.UPDATE_USERINFO,
	data
})

/*begin project*/
export const setProjectList = value => ({
	type: types.SET_PROJECTLIST,
	value
})

export const setProjectTrans = value => ({
	type: types.SET_PROJECTTRANS,
	value
})

export const updateProjectTempData = value => ({
	type: types.UPDATE_TEMP_DATA,
	value
})
/*end project*/

export const setUserList = value => ({
	type: types.SET_USERLIST,
	value
})

export const setSubmissionList = value => ({
	type: types.SET_SUBMISSIONLIST,
	value
})

export const setProjectDetail = value => ({
	type: types.SET_PROJECTDETAIL,
	value
})

export const setAdminProject = value => ({
	type: types.SET_ADMINPROJECT,
	value
})

export const setAdminUsers = value => ({
	type: types.SET_ADMINUSER,
	value
})

export const setWebsitesList = value => ({
	type: types.SET_WEBSITES_LIST,
	value
})