import React from 'react';

class PageContent extends React.Component
{
	render() {
		return (
            <div className={ this.props.className || 'm-content' }>
                {this.props.children}
            </div>
		);
	}
}

export default PageContent;