import axios from "axios";
import React from "react";
import Layout from "../../components/Layout";
import Portlet from "../../components/Portlet";
import * as utils from "../../utils";
import * as apis from "../../constants/Apis";
import { Link } from "react-router-dom";
import LoadingPage from "../../components/Layout/LoadingPage";
import LazyLoad from "react-lazyload";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: true,
      templates: {},
    };
  }

  componentWillMount() {
    const config = utils.getConfig();
    axios
      .get(apis.BASE_URL + "website/layouts", config)
      .then((res) => {
        this.setState({
          templates: { ...res.data.message },
          showLoading: false,
        });
      })
      .catch((error) => console.log(error));
  }

  render() {
    const { t, dispatch } = this.props;
    const { templates, showLoading } = this.state;
    return (
      <Layout>
        {showLoading && <LoadingPage />}
        <Layout.Content>
          <Portlet>
            <Portlet.Body>
              <div className="row">
                {Object.values(templates).map((layout, index) => {
                  return (
                    <LazyLoad key={`layoutlist${index}`} className="col-md-3"  placeholder={<Skeleton count={3} />}>
                    <div>
                        <div className="card card-custom card-shadowless">
                          <div className="card-body p-0">
                            <a
                              href={layout.layout_preview}
                              target="_blank"
                            >
                              <LazyLoad placeholder={<Skeleton count={3} />}>
                                <img
                                  src={
                                    layout.layout_category === "aitthemes" ?
                                    apis.BASE_URL.replace("api/", "") + layout.layout_image : 
                                    layout.layout_image
                                  }
                                  alt=""
                                  className="mw-100 w-200px"
                                />
                              </LazyLoad>
                            </a>
                            <div className="text-center mt-3 mb-md-0 mb-lg-3 mb-md-0 mb-lg-3 mb-lg-0 mb-3 d-flex flex-row justify-content-around">
                              <a
                                href={layout.layout_preview}
                                className="m-menu__link font-size-h5 font-weight-bolder text-dark-75 text-hover-primary mb-1"
                                target="_blank"
                              >
                                {t("Preview")}
                              </a>
                              <Link
                                to={`/website/create/${layout.layout_category}/${layout.layout_zip}`}
                                className="m-menu__link font-size-h5 font-weight-bolder text-dark-75 text-hover-primary mb-1"
                                replace
                              >
                                {t("Deploy")}
                              </Link>
                            </div>
                          </div>
                        </div>
                    </div>
                      </LazyLoad>
                  );
                })}
              </div>
            </Portlet.Body>
          </Portlet>
        </Layout.Content>
      </Layout>
    );
  }
}

export default Templates;
