import React from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import WizardNav from './WizardNav';
import LoadingPage from '../../components/Layout/LoadingPage';
import { FormGroup, Col, FormControl, Row } from 'react-bootstrap';
import {updateUserInfo} from '../../actions/ecommerce';
import {ToastContainer} from 'react-toastr';
import '../../assets/css/toastr.css';


export class WizardFormThirdStep extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: [],
            showLoading: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.updateBillingInfo = this.updateBillingInfo.bind(this)
    }

    validateInput(error, name, value){
        const {t} = this.props
        let emptyMessage = {
            "lastName": t("Last Name cannot empty"),
            "firstName": t("First Name cannot empty"),
            "phone": t("Phone cannot empty"),
            "email": t("Email cannot empty"),
            "address": t("Address cannot empty"),
            "postalCode": t("Zip Code cannot empty"),
            "country": t("Country cannot empty"),
            "city": t("City cannot empty")
        }
        if (value.toString().trim().length === 0 && emptyMessage[name]) {
            error[name] = emptyMessage[name];
        } else if (name ==='phone' && !utils.validateNumber(value)) {
            error[name] = t("Phone is not valid");
        } else if (name ==='email' && !utils.validateEmail(value)) {
            error[name] = t("Email is not valid");
        } else {
            delete error[name];
        }
        return error;
    }

    handleChange(ev){
        let {dispatch} = this.props
        let {error} = this.state
        let name = ev.target.name
        let value = ev.target.value
        let newValue = {}
        newValue[name] = value;
        error = this.validateInput(error, name, value);
        this.setState({error: error})
        dispatch(updateUserInfo(newValue))
    }

    updateBillingInfo(event){
        event.preventDefault()
        let _this = this,
        {t, nextPage} = this.props,
        {error} = this.state;        
        let data = this.props.userInfo;       
        Object.keys(data).map(index=>{
            error = this.validateInput(error, index, data[index])
            return true
        })

        if(Object.keys(error).length >0){
            this.refs.toastContainer.error(t('Please enter information for the required field!'));
            this.setState(error);
            return false;
        }

        this.setState({showLoading: true})
        axios.put( apis.BASE_URL + 'user/' + utils.getUserId() + '/billing-info', data)
        .then( res => {
            nextPage();
        }).catch( error => {
            _this.setState({showLoading: false})
            _this.refs.toastContainer.error(t('Update data not success !'));
        });
    }

    render(){
        const {t} = this.props,
        {firstName, lastName, email, phone, address, city, postalCode, country, company, vatInfo} = this.props.userInfo
        let {error, showLoading} = this.state;

		return(
            <div className="m-wizard m-wizard--2 m-wizard--success" id="m_wizard">
                {showLoading && <LoadingPage />}
                <WizardNav t={t} step={3} />
                <div className="m-wizard__form">
                    <div className="m-form m-form--label-align-left- m-form--state-">
                        <div className="m-wizard__form-step m-wizard__form-step--current" id="m_wizard_form_step_3">
                            <Row>
                                <Col lg={8} className="offset-xl-2">
                                    <div className="m-form__section">
                                        <div className="m-form__heading">
                                            <h3 className="m-form__heading-title"><b>{t('BILLING DETAIL')}</b></h3>
                                        </div>
                                        <FormGroup className="m-form__group row">
                                            <Col lg={6}>
                                                <label className="form-control-label required">{t('First Name')}</label>
                                                <FormControl type="text" name="firstName" value={firstName} onChange={this.handleChange} />
                                                {error['firstName'] && <p className="error-msg">{error['firstName']}</p>}
                                            </Col>
                                            <Col lg={6}>
                                                <label className="form-control-label required">{t('Last Name')}</label>
                                                <FormControl type="text" name="lastName" value={lastName} onChange={this.handleChange} />
                                                {error['lastName'] && <p className="error-msg">{error['lastName']}</p>}                                                
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="m-form__group row">
                                            <Col lg={6}>
                                                <label className="form-control-label required">{t('Email')}</label>
                                                <FormControl type="email" name="email" value={email } onChange={this.handleChange} />
                                                {error['email'] && <p className="error-msg">{error['email']}</p>}  
                                            </Col>
                                            <Col lg={6}>
                                                <label className="form-control-label required">{t('Phone Number')}</label>
                                                <FormControl type="text" name="phone" value={phone} onChange={this.handleChange} />
                                                {error['phone'] && <p className="error-msg">{error['phone']}</p>}  
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="m-form__group row">
                                            <Col lg={12}>
                                                <label className="form-control-label required">{t('Address')}</label>
                                                <FormControl type="text" name="address" value={address} onChange={this.handleChange} />
                                                {error['address'] && <p className="error-msg">{error['address']}</p>}  
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="m-form__group row">
                                            <Col lg={6} className="m-form__group-sub">
                                                <label className="form-control-label required">{t('City')}</label>
                                                <FormControl type="text" name="city" value={city} onChange={this.handleChange} />
                                                {error['city'] && <p className="error-msg">{error['city']}</p>}  
                                            </Col>
                                            <Col lg={6} className="m-form__group-sub">
                                                <label className="form-control-label required">{t('Zip Code')}</label>
                                                <FormControl type="text" name="postalCode" value={postalCode} onChange={this.handleChange} />
                                                {error['postalCode'] && <p className="error-msg">{error['postalCode']}</p>}  
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="m-form__group row">
                                            <Col lg={12} className="m-form__group-sub">
                                                <label className="form-control-label required">{t('Country')}</label>
                                                <FormControl type="text" name="country" value={country} onChange={this.handleChange} />
                                                {error['country'] && <p className="error-msg">{error['country']}</p>}  
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="m-form__group row">
                                            <Col lg={12}>
                                                <label className="form-control-label">{t('Company')}</label>
                                                <FormControl type="text" name="company" value={company} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="m-form__group row">
                                            <Col lg={12}>
                                                <label className="form-control-label">{t('VAT Billing Info')}</label>
                                                <FormControl as="textarea" rows="3" name="vatInfo" value={vatInfo} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                    </div>
                                    
                                    <div className="m-form__actions m--align-right">
                                        <button className="btn btn-success m-btn m-btn--custom m-btn--icon" onClick={this.updateBillingInfo}>
                                            <span>
                                                <span>{t('Next')}</span>&nbsp;&nbsp;
                                                <i className="fa fa-arrow-right"></i>
                                            </span>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <ToastContainer 
                    ref="toastContainer"
                    className="toast-top-right"
                /> 
            </div> 
		);
	}
}

export const mapStateToProps = state => {
    let defaultProps = utils.unionObject({
        "firstName": "",
        "lastName": "",
        "email": "",
        "phone": "",
        "address": "",
        "city": "",
        "postalCode": "",
        "country": "",
        "company": "",
        "vatInfo": ""
    }, state.ecommerce.userInfo)

    return ({userInfo: defaultProps})
}

export default connect(mapStateToProps)(WizardFormThirdStep);
