import React from 'react';
import {  HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Main from './components/Layout/Main';
import SignIn from './containers/User/SignIn';
import SignUp from './containers/User/SignUp';
import SignUpProject from './containers/User/SignUpProject';
import SignOut from './containers/User/SignOut';
import Verify from './containers/User/Verify';
import ForgetPassword from './containers/User/ForgetPassword';
import Success from './components/Response/Success.js';
import Fail from './components/Response/Fail.js';
import ResetPassword from './containers/User/ResetPassword'
import './assets/css/custom.css';

export class App extends React.Component
{
    componentWillMount(){
        fetch(
            "https://geolocation-db.com/json/8dd79c70-0801-11ec-a29f-e381a788c2c0"
        )
        .then(response => response.json())
        .then(data => {
            const country_code = data.country_code;
            // translate
            const {i18n} = this.props
            let language = localStorage.getItem('language');
            if(language === null){
                if (country_code === "VN") {
                    localStorage.setItem('language','vi');
                    language = "vi"
                } else if (country_code === "FR") {
                    localStorage.setItem('language','fr');
                    language = "fr"
                } else {
                    localStorage.setItem('language','en');
                    language = "en"
                }
            }
            i18n.changeLanguage(language)  
        });
    }
	render() {
        let prs = this.props
        return (
            <HashRouter>
                <Switch>
                    <Route path="/" exact render={(props) => (<Main {...prs} {...props}/>)}/>
                    <Route path="/sign-in" render={(props) => (<SignIn {...prs} {...props}/>)}/>
                    <Route path="/forget-password" render={(props) => (<ForgetPassword {...prs} {...props}/>)}/>
                    <Route path="/sign-up" render={(props) => (<SignUp {...prs} {...props}/>)}/>
                    <Route path="/sign-up-project" render={(props) => (<SignUpProject {...prs} {...props}/>)}/>
                    <Route path="/sign-out" component={SignOut}/>
                    <Route path="/verify-user" render={(props) => (<Verify {...prs} {...props}/>)}/>
                    <Route path="/success" render={(props) => (<Success {...prs} {...props}/>)}/>
                    <Route path="/fail" render={(props) => (<Fail {...prs} {...props}/>)}/>
                    <Route path="/reset-password" render={(props) => (<ResetPassword {...prs} {...props}/>)}/>

                    <Route path="/admin" exact render={(props) => (localStorage.getItem("rtisad") !== "true") ? (<Redirect to="/"/>) : (<Main page="AdminProject" {...prs} {...props}/>)}/>
                    <Route path="/admin/project" exact render={(props) => (localStorage.getItem("rtisad") !== "true") ? (<Redirect to="/"/>) : (<Main page="AdminProject" {...prs} {...props}/>)}/>
                    <Route path="/admin/tracking" exact render={(props) => (localStorage.getItem("rtisad") !== "true") ? (<Redirect to="/"/>) : (<Main page="Tracking" {...prs} {...props}/>)}/>
                    <Route path="/admin/user" exact render={(props) => (localStorage.getItem("rtisad") !== "true") ? (<Redirect to="/"/>) : (<Main page="UserManagement" {...prs} {...props}/>)}/>
                    <Route path="/admin/user/detail/:id" render={(props) => (localStorage.getItem("rtisad") !== "true") ? (<Redirect to="/"/>) : (<Main page="UserProfile" {...prs} {...props}/>)}/>

                    <Route path="/project" exact render={(props) => (localStorage.getItem("rtisad") === "true") ? (<Redirect to="/"/>) : (<Main page="Project" {...prs} {...props}/>)}/>
                    <Route path="/project/create" render={(props) => (localStorage.getItem("rtisad") === "true") ? (<Redirect to="/"/>) : (<Main page="NewProject" {...prs} {...props}/>)}/>
                    <Route path="/project/detail" render={(props) => (<Main page="ProjectDetail" {...prs} {...props}/>)}/>
                    <Route path="/project/billing-payment" render={(props) => (<Main page="Billing-Payment" {...prs} {...props}/>)}/>
                    <Route path="/billing" exact render={(props) => (localStorage.getItem("rtisad") === "true") ? (<Redirect to="/"/>) : (<Main page="Billing" {...prs} {...props}/>)}/>
                    <Route path="/detail-payment" exact render={(props) => (localStorage.getItem("rtisad") === "true") ? (<Redirect to="/"/>) : (<Main page="DetailPayment" {...prs} {...props}/>)}/>
                    <Route path="/change-password" render={(props) => (<Main page="ChangePassword" {...prs} {...props}/>)}/>
                    <Route path="/referrals" exact render={(props) => (localStorage.getItem("rtisad") === "true") ? (<Redirect to="/"/>) : (<Main page="Referrals" {...prs} {...props}/>)}/>

                    <Route path="/templates" exact render={(props) => (localStorage.getItem("rtisad") === "true") ? (<Redirect to="/"/>) : (<Main page="Templates" {...prs} {...props}/>)}/>
                    <Route path="/website/create/:category/:id" exact render={(props) => (localStorage.getItem("rtisad") === "true") ? (<Redirect to="/"/>) : (<Main page="NewWebsite" {...prs} {...props}/>)}/>
                    <Route path="/websites" exact render={(props) => (localStorage.getItem("rtisad") === "true") ? (<Redirect to="/"/>) : (<Main page="WebsiteList" {...prs} {...props}/>)}/>

                    <Route path="/:other" render={(props) => (<Main {...prs} {...props}/>)}/>
                </Switch>
            </HashRouter>
        );
    }
}

export default withTranslation()(App);
