import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import Layout from '../../components/Layout';
import Portlet from '../../components/Portlet';
import DataTable from '../../components/DataTable';
import LoadingPage from '../../components/Layout/LoadingPage';
import { Link } from 'react-router-dom';
import {setAdminProject} from '../../actions/ecommerce';
import {ToastContainer} from 'react-toastr';
import '../../assets/css/toastr.css';

export class AdminProject extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            reDeployLoading: false,
            meta:[],
            paramsFilter: "[]"
        }
    }

    componentDidMount(){
        let _this = this,
        sort= {property: "created_at", direction: "DESC"};
        const {t, dispatch} = this.props
        this.setState({showLoading: true})
        axios.get(apis.BASE_URL + 'projects/list/1/10/{"'+sort.property+'":"SORT_'+sort.direction+'"}/[]', utils.getConfig())
        .then( res => {
            dispatch(setAdminProject({data: res.data.items,meta: res.data._meta}))
            _this.setState({
                showLoading : false
            });
        })
        .catch( error => {
            _this.setState({showLoading: false});
            _this.refs.toastContainer.error(t("Cannot get project list"));
        });
    }

    changeMeta(meta, filter = false){
        const {t, dispatch} = this.props,
        {paramsFilter} = this.state

        //sort
        let sort= {property: "created_at", direction: "DESC"};
        if(meta.sortInfo){
            sort = meta.sortInfo
        }
        let _this= this;

        //define str to handle api
        let str = 'projects/list/'+meta.currentPage+'/'+meta.perPage+'/{"'+sort.property+'":"SORT_'+sort.direction+'"}'
        if(filter || filter===""){
            this.setState({paramsFilter: filter})
            str = str + '/'+filter
        }
        else{
            str = str + '/'+paramsFilter
        }
        axios.get(apis.BASE_URL + str, utils.getConfig())
        .then( res => {
            dispatch(setAdminProject({data: res.data.items,meta: res.data._meta}))
        })
        .catch( error => {
            _this.refs.toastContainer.error(t("Cannot get project list"));
        });
    }

    reDeploy(id){
        const {t} = this.props
        let _this = this
        this.setState({reDeployLoading: true})
        axios.put(apis.BASE_URL + 'projects/'+id+'/re-deploy',[] ,utils.getConfig())
        .then( res => {
            _this.setState({reDeployLoading : false});
            _this.refs.toastContainer.success(t("Re-Deploy success")+"!!!");
        })
        .catch( error => {
            _this.setState({reDeployLoading: false});
            _this.refs.toastContainer.error(t("Re-Deploy not success")+"!!!");
        });
    }

    render(){
        const { t, adminProject } = this.props,
        {reDeployLoading, showLoading} = this.state;
        let status = "",
        pro_id = "",
        config = {
            api: true,
            filter:true,
            cols: [
                { property: "id", header: t("Id"),sortable: false,
                    display:"none",
                    noFilter: true,
                    decorator : () => ({ style : { width : "20%" } }),
                    renderer : value => {
                        pro_id=value
                    }
                },
                { property: "proj_url", header: t("Project URL"), sortable: true,
                    filterType: {type: "text"},
                    decorator : () => ({ style : { width : "20%" } }),
                    renderer : value =>  value ? (<a href={value} target="_blank" rel="noopener noreferrer">{value}</a>) : ""
                },
                { property: "username", header: t("UserName"), sortable: true,
                    filterType: {type:"text"},
                    decorator : () => ({ style : { width : "20%" } }),
                    renderer : value =>  value ? value : ""
                },
                { property: "email", header: t("Email"), sortable: true,
                    filterType: {type:"text"},
                    decorator : () => ({ style : { width : "20%"} }),
                    renderer : value =>  value ? value : ""
                },
                { property: "status", header: t("Status"), sortable: true,
                    filterType: {type: "number", prefix:"e_user_project__", values: [{label: t("Select all")+"...", value: ""},
                                                        {label: t("Deploy Fail"), value: "-2"},
                                                        {label: t("Inactive"), value: "-1"},
                                                        {label: t("Pause"), value: "0"},
                                                        {label: t("Active"), value: "1"},
                                                        {label: t("In Transaction"), value: "2"},
                                                        {label: t("Paid"), value: "3"},
                                                        {label: t("Deploying"), value: "4"},
                                                        {label: t("Unpaid"), value: "5"},
                                                        {label: t("Verifying Payment"), value: "6"},
                                                        {label: t("Verify Fail"), value: "8"}]},

                    decorator : () => ({ style : {width : "20%",minWidth: "60px"}}),
                    renderer : value => {
                        status = value
                        let className = utils.projectStatusClassName[value]
                        let text = utils.projectStatusText[value]
                        return (<span className={className}>{t(text)}</span>);
                    }
                },
                { property: "created_at", header: t("Created At"), sortable: true,
                    filterType: {type: "date", prefix:"e_user_project__"},
                    decorator : () => ({ style : { width : "100%", minWidth: "75px"} }),
                    renderer : value => utils.convertTimestampToDate(value)
                },
                { property: "created_at", header: t("Action"),sortable: false,
                    filterType: {type:"none"},
                    decorator : () => ({ style : { width : "20%" } }),
                    renderer : value => (<div> {status === "1"?[<Link to={"/project/detail/"+pro_id} className="btn btn-outline-accent m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air">
                                                                        <i className="fa fa-eye"></i>
                                                                </Link>,
                                                                <Link to={`/project/billing-payment/${pro_id}/${value}`} className="btn btn-outline-warning m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air">
                                                                        <i className="fa fa-calendar-check"></i>
                                                                </Link>
                                                            ]:null }
                                                {status === "-2"?<button disabled={reDeployLoading} className={reDeployLoading?"btn btn-outline-danger m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air m-loader m-loader--light m-loader--right disabled":"btn btn-outline-danger m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air"} onClick={this.reDeploy.bind(this, pro_id)}><i className="la la-rotate-right"></i></button>:null}
                                        </div>)
                }
            ]
        }

        return(
            <Layout>
                {showLoading && <LoadingPage />}
                <Layout.Content>
                    <Portlet>
                        <Portlet.Title>
                            <Portlet.Caption>
                                <h3 className="m-portlet__head-text">{t("Project List")}</h3>
                            </Portlet.Caption>
                        </Portlet.Title>
                        <Portlet.Body>
                            <DataTable t={t} className="margin-top--60" datasource={adminProject.data} config={config} meta={adminProject.meta} changeMeta={this.changeMeta.bind(this)}/>
                        </Portlet.Body>
                    </Portlet>
                </Layout.Content>
                <ToastContainer
                    ref="toastContainer"
                    className="toast-top-right"
                />
            </Layout>
        );
    }
}

export const mapStateToProps = ({ecommerce}) => {
    return {
            adminProject: ecommerce.adminProject
        }
}

export default connect(mapStateToProps)(AdminProject);
