import React from 'react';
import '../../assets/css/custom.css';
import AffiliateLink from './AffiliateLink';
import LoadingPage from '../../components/Layout/LoadingPage';
import axios from 'axios';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import {ToastContainer} from 'react-toastr';

class ReferralTab extends React.Component{
	constructor(props){
      super(props);
      this.state = {
				showLoading: false,
				setReferral: [],
				isEdit: true,
				referralCode: '',
				isReferral: true
      }
  }

	componentDidMount(){
		let _this = this
		this.setState({showLoading: true});
		axios.get('https://rtcenter.org/services/ecommerce-set-referral')
		.then( res => {
			let status = res.data.status
			if(status === "success"){
				_this.setState({
					setReferral: res.data.msg,
					showLoading: false,
				})
			}
		})
		.catch( error => {
				console.log(error);
		});
	}

	onChangeCode = (event) =>{
		let format = /[ àảãáạăằẳẵắặâầẩẫấậÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬđĐèẻẽéẹêềểễếệÈẺẼÉẸÊỀỂỄẾỆìỉĩíịÌỈĨÍỊòỏõóọôồổỗốộơờởỡớợÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢùủũúụưừửữứựÙỦŨÚỤƯỪỬỮỨỰỳỷỹýỵỲỶỸÝỴ!@#$%^&*()+\=\]{};':"\\|,<>?]+/;
		let name = event.target.name;
    	let value = event.target.value.toUpperCase();
		if(format.test(value) === true){
			this.setState({
				isReferral: false
			})
		}else {
			this.setState({
				isReferral: true
			})
		}
    this.setState({
      [name]: value,
    })
	}

	onSave = () => {
		let {referralCode, isReferral} = this.state
		let {t} = this.props
		let config = utils.getConfig();
		let _this = this
		let data = {"code": referralCode}
		if(referralCode !== ""){
			if(isReferral === true){
				axios.post(apis.BASE_URL + 'users/generate-referral-code', data, config)
				.then(res => {
					this.refs.toastContainer.success(t("Generate Referral Code Successful"))
					window.location.reload()
				}).catch( error => {
						if(error.response.data.error_code === "500"){
							_this.refs.toastContainer.error(t('Referral Code ')+error.response.data.errors[0].match(/(?!^)".*?"/g)+' '+t('has already been taken'))
						}
				});
			}
		}else {
			_this.setState({
				isEdit: true
			})
		}
	}

	onEdit = () =>{
		this.setState({
			isEdit: !this.state.isEdit
		})
		this.referralInput.focus();
	}

	onGetRandomCode = () =>{
		let {t} = this.props
		let config = utils.getConfig();
		let _this = this
		let data = {"code": ""}
		if (window.confirm(t("After you change referral code, all your friends and referral histories will be reset"))) {
			axios.post(apis.BASE_URL + 'users/generate-referral-code', data, config)
			.then(res => {
				this.refs.toastContainer.success(t("Generate Referral Code Successful"))
				window.location.reload()
			}).catch( error => {
				_this.refs.toastContainer.error(t('Generate Referral Code Unsuccessful'))
					console.log(error)
			});
		}
	}

	setAffiliateLink=(code)=>{
		let {referral_code} = this.props.userInfo
		return "https://myaccount.rtworkspace.com/#/sign-up?ref="+referral_code+"&set="+code
	}

	render(){
    const {t, userInfo}= this.props
		let {setReferral, showLoading, isEdit, referralCode, isReferral} = this.state
		let affiliateLink = setReferral.map((module, idx) => {
													return <AffiliateLink key={module.code} t={t} AffiliateLink={this.setAffiliateLink(module.code)} moduleName={module.name}/>
												})
		let btnEdit= "ref-button-edit ref-mar-left btn m-btn--pill m-btn--wide m-btn--uppercase m-btn--bolder activeBtn"
		let btnSave = "ref-mar-left btn m-btn--pill m-btn--wide m-btn--uppercase m-btn--bolder activeBtn"

		return (
        <div className="ref-padd-bottom">
					{showLoading && <LoadingPage />}
					<div>
	          <h4 className="m-portlet__head-text"><b>{t('Referral Code')}</b></h4><br/>
						<div className="ref-fl-left">
							<a>https://myaccount.rtworkspace.com/#/sign-up?ref=
								<input autoComplete="off" ref={(input) => { this.referralInput = input; }}  className={isEdit === true ? "ref-wrapper-code" : "ref-wrapper-code-focus"}  placeholder={userInfo.referral_code} name="referralCode" value={referralCode} onChange={this.onChangeCode} readOnly={isEdit === true}/>
							</a>
							<button className={isEdit === false ? btnEdit : btnSave} onClick={isEdit === true ? this.onEdit : this.onSave}>{isEdit === true ? t("Edit") : t("Save")}</button>
							<span className="ref-mar-left">{t("or")}</span>
						<a className="ref-mar-left" status="_blank" href="javascript:void(0)" onClick={this.onGetRandomCode}>{t("Get random code and Save")}</a>
						{isReferral === false ? <div className="form-control-feedback ref-clear">{t('Cannot contain special characters')}</div> : ''}
						</div>
						{
							isEdit === false ? <div className="ref-fl-left ref-mar-left ref-code-warning ref-align">
																		<span className="ref-mar-left ref-fl-left flaticon-warning ref-flaticon-warning "></span>
																		<p className="ref-mar-left-warning ref-fl-left ref-warning-change-code">{t("If you change referral code, all your referral friends and history will be reset")}</p>
																</div>
																: ""
						}
						<div className="ref-clear col-xl-7">
							<br/>
							{affiliateLink}
						</div>
					</div>
					<div className="ref-clear ref-padd-top">
							<h4 className="m-portlet__head-text"><b>{t('Current Real-Time Partnership')}: {t("Real-Time Partner Plus")}</b></h4><br/>
							<p>{t("In this month, you have had ")}03{t(" your referral friends made payment until now, you will receive")}: </p>
							<p className="ref-text-indent">- 20,000.00 {t("for each (every 5) user(s) that made at least one payment in month.")}</p>
							<p className="ref-text-indent">- 0.1% {t("of payment amount for every payment of your referral friends")}</p><br/>
							<p>{t("In this month, your referral friends have paid ")}2,500,000.00 VND{t(" until now, you will receive")}: </p>
							<p className="ref-text-indent">- 20,000.00 {t("for each (every 5) user(s) that made at least one payment in month.")}</p>
							<p className="ref-text-indent">- 0.1% {t("of payment amount for every payment of your referral friends")}</p>
					</div>
					<div className="ref-clear ref-padd-top">
							<h4 className="m-portlet__head-text"><b>{t('Next Commission Level')}</b></h4><br/>
							<p>{t("If you have ")}03{t(" or more referral friends made payment, in their next payments, you will receive")}: </p>
							<p className="ref-text-indent">- 20,000.00 {t("for each (every 5) user(s) that made at least one payment in month.")}</p>
							<p className="ref-text-indent">- 0.1% {t("of payment amount for every payment of your referral friends")}</p><br/>
						<p>{t("If your referral friends paid ")}2,500,000.00 VND{t(" or more, in their next payment, you will receive")}: </p>
							<p className="ref-text-indent">- 20,000.00 {t("for each (every 5) user(s) that made at least one payment in month.")}</p>
							<p className="ref-text-indent">- 0.1% {t("of payment amount for every payment of your referral friends")}</p>
					</div>
					<ToastContainer
							ref="toastContainer"
							className="toast-top-right"
					/>
        </div>
			);
	}
}

export default ReferralTab;
