import React from 'react';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import * as utils from '../../utils';
import '../../assets/css/pricing_table.css'

const itemColor = [
    "m-pricing-table__green-item",
    "m-pricing-table__turquoise-item",
    "m-pricing-table__purple-item",
    "m-pricing-table__blue-item",
    "m-pricing-table__neutral-item",
    "m-pricing-table__orange-item",
    "m-pricing-table__slate-item",
    "m-pricing-table__red-item"
]
const Item = ({t, data, category, active, index, onClick}) => {
    let item = data.content;
    return (
         <div className={"col-xlg-3 col-md-4 col-sm-6 " + itemColor[index]}>
            <div className={active ? "m-pricing-table__item m-pricing-table__active" : "m-pricing-table__item"} onClick={onClick}>
                <div className="m-pricing-table__head">
                    <h3 className="m-pricing-table__title">{utils.handleLanguageModule(item.title)}</h3>
                    <div className={category === "rtsurvey,annually" ? "m-pricing-table__type" : "m-pricing-table__type monthly"}>
                        {category === "rtsurvey,annually" ? t('Paying Annually') : t('Paying Monthly')}
                    </div>
                </div>
                <div className="m-pricing-table__price">
                    {utils.handleCurrency(data.price)}
                    <p>{t('/month')}</p>
                </div>
                <div className="m-pricing-table__description">
                    {ReactHtmlParser(utils.handleLanguageModule(data.content_html))}
                </div>
            </div>
         </div>
    )
};



class Plan extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            plans: [],
            category: ''
        }
    }

    componentWillMount(){
        let {id, onChange, category} = this.props
        let _this = this
    		let currency = localStorage.getItem("currency")
        if (id !== "") {
            axios.get('https://rtcenter.org/services/ecommerce-plan-category/' + id)
            .then( res => {
                // let category = "rtworkspace_plan"
                axios.get('https://rtcenter.org/services/ecommerce-workspace-plan', {params: {categoryName: category, currency: currency}})
                .then( res => {
                    _this.setState({
                        plans: res.data.msg,
                        category: category
                    }, () => {
                        let data = res.data.msg.filter(pln => pln.name === id)
                        onChange("price", data[0].price)
                        onChange("cyclePrice", data[0].full_cycle_price)
                        onChange("planName", data[0].content.title)
                        onChange("planCategory", data[0].content.name)
                        onChange("priceDesc", data[0].content.price_desc)
                        onChange("phasePrice", data[0].phase_price)
                    });
                })
                .catch( error => {
                    console.log(error);
                });
            })
            .catch( error => {
                console.log(error);
            });
        }
    }

    componentWillReceiveProps(nextProps){
        let {id, category} = nextProps
        let _this = this
    		let currency = localStorage.getItem("currency")
        if (id !== "") {
            axios.get('https://rtcenter.org/services/ecommerce-plan-category/' + id)
            .then( res => {
                // let category = "rtworkspace_plan"
                axios.get('https://rtcenter.org/services/ecommerce-workspace-plan', {params: {categoryName: category, currency: currency}})
                .then( res => {
                    _this.setState({
                        plans: res.data.msg,
                        category: category
                    });
                })
                .catch( error => {
                    console.log(error);
                });
            })
            .catch( error => {
                console.log(error);
            });
        }
    }

    componentDidMount(){
        let {id} = this.props
        let _this = this;
    		let currency = localStorage.getItem("currency")
        if (id === "") {
            axios.get('https://rtcenter.org/services/ecommerce-workspace-plan', {params: {categoryName: "rtworkspace_plan", currency: currency}})
            .then( res => {
                _this.setState({
                    plans: res.data.msg,
                    category: "rtworkspace_plan"
                });
            })
            .catch( error => {
                console.log(error);
            });
        }
    }

    handleFilter(value) {
        let _this = this;
        let currency = localStorage.getItem("currency")
        axios.get('https://rtcenter.org/services/ecommerce-workspace-plan', {params: {categoryName: value, currency: currency}})
        .then( res => {
            _this.setState({
                plans: res.data.msg,
                category: value
            });
        })
        .catch( error => {
            console.log(error);
        });
    }


    handleChangePlan(data) {
        console.log("plan",data);
        let {category} = this.state
        this.props.onChange("plan", data.name)
        this.props.onChange("price", data.price)
        this.props.onChange("cyclePrice", data.full_cycle_price)
        this.props.onChange("planName", data.content.title)
        this.props.onChange("planCategory", data.content.name)
        this.props.onChange("priceDesc", data.content.price_desc)
        this.props.onChange("phasePrice", data.phase_price)
        this.props.onChange("category", category)
        if(category == "rtworkspace_plan"){
            this.props.onChange("projectDomain", "rtworkspace.com")
            this.props.onChange("projectType", "rtwork")
        }else{
            this.props.onChange("projectDomain", "rtsurvey.com")
            this.props.onChange("projectType", "rtsurvey")
        }
        
    }

    render(){
        const {t, id} = this.props
        let {plans, category} = this.state
        let className = "btn m-btn--pill m-btn--wide m-btn--uppercase m-btn--bolder";
        return(
            <div className="m-pricing-table">
                <div className="m-pricing-table__head">
                    <div className="m-pricing-table__head-title">
                        <h2>{t('Choose a plan for your business')}</h2>
                    </div>
                    <div className="btn-group m-btn-group m-btn-group--pill m-btn-group--air" role="group">
                        <button type="button" className={category === "rtworkspace_plan" ? className + " active" : className} onClick={this.handleFilter.bind(this, "rtworkspace_plan")}>
                            {t("rtWork Monthly Plans")}
                        </button>
                        <button type="button" className={category === "rtsurvey,annually" ? className + " active" : className} onClick={this.handleFilter.bind(this, "rtsurvey,annually")}>
                            {t("rtSurvey Annual Plans")}
                        </button>
                        <button type="button" className={category === "rtsurvey,monthly" ? className + " active" : className}  onClick={this.handleFilter.bind(this, "rtsurvey,monthly")}>
                            {t("rtSurvey Monthly Plans")}
                        </button>
                    </div>

                </div>
                <div className="m-pricing-table__items row">
                {
                    plans.map((data, idx) => (<Item key={idx} t={t} category={category} active={id === data.name} data={data} index={idx} onClick={this.handleChangePlan.bind(this, data)}/>))
                }
                </div>
            </div>
        );
    }
}

export default Plan;
