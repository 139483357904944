import React from 'react';
import background from '../../assets/bg-3.jpg';
import {Link} from 'react-router-dom';

class Success extends React.Component{
	render(){
		const {t} = this.props
		return(
			<div className="m-grid__item m-grid__item--fluid m-grid  m-error-6 m-login m-login--signin  m-login--5" style={{backgroundImage: "url("+background+")"}}>
				<div className="m-error_container m-success-container">
					<div className="swal2-icon swal2-success swal2-animate-success-icon" style={{display: "flex"}}><div className="swal2-success-circular-line-left" style={{backgroundColor: "rgb(255, 255, 255)"}}></div><span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span><div className="swal2-success-ring"></div> <div className="swal2-success-fix" style={{backgroundColor: "rgb(255, 255, 255)"}}></div><div className="swal2-success-circular-line-right" style={{backgroundColor: "rgb(255, 255, 255)"}}></div></div>
					<div className="m-error_subtitle m--font-dark m-success-subtitle">
						<h1>{t('Success')} !</h1>
					</div>
					<p className="m-success_description m--font-dark">
						{t('Your submission is received')}.
						{t('We will contact you soon')}.
					</p>
					<div className="swal2-actions" style={{display: "flex"}}>
						<Link to={{ pathname: "/project"}} className="swal2-confirm btn btn-success m-btn m-btn--custom" aria-label="">{t('OK')}</Link>
					</div>
				</div>
			</div>
			);
	}
}

export default Success;