import React from 'react';
import background from '../../assets/bg-3.jpg';
import {Link} from 'react-router-dom';

class Fail extends React.Component{
	render(){
		const {t} = this.props
		return(
			<div className="m-grid__item m-grid__item--fluid m-grid  m-error-6 m-login m-login--signin  m-login--5" style={{backgroundImage: "url("+background+")"}}>
				<div className="m-error_container m-success-container">
					<div className="swal2-icon swal2-error swal2-animate-error-icon" style={{display: "flex"}}><span className="swal2-x-mark"><span className="swal2-x-mark-line-left"></span><span className="swal2-x-mark-line-right"></span></span></div>
					<div className="m-error_subtitle m--font-dark m-success-subtitle">
						<h1>{t('Fail')} </h1>
					</div>
					<p className="m-success_description m--font-dark">
						{t('Your Payment failed')}.
						{t('Please try again')}.
					</p>
					<div className="swal2-actions" style={{display: "flex"}}>
						<Link to={{ pathname: "/project"}} className="swal2-confirm btn btn-success m-btn m-btn--custom" aria-label="">{t('Back to home')}</Link>
					</div>
				</div>
			</div>
			);
	}
}

export default Fail;