import React from 'react';
import Pagination from '../DataTable/Pagination';

class PaginationList extends React.Component{
	constructor(props){
		super(props);
		this.state = { 
			meta: this.props._meta
		}
	}

	changePage(currentPage){
		const {meta} = this.props
        meta.currentPage = currentPage
        this.props.changeMeta(meta)
	}

	changePerPage(event){
		const {meta} = this.props
        meta.currentPage = 1
        meta.perPage = event.target.value
        this.props.changeMeta(meta)
	}

	render(){
		const {meta, t} = this.props
		return (
			<div className="dataTables_wrapper pagination_list">
			{meta ? <Pagination t={t} meta={meta}
                changePage={this.changePage.bind(this)} 
                changePerPage={this.changePerPage.bind(this)}/> : null}
            </div>
			);
	}
}

export default PaginationList;