import React from "react";
import { Button, Col, FormGroup, Modal, Row } from "react-bootstrap";
import { generatePath } from "react-router";

class NewWebsiteHelp extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, show, hideTipModal } = this.props;
    return (
      <Modal
        id="domain_help_modal"
        show={show}
        onHide={hideTipModal}
        dialogClassName="modal-90w"
        className="modal-full-height"
        aria-labelledby="domain_help_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Help - Configuring your domain")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowX: "scroll" }}>
          <FormGroup className="h-100">
            <Row className=" h-100">
              <Col md={12}>
                <p>Để dễ dàng hơn trong việc xây dựng 1 website, RTA xin được chia sẻ các bước để bạn có thể khởi tạo 1 website thông qua công cụ của chúng tôi.</p>
                <p><strong>BƯỚC 1: Điền thông tin website</strong></p>
                <p><strong>Domain Name:</strong></p>
                <p>Tên miền là địa chỉ trang web, thứ mà mọi người gõ vào thanh URL của trình duyệt để truy cập website của bạn.</p>
                <p>Ví dụ: rtworkspace.com</p>
                <p>Lưu ý, domain name cần phải được trỏ về server của chúng tôi mới có tác dụng truy cập vào website.</p>
                <p><strong>Website Title:</strong></p>
                <p>Đây là tên mà bạn muốn đặt cho website của mình.</p>
                <p>Ví dụ: Real-Time Workspace</p>
                <p><strong>Shortened Description:</strong></p>
                <p>Chúng tôi cung cấp thuộc tính này để bạn có thể thêm vào website của mình slogan, phương châm hoạt động ngắn gọn của doanh nghiệp mình.</p>
                <p>Ví dụ: Known Your Truth In Time</p>
                <p><strong>Chat Widget:</strong></p>
                <p>Bạn có thể tương tác với khách hàng thông qua Chat Plugin của chúng tôi bằng cách xác nhận với lựa chọn <strong>Do you want to use Chat Widget</strong>.</p>
                <p>Ví dụ: Known Your Truth In Time</p>
                <p><strong>Username, Password:</strong></p>
                <p>Để có thể đăng nhập vào trang quản trị website của bạn, bạn cần khai báo Username và Password. RTA cam kết thông tin bạn cung cấp sẽ được mã hóa.</p>
                <p>Ví dụ: Known Your Truth In Time</p>
                <p><strong>Contact Email:</strong></p>
                <p>Đây là địa chỉ email sẽ được hiển thị trên website nhằm giúp cho khách hàng của bạn liên hệ với doanh nghiệp.</p>
                <p><strong>BƯỚC 2: Bắt đầu tiến trình</strong></p>
                <p>Sau khi đảm bảo mọi thứ đã hoàn tất, bạn chỉ cần click vào nút Deploy.</p>
                <p>Hệ thống sẽ chuyển sang trang quản lý các website của bạn. Lúc này, trạng thái của tiến trình là <code>DEPLOYING</code>. Hãy chờ cho đến khi trạng thái của website vừa khởi tạo chuyển sang <code>DEPLOYED</code>.</p>
                <p>Nếu tiến trình chuyển sang <code>FAILED</code>, hãy liên hệ với chúng tôi để được hỗ trợ.</p>
                <p><strong>BƯỚC 3: Hoàn tất và kiểm tra</strong></p>
                <p>Sau khi hệ thống đã xác nhận hoàn tất quá trình khởi tạo website. Hãy mở trình duyệt và gõ vào domain name mà bạn đã đăng ký và trải nghiệm website của mình.</p>
                <p>Nếu bạn muốn truy cập trang quản trị, hãy gõ đường dẫn sau: <code>&lt;domain_name&gt;/wp-admin</code>.</p>
              </Col>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideTipModal}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default NewWebsiteHelp;
