import React from 'react';
import { Redirect } from 'react-router-dom';

class SignOut extends React.Component {
    componentDidMount() {
        localStorage.setItem("access_token", "");
    }
    render(){
        return(<Redirect to='/sign-in'/>);
    }
}

export default SignOut;