import React from 'react';
import Selection from 'react-select';

class Select extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			selectedOption : null
		}
	}

	handleChange(selectedOption){
		this.setState({selectedOption});
		const {onChange, name} = this.props
		if(onChange && selectedOption){
			onChange(name, selectedOption.value)			
		}
	}

	render(){
		const {options} = this.props,
		{selectedOption} = this.state
		return(<Selection	placeholder =""
							 options={options}
							value={selectedOption}
        					onChange={this.handleChange.bind(this)}/>)
	}
}
export default Select;