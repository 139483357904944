import React from 'react';

class PortletTool extends React.Component
{
	render() {
		return (
            <div className={ this.props.className || 'm-portlet__head-tools' }>
				{ this.props.children }
			</div>
		);
	}
}

export default PortletTool;
