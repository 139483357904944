export const UPDATE_BALANCE = 'UPDATE_BALANCE';
export const UPDATE_USERINFO = 'UPDATE_USERINFO';
export const UPDATE_PROJECT_ID = 'UPDATE_PROJECT_ID';
export const SET_PROJECTLIST = 'SET_PROJECTLIST';
export const SET_PROJECTTRANS = 'SET_PROJECTTRANS';
export const UPDATE_TEMP_DATA = 'UPDATE_TEMP_DATA';
export const SET_USERLIST = 'SET_USERLIST';
export const SET_SUBMISSIONLIST = 'SET_SUBMISSIONLIST';
export const SET_PROJECTDETAIL = 'SET_PROJECTDETAIL';
export const SET_ADMINPROJECT = 'SET_ADMINPROJECT'
export const SET_ADMINUSER = 'SET_ADMINUSER'
export const UPDATE_FREECREDIT = 'UPDATE_FREECREDIT'
export const SET_WEBSITES_LIST = 'SET_WEBSITES_LIST'
