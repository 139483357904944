import React from 'react';
import {connect} from 'react-redux';
import WizardNav from './WizardNav';
import {Link} from 'react-router-dom';
import axios from 'axios';
import * as apis from '../../constants/Apis';
import * as utils from '../../utils';

export class WizardFormFourStep extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            status: ""
        }
    }

    componentDidMount(){
        let _this = this;
        const {projectId} = this.props;
        let config = utils.getConfig();
        axios.get(apis.BASE_URL + 'projects/1/10/[]/{"e_user_project__id":"'+projectId+'"}', config)
        .then( res => {
            if (res.data.items.length > 0 && res.data.items[0].hasOwnProperty('status')) {
                _this.setState({status: res.data.items[0].status})
            }
        })
        .catch( error => console.log('error', error));
    }

    componentWillReceiveProps(nextProps){
        const {projectList, projectId} = nextProps

        let dataFilter = projectList.data.filter(function (index) {
            return index.id === projectId
        })
        if (dataFilter.length > 0 && dataFilter[0].hasOwnProperty('status')) {
            this.setState({status: dataFilter[0].status})
        }
    }

    render(){
        const {t} = this.props
        let {status} = this.state

        let verifying = false,
        verifyFail = false,
        verifySuccess = false,
        paid = false,
        deploying = false,
        deployFail = false,
        deploySuccess = false,
        actived = false

        switch (status) {
            case "-2": // deploy fail
                verifying = true
                verifySuccess = true
                paid = true
                deploying = true
                deployFail = true
                break

            case "1" : // active
                verifying = true
                verifySuccess = true
                paid = true
                deploying = true
                deploySuccess = true
                actived = true
                break

            case "3" : // paid
                verifying = true
                verifySuccess = true
                paid = true
                break

            case "4" : // deploying
                verifying = true
                verifySuccess = true
                paid = true
                deploying = true
                break

            case "6" : // verifying
                verifying = true
                break

            case "8" : // verify fail
                verifying = true
                verifyFail = true
                break

            default :
                break
        }
		return(
            <div className="m-wizard m-wizard--2 m-wizard--success" id="m_wizard">
                <WizardNav t={t} step={4} />
                <div className="m-wizard__form">
                    <div className="m-form m-form--label-align-left- m-form--state-">
                        <div className="m-wizard__form-step m-wizard__form-step--current" id="m_wizard_form_step_4">
                            <div className="row">
                                <div className="col-xl-10 offset-xl-1">
                                    <h3 className="title">{t('Deployment Status')}</h3>
                                    <div className="deploy-status">
                                        <table>
                                            <tbody className="m-checkbox-list">
                                            <tr>
                                                <td>
                                                    <label className="m-checkbox m-checkbox--success">
                                                        <input type="checkbox" checked={verifying} disabled={true}/><span></span>
                                                    </label>
                                                </td>
                                                <td>{t('Waiting for Payment verification')}</td>
                                            </tr>
                                            {
                                                verifySuccess ?
                                                <tr>
                                                    <td>
                                                        <label className="m-checkbox m-checkbox--success">
                                                            <input type="checkbox" defaultChecked={true} disabled={true}/><span></span>
                                                        </label>
                                                    </td>
                                                    <td>{t('Having completed Payment verification')}</td>
                                                </tr> : null
                                            }
                                            {
                                                verifyFail ?
                                                <tr>
                                                    <td>
                                                        <label className="m-check m-checkbox--danger">
                                                            <input type="checkbox" defaultChecked={true} disabled={true}/><span></span>
                                                        </label>
                                                    </td>
                                                    <td>{t('Having uncompleted Payment verification')}</td>
                                                </tr> : null
                                            }
                                            <tr>
                                                <td>
                                                    <label className="m-checkbox m-checkbox--success">
                                                        <input type="checkbox" checked={paid} disabled={true}/><span></span>
                                                    </label>
                                                </td>
                                                <td>{t('Paid your project')}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label className="m-checkbox m-checkbox--success">
                                                        <input type="checkbox" checked={deploying} disabled={true}/><span></span>
                                                    </label>
                                                </td>
                                                <td>{t('Deploying software system')}</td>
                                            </tr>
                                            {
                                                deployFail ?
                                                <tr>
                                                    <td>
                                                        <label className="m-check m-checkbox--danger">
                                                            <input type="checkbox" defaultChecked={true} disabled={true}/><span></span>
                                                        </label>
                                                    </td>
                                                    <td>{t('Deploy software system is uncompleted')}</td>
                                                </tr> : null
                                            }
                                            {
                                                deploySuccess ?
                                                <tr>
                                                    <td>
                                                        <label className="m-checkbox m-checkbox--success">
                                                            <input type="checkbox" defaultChecked={true} disabled={true}/><span></span>
                                                        </label>
                                                    </td>
                                                    <td>{t('Deployed software system')}</td>
                                                </tr> : null
                                            }
                                            <tr>
                                                <td>
                                                    <label className="m-checkbox m-checkbox--success">
                                                        <input type="checkbox" checked={actived} disabled={true}/><span></span>
                                                    </label>
                                                </td>
                                                <td>{t('Actived your project')}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="note">
                                        {t('Your rtWorkspace system is now ready to use Please check your email for detailed instruction')}
                                        <br />
                                        {t('We wish you a successfull Data Collection campaign Thank you for choosing rtWorkspace')}
                                    </div>
                                    <div className="m-form__actions m--align-center">
                                        <Link to="/" className="btn btn-success m-btn">{t('Done')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			);
	}
}

export const mapStateToProps = ({ecommerce}) => {
    return {
            projectList: ecommerce.projectList
        }
}
export default connect(mapStateToProps)(WizardFormFourStep);
