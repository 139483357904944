import React from 'react';
import Select from '../Select';
import Datetime from '../DateTime';
import * as utils from '../../utils';

const SortControl = ({col, sortInfo}) => {
    if ( col.sortable && sortInfo.property === col.property ) {
        return (sortInfo.direction === "ASC") ? <i className="la la-arrow-up"></i> : <i className="la la-arrow-down"></i>;
    }
    return null;
}

const typeSearch =(col, filterText, onBlurText, filterNumber)=>{
    let str = ""
    switch (col.filterType.type) {
        case "text":
            str = <input type="text" className="form-control form-control-sm form-filter m-input" name={col.property} onKeyPress={filterText} onBlur={onBlurText} />;
            break;
        
        case "date": 
            str = <Datetime closeOnSelect={true} timeFormat={false} name={col.property} onChange={filterNumber} />
            break;

        case "number":
            str = <Select options={col.filterType.values} name={col.property} onChange={filterNumber}/>;
            break;
        default:
            // code...
            break;
    }

    return str;
}


class RTColumn extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            comboFilter: {}
        }
    }

    sortData(col){
        if(col.sortable){
            this.props.sortData(col.property);
        }
    }

    renderElement( header ) {
        try {
            return header.call();
        } catch ( e ) {
            return null;
        } 
    }

    cellDecoration(decorator){
        let className = "";
        let style = {};
        let cellClassName = "";
        let cellStyle = {};
        
        if ( typeof decorator === 'function' ) {
            try {
                let decoration = decorator.call();

                if ( typeof decoration === 'string' ) {
                    className = decoration;
                } else  {
                    className = decoration.className || "m-datatable__cell";
                    style = decoration.style || {};
                    cellClassName = decoration.cellClassName || "";
                    cellStyle = decoration.cellStyle || {};
                }
            } catch ( e ) {
                throw new Error("Error on decorator: " + e);
            }
        } else if ( typeof decorator === 'string' ) {
            className = decorator;
        }
        return {
            className : className,
            style : style,
            cellClassName : cellClassName,
            cellStyle : cellStyle
        }
    }

    createParam(comboFilter){
        let str = ""
        let params = ""
        let flag = true
        for(var key in comboFilter){
            let {value, type, prefix} = comboFilter[key]
            if (!utils.validateNotDot(value)) {
                value = value.split(".")[0];
            }
            if (type === "number") {
                if (Object.keys(comboFilter).length === 1) {
                    return `{"${prefix}${key}":"${value}"}`
                }
                str = `"${prefix}${key}=${value}"`
            } else if(type === "date"){
                str = `["like", "from_unixtime(${prefix}${key})","${value}"]`
            } else {
                str = `["like","${prefix}${key}","${value}"]`
            } 
            if(flag){
                params = str
                flag = false
            }
            else{
                params = '["and",'+str+','+params+']'                
            }
        }
        return params
    }

    filterText(filterType, ev){
        if (ev.key === 'Enter') {
           this.onBlurText(filterType, ev)
        }
    }    

    onBlurText(filterType, ev){
        this.handleFilter(ev.target.name, ev.target.value, filterType)        
    }

    handleFilter(name, value, filterType){
        const {changeMeta, updateFilter} =this.props
        let {comboFilter} = this.state
        let meta = {currentPage: 1, perPage: 10}
        let prefix = filterType.hasOwnProperty("prefix") ? filterType["prefix"] : ""
        
        if(value.length === 0){
            delete comboFilter[name]
        } else {
            comboFilter[name] = {
                value: value,
                type: filterType["type"],
                prefix: prefix
            }
        }
        let params = this.createParam(comboFilter)
        updateFilter(params)
        changeMeta(meta, params)
    }

    filterNumber(filterType, name, value){
        this.handleFilter(name, value, filterType)
    }

    render(){
        const {cols, filter, sortInfo} = this.props;
        return (
            <thead className="m-datatable__head">
                <tr className="m-datatable__row" style={{height: "53px"}}>
                {
                    cols.map((col,idx) => {
                        if(col.display === "none"){
                            return null
                        }

                        let decoration = this.cellDecoration(col.decorator);
                        if(col.sortable){
                            decoration.className += " m-datatable__cell--sort";
                        }
                        let redenderValue = "";
                        if ( typeof col.header === 'function' ) {
                           redenderValue = this.renderElement(col.header);
                        }
                        else{
                            redenderValue = col.header;
                        }
                        return (
                            <th data-field={col.property} key={idx} className={decoration.className} style={decoration.style} onClick={this.sortData.bind(this, col)}>
                                <span className={decoration.cellClassName} style={decoration.cellStyle} style={redenderValue==='Đường dẫn hệ thống' || redenderValue==='Project URL'?{minWidth:'250px',display:'block',}:null}>
                                    {redenderValue}
                                    <SortControl col={col} sortInfo={sortInfo}/>
                                </span>
                            </th>
                        )
                    })
                }
                </tr>
                {
            filter ?
                <tr className="filters m-datatable__row">
                {
                    cols.map((col,idx) => {
                        if(col.display === "none"){
                            return null
                        }
                        let filterType = col.hasOwnProperty("filterType") ? col.filterType : ""
                        let decor = this.cellDecoration(col.decorator);
                        return (
                            <td data-field={col.property} key={idx} className={decor.className} style={decor.style}>
                                {/*!col.noFilter ? <input type="text" className="form-control form-control-sm form-filter m-input" name={col.property} onKeyPress={this.filter.bind(this)} onBlur={this.onBlur.bind(this)} />: null*/}
                                {filterType && typeSearch(col, this.filterText.bind(this, filterType), this.onBlurText.bind(this, filterType), this.filterNumber.bind(this, filterType))}
                            </td>
                        )
                    })
                }
                </tr>
                : null
                }
            </thead>
        );
    }
}

export default RTColumn;