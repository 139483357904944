import React from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import { Form, FormGroup, Col, FormControl, Row, Button } from 'react-bootstrap';
import {updateUserInfo} from '../../actions/ecommerce';
import {ToastContainer} from 'react-toastr';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import '../../assets/css/toastr.css';

export class Info extends React.Component{
	constructor(props){
        super(props);
        this.state = {
            error: [],
						referralBy: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
				this.onCopy = this.onCopy.bind(this);
    }

		componentDidMount(){
			const {userInfo} = this.props;
			let _this = this
			let {referral_by} = userInfo
			axios.get('https://rtcenter.org/services/get-referral-info', {params: {referralCode: referral_by}})
			.then( res => {
				_this.setState({
					referralBy: res.data.info.username
				})
			}).catch( error => {
					console.log(error)
			});
		}

    test(error, name, value){
        const {t} = this.props
        let emptyMsg = {
            "firstName": t("First Name cannot empty"),
            "lastName": t("Last Name cannot empty"),
            "email": t("Email cannot empty"),
            "phone": t("Phone cannot empty"),
            "address": t("Address cannot empty"),
            "city": t("City cannot empty"),
            "postalCode": t("Zip Code cannot empty"),
            "country": t("Country cannot empty")
        }
        if (value.toString().trim().length === 0 && emptyMsg[name]) {
            error[name] = emptyMsg[name];
        } else if (name === 'email' && !utils.validateEmail(value)) {
            error[name] = t("Email is not valid");
        } else if (name === 'phone' && !utils.validateNumber(value)) {
            error[name] = t("Phone is not valid");
        } else {
            delete error[name];
        }
        return error;
    }

    handleChange(ev){
        let {dispatch} = this.props,
        error = this.state.error,
        newValue = {},
        name = ev.target.name,
        value = ev.target.value;
        newValue[name] = value;
        error = this.test(error, name, value);
        this.setState({error: error})
        dispatch(updateUserInfo(newValue))
    }

		onCopy(){
			const {t} = this.props
			this.refs.toastContainer.success(t("Copied"));
	  };

    submit(event){
        event.preventDefault()
        let _this = this,
        {t} = this.props,
        {error} = this.state;
        let data = this.props.userInfo;

        Object.keys(data).map(index=>{
            error = this.test(error, index, data[index])
            return true
        })

        if(Object.keys(error).length >0){
            this.refs.toastContainer.warning(t('Please enter information for the required field!'));
            this.setState(error);
            return false;
        }

        axios.put( apis.BASE_URL + 'user/' + utils.getUserId() + '/billing-info', data)
        .then( res => {
            _this.refs.toastContainer.success(t('Success'));
        }).catch( error => {
            if(error.response){
                if(error.response.data.errors.hasOwnProperty('email')){
                    _this.refs.toastContainer.error(t('Email')+' '+error.response.data.errors.email[0].match(/(?!^)".*?"/g)+' '+t('has already been taken'))
                }else{
                    _this.refs.toastContainer.error(error.response.data.error_code)
                }  
            }
            else{
                _this.refs.toastContainer.error(t('Update data not success!'));
            }
            console.log('error', error)
        });
    }

	render(){
        const {t, userInfo} = this.props;
				let { firstName, lastName, email, phone, address, city, postalCode, country, company, vatInfo, referral_by, referral_code } = userInfo;
        let {error, referralBy} = this.state;

        return(
            <div>
                <Row>
                    <Col lg={8} className="offset-xl-2">
                        <Form className="m-form__section m-form__section--first">
                           <FormGroup className="m-form__group row">
                                <Col lg={6}>
                                    <label className="form-control-label required">{t('First Name')}</label>
                                    <FormControl type="text" name="firstName" value={firstName} onChange={this.handleChange} />
                                    {error['firstName'] && <p className="error-msg">{error['firstName']}</p>}
                                </Col>
                                <Col lg={6}>
                                    <label className="form-control-label required">{t('Last Name')}</label>
                                    <FormControl type="text" name="lastName" value={lastName} onChange={this.handleChange} />
                                    {error['lastName'] && <p className="error-msg">{error['lastName']}</p>}
                                </Col>
                            </FormGroup>
                            <FormGroup className="m-form__group row">
                                <Col lg={6}>
                                    <label className="form-control-label required">{t('Email')}</label>
                                    <FormControl type="email" name="email" value={email } onChange={this.handleChange} />
                                    {error['email'] && <p className="error-msg">{error['email']}</p>}
                                </Col>
                                <Col lg={6}>
                                    <label className="form-control-label required">{t('Phone Number')}</label>
                                    <FormControl type="text" name="phone" value={phone} onChange={this.handleChange} />
                                    {error['phone'] && <p className="error-msg">{error['phone']}</p>}
                                </Col>
                            </FormGroup>
                            <FormGroup className="m-form__group row">
                                <Col lg={12} className="m-form__group-sub">
                                    <label className="form-control-label required">{t('Country')}</label>
                                    <FormControl type="text" name="country" value={country} onChange={this.handleChange} />
                                    {error['country'] && <p className="error-msg">{error['country']}</p>}
                                </Col>
                            </FormGroup>
                            <FormGroup className="m-form__group row">
                                <Col lg={6} className="m-form__group-sub">
                                    <label className="form-control-label required">{t('City')}</label>
                                    <FormControl type="text" name="city" value={city} onChange={this.handleChange} />
                                    {error['city'] && <p className="error-msg">{error['city']}</p>}
                                </Col>
                                <Col lg={6} className="m-form__group-sub">
                                    <label className="form-control-label required">{t('Zip Code')}</label>
                                    <FormControl type="text" name="postalCode" value={postalCode} onChange={this.handleChange} />
                                    {error['postalCode'] && <p className="error-msg">{error['postalCode']}</p>}
                                </Col>
                            </FormGroup>
                            <FormGroup className="m-form__group row">
                                <Col lg={12}>
                                    <label className="form-control-label required">{t('Address')}</label>
                                    <FormControl type="text" name="address" value={address} onChange={this.handleChange} />
                                    {error['address'] && <p className="error-msg">{error['address']}</p>}
                                </Col>
                            </FormGroup>
                            <FormGroup className="m-form__group row">
                                <Col lg={12}>
                                    <label className="form-control-label">{t('Company')}</label>
                                    <FormControl type="text" name="company" value={company} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="m-form__group row">
                                <Col lg={12}>
                                    <label className="form-control-label">{t('VAT Billing Info')}</label>
                                    <FormControl as="textarea" rows="3" name="vatInfo" value={vatInfo} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
														<FormGroup className="m-form__group row">
                                <Col lg={6}>
                                    <label className="form-control-label ">{t("Referral Code")}</label>
																	<FormControl  type="email" ref={(textarea) => this.textArea = textarea} name="email" value={referral_code} onChange={this.handleChange} readOnly/>
																{referral_code !== "" && <p className="error-msg">{t("Your invite URL")}:
																														<CopyToClipboard text={"https://myaccount.rtworkspace.com/#/sign-up?ref="+referral_code}>
																																<a href="javascript:void(0)" onClick={this.onCopy}> https://myaccount.rtworkspace.com/#/sign-up?ref={referral_code}</a>
																														</CopyToClipboard>
																													</p>}
                                </Col>
                                {referral_by !== "" && <Col lg={6}>
                                    <label className="form-control-label ">{t("Referraled By")}</label>
                                    <FormControl type="text" name="phone" value={referralBy} onChange={this.handleChange} readOnly/>
                                </Col> }
                            </FormGroup>
                            <Button className="btn btn-success m-btn m-btn--custom" onClick={this.submit}>{t('Update')}</Button>
                        </Form>
                    </Col>
                </Row>
                <ToastContainer
                    ref="toastContainer"
                    className="toast-top-right"
                />
            </div>
        );
	}
}

export const mapStateToProps = state => {
    let defaultProps = utils.unionObject({
        "firstName": "",
        "lastName": "",
        "email": "",
        "phone": "",
        "address": "",
        "city": "",
        "postalCode": "",
        "country": "",
        "company": "",
        "vatInfo": "",
				"referral_by": "",
				"referral_code": ""
    }, state.ecommerce.userInfo)

    return ({userInfo: defaultProps})
}

export default connect(mapStateToProps)(Info)
