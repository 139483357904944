import React from 'react';


class WizardNav extends React.Component {

    render(){
        const {t, step}= this.props
        let stepOne = "m-wizard__step", stepSecond = "m-wizard__step", stepThird = "m-wizard__step", stepFour = "m-wizard__step"

        switch (step) {
            case 1:
                stepOne = "m-wizard__step m-wizard__step--current"
                break;
            
            case 2:
                stepOne = "m-wizard__step m-wizard__step--done"
                stepSecond = "m-wizard__step m-wizard__step--current"
                break;
            
            case 3:
                stepOne = "m-wizard__step m-wizard__step--done"
                stepSecond = "m-wizard__step m-wizard__step--done"
                stepThird = "m-wizard__step m-wizard__step--current"
                break;

            default:
                // step 4
                stepOne = "m-wizard__step m-wizard__step--done"
                stepSecond = "m-wizard__step m-wizard__step--done"
                stepThird = "m-wizard__step m-wizard__step--done"
                stepFour = "m-wizard__step m-wizard__step--current"
                break;
        }

		return(
            <div className="m-wizard__head m-portlet__padding-x">
                <div className={"m-wizard__progress m-wizard__step-" + step}>
                     <div className="progress">
                        <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" ria-valuemax="100"></div>
                     </div>
                </div>
                <div className="m-wizard__nav">
                    <div className="m-wizard__steps">
                        <div className={stepOne}>
                            <span className="m-wizard__step-number">
                                <span>1</span>
                            </span>
                            <div className="m-wizard__step-info">
                                <div className="m-wizard__step-title">
                                    {t('Select Plan')}
                                </div>
                            </div>
                        </div>
                        <div className={stepSecond}>
                            <span className="m-wizard__step-number">
                                <span>2</span>
                            </span>
                            <div className="m-wizard__step-info">
                                <div className="m-wizard__step-title">
                                    {t('Payment')}
                                </div>
                            </div>
                        </div>
                        <div className={stepThird}>
                            <span className="m-wizard__step-number">
                                <span>3</span>
                            </span>
                            <div className="m-wizard__step-info">
                                <div className="m-wizard__step-title">
                                    {t('Billing Info')}
                                </div>
                            </div>
                        </div>
                        <div className={stepFour}>
                            <span className="m-wizard__step-number">
                                <span>4</span>
                            </span>
                            <div className="m-wizard__step-info">
                                <div className="m-wizard__step-title">
                                    {t('Deploy')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
	    );
	}
}

export default WizardNav;
