import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Portlet from '../../components/Portlet';
import * as utils from '../../utils';
import '../../assets/css/toastr.css';

class Detail extends React.Component {
  render(){
    const {t, plan, projectDetail, UnlimitedItem, percent_user, percent_submition} = this.props
    return(
      <Portlet>
          <Portlet.Title>
              <Portlet.Caption>
                  <h3 className="m-portlet__head-text">{t("Project Detail")}</h3>
              </Portlet.Caption>
              <i className="project-detail">{t(projectDetail.phase)}</i>
          </Portlet.Title>
          <Portlet.Body>
            <div className="row m-row--no-padding m-row--col-separator-xl ">
              <div className="col-md-12 col-lg-6 col-xl-6 project-info row">
                <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 m-pricing-table__green-item">
                  <div className="m-pricing-table__item m-pricing-table__active">
                            <div className="m-pricing-table__head">
                                <h3 className="m-pricing-table__title">{plan.hasOwnProperty("content") && plan.content.hasOwnProperty("title") && utils.handleLanguageModule(plan.content.title)}</h3>
                                <div className="m-pricing-table__type">
                                    {plan.hasOwnProperty("content") && plan.content.hasOwnProperty("name") && utils.handleLanguageModule(plan.content.name)}
                                </div>
                            </div>
                            <div className="m-pricing-table__price">
                                {plan.hasOwnProperty("price") && plan.price !== "" && utils.handleCurrency(plan.price)}
                                <p>{t('monthly')}</p>
                            </div>
                            <div className="m-pricing-table__description">
                                {plan.hasOwnProperty("content_html") && plan.content_html !== "" && ReactHtmlParser(utils.handleLanguageModule(plan.content_html))}
                            </div>
                        </div>
                    </div>
              </div>
            </div>
        </Portlet.Body>
      </Portlet>
    )
  }
}

export default Detail;
