import React from 'react';
import Layout from '../../components/Layout';
import Portlet from '../../components/Portlet';
import History from './History';
import Payment from './Payment';
import Info from './Info';

class Billing extends React.Component{

    constructor(props){
        super(props);
        let params = new URLSearchParams(props.location.search);
        let activeTab = "payment"
        if (params.has("history")) {
            activeTab = "history"
            var url = window.location.toString();
            window.location = url.replace('?history', '');
        } else if (params.has("info")) {
            activeTab = "info"
        }
        this.state = {
            activeTab: activeTab
        }
    }

    setActiveTab(tab) {
        this.setState({ activeTab: tab })
    }
    render(){
        const { t } = this.props;
        let {activeTab} = this.state
        let navLink = "nav-link m-tabs__link"
        let navActiveLink = "nav-link m-tabs__link active"
        return(
            <Layout>
                <Layout.Content>
                    <Portlet>
                        <Portlet.Body>
                            <ul className="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--success" role="tablist">
                                <li className="nav-item m-tabs__item" onClick={this.setActiveTab.bind(this, "payment")}>
                                    <span className={activeTab === "payment" ? navActiveLink : navLink}>{t('Make Payment')}</span>
                                </li>
                                <li className="nav-item m-tabs__item" onClick={this.setActiveTab.bind(this, "history")}>
                                    <span className={activeTab === "history" ? navActiveLink : navLink}>{t('Payment History')}</span>
                                </li>
                                <li className="nav-item m-tabs__item" onClick={this.setActiveTab.bind(this, "info")}>
                                    <span className={activeTab === "info" ? navActiveLink : navLink}>{t('Billing Info')}</span>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane active" id="m_tabs_payment">
                                    {activeTab === "payment" && <Payment t={t} setActiveTab={this.setActiveTab.bind(this)}/>}
                                    {activeTab === "history" && <History t={t} meta={this.props.location.state} />}
                                    {activeTab === "info" && <Info t={t} />}
                                </div>
                            </div>
                        </Portlet.Body>
                    </Portlet>
                </Layout.Content>                                  
            </Layout>
        );
    }
}

export default Billing;
