import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import Layout from '../../components/Layout';
import Portlet from '../../components/Portlet';
import DataTable from '../../components/DataTable';
import LoadingPage from '../../components/Layout/LoadingPage';
import { setAdminUsers } from '../../actions/ecommerce';
import { ToastContainer } from 'react-toastr';
import { Modal, Form, FormControl, FormGroup, Button } from 'react-bootstrap';
import '../../assets/css/toastr.css';

export class UserManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attachName: "",
            attachment: "",
            meta: [],
            paramsFilter: "[]",
            showLoading: false,
            credit: "",
            description: "",
            userId: "",
            success: false,
            error: "",
            showModal: false,
            modalStatus: 0,
            submiting: false,
        }

        this.handleChangeFile = this.handleChangeFile.bind(this)
    }

    componentDidMount() {
        let _this = this
        const { t, dispatch } = this.props
        this.setState({ showLoading: true })
        axios.get(apis.BASE_URL + 'users/user-list/1/10/[]/[]', utils.getConfig())
            .then(res => {
                dispatch(setAdminUsers({ data: res.data.items, meta: res.data._meta }))
                _this.setState({ showLoading: false });
            })
            .catch(error => {
                _this.setState({ showLoading: false });
                _this.refs.toastContainer.error(t("Cannot get user list"));
            });
    }

    changeMeta(meta, filter = false) {
        const { t, dispatch } = this.props
        let { paramsFilter } = this.state
        let _this = this

        //sort
        let sort = { property: "created_at", direction: "DESC" };
        if (meta.sortInfo) {
            sort = meta.sortInfo
        }
        this.setState({ showLoading: true })

        //define str to handle api
        let str = 'users/user-list/' + meta.currentPage + '/' + meta.perPage + '/{"' + sort.property + '":"SORT_' + sort.direction + '"}'
        if (filter || filter === "") {
            this.setState({ paramsFilter: filter })
            str = str + '/' + filter
        } else {
            str = str + '/' + paramsFilter
        }
        axios.get(apis.BASE_URL + str, utils.getConfig())
            .then(res => {
                dispatch(setAdminUsers({ data: res.data.items, meta: res.data._meta }))
                _this.setState({ showLoading: false });
            })
            .catch(error => {
                _this.setState({ showLoading: false });
                _this.refs.toastContainer.error(t("Cannot get project list"));
            });
    }

    handleChange(ev) {
        let name = ev.target.name
        let value = ev.target.value
        console.log(value);
        this.setState({
            [name]: value,
            success: false,
            error: ""
        })
    }

    async handleChangeFile(ev) {
        let _this = this;
        let file = ev.target.files[0];
        if (file) {
            await utils.readUploadedFileAsBinaryString(file)
                .then(result => {
                    let base64String = window.btoa(result);
                    _this.setState({
                        attachment: base64String,
                        attachName: file.name
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    openModal(userId, modalStatus) {
        this.setState({ showModal: true, userId: userId, modalStatus: modalStatus })
    }

    hideModal() {
        this.setState({ showModal: false, success: false, error: "", credit: "", userId: "" })
    }

    addCredit() {
        const { t } = this.props
        let { credit, userId, description, modalStatus, attachment } = this.state
        if (credit === "") {
            this.setState({ error: t('Please enter amount to add credit') })
            return false;
        }
        let _this = this
        let data = {
            "user_id": userId,
            "amount": credit,
            "description": description
        }
        if (modalStatus === 1) {
            data.transaction_file = attachment
        }

        this.setState({ submiting: true })
        if (modalStatus === 1) {
            axios.post(apis.BASE_URL + "users/add-credit", data, utils.getConfig())
                .then(res => {
                    this.setState({ success: true, credit: "", submiting: false })
                })
                .catch(error => {
                    _this.setState({ submiting: false })
                    if (error.response) {
                        _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))
                    }
                });
        } else {
            axios.post(apis.BASE_URL + "users/external-charge", data, utils.getConfig())
                .then(res => {
                    this.setState({ success: true, credit: "", submiting: false })
                })
                .catch(error => {
                    _this.setState({ submiting: false })
                    if (error.response) {
                        _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))
                    }
                });
        }
    }

    render() {
        const { t, adminUsers } = this.props
        let { credit, showLoading, showModal, success, error, submiting, modalStatus, description, attachName, attachment } = this.state;
        let status = ""
        let config = {
            api: true,
            filter: true,
            cols: [
                {
                    header: t("UserName"), property: "username", sortable: true,
                    decorator: () => ({ style: { width: "22%" } }),
                    filterType: { type: "text" },
                    renderer: value => value ? value : ""
                },
                {
                    header: t("Email"), property: "email", sortable: true,
                    decorator: () => ({ style: { width: "22%" } }),
                    filterType: { type: "text" },
                    renderer: value => value ? value : ""
                },
                {
                    property: "status", header: t("Status"), sortable: true,
                    filterType: {
                        type: "number",
                        values: [
                            { label: t("Select all") + "...", value: "" },
                            { label: t("Verify Fail"), value: "-2" },
                            { label: t("Unverify"), value: "-1" },
                            { label: t("Inactive"), value: "0" },
                            { label: t("Active"), value: "10" }
                        ]
                    },
                    decorator: () => ({ style: { width: "20%", minWidth: "60px" } }),
                    renderer: value => {
                        let text = "",
                            className = "";
                        status = value
                        switch (value) {
                            case "-2":
                                text = t("VERIFY FAIL")
                                className = "m-badge m-badge--danger m-badge--wide"
                                break;

                            case "-1":
                                text = t("UNVERIFY")
                                className = "m-badge m-badge--metal m-badge--wide"
                                break;

                            case "0":
                                text = t("INACTIVE")
                                className = "m-badge m-badge--Pending m-badge--wide"
                                break;

                            default: //10
                                text = t("ACTIVE")
                                className = "m-badge m-badge--accent m-badge--wide"
                                break;
                        }
                        return (<span className={className}>{text}</span>);
                    }
                },
                {
                    property: "created_at", header: t("Created At"), sortable: true,
                    filterType: { type: "date" },
                    decorator: () => ({ style: { width: "26%", minWidth: "75px" } }),
                    renderer: value => utils.convertTimestampToDate(value)
                },
                {
                    property: "id",
                    header: t("Action"),
                    sortable: false,
                    decorator: () => ({ style: { width: "10%" } }),
                    renderer: value => (value !== "1" && status === "10" && [
                        <button key="btn-add" className="btn btn-outline-success m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air" onClick={this.openModal.bind(this, value, 1)}><i className="la la-plus"></i></button>,
                        <button key="btn-external-charge" className="btn btn-outline-warning m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air" onClick={this.openModal.bind(this, value, 0)}><i className="la la-minus"></i></button>,
                        <Link key="btn-detail" to={"/admin/user/detail/" + value} className="btn btn-outline-accent m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air"><i className="fa fa-eye"></i></Link>,
                    ])
                }
            ]
        }

        return (
            <Layout>
                {showLoading && <LoadingPage />}
                <Layout.Content>
                    <Portlet>
                        <Portlet.Title>
                            <Portlet.Caption>
                                <h3 className="m-portlet__head-text">{t("User List")}</h3>
                            </Portlet.Caption>
                        </Portlet.Title>
                        <Portlet.Body>
                            <DataTable t={t} datasource={adminUsers.data} config={config} meta={adminUsers.meta} changeMeta={this.changeMeta.bind(this)} />
                        </Portlet.Body>
                    </Portlet>
                </Layout.Content>
                <ToastContainer
                    ref="toastContainer"
                    className="toast-top-right"
                />
                <Modal
                    id="e_modal_add_credit"
                    show={showModal}
                    onHide={this.hideModal.bind(this)}
                    dialogClassName="modal-90w"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {modalStatus === 0 ? t("External Charge") : t('Add Credit')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {success && <div className="alert alert-success" role="alert">{t('You added credit successfull')}</div>}
                        <Form>
                            <FormGroup>
                                <Form.Label>{t('Amount')}</Form.Label>
                                <Form.Control type="number" placeholder={t("Enter mount to add")} name="credit" value={credit} onChange={this.handleChange.bind(this)} />
                                <p className="error-msg">{error ? error : null}</p>
                                {modalStatus === 0 ? "" :
                                    <div className="m-form__group">
                                        <Form.Label>{t('Attach images/document')}</Form.Label>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" accept="image/*,.pdf" name="attachment" id="customFile" onChange={this.handleChangeFile} />
                                            <label className="custom-file-label" htmlFor="customFile">{attachName ? attachName : t('Choose file')}</label>
                                        </div>
                                        {attachment === "" ? null : <img alt="" className="Img_Bank_Wire_Transfer" src={"data:image/jpeg;base64," + attachment} />}
                                    </div>}
                                <Form.Label>{t('Description')}</Form.Label>
                                <FormControl type="text" placeholder={t("Enter Description")} name="description" value={description} onChange={this.handleChange.bind(this)} />
                            </FormGroup>
                            <Button variant="success" className={submiting ? "m-loader m-loader--light m-loader--right" : null} onClick={this.addCredit.bind(this)}>{t('Submit')}</Button>
                            <Button variant="secondary" className="m--margin-left-10" onClick={this.hideModal.bind(this)}>{t('Cancel')}</Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </Layout>
        );
    }
}

export const mapStateToProps = ({ ecommerce }) => {
    return { adminUsers: ecommerce.adminUsers }
}

export default connect(mapStateToProps)(UserManagement);
