import React from 'react'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'

class DateTime extends React.Component {
    constructor(props){
        super(props);
        this.state={
            valueSelected: ""
        }
    }
    componentDidMount(){
        if( this.props.defaultValue ){
            var defaultValue = this.props.defaultValue;
            if( this.props.dateFormat !== false ){
                var formatString = this.getFormats(this.props).datetime;
                defaultValue = Datetime.moment(defaultValue).format(formatString);
            }
            if( this.props.onChange ){
                this.props.onChange(defaultValue);
            }
        }
    }

    handleChange(datetime){
        var formatString = this.getFormats(this.props).datetime;
        if( this.props.onChange ){
            const {name} = this.props
            if(typeof datetime === 'string'){    
                if(datetime === ""){
                    this.setState({valueSelected:""})
                    this.props.onChange(name, "");                    
                }         
            }
            else{
                this.setState({valueSelected: datetime.format(formatString)})
                this.props.onChange(name, datetime.format(formatString));
            }
        }
    }

    getFormats( props ){
        var formats = {
                date: props.dateFormat || '',
                time: props.timeFormat || ''
            },
            locale = this.localMoment( props.date, null, props ).localeData()
        ;

        if ( formats.date === true ) {
            formats.date = locale.longDateFormat('L');
        }
        else if ( this.getUpdateOn(formats) !== 'days' ) {
            formats.time = '';
        }

        if ( formats.time === true ) {
            formats.time = locale.longDateFormat('LT');
        }

        formats.datetime = formats.date && formats.time ?
            formats.date + ' ' + formats.time :
            formats.date || formats.time
        ;

        return formats;
    }

    localMoment( date, format, props ){
        var momentFn = props.utc ? Datetime.moment.utc : Datetime.moment;
        var m = momentFn( date, format, props.strictParsing );
        if ( props.locale )
            m.locale( props.locale );
        return m;
    }

    getUpdateOn( formats ){
        if ( formats.date.match(/[lLD]/) ) {
            return 'days';
        }
        else if ( formats.date.indexOf('M') !== -1 ) {
            return 'months';
        }
        else if ( formats.date.indexOf('Y') !== -1 ) {
            return 'years';
        }

        return 'days';
    }

    cancel(){
        this.setState({valueSelected:''})
        this.handleChange("")
    }
    render(){
        
        // enable day before current
        /*var date = new Date();
        var yesterday = Datetime.moment(date).subtract(1, 'day');
        var valid = (current) => {
            return current.isAfter(yesterday);
        };*/
        const { onChange,...atts } = this.props,
        {valueSelected} = this.state
        return (
                <div className="input-group">
                    <Datetime {...atts} utc= {false} inputProps={{value: valueSelected}} onChange={(val)=>this.handleChange(val)}/>
                    <div className="rdt_append input-group-append">
                        <span className="input-group-text" onClick={this.cancel.bind(this)}><i className="la la-times"></i></span>
                    </div>
                </div>
            );
    }
}

DateTime.defaultProps = {
    timeFormat: "HH:mm",
    dateFormat: "YYYY-MM-DD"
}

export default DateTime;