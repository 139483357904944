import React from 'react';
import '../../assets/css/custom.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ToastContainer} from 'react-toastr';

class AffiliateLink extends React.Component{

  onCopy = () =>{
    const {t} = this.props
    this.refs.toastContainer.success(t("Copied"));
  }

	render(){
    const {t, AffiliateLink, moduleName}= this.props
		return (
      <div>
          <span className="ref-affiliate-link">{t("Affiliate link with all ")}{moduleName}{t(" modules")}</span>
          <div>
            <span>{AffiliateLink}</span>
            <CopyToClipboard text={AffiliateLink}>
              <span className="ref-fl-right ref-affiliate-copy-link" onClick={this.onCopy}>{t("Copy")}</span>
            </CopyToClipboard>
          </div>
          <ToastContainer ref="toastContainer" className="toast-top-right"/>
      </div>
    )
  }
}

export default AffiliateLink;
