import React from 'react';

class LoadingPage extends React.Component
{
	render() {
		return (
			<div className="loading-page">
                <div className="loader"></div>
            </div>
		);
	}
}

export default LoadingPage;