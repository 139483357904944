import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import {Link} from 'react-router-dom';
import Translate from '../../components/Translate';
import LeftBanner from '../../components/Layout/LeftBanner';
import {ToastContainer} from 'react-toastr';
import '../../assets/css/toastr.css';
import background from '../../assets/bg-3.jpg';

class SignIn extends React.Component{
	constructor(props){
		super(props);
		let params = new URLSearchParams(props.location.search);
        let modules = []
        if (params.get("modules")) {
            modules = params.get("modules").split(",")
            localStorage.setItem('listModuleWP', JSON.stringify(modules));
        }

        if (params.get("website")) {
            localStorage.setItem('prebuiltWebsite', JSON.stringify([params.get("category"),params.get("website")]));
        }
		this.state = {
			username: "",
			password: "",
			error: {},
			submitLoading: false
		}
	}

	onChange(ev){
		let newValue = {},
        name = ev.target.name,
        value = ev.target.value;
        newValue[name] = value;
        this.setState(newValue);
	}
	onBlurUserName(){
		let error = this.state.error;
		error.userEmpty = this.state.username === "";
		this.setState({error})
	}

	onBlurPassword(){
		let error = this.state.error;
		error.passEmpty = this.state.password === "";
		this.setState({error})
	}

	submit(event){

		event.preventDefault();
		const {t}= this.props;
		let error = this.state.error;

        error.userEmpty = this.state.username.toString().trim().length === 0 ? true : false;

        error.passEmpty = this.state.password.toString().trim().length === 0 ? true : false;

      	let validate = false;
        Object.keys(error).map((e, i) => {
            if(error[e]){
            	validate = true;
            }
            return validate;
        });
        if(validate){
        	this.setState({
        		error
        	})
        	return;
        }

      	this.setState({submitLoading: true})

        let _this = this;
        axios.post( apis.BASE_URL + 'users/authorize', {
            username: _this.state.username.toString().trim(),
            password: _this.state.password.toString().trim()
        })
        .then( res => {
            if(res.data.items.authorization_code !== undefined) {
                axios.post( apis.BASE_URL + 'users/accesstoken', {
                    authorization_code: res.data.items.authorization_code
                })
                .then( res => {
                    localStorage.setItem("access_token", res.data.items.access_token);
                    localStorage.setItem("id", res.data.items.user_id);
                    localStorage.setItem("userName", _this.state.username);
                    localStorage.setItem("rtisad", res.data.items.is_admin === 1);
                    _this.setState({submitError: false, submitLoading: false});
                }).catch(error =>{
                	if(error.response){
						_this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))
					} else{
						_this.refs.toastContainer.error(t("Sorry! Error server"));
					}
                	_this.setState({submitError: true, submitLoading: false});
                })
            } else{
                _this.setState({submitError: true, submitLoading: false});
            }
        }).catch( error => {
            _this.setState({submitError: true, submitLoading:false});
            _this.refs.toastContainer.error(t(utils.catchMessageError(error.response.data.error_code)))
        });
    }

	render(){
		const {t, i18n, location} = this.props,
		{submitLoading} = this.state
        let access_token = localStorage.getItem("access_token");
		if( access_token ){
			if(location.state && location.state.pathname) {
				return (<Redirect to={{pathname: location.state.pathname, state: location.state}}/>);
			}
            return (<Redirect to='/'/>);
        }
		return(
			<div className="m-login m-login--signin  m-login--5" id="m_login" style={{backgroundImage: "url("+background+")"}}>
				<LeftBanner t={t} />
				<div className="m-login__wrapper-2 m-portlet-full-height">
					<div className="m-login__contanier">
						<div className="m-login__signin animated flipInX">
							<div className="m-login__head">
							<h3 className="m-login__title">{t('Login To Your Account')}</h3>
							</div>
							<form className="m-login__form m-form" action="">
								<div className="form-group m-form__group">
									<input className="form-control m-input" type="text" placeholder={t('Username')} name="username" onBlur={this.onBlurUserName.bind(this)} onChange = {this.onChange.bind(this)}/>
									{this.state.error.userEmpty ? <div className="form-control-feedback">{t('This field is required')}.</div> : null}
								</div>
								<div className="form-group m-form__group">
									<input className="form-control m-input m-login__form-input--last" type="Password" placeholder={t('Password')} name="password" onBlur={this.onBlurPassword.bind(this)} onChange = {this.onChange.bind(this)} />
									{this.state.error.passEmpty? <div className="form-control-feedback">{t('This field is required')}.</div> : null}

								</div>
								<div className="row m-login__form-sub">
									<div className="col m--align-left">
										<label className="m-checkbox m-checkbox--focus">
											<input type="checkbox" name="remember" /> {t('Remember me')}
											<span></span>
										</label>
									</div>
									<div className="col m--align-right">
										<Link to="/forget-password" className="m-link">{t('Forget Password')} ?</Link>
									</div>
								</div>
								<div className="m-login__form-action">
			        				<button disabled={submitLoading} className={submitLoading?"btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-loader m-loader--light m-loader--right":"btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air"} onClick = {this.submit.bind(this)}>{t('Sign In')}</button>
								</div>
							</form>
						</div>
						<div className="m-login__account">
							<span className="m-login__account-msg">
								{t("Don't have an account yet")} ?
							</span>
							<Link to="/sign-up" className="m-link m-link--dark m-login__account-link">{t('Sign Up')}</Link>
						</div>
					</div>
					<ToastContainer
		                ref="toastContainer"
		                className="toast-top-right"
		            />
		            <Translate i18n={i18n}/>
				</div>
			</div>
			);
	}
}

export default SignIn;
