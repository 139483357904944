import React from "react";
import { Button, Col, FormGroup, Modal, Row } from "react-bootstrap";
import { generatePath } from "react-router";
import { domainHosts } from "../../utils";

class DomainHelpModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      domainHost: "godaddy",
    };
  }

  handleChange(e) {
    this.setState({
      domainHost: e.target.value,
    });
  }

  render() {
    const { t, show, hideModal } = this.props;
    const { domainHost } = this.state;
    return (
      <Modal
        id="domain_help_modal"
        show={show}
        onHide={hideModal}
        dialogClassName="modal-90w"
        className="modal-full-height"
        aria-labelledby="domain_help_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Help - Configuring your domain")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup className="h-100">
            <Row className="mb-5">
              <Col md={4}>
                <label className="form-label">Choose your domain host:</label>
              </Col>
              <Col md={8}>
                <select
                  className="form-control"
                  onChange={this.handleChange}
                  defaultChecked={Object.keys(domainHosts)[0]}
                >
                  {Object.entries(domainHosts).map((host) => {
                    return (
                      <option key={`domainhost${host[0]}`} value={host[0]}>
                        {host[1]}
                      </option>
                    );
                  })}
                </select>
              </Col>
            </Row>
            <Row className=" h-100">
              <Col md={12}>
                <iframe
                  className="w-100 h-100"
                  frameBorder="0"
                  src={generatePath(`/documents/${domainHost}.html`)}
                ></iframe>
              </Col>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DomainHelpModal;
