import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import * as apis from "../../constants/Apis";
import * as utils from "../../utils";
import { ToastContainer } from "react-toastr";
import DataTable from "../../components/DataTable";
import Layout from "../../components/Layout";
import LoadingPage from "../../components/Layout/LoadingPage";
import Portlet from "../../components/Portlet";
import { setWebsitesList } from "../../actions/ecommerce";

class WebsiteList extends React.Component {
  constructor(props) {
    super(props);
    const initWebsites = {
      data: [],
      _meta: {
        totalCount: 0,
        pageCount: 0,
        currentPage: 0,
        perPage: 10,
      },
    };

    this.state = {
      showLoading: true,
      websites: initWebsites,
    };
  }

  getDataTableConfig() {
    const { t } = this.props;
    return {
      sortDefault: { property: "id", direction: "DESC" },
      api: true,
      // filter: true,
      cols: [
        {
          property: "id",
          header: t("Id"),
          sortable: false,
          display: "none",
          noFilter: true,
          decorator: () => ({ style: { width: "10%" } }),
        },
        {
          property: "domain",
          header: t("Domain Name"),
          sortable: true,
          decorator: () => ({ style: { width: "20%" } }),
          renderer: (value) =>
            value ? (
              <a href={`http://${value}/`} target="_blank" rel="noopener noreferrer">
                {value}
              </a>
            ) : (
              ""
            ),
        },
        {
          property: "site_title",
          header: t("Site Title"),
          sortable: true,
          noFilter: true,
          decorator: () => ({ style: { width: "20%" } }),
        },
        {
          property: "status",
          header: t("Status"),
          sortable: true,
          noFilter: true,
          decorator: () => ({ style: { width: "10%" } }),
          renderer: (value) => {
            let className = utils.websiteStatusClassName[value];
            let text = utils.websiteStatusText[value];
            return <span className={className}>{t(text)}</span>;
          },
        },
        {
          property: "created_at",
          header: t("Created At"),
          sortable: true,
          noFilter: true,
          decorator: () => ({ style: { width: "20%" } }),
          renderer: (value) => utils.convertTimestampToDate(value),
        },
      ],
    };
  }

  componentDidMount() {
    const {dispatch} = this.props
    const config = utils.getConfig();
    const userId = localStorage.getItem("id");
    const data = { ...this.state };
    axios
      .get(apis.BASE_URL + "website/user/" + userId, config)
      .then((res) => {
        if (res.status === 200) {
          const responseData = res.data;
          // data.websites.data = [...responseData.data];
          // data.websites._meta = { ...responseData._meta };
          dispatch(setWebsitesList(responseData))
        }
        // data.showLoading = false;
        // this.setState(data);
      })
      .catch((error) => console.log(error));
  }

  render() {
    const { showLoading } = this.state;
    const { t, websites } = this.props;
    
    return (
      <Layout>
        {/* {showLoading && <LoadingPage />} */}
        <Layout.Content>
          <Portlet>
            <Portlet.Title>
              <Portlet.Caption>
                <h3 className="m-portlet__head-text">{t("Website List")}</h3>
              </Portlet.Caption>
              <div className="m-portlet__head-tools">
                <ul className="m-portlet__nav">
                  <li className="m-portlet__nav-item">
                    <Link
                      to="/templates"
                      className=" btn btn-outline-accent m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air"
                    >
                      <i className="fa fa-plus"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </Portlet.Title>
            <Portlet.Body>
              <DataTable
                t={t}
                className="margin-top--60"
                datasource={websites.data}
                config={this.getDataTableConfig()}
                meta={websites._meta}
              />
            </Portlet.Body>
          </Portlet>
        </Layout.Content>
        <ToastContainer ref="toastContainer" className="toast-top-right" />
      </Layout>
    );
  }
}

export const mapStateToProps = ({ ecommerce }) => {
  return {
    projectList: ecommerce.projectList,
    projectTrans: ecommerce.projectTrans,
    websites: ecommerce.websitesList,
  };
};

export default connect(mapStateToProps)(WebsiteList);
