import React from 'react';
import axios from 'axios';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import LoadingPage from '../../components/Layout/LoadingPage';
import DataTable from '../../components/DataTable';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastr';
import '../../assets/css/toastr.css';
import Invoice from './invoicePDF';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'

class History extends React.Component {
    constructor(props) {
        super(props);
        let perPage=10;
        let currentPage=1;
        if(props.meta!==undefined){
            perPage=props.meta.perPage;
            currentPage=props.meta.currentPage;
        }
        this.state = {
            showLoading: false,
            data: [],
            meta: {
                perPage: perPage,
                currentPage: currentPage
            },
            test:'',
            showInvoi:false,
        }
    }

    changeMeta(meta) {
        this.setState({ meta })
    }

    componentDidMount() {
        const { t } = this.props
        let _this = this;
        this.setState({ showLoading: true })
        let config = utils.getConfig();
        let history=[]
        axios.get(apis.BASE_URL + 'users/' + utils.getUserId() + '/billing-history', config)
            .then(res => {
                res.data.items.map((item)=>{
                    if(item.amount!==0 || item.creditAdj!==0){
                        history.push(item)
                        _this.setState({
                            data: history,
                            showLoading: false
                        }); 
                    }
                }) 
            })
            .catch(error => {
                this.setState({ showLoading: false });
                _this.refs.toastContainer.error(t("Can not get project list"));
            });
    }
    showInvoice(value){
        this.setState({
            test:value,
            showInvoi:true
        })           
    }
    checkInvoice(value){
        if(value==='success'){
            this.setState({
                showInvoi:value
            }) 
        }else if(value==='error'){
            this.setState({
                showInvoi:'Error'
            }) 
        }
                  
    }
    render() {
        const { t } = this.props
       
        let { meta, data, showLoading } = this.state,
            invoiceNumber = "",
            config = {
                api: false,
                sortDefault: {
                    property: "invoiceDate",
                    direction: "DESC"
                },
                cols: [
                    {
                        property: "invoiceNumber",
                        sortable: false,
                        display: "none",
                        renderer: value => {
                            invoiceNumber = value
                        }
                    },
                    {
                        property: "amount", header: t("Decription"),
                        sortable: false,
                        decorator: () => ({ style: { width: "20%" } }),
                        renderer: value => {
                            if (value !== 0) {
                                return t("Invoice") + " #" + invoiceNumber
                            }
                            else {
                                return t("Add Credit")
                            }
                        }
                    },
                    {
                        property: "invoiceDate", header: t("Invoice Date"),
                        sortable: false,
                        decorator: () => ({ style: { width: "20%" } }),
                        renderer: value => value ? value : t("(not set)")
                    },
                    {
                        property: "amount", header: t("Amount"),
                        sortable: false,
                        decorator: () => ({ style: { width: "20%" } }),
                        renderer: value => <span className="m--font-bold">{utils.handleCurrency(value)}</span>
                    },
                    {
                        property: "creditAdj", header: t("Credit Adjustment"),
                        sortable: false,
                        decorator: () => ({ style: { width: "20%" } }),
                        renderer: value => <span className="m--font-bold"> {utils.handleCurrency(value)}</span>
                    },
                    {
                        property: "balance", header: t("Balance"),
                        sortable: false,
                        decorator: () => ({ style: { width: "20%" } }),
                        renderer: value => utils.handleCurrency(value)
                    },
                    {
                        property: "invoiceId", header: t(""),
                        sortable: false,
                        decorator: () => ({ style: { width: "20%" } }),
                        renderer: value => {
                            return (<Link target="_blank" to={{
                                pathname: "/detail-payment",
                                search: "?Iv=" + value,
                                state: { projectId: value,meta:this.state.meta}
                            }} className="btn btn-outline-info m-btn m-btn--custom m-btn--icon m-btn--air">
                                <span>
                                    <i className="fa fa-file-excel-o"></i>
                                    <span style= {{ width : "50px",display:'block',}}>{t('Detail')}</span>
                                </span>
                            </Link>);
                        }
                    },
                    {
                        property: "invoiceId", header: t("Download Invoice"),
                        sortable: false,
                        decorator: () => ({ style: { width: "20%" } }),
                        renderer: value => {
                        return (
                            <div style={{ width: "100px" }}>
                                { this.state.test!==value ?    <span className="btn btn-outline-info"  onClick={() => this.showInvoice(value)}><i className="flaticon-download"></i></span>:null}
                                {this.state.showInvoi===true && this.state.test===value && this.state.showInvoi!=='Error' ? <span >{t('Converting...')}</span> :null}
                                {this.state.showInvoi==='Error' && this.state.test===value ? <span >{t('Sorry! Error Server')}</span> :null}
                                { this.state.test===value ?  <PDFDownloadLink document={<Invoice invoice={value} t={t} checkInvoice={this.checkInvoice.bind(this)}/>} fileName={value+".pdf"}>
                                {({ blob, url, loading, error }) => ( this.state.showInvoi!=true && this.state.showInvoi!=='Error' ?  <span >{t('Download now!')}</span>:null)}
                                </PDFDownloadLink>: null}
                            </div>
                        );
                        }
                    },
                ]
            }

        return (
            <div>
                {showLoading && <LoadingPage />}
                <DataTable datasource={data}
                    config={config}
                    meta={meta}
                    changeMeta={this.changeMeta.bind(this)}
                    t={t} />
                <ToastContainer ref="toastContainer" className="toast-top-right" />
            </div>
        );
    }
}

export default History;
