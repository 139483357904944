import React from 'react';
import {connect} from 'react-redux';
import Layout from '../../components/Layout';
import Portlet from '../../components/Portlet';
import ReferralTab from "./ReferralTab";
import MyFriendsTab from "./MyFriendsTab";
import CommissionsTab from "./CommissionsTab";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ToastContainer} from 'react-toastr';

class Referrals extends React.Component{
  constructor(props){
      super(props);
      this.state = {
          activeTab: -1
      }
  }

  setActiveTab=(activeTab)=>{
    window.scrollTo(0, 0);
		this.setState({
			activeTab: activeTab,
		})
  }

  onCopy = () =>{
    const {t} = this.props
    this.refs.toastContainer.success(t("Copied"));
  }

	render(){
      const { t, userInfo } = this.props;
      let {activeTab} = this.state
      let navLink = "nav-link m-tabs__link"
      let navActiveLink = "nav-link m-tabs__link active"
		return (
      <Layout>
          <Layout.Content>
              <Portlet>
                  <Portlet.Body>
                      <ul className="nav nav-tabs m-tabs-line m-tabs-line--2x m-tabs-line--success " role="tablist">
                          <li className="nav-item m-tabs__item" onClick={()=>this.setActiveTab(-1)}>
                              <span className={activeTab === -1 ? navActiveLink : navLink}>{t('Referral Code')}</span>
                          </li>
                          <li className="nav-item m-tabs__item" onClick={()=>this.setActiveTab(0)}>
                              <span className={activeTab === 0 ? navActiveLink : navLink}>{t('My Friends')}</span>
                          </li>
                          <li className="nav-item m-tabs__item" onClick={()=>this.setActiveTab(1)}>
                              <span className={activeTab === 1 ? navActiveLink : navLink}>{t('Commissions')}</span>
                          </li>
                          <div className="ref-fl-right col-xl-2"></div>
                          {activeTab === -1 && <div className="ref-fl-right ref-text-header col-xl-6">
                            <p className="nav-link m-tabs__link ref-text-indent ref-text-header">
                              <CopyToClipboard  text={"https://myaccount.rtworkspace.com/#/sign-up?ref="+userInfo.referral_code}>
                                <p className="ref-fl-left ref-affiliate-copy-link" onClick={this.onCopy}>{t("Copy ")}</p>
                              </CopyToClipboard>
                              <p className="ref-fl-left ref-share-url">
                                {t("your referral link or send it to your friends via ")}
                                <a target="_blank" href="https://www.facebook.com">Facebook</a>,&nbsp;
                                <a target="_blank" href="https://chat.zalo.me">Zalo</a>, ...
                              </p>
                            </p>
                          </div>}
                      </ul>
                      <div className="tab-content ref-clear">
                          <div className="tab-pane active" id="m_tabs_payment">
                            {activeTab === -1 && <ReferralTab t={t} userInfo={userInfo}/>}
                            {activeTab === 0 && <MyFriendsTab t={t}/>}
                            {activeTab === 1 && <CommissionsTab t={t}/>}
                          </div>
                      </div>
                  </Portlet.Body>
              </Portlet>
          </Layout.Content>
          <ToastContainer ref="toastContainer" className="toast-top-right"/>
      </Layout>
			);
	}
}

export const mapStateToProps = (state) => ({
    userInfo: state.ecommerce.userInfo
})

export default connect(mapStateToProps, null)(Referrals);
