import React from 'react';

class EnterAmount extends React.Component{
	handleChange(event){
		const {updateAmount, amount} = this.props
		let value = parseInt(event.target.value)       
        if(value.toString().length > 9){
            value = amount;
        }
        updateAmount(isNaN(value) ? "" : value)
	}

	render(){
		const {errorEmpty, amount, t} = this.props 
		return(<div>
                    <div className="input-group">
                        <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-dollar-sign"></i></span></div>
                        <input name="otherAmount" type="text" className="form-control m-input" placeholder={t("Enter Amount")} value={amount} onChange={this.handleChange.bind(this)}/>
                    </div>
                    {errorEmpty && <p className="error-msg">{t('This field is required')}.</p>}
                </div> );
	}
}

export default EnterAmount;