import React from 'react'; 
import {Form} from 'react-bootstrap';
import axios from 'axios';
import * as utils from '../../utils';
import * as apis from '../../constants/Apis';
import LoadingPage from '../../components/Layout/LoadingPage';  
import EnterAmount from '../../components/EnterAmount';  
import {ToastContainer} from 'react-toastr';
import '../../assets/css/toastr.css';

class Payment extends React.Component{
	constructor(props){
        super(props);
        this.state = {
            paymentDefault:"10",
            otherMount: "",
            transactionId: "",
            attachName: "",
            attachment: "",
            transIdEmpty: false,
            transAmount: "",
            transAmountEmpty: false,
            errorOtherMount: false,
            showLoading: false,
            active_tab_left: 1,
            choseMethod_tab: false
        }
        this.handleInputChange     = this.handleInputChange.bind(this)
        this.handleChangeFile      = this.handleChangeFile.bind(this)
        this.handleBankTransfer    = this.handleBankTransfer.bind(this)
        this.changeChoseMethodTab  = this.changeChoseMethodTab.bind(this)
    }

    handle(event){
        this.setState({paymentDefault :event.target.value})
    }

    updateAmount(amount){
        this.setState({otherMount: amount, errorOtherMount: false})
    }

    updateTransferAmount(amount){
        this.setState({transAmount: amount, transAmountEmpty: false})
    }

    submit(event){
        event.stopPropagation();
        let amount = "";
        let _this = this;
        const {t} = this.props
        if(this.state.paymentDefault === "other"){
            if(this.state.otherMount === ""){
                this.setState({errorOtherMount: true})
                return false;
            }
            amount = this.state.otherMount;
        }
        else{
            amount = this.state.paymentDefault;
        }
        let confirm = window.confirm(t("Do you want to make payment with amount") + " $" + amount)
        if (!confirm) {
            return false
        }
        let data = {
            "payment_method": "paypal",
            "amount": amount,
            "banking_transaction_number" : "",
            "banking_transaction_file" : ""
        }
        this.setState({showLoading: true})
        let config = utils.getConfig();
        axios.post(apis.BASE_URL + 'payment/make-payment', data, config)
        .then( res => {
               window.location.replace(res.data.items)
        })
        .catch( error => {
            _this.setState({showLoading: false})
            if(error.response){
                _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))                
            }
            else{  
                _this.refs.toastContainer.error(t("Can not payment"))
            }
        });
    }

    handleInputChange(ev) {
        let data = {},
        name = ev.target.name,
        value = ev.target.value
        data["transIdEmpty"] = (name === "transactionId" && value.length === 0)
        data[name] = value
        this.setState(data)
    }

    async handleChangeFile(ev){
        let _this = this;
        let file = ev.target.files[0];
        if(file){
            await utils.readUploadedFileAsBinaryString(file)
            .then( result => {
                let base64String = window.btoa(result);
                _this.setState({attachment: base64String, attachName: file.name})
            })
            .catch( error => {
                console.log(error)
            })
        }        
    }

    handleBankTransfer(){
        let _this = this
        let {setActiveTab, t} = this.props
        let {transAmount} = this.state
        if(this.state.transactionId.length === 0) {
            this.setState({transIdEmpty: true})
            return false
        }
        if(transAmount.length === 0) {
            this.setState({transAmountEmpty: true})
            return false
        }
        let data = {
            "payment_method": "bank_transfer",
            "amount": transAmount,
            "banking_transaction_number" : this.state.transactionId,
            "banking_transaction_file" : this.state.attachment
        }
        this.setState({showLoading: true})
        let config = utils.getConfig();
        axios.post(apis.BASE_URL + 'payment/make-payment', data, config)
        .then( res => {
                _this.setState({showLoading: false})
               setActiveTab("history")
        })
        .catch( error => {
            _this.setState({showLoading: false})
            if(error.response){
                _this.refs.toastContainer.error(utils.catchMessageError(error.response.data.error_code))                
            }
            else{                
                _this.refs.toastContainer.error(t('Can not handle bank transfer'))
            }
        });
    }

    changeActiveTabRight(value){
        this.setState({
            active_tab_left: value,
            choseMethod_tab: false
        })
    }

    changeChoseMethodTab(){
        this.setState({choseMethod_tab: !this.state.choseMethod_tab})
    }

    changeOverlayChoseMethod_tab(){
        this.setState({choseMethod_tab: false})
    }

	render(){
        const {t} = this.props;
        let {transAmount, transAmountEmpty, transactionId, transIdEmpty, attachName, errorOtherMount, otherMount, showLoading, attachment, active_tab_left, choseMethod_tab} = this.state;
        let choseMethod = (active_tab_left === 2) ? t('Bank Wire Transfer') : t('Paypal');
        return(
            <div className="row billing">
                <div className="col-md-2 col-sm-2">
                    <div className={choseMethod_tab?"m-dropdown m-dropdown--inline m-dropdown--arrow choseMethod m-dropdown--open":"m-dropdown m-dropdown--inline m-dropdown--arrow choseMethod"}>
                        <span className="m-dropdown__toggle btn btn-success dropdown-toggle" onClick={this.changeChoseMethodTab} onBlur={this.changeChoseMethodTab}>
                            {choseMethod}
                        </span>
                        <div className="m-dropdown__wrapper" style={{zIndex: "101"}}>
                            <span className="m-dropdown__arrow m-dropdown__arrow--left"></span>
                            <div className="m-dropdown__inner">
                                <div className="m-dropdown__body">
                                    <div className="m-dropdown__content">
                                        <ul className="m-nav">
                                            <li className="m-nav__item">
                                                <span className="m-nav__link" onClick={this.changeActiveTabRight.bind(this, 1)}>
                                                    <i className="m-nav__link-icon fab fa-cc-paypal"></i>
                                                    <span className="m-nav__link-text">{t('Paypal')}</span>
                                                </span>
                                            </li>
                                            <li className="m-nav__item">
                                                <span className="m-nav__link" onClick={this.changeActiveTabRight.bind(this, 2)}>
                                                    <i className="m-nav__link-icon flaticon-piggy-bank"></i>
                                                    <span className="m-nav__link-text">{t('Bank Wire Transfer')}</span>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                
                    <div className="nav flex-column choseMethod" role="tablist" aria-orientation="vertical">
                        <span className={active_tab_left === 1 ?"nav-link active":"nav-link"} onClick={this.changeActiveTabRight.bind(this, 1)}><span>{t('Paypal')}</span></span>
                        <span className={active_tab_left === 2 ?"nav-link active":"nav-link"} onClick={this.changeActiveTabRight.bind(this, 2)}><span>{t('Bank Wire Transfer')}</span></span>
                    </div>
                </div>
                <div className="col-md-8 col-sm-8 billing_payment">
                    <div className="tab-content">
                        <div className={active_tab_left === 1 ?"tab-pane fade show active":"tab-pane fade"} id="v-pills-paypal" role="tabpanel" aria-labelledby="v-pills-home-tab">
                            <h3 className="left">{t('Make a PayPal Payment')}</h3>    <img className="right" src="https://www.paypalobjects.com/webstatic/mktg/logo/PP_AcceptanceMarkTray-NoDiscover_243x40.png" alt={t('Pay with Paypal')} />
                            <Form className="flexlayoutform flexlayout100p" action="" >
                                <input name="amount" type="radio" id="paypal10" value="10" className="radiolabelbutton" onChange={this.handle.bind(this)} />
                                <label htmlFor="paypal10" className={this.state.paymentDefault === "10"?"flexwidth1sixth active":"flexwidth1sixth"} >
                                    $10
                                </label>
                                <input name="amount" type="radio" id="paypal25" value="25" className="radiolabelbutton " onChange={this.handle.bind(this)}/>
                                <label htmlFor="paypal25" className={this.state.paymentDefault === "25"?"flexwidth1sixth active":"flexwidth1sixth"} >
                                    $25
                                </label>
                                <input name="amount" type="radio" id="paypal50" value="50" className="radiolabelbutton" onChange={this.handle.bind(this)}/>
                                <label htmlFor="paypal50" className={this.state.paymentDefault === "50"?"flexwidth1sixth active":"flexwidth1sixth"} >
                                    $50
                                </label>
                                <input name="amount" type="radio" id="paypal100" value="100" className="radiolabelbutton" onChange={this.handle.bind(this)}/>
                                <label htmlFor="paypal100" className={this.state.paymentDefault === "100"?"flexwidth1sixth active":"flexwidth1sixth"}>
                                    $100
                                </label>
                                <input name="amount" type="radio" id="paypal250" value="250" className="radiolabelbutton" onChange={this.handle.bind(this)}/>
                                <label htmlFor="paypal250" className={this.state.paymentDefault === "250"?"flexwidth1sixth active":"flexwidth1sixth"}>
                                    $250
                                </label>

                                <input name="amount" type="radio" id="other" value="other" className="radiolabelbutton" onChange={this.handle.bind(this)}/>
                                <label htmlFor="other" className={this.state.paymentDefault === "other"?"flexwidth1sixth active":"flexwidth1sixth"}>
                                    <span className="hide_on_mobile">{t('Other')}</span>
                                    <i className="fa fa-plus-circle"></i> 
                                </label>
                                {this.state.paymentDefault === "other" ? <div className="form-group form-select-input m-t-20 w100p other-amount slowFadeIn"> <EnterAmount t={t} amount={otherMount} errorEmpty={errorOtherMount} updateAmount = {this.updateAmount.bind(this)}/></div>: null}
                                <button type="button" className="btn m-btn--air btn m-btn--square  btn-primary btn-block" onClick={this.submit.bind(this)}>{t('Payment')}</button>
                            </Form>
                        </div>
                        <div className={active_tab_left === 2 ?"tab-pane fade show active":"tab-pane fade"} id="v-pills-bank-transfer" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                            <h3>{t('Bank Wire Transfer')}</h3>
                            <div className="m-form bank-tranfer-info">
                                <div className="m-form__group">
                                    <div className="account-info">
                                        <div className="account-info-desc">{t('If you are located in Vietnam please TT to one of our bank accounts below')}</div>
                                            <div className="account-list">
                                            <ol>
                                                <li>
                                                    <span>Vietcombank (VCB)</span>
                                                    <ul>
                                                        <li>{t('Account No')} : 0331000419590 (VND)</li>
                                                        <li>{t('Beneficiary')}: {t('Real-Time Analytics Company Limited')}</li>
                                                        <li>{t('Branch')} : {t('Sai Gon Branch')}</li>
                                                    </ul>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                                <div className="m-form__group">
                                    <label className="form-control-label">{t('Amount')}<span className="required">*</span></label>
                                    <EnterAmount errorEmpty ={transAmountEmpty} t={t} amount={transAmount} updateAmount={this.updateTransferAmount.bind(this)} />
                                </div>

                                <div className="m-form__group">
                                    <label className="form-control-label">{t('Transfer account code')}<span className="required">*</span></label>
                                    <input type="text" className="form-control" name="transactionId" value={transactionId} onChange={this.handleInputChange}/>
                                    {transIdEmpty && <p className="error-msg">{t('Please enter your transfer transaction code')}.</p>}
                                </div>

                                <div className="m-form__group">
                                    <label className="form-control-label">{t('Attached photos (optional, help RTA check faster)')}</label>
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" accept="image/*,.pdf" name="attachment" id="customFile" onChange={this.handleChangeFile}/>
                                        <label className="custom-file-label" htmlFor="customFile">{attachName ? attachName : t('Choose file')}</label>
                                    </div>
                                    {attachment === "" ? null : <img alt="" className="Img_Bank_Wire_Transfer" src={"data:image/jpeg;base64,"+attachment} />}
                                </div>
                                <div className="m-form__group">
                                    <button className="btn btn-success m-btn m-btn--custom m-btn--icon m--align-right" onClick={this.handleBankTransfer}>{t('Make a payment')}</button>
                                </div>
                            </div>
                        </div>
                    </div>                   
                </div>
                <ToastContainer 
                    ref="toastContainer"
                    className="toast-top-right"
                />
                {showLoading && <LoadingPage />}
                {choseMethod_tab?<div className="m-header-overlay" onClick={this.changeOverlayChoseMethod_tab.bind(this)}></div>:null}
            </div>
        );    
	}
}

export default Payment;
