import React from 'react';
import RTRow from './RTRow';

class RTBody extends React.Component {
  
    render(){
        const {config, datasource, meta} = this.props;
        return(
            <tbody className="m-datatable__body">
                {
                    datasource.map((data,idx) => {
                        let startIdx = (meta.currentPage - 1) * meta.perPage;
                        let endIdx = startIdx + (meta.perPage - 1);
                        if((idx < startIdx || idx > endIdx )&& config.api === false) return null;
                        return (
                            <RTRow key={idx} idx={idx} record={data} cols={config.cols}/>
                        )
                    })
                }
            </tbody>
        );
    }
}

export default RTBody;